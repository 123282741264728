import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import {
  SharingModal,
  SharingModalBody,
  SharingModalControls,
} from './WishlistShareContent.styled';

interface IWishlistShareNotAvailableModalProps {
  onClose(): void;
}

export default function WishlistShareNotAvailableModal({
  onClose,
}: IWishlistShareNotAvailableModalProps) {
  const [t] = useTranslation('wishlist');

  return (
    <SharingModal
      show={true}
      name={'wishlist-sharing-not-available-modal'}
      title={t('wishlist:share.notAvailable.title')}
      onClose={onClose}
      controls={
        <SharingModalControls>
          <div />
          <Button onClick={onClose}>{t('common:cancel')}</Button>
        </SharingModalControls>
      }
    >
      <SharingModalBody>
        <div className="sharing-text">
          {t('wishlist:share.notAvailable.text')}
        </div>
      </SharingModalBody>
    </SharingModal>
  );
}
