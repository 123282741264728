import styled from 'styled-components';
import { MapPin } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ProductPin } from 'components/domain/map/Map.types';
import MapProductCard from 'components/domain/map/MapProductCard/MapProductCard';
import MapProductCardSkeleton from 'components/domain/map/MapProductCard/MapProductCard.skeleton';
import useMapProductCard from './useMapProductCard';

const DesktopMapPin = styled(MapPin<ProductPin>)(
  sx2CssThemeFn({
    '.map-pin-content': {
      bottom: 'initial',
      top: '9px',
      cursor: 'auto',
    },

    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '20px 0 0 20px',
      borderColor: 'transparent transparent transparent white',
      bottom: '-16px',
      left: '50%',
      ml: '-20px',
    },
  })
);

interface IPdpMapProductCardProps {
  selectedProduct: ProductPin;
  onCloseCard: () => void;
  className?: string;
}

const PdpMapProductCardsDesktop: React.FC<IPdpMapProductCardProps> = (
  props: IPdpMapProductCardProps
) => {
  const { selectedProduct, onCloseCard, className } = props;
  const { cards, loading } = useMapProductCard(selectedProduct);

  if (!cards) {
    return null;
  }

  return (
    <>
      {cards.map(card => (
        <DesktopMapPin
          coordinates={selectedProduct.coordinates}
          object={selectedProduct}
          key={`c-${card.giata}`}
        >
          {loading ? (
            <MapProductCardSkeleton />
          ) : (
            <MapProductCard
              product={card}
              onClose={onCloseCard}
              className={className}
            />
          )}
        </DesktopMapPin>
      ))}
    </>
  );
};

export default PdpMapProductCardsDesktop;
