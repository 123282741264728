import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlDestinationGroupsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  searchCriteria: Types.SrlSearchCriteriaInput;
}>;

export type GetSrlDestinationGroupsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      destinationGroup: {
        __typename?: 'SrlDestinationGroupsResult';
        continentGroupItems: Array<{
          __typename?: 'ContinentGroupItem';
          id: string;
          name: string;
          countryGroupItems?: Array<{
            __typename?: 'CountryGroupItem';
            id: string;
            name: string;
            productCount: number;
            destinations: Array<{
              __typename?: 'DestinationGroupItem';
              id: string;
              name: string;
              productCount: number;
            } | null>;
          } | null> | null;
        }>;
      };
    };
  };
};

export type DestinationGroupFragment = {
  __typename?: 'SrlDestinationGroupsResult';
  continentGroupItems: Array<{
    __typename?: 'ContinentGroupItem';
    id: string;
    name: string;
    countryGroupItems?: Array<{
      __typename?: 'CountryGroupItem';
      id: string;
      name: string;
      productCount: number;
      destinations: Array<{
        __typename?: 'DestinationGroupItem';
        id: string;
        name: string;
        productCount: number;
      } | null>;
    } | null> | null;
  }>;
};

export type ContinentGroupItemFragment = {
  __typename?: 'ContinentGroupItem';
  id: string;
  name: string;
  countryGroupItems?: Array<{
    __typename?: 'CountryGroupItem';
    id: string;
    name: string;
    productCount: number;
    destinations: Array<{
      __typename?: 'DestinationGroupItem';
      id: string;
      name: string;
      productCount: number;
    } | null>;
  } | null> | null;
};

export type CountryGroupItemFragment = {
  __typename?: 'CountryGroupItem';
  id: string;
  name: string;
  productCount: number;
  destinations: Array<{
    __typename?: 'DestinationGroupItem';
    id: string;
    name: string;
    productCount: number;
  } | null>;
};

export type DestinationGroupItemFragment = {
  __typename?: 'DestinationGroupItem';
  id: string;
  name: string;
  productCount: number;
};

export const DestinationGroupItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'destinationGroupItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DestinationGroupItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CountryGroupItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'countryGroupItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CountryGroupItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'destinationGroupItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DestinationGroupItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const ContinentGroupItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'continentGroupItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContinentGroupItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryGroupItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'countryGroupItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CountryGroupItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const DestinationGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'destinationGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlDestinationGroupsResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continentGroupItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'continentGroupItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ContinentGroupItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetSrlDestinationGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLDestinationGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchCriteriaInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destinationGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'destinationGroup' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DestinationGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlDestinationGroupsQuery__
 *
 * To run a query within a React component, call `useGetSrlDestinationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlDestinationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlDestinationGroupsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetSrlDestinationGroupsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlDestinationGroupsQuery,
    GetSrlDestinationGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlDestinationGroupsQuery,
    GetSrlDestinationGroupsQueryVariables
  >(GetSrlDestinationGroupsDocument, options);
}
export function useGetSrlDestinationGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlDestinationGroupsQuery,
    GetSrlDestinationGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlDestinationGroupsQuery,
    GetSrlDestinationGroupsQueryVariables
  >(GetSrlDestinationGroupsDocument, options);
}
export type GetSrlDestinationGroupsQueryHookResult = ReturnType<
  typeof useGetSrlDestinationGroupsQuery
>;
export type GetSrlDestinationGroupsLazyQueryHookResult = ReturnType<
  typeof useGetSrlDestinationGroupsLazyQuery
>;
export type GetSrlDestinationGroupsQueryResult = Apollo.QueryResult<
  GetSrlDestinationGroupsQuery,
  GetSrlDestinationGroupsQueryVariables
>;
