import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ProductPin } from 'components/domain/map/Map.types';
import MapProductCard from 'components/domain/map/MapProductCard/MapProductCard';
import MapProductCardSkeleton from 'components/domain/map/MapProductCard/MapProductCard.skeleton';
import useMapProductCard from './useMapProductCard';

const MobileCardWrapper = styled.div(
  sx2CssThemeFn({
    pt: 2,
  })
);

interface IPdpMapProductCardProps {
  selectedProduct: ProductPin;
  onCloseCard: () => void;
}

const PdpMapProductCardsMobile: React.FC<IPdpMapProductCardProps> = (
  props: IPdpMapProductCardProps
) => {
  const { selectedProduct, onCloseCard } = props;
  const { cards, loading } = useMapProductCard(selectedProduct);

  if (loading) {
    // todo: add skeleton
    return <MapProductCardSkeleton />;
  }

  if (!cards) {
    return null;
  }

  return (
    <MobileCardWrapper>
      {cards.map(card => (
        <MapProductCard
          key={`c-${card.giata}`}
          product={card}
          onClose={onCloseCard}
        />
      ))}
    </MobileCardWrapper>
  );
};

export default PdpMapProductCardsMobile;
