import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const WishlistContainer = styled.div<{ isLegacyDesign?: boolean }>(
  ({ isLegacyDesign }) =>
    sx2CssThemeFn({
      mt: isLegacyDesign ? [null, 2] : [4, '36px'],
      border: [null, '1px solid'],
      borderColor: [null, 'borderGray'],
      borderRadius: [null, '5px 5px 0 0'],
    })
);

export default WishlistContainer;
