import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistIconWrapper = styled.div<{
  isActive?: boolean;
}>(({ isActive }) =>
  sx2CssThemeFn({
    position: 'relative',
    width: '45px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: [isActive ? '45px' : '30px', '45px'],
      height: [isActive ? '45px' : '30px', '45px'],
      backgroundColor: isActive ? 'secondary' : 'backgroundDarkGray',
      borderRadius: '50%',
      zIndex: '0',
    },
    '.count': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: ['12px', 0],
      zIndex: '1',
    },
  })
);

export const WishlistHeartIcon = styled(Icon)<{
  isActive?: boolean;
}>(({ isActive }) => {
  return sx2CssThemeFn({
    width: [isActive ? '45px' : '25px', '23px'],
    height: [isActive ? '45px' : '25px', '23px'],
    p: '2px',
    color: isActive ? 'secondary' : '#eceaea',
  });
});
