import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH } from '@hotelplan/libs.auth-next';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';
import { useGetAccountPersonalDataQuery } from 'graphql/my-account/GetAccountPersonalData.generated';
import LoginLink from './LoginLink';
import {
  LoginLinkOverlayWrapper,
  LogoutButton,
  LogoutSectionWrapper,
} from './LoginLink.styled';

export default function LoginLinkOverlay({
  children,
}: PropsWithChildren): React.ReactElement {
  const { logout, channelType } = useAuthentication();
  const { push } = useRouter();
  const { isEnabled } = useFeatureToggle();
  const [t] = useTranslation('myaccount');
  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  const logoutHandler = useCallback(async () => {
    if (isMeinKontoEnabled) {
      push(SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH);
    } else {
      await logout();
      window.location.pathname = '/';
    }
  }, [isMeinKontoEnabled, logout]);

  const isAuthenticated = useMemo(
    () => channelType === AuthChannelType.B2C,
    [channelType]
  );

  const { data, loading } = useGetAccountPersonalDataQuery({
    skip: !isAuthenticated,
  });

  return (
    <LoginLinkOverlayWrapper>
      <div className="auth-buttons">
        {!isAuthenticated ? (
          <LoginLink />
        ) : (
          <LogoutSectionWrapper>
            {loading ? (
              // TODO: Add skeleton for authorizes user data
              <span>Loading...</span>
            ) : (
              <div className={'user-data'}>
                <span className={'user-account'}>
                  {t('myaccount:user.account')}
                </span>
                <span className={'email'}>
                  {data?.account.personalData.email}
                </span>
              </div>
            )}
            <LogoutButton onClick={logoutHandler} data-id="logout-btn">
              {t('logout')}
            </LogoutButton>
          </LogoutSectionWrapper>
        )}
      </div>

      <div className="content">{children}</div>
    </LoginLinkOverlayWrapper>
  );
}
