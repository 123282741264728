import { useTranslation } from 'next-i18next';
import React from 'react';
import { ProductClass, ProductType } from '@hotelplan/graphql.types';
import { mapDurationToWeeks, getFormattedTravelType } from 'libs';

interface ITripInfoProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  type: ProductType;
  duration: number;
  mealType: string;
  productClass: ProductClass;
}

const TripInfo: React.FC<ITripInfoProps> = (props: ITripInfoProps) => {
  const { type, duration, mealType, productClass, ...restProps } = props;
  const [t] = useTranslation();

  return (
    <div {...restProps}>
      {t(getFormattedTravelType(productClass, type))},{' '}
      {mapDurationToWeeks(duration, t)}
      {mealType && `, ${mealType}`}
    </div>
  );
};
export default TripInfo;
