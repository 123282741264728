import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Status } from '@hotelplan/graphql.types';
import {
  TPossibleMutation,
  getResult,
  isInWishlist,
  removeWishlistActiveCache,
  updateOffer,
} from 'components/domain/wishlist/utils';
import { useGetActiveWishlistLazyQuery } from 'graphql/wishlist/queries/GetActiveWishlist.generated';
import { useGetWishlistHeaderLinkLazyQuery } from 'graphql/wishlist/queries/GetWishlistHeaderLink.generated';
import { useGetWishlistItemLazyQuery } from 'graphql/wishlist/queries/GetWishlistItem.generated';
import useAddToWishlist from './useAddToWishlist';
import useRemoveFromWishlist from './useRemoveFromWishlist';
import useWishlistOfferManage from './useWishlistOfferManage';

export default function useWishlistOffer(
  offerId: string,
  inWishlist?: boolean
) {
  const client = useApolloClient();

  const [isActive, seIstActive] = useState(inWishlist);
  const cacheManageRef = useWishlistOfferManage();

  const [getWishlist, { data: refreshedData }] = useGetWishlistItemLazyQuery();
  const [queryActiveWishlist] = useGetActiveWishlistLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [queryWishlistHeaderLink] = useGetWishlistHeaderLinkLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (refreshedData) {
      seIstActive(isInWishlist(refreshedData?.wishlistItem, offerId));
    }
  }, [refreshedData]);

  useEffect(() => {
    if (offerId && inWishlist === undefined) {
      getWishlist({ variables: { id: offerId } });
    }
  }, [offerId]);

  function onCompleted(data: TPossibleMutation) {
    const result = getResult(data);
    if (result.status !== Status.Success) {
      // eslint-disable-next-line no-console
      console.error(result.message);
      return;
    }

    removeWishlistActiveCache(client.cache);
    queryActiveWishlist();
    queryWishlistHeaderLink();
    updateOffer(data, cacheManageRef, offerId);
    getWishlist({ variables: { id: offerId } });
  }

  const [add, adding] = useAddToWishlist(offerId, onCompleted);
  const [remove, deleting] = useRemoveFromWishlist(offerId, onCompleted);

  async function onToggle() {
    await (isActive ? remove() : add());
  }

  return { isActive, onToggle, loading: adding || deleting };
}
