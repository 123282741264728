import { useGoogleMap } from '@react-google-maps/api';
import React, { useEffect, useMemo, useRef } from 'react';
import { MapProvider } from '@hotelplan/components.common.map';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import PdpMapProducts from 'components/domain/pdp/map/PdpMapProducts';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import { useWishlistMapConfig } from 'components/domain/wishlist/hooks';
import { SrlMapProductPinFragment } from 'graphql/srl/map/SrlMapProductPin.generated';
import { MapContainer, MapContainerWrapper } from './WishlistMap.styled';

export default function WishlistMap(): React.ReactElement {
  const mapConfig = useWishlistMapConfig();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapSize = useElementSize(mapContainerRef);

  const mapProviderProps = useMemo(
    () => ({
      styles: {
        position: 'absolute',
        width: `${mapSize.w}px`,
        height: `${mapSize.h}px`,
      } as React.CSSProperties,
    }),
    [mapSize.h, mapSize.w]
  );

  return (
    <MapContainerWrapper>
      <MapContainer ref={mapContainerRef}>
        <MapProvider
          {...mapProviderProps}
          zoom={mapConfig.zoom}
          options={mapConfig.options}
        >
          <WishlistMapPins />
        </MapProvider>
      </MapContainer>
    </MapContainerWrapper>
  );
}

function WishlistMapPins(): React.ReactElement {
  const map = useGoogleMap();
  const { items, itemsLoading: loading } = useWishlistSelectedItemsContext();

  const mapItems: SrlMapProductPinFragment[] = useMemo(
    () =>
      items?.map(({ product, offer }) => {
        const isBestseller = product.featureSummary.some(
          feature => feature.id === 'bestseller'
        );

        return {
          id: product.id,
          name: product.name,
          price: offer.pricePerPerson,
          top: false,
          zoom: product.zoom,
          coordinates: product.coordinates,
          bestseller: isBestseller,
        };
      }) || [],
    [items]
  );

  useEffect(
    function centerPin() {
      if (map && !loading && mapItems.length) {
        map.setCenter(mapCoordinatesToLatLng(mapItems[0].coordinates));
      }
    },
    [map, mapItems, loading]
  );

  return <PdpMapProducts products={mapItems} />;
}
