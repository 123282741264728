import { IRouterLinkProps } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';

interface ILink {
  label: string;
  routerProps: IRouterLinkProps;
  pageType: PageType;
}

interface IOptions {
  hiddenQueryParams?: Record<string, string | undefined>;
}

export default function mapPageTypeTableToLinksList(
  linksTable: Record<string, string>,
  locale: string,
  options: IOptions = {}
): Array<ILink> {
  return Object.keys(linksTable).reduce((acc, pageType: PageType) => {
    const route = getRouteByPageTypeLocale(pageType, locale);

    acc.push({
      label: linksTable[pageType],
      routerProps: getTargetPageData(
        {
          targetPageType: route.pageType,
          uri: route.builder({}),
        },
        { ...options.hiddenQueryParams }
      ),
      pageType,
    });

    return acc;
  }, []);
}
