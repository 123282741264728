import { MutableRefObject, useMemo, useRef, useState } from 'react';
import type { ISelectableItem } from '@hotelplan/components.common.map-pin';
import { useElementSize } from '@hotelplan/libs.hooks-dom';

export default function useMapForm<TPin>() {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapSize = useElementSize(mapContainerRef);

  const mapProviderProps = useMemo(() => {
    const styles: React.CSSProperties = {
      position: 'absolute',
      width: `${mapSize.w}px`,
      height: `${mapSize.h}px`,
    };

    return { styles };
  }, [mapSize.h, mapSize.w]);

  const selectedMapObjectRef = useRef<ISelectableItem | null>(
    null
  ) as MutableRefObject<ISelectableItem | null>;

  const [selectedObjects, setSelectedObjects] = useState<Array<TPin>>([]);

  const onSelectObjects = (
    objects: Array<TPin>,
    selectableMapItem: ISelectableItem
  ): void => {
    selectedMapObjectRef.current?.deselect();
    selectedMapObjectRef.current = selectableMapItem;
    selectableMapItem.select();
    setSelectedObjects(objects);
  };

  const deselect = (): void => {
    selectedMapObjectRef.current?.deselect();
    selectedMapObjectRef.current = null;
    setSelectedObjects([]);
  };

  return {
    mapContainerRef,
    mapProviderProps,
    selectedObjects,
    onSelectObjects,
    deselect,
  };
}
