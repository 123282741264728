import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import {
  ProductFeatureGroup,
  WishlistProductItem,
} from '@hotelplan/graphql.types';

const UNIQUE_FEATURE_FIELD = 'id';
const EXCLUDED_IDS = ['all-inclusive', 'bestseller'];

export default function useCommonFeatureGroups(
  selectedItems: Array<WishlistProductItem>
) {
  // NOTE: Collect the list of ALL different feature groups and features through all selected wishlist items.
  return useMemo(() => {
    return selectedItems.reduce(
      (groups: ProductFeatureGroup[], wishlistItem) => {
        if (!wishlistItem) return groups;

        const groupsToAdd = wishlistItem.product.featureGroups
          .filter(featureGroup => !EXCLUDED_IDS.includes(featureGroup.id))
          .map(featureGroup => {
            const existedGroupId = groups.findIndex(
              g => g.id === featureGroup.id
            );

            if (existedGroupId !== -1) {
              const features = uniqBy(
                [...groups[existedGroupId].features, ...featureGroup.features],
                UNIQUE_FEATURE_FIELD
              );

              const groupToAdd = {
                ...groups[existedGroupId],
                features,
              };

              groups.splice(existedGroupId, 1);

              return { ...groupToAdd };
            } else {
              return { ...featureGroup };
            }
          });

        return [...groups, ...groupsToAdd];
      },
      []
    );
  }, [selectedItems]);
}
