import { useGetWishlistActiveOfferQuery } from 'graphql/wishlist/queries/GetWishlistActiveOffer.generated';
import { useGetWishlistItemQuery } from 'graphql/wishlist/queries/GetWishlistItem.generated';

export default function useWishlistProductActiveOffer(productId: string) {
  const { data, loading } = useGetWishlistActiveOfferQuery({
    variables: { productId },
    ssr: false,
  });

  const offer = data?.wishlist.activeOffer.items?.[0]?.offer;

  const { data: wishlistItemData, loading: cacheLoading } =
    useGetWishlistItemQuery({
      variables: { id: offer?.id || '' },
      skip: !data,
    });

  const active = Boolean(wishlistItemData?.wishlistItem?.inWishlist);

  return {
    offer: active ? offer : undefined,
    loading: loading || cacheLoading,
    active,
  };
}
