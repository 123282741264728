import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { CountryGroupItemFragment } from 'graphql/srl/GetSRLDestinationGroups.generated';
import CountryGroupCheckboxes from './CountryGroupCheckboxes';
import { TDestination } from './TravelDestination.types';

const CheckboxesAreaWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: [null, '650px'],
    maxHeight: [null, '300vh'],
    overflowX: 'hidden',
    py: 4,
    gap: 2,
    '.cities': {
      mb: 2,
    },
    '.product-count': {
      fontSize: '13px',
      ml: 1,
    },
    '.cities-count': {
      ml: 1,
    },
    '.label': {
      '.city-name, .country-name, .product-count, .cities-count': {
        color: 'textGray',
      },
      '.city-name, .cities-count': {
        fontSize: 0,
      },
      '.country-name': {
        fontWeight: [null, 'bold'],
        fontSize: ['14px', 1],
      },
    },
  })
);

const ContentWrapper = styled.div(
  sx2CssThemeFn({
    display: [null, 'flex'],
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    px: [2, 4],
    borderTop: '1px solid #dedede',
    flex: 1,
  })
);

interface IContinentGroupContentProps {
  activeCountryGroupItems: CountryGroupItemFragment[];
  checkedDestinations: TDestination[];
  onChangeDestinations(
    nextValue: string,
    currentCountry: TDestination,
    currentCities: TDestination[]
  ): void;
  checkedDestinationsComponent?: React.ReactNode;
}

export default function CountryGroupCheckboxesArea({
  activeCountryGroupItems,
  checkedDestinations,
  onChangeDestinations,
  checkedDestinationsComponent,
}: IContinentGroupContentProps): React.ReactElement {
  return (
    <ContentWrapper>
      <CheckboxesAreaWrapper>
        {activeCountryGroupItems.map((country, index) => {
          return (
            <CountryGroupCheckboxes
              key={`${country.id}-${index}`}
              country={country}
              checked={checkedDestinations}
              onChangeDestinations={onChangeDestinations}
            />
          );
        })}
      </CheckboxesAreaWrapper>
      {checkedDestinationsComponent}
    </ContentWrapper>
  );
}
