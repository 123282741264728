import { useTranslation } from 'next-i18next';
import React from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FeatureTitle } from './Filters.styles';
import { ICommonFilterProps, TFilterCountsOptions } from './Filters.types';
import ProductFeatures from './ProductFeatures';
import SearchFilterInputField from './SearchFilterInputField';

export interface IProductFeaturesFilterField extends ICommonFilterProps {
  counts: TFilterCountsOptions;
}

export default function ProductFeaturesFilterField(
  props: IProductFeaturesFilterField
): React.ReactElement {
  const { formFieldName, filterCaption, options, counts } = props;

  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');

  const [value, onChange] = useField<string[]>(formFieldName);
  const [showFeatures, , , toggle] = useToggleState(!mobile);

  return (
    <>
      {!mobile ? (
        <FeatureTitle>{filterCaption}</FeatureTitle>
      ) : (
        <SearchFilterInputField
          name={t('product.features.field.title')}
          onClick={toggle}
          icon={{
            name: showFeatures ? 'minus' : 'plus',
          }}
        />
      )}
      {showFeatures ? (
        <ProductFeatures
          options={options}
          formValue={value}
          counts={counts}
          formOnChange={onChange}
        />
      ) : null}
    </>
  );
}
