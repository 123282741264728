import { WishlistProductItem } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  COMPARE_ITEMS_COUNT_DESKTOP,
  COMPARE_ITEMS_COUNT_MOBILE,
} from 'components/domain/wishlist/utils';

type SelectOptionsType = {
  value: string;
  label: string;
};

export default function useWishlistCompareOptions(
  items: WishlistProductItem[]
): Array<SelectOptionsType[]> {
  const { mobile } = useDeviceType();

  const pureOptions =
    items?.map(it => {
      return { value: it.product.id, label: it.product.name };
    }) || [];

  return Array.from({
    length: mobile ? COMPARE_ITEMS_COUNT_MOBILE : COMPARE_ITEMS_COUNT_DESKTOP,
  }).map((_, index) => {
    if (items.length <= index) return [];
    return pureOptions;
  });
}
