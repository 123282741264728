import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddSharedWishlistMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AddSharedWishlistMutation = {
  __typename?: 'Mutation';
  addSharedWishlist: {
    __typename?: 'WishlistMutationResponse';
    status: Types.Status;
    message?: string | null;
    activeChanged: boolean;
    sharedAvailable?: boolean | null;
  };
};

export type AddWishlistFragment = {
  __typename?: 'WishlistMutationResponse';
  status: Types.Status;
  message?: string | null;
  activeChanged: boolean;
  sharedAvailable?: boolean | null;
};

export const AddWishlistFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'addWishlist' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistMutationResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChanged' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedAvailable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AddSharedWishlistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSharedWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSharedWishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wishlistId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'addWishlist' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AddWishlistFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export type AddSharedWishlistMutationFn = Apollo.MutationFunction<
  AddSharedWishlistMutation,
  AddSharedWishlistMutationVariables
>;

/**
 * __useAddSharedWishlistMutation__
 *
 * To run a mutation, you first call `useAddSharedWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSharedWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSharedWishlistMutation, { data, loading, error }] = useAddSharedWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddSharedWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSharedWishlistMutation,
    AddSharedWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddSharedWishlistMutation,
    AddSharedWishlistMutationVariables
  >(AddSharedWishlistDocument, options);
}
export type AddSharedWishlistMutationHookResult = ReturnType<
  typeof useAddSharedWishlistMutation
>;
export type AddSharedWishlistMutationResult =
  Apollo.MutationResult<AddSharedWishlistMutation>;
export type AddSharedWishlistMutationOptions = Apollo.BaseMutationOptions<
  AddSharedWishlistMutation,
  AddSharedWishlistMutationVariables
>;
