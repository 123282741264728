import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { Image, INormalizedImage } from '@hotelplan/components.common.image';
import RouterLink from '@hotelplan/components.common.router-link';
import {
  DisrupterObject,
  GeoObject,
  ProductClass,
  ProductFeatureGroup,
  ProductType,
} from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import ProductFeatures from 'components/core/product/ProductFeatures';
import ProductPrice from 'components/core/product/ProductPrice';
import ProductRating from 'components/core/product/ProductRating';
import ProductTripAdvisorInfo from 'components/core/product/ProductTripAdvisorInfo';
import ProductTripInfoAndTransferGroup from 'components/core/product/ProductTripInfoAndTransferGroup';
import ProductTypeLabel from 'components/core/product/ProductTypeLabel';
import { Disrupter, EDisrupterPlacement } from 'components/domain/disrupter';
import { PriceFragment } from 'graphql/fragments/Price.generated';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import useProductFeatures from './useProductFeatures';

const SRLProductItemWrapper = styled.a.attrs({
  className: 'product-card',
})<{
  isBestseller: boolean;
  isHolidayHome: boolean;
}>(
  sx2CssThemeFn(({ isBestseller, isHolidayHome, theme: { media } }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:
      isHolidayHome && !isBestseller
        ? '#fcc'
        : isBestseller
        ? '#ffe0cc'
        : 'white',
    mb: '12px',
    transition: 'fast',
    '&:hover': {
      backgroundColor:
        isHolidayHome && !isBestseller
          ? '#fcc'
          : isBestseller
          ? '#ffe0cc'
          : '#eee',
      button: {
        display: 'flex',
      },
    },
    '.hotel-name': {
      width: ['85%', '360px'],
      color: 'secondary',
      fontSize: ['14px', '28px'],
      fontFamily: '"Helvetica Neue", sans-serif',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.product-content': {
      position: 'relative',
      overflow: 'hidden',
      pt: [1, 3],
      pl: [2, 3],
      flex: 1,
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      borderRight: [null, '1px solid #ccc'],
      display: 'flex',
      flexDirection: 'column',
      mr: [null, '-1px'],
      '.from-to': {
        fontSize: ['12px', 0],
        mb: 1,
      },
    },
    '.product-image-wrapper': {
      position: 'relative',
      flex: ['45% 0 0', '314px 0 0'],
    },
    '.product-image': {
      borderRadius: ['0px', '0 5px 0 5px'],
      height: '100%',
    },
    '.breadcrumbs': {
      fontSize: ['12px', '14px'],
      fontWeight: 'bold',
      mb: 2,
      mr: 2,
    },
    '.trip-info': {
      fontSize: 0,
      mt: [0, 2],
    },
    '.offer-transfer': {
      mt: [1, 2],
    },
    '.group-price': {
      position: ['relative', 'absolute'],
      right: 0,
      bottom: [0, '25px'],
      textAlign: 'right',
      minWidth: '125px',
      mt: 'auto',
      [media.mobile]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  }))
);

const ProductImage = styled(Image)(
  sx2CssThemeFn({
    width: ['100%', '314px'],
    height: ['auto', '205px'],
    objectFit: 'cover',
    display: 'block',
  })
);

const BestsellerLabel = styled(ProductTypeLabel)(
  sx2CssThemeFn({
    backgroundColor: 'secondary',
    pl: 1,
    '&:after': {
      borderColor: 'transparent transparent transparent #a44809',
    },
  })
);

const HolidayHomeLabel = styled(ProductTypeLabel)(
  sx2CssThemeFn({
    backgroundColor: '#f00',
    '&:after': {
      borderColor: 'transparent transparent transparent #ad0000',
    },
  })
);

const ProductFeaturesWrapper = styled(ProductFeatures)(
  sx2CssThemeFn({
    position: 'absolute',
    bottom: 0,
    pb: 1,
    pl: 2,
    '.featureGroupWrapper': {
      ml: 0,
      mr: 2,
      mb: 1,
    },
    '.feature-summary-item-icon': {
      color: 'secondary',
      stroke: 'white',
      strokeWidth: '4px',
      paintOrder: 'stroke',
      width: [null, '30px'],
      height: [null, '30px'],
    },
  })
);

const ProductTripInfoAndTransferGroupWrapper = styled(
  ProductTripInfoAndTransferGroup
)(
  sx2CssThemeFn({
    maxWidth: ['initial', '240px'],
    '.trip-info': {
      color: 'textGray',
      fontSize: ['12px', 0],
    },
  })
);

const PerPersonPrice = styled(ProductPrice)(
  sx2CssThemeFn({
    mr: 2,
    mb: [1, 2],
    pt: [1, 0],
    '.title': {
      color: 'textGray',
    },
    '.value': {
      color: 'secondary',
    },
  })
);

const TotalPrice = styled(ProductPrice)(
  sx2CssThemeFn({
    mr: 2,
    mb: 1,
    '.title, .value': {
      color: '#999',
    },
  })
);

const ShowDetailsButton = styled(Button)(
  ({ theme: { buttons, media } }) => ({
    [media.mobile]: {
      ...buttons.iconBtn,
    },
    [media.tablet]: {
      ...buttons.primary,
      ...buttons.btnWithIcon,
    },
  }),
  sx2CssThemeFn(({ theme: { media } }) => ({
    [media.mobile]: {
      '.icon': {
        color: 'primary',
        mr: 0,
      },
    },
    p: ['0px 6px', '1px 8px'],
    display: ['flex', 'none'],
    position: 'absolute',
    bottom: 0,
    right: 0,
    transition: [null, 'fast'],
    textTransform: 'uppercase',
  }))
);

export const ProductTravelType = styled.span(
  ({ theme: { fontSizes, media } }) => ({
    fontSize: fontSizes[0],
    [media.mobile]: {
      fontSize: '12px',
    },
  })
);

export interface ISrlProductItemProps {
  image: INormalizedImage;
  hpRating?: number;
  name: string;
  geoBreadcrumbs: Array<GeoObject>;
  taRating?: number;
  taReviews?: number;
  type: ProductType;
  mealType: string;
  duration: number;
  transferIncluded?: boolean;
  transferPrice?: PriceFragment;
  price: PriceFragment;
  totalPrice: PriceFragment;
  link: InternalLinkFragmentFragment;
  featureSummary?: Array<ProductFeatureGroup>;
  productClass: ProductClass;
  detailsButtonText?: string;
  page?: number;
  geoObjectId?: string;
  fromTo?: string;
  travelType?: string;
  disrupterObject?: DisrupterObject;
  onClick?(): void;
}

const SrlProductItem: React.FC<ISrlProductItemProps> = (
  props: ISrlProductItemProps
) => {
  const {
    image,
    hpRating,
    name,
    geoBreadcrumbs,
    taRating,
    taReviews,
    type,
    mealType,
    duration,
    transferIncluded,
    transferPrice,
    price: pricePerPerson,
    totalPrice,
    link,
    featureSummary,
    productClass,
    detailsButtonText = 'search:search.hotel.showDetails',
    page,
    geoObjectId,
    fromTo,
    travelType,
    disrupterObject,
    onClick,
  } = props;

  const [t] = useTranslation(['common', 'search']);
  const { mobile } = useDeviceType();

  const routerProps = useMemo(
    () =>
      getTargetPageData(
        link,
        page || geoObjectId
          ? {
              page: page.toString(),
              geoObjectId,
            }
          : null
      ),
    [link]
  );

  const breadCrumbItems = useMemo(
    () => geoBreadcrumbs?.map(item => item.name).join(' | ') || '',
    [geoBreadcrumbs]
  );

  const { features, isBestseller } = useProductFeatures(featureSummary);
  const isHolidayHome = productClass === ProductClass.Apartment;
  const isRoundtrip = productClass === ProductClass.Roundtrip;

  // todo: define holiday_home source
  return (
    <RouterLink {...routerProps} passHref>
      <SRLProductItemWrapper
        isBestseller={isBestseller}
        isHolidayHome={isHolidayHome}
        onClick={onClick}
      >
        <div className="product-image-wrapper">
          {disrupterObject ? (
            <Disrupter
              placement={EDisrupterPlacement.CARD}
              {...disrupterObject}
            />
          ) : null}
          <ProductImage className="product-image" {...image} />
          {isBestseller && (
            <BestsellerLabel>
              <Icon name="map-bestseller" size="lg" />
              {t('search:search.hotel.bestseller')}
            </BestsellerLabel>
          )}
          {/* show holiday home label only if bestseller is false */}
          {isHolidayHome && !isBestseller && (
            <HolidayHomeLabel>
              <Icon name="home-menu" size="sm" />
              {t('search:search.hotel.holidayHome')}
            </HolidayHomeLabel>
          )}
          {!mobile && <ProductFeaturesWrapper featuresSummary={features} />}
        </div>
        <div className="product-content">
          {!isRoundtrip && <ProductRating rating={hpRating} />}
          <h4 className="hotel-name">{name}</h4>
          <p className="breadcrumbs">{breadCrumbItems}</p>
          {!mobile && (
            <ProductTripAdvisorInfo taRating={taRating} taReviews={taReviews} />
          )}
          <ProductTripInfoAndTransferGroupWrapper
            type={type}
            mealType={!isRoundtrip ? mealType : ''}
            duration={duration}
            transferIncluded={transferIncluded}
            transferPrice={transferPrice}
            productClass={productClass}
          />
          {travelType && <ProductTravelType>{travelType}</ProductTravelType>}
          {fromTo ? <span className="from-to">{fromTo}</span> : null}
          <div className="group-price">
            <PerPersonPrice
              title={t('common:price.perPerson')}
              price={pricePerPerson}
            />
            {!mobile && (
              <TotalPrice title={t('common:price.total')} price={totalPrice} />
            )}
          </div>
          <ShowDetailsButton
            variant={mobile ? 'iconBtn' : 'primary'}
            icon={{ name: 'chevron-long-right', size: mobile ? 'sm' : 'md' }}
          >
            {t(detailsButtonText)}
          </ShowDetailsButton>
        </div>
      </SRLProductItemWrapper>
    </RouterLink>
  );
};

export default SrlProductItem;
