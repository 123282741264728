import styled from 'styled-components';
import { FeaturesSummary } from '@hotelplan/components.common.feature';
import { ProductFeatureGroup } from '@hotelplan/graphql.types';
import { FeatureTooltipsProvider } from '@hotelplan/libs.context.feature-tooltips';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

interface IPageLeadFeaturesProps {
  featuresSummary: ProductFeatureGroup[];
  className?: string;
}

const FeaturesSummaryWrap = styled(FeaturesSummary)(
  sx2CssThemeFn({
    whiteSpace: 'nowrap',
    '.featureGroupWrapper': {
      ml: 2,
      mr: '2px',
    },
    '.feature-summary-item-icon': {
      variant: ['icons.lg', 'icons.xlg'],
    },
    '.tooltipArrow': {
      display: 'none',
    },
    '.tooltip': {
      bottom: '40px',
      maxHeight: 'none',
      overflow: 'visible',
      left: '-7px',
      p: 0,
      border: 'none',
      '&:before': {
        left: '20px',
      },
      '&:after': {
        display: 'block',
        left: '20px',
      },
    },
    '.feature-description': {
      display: 'flex',
      padding: '3px 10px 0 10px',
      borderLeftColor: 'lightgrey',
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderRightColor: 'lightgrey',
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      '&:nth-of-type(2)': {
        pt: '10px',
      },
      '&:last-of-type': {
        pb: '10px',
        borderColor: 'lightgrey',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderTop: 'none',
        borderRadius: '0 0 5px 5px',
      },
      '.icon': {
        width: '13px',
        height: '13px',
      },
    },
    '.feature-name': {
      fontSize: 0,
      color: 'textGray',
    },
    '.feature-group-name': {
      backgroundColor: 'secondary',
      p: '6px 12px',
      color: 'white',
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: '22px',
      lineHeight: '1.25',
      whiteSpace: 'nowrap',
      mb: '0',
      borderRadius: '5px 5px 0 0',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderBottom: 'none',
      borderColor: 'secondary',
    },
  })
);

const ProductFeatures: React.FC<IPageLeadFeaturesProps> = ({
  featuresSummary,
  className,
}: IPageLeadFeaturesProps) => {
  return (
    <FeatureTooltipsProvider>
      <FeaturesSummaryWrap
        groups={featuresSummary || []}
        className={className}
      />
    </FeatureTooltipsProvider>
  );
};

export default ProductFeatures;
