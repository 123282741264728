import React, { useEffect, useRef } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { IIconProps } from '@hotelplan/components.common.icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { ratingsIdsToNumberDict } from './Filters.common';
import { MainFilterCounts, RatingFilterWrapper } from './Filters.styles';
import { ICommonFilterProps, TFilterCountsOptions } from './Filters.types';
import { mapOptionsToRatingOptions } from './Filters.utils';
import RatingSymbols from './RatingSymbols';
import SearchFilterField from './SearchFilterField';
import SearchFilterRadioGroup from './SearchFilterRadioGroup';

interface IUnionRadioFilterFieldProps extends ICommonFilterProps {
  coloredIcon?: IIconProps;
  emptyIcon?: IIconProps;
  allOptionId?: string;
  counts?: TFilterCountsOptions;
}

export default function UnionRadioFilterField({
  options,
  filterCaption,
  formFieldName,
  filterTitle,
  coloredIcon,
  emptyIcon,
  className,
  allOptionId,
  icon,
  counts,
}: IUnionRadioFilterFieldProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [value, onChange] = useField<string>(formFieldName);
  const [checkedOption, setCheckedOption] = React.useState<string>(
    value || allOptionId
  );

  const ratingOptions = mapOptionsToRatingOptions(
    options,
    coloredIcon,
    emptyIcon
  );

  const rating = useRef<number>(ratingsIdsToNumberDict[checkedOption] || null);
  const textInputPlaceholderRef = useRef<string>(updateTextInputPlaceholder());

  function handleOnApply(): void {
    rating.current = ratingsIdsToNumberDict[checkedOption] || null;
    textInputPlaceholderRef.current = updateTextInputPlaceholder();
    onChange(checkedOption);
  }

  function updateTextInputPlaceholder() {
    if (checkedOption === allOptionId) return filterCaption;
    if (Object.keys(ratingsIdsToNumberDict).includes(checkedOption)) {
      return `${ratingOptions.find(o => o.id === checkedOption).caption}`;
    }
    return checkedOption;
  }

  useEffect(
    // On mobile form state should be updated on every checkbox change
    function updateFormState() {
      if (mobile) {
        handleOnApply();
      }
    },
    [checkedOption]
  );

  return (
    <RatingFilterWrapper>
      <SearchFilterField
        filterTitle={filterTitle}
        textInputPlaceholder={textInputPlaceholderRef.current}
        onApply={handleOnApply}
        className={className}
        icon={icon}
        renderRatingSymbols={
          rating.current
            ? ({ onClick }) => (
                <RatingSymbols
                  filterName={formFieldName}
                  rating={rating.current}
                  onClick={onClick}
                />
              )
            : null
        }
      >
        <SearchFilterRadioGroup
          options={ratingOptions}
          checkedOptions={[checkedOption]}
          onChange={setCheckedOption}
          checkIfDisabled={id =>
            !Boolean(
              counts?.options?.find(count => count.id === id)?.productCount
            )
          }
          getCounts={filterOptionId => {
            if (!counts) return null;

            return (
              <MainFilterCounts
                options={counts.options}
                loading={counts.loading}
                filterOptionId={filterOptionId}
              />
            );
          }}
        />
      </SearchFilterField>
    </RatingFilterWrapper>
  );
}
