import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { TDestination } from './TravelDestination.types';

const CheckedAreaWrapper = styled.div(
  sx2CssThemeFn({
    pt: 3,
    pb: 3,
    width: ['100%', '240px'],
    px: [2, 0],
    backgroundColor: ['#f2f2f2', 'white'],
    position: [null, 'sticky'],
    top: [null, '60px'],
    h4: {
      fontSize: ['18px', '26px'],
      fontWeight: 'bold',
      color: 'primary',
      pb: 2,
      borderBottom: [null, '1px solid #dedede'],
    },
    '.destination-line': {
      borderTop: ['none', '1px dotted #bcbcbc'],
      borderBottom: '1px dotted #bcbcbc',
      marginTop: '-1px',
      py: 2,
      fontSize: '14px',
      fontWeight: 'bold',
      width: '100%',
      position: 'relative',
      pl: 5,
      transition: 'fast',
      '&:hover': {
        backgroundColor: 'backgroundGray',
        '&:before': {
          transition: 'fast',
          backgroundImage: 'url("/images/svg/Plus-blue.svg")',
          transform: 'rotate(45deg)',
          width: '18px',
          height: '18px',
          top: '8px',
          left: '6px',
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '7px',
        display: 'block',
        width: '15px',
        height: '15px',
        backgroundImage: 'url("/images/svg/check-solid.svg")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      textAlign: 'start',
    },
    '.destination-tag': {
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: 'default',
      px: 2,
      py: 1,
      fontSize: '12px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      span: {
        display: 'flex',
        alignItems: 'center',
        '&:after': {
          content: '"✕"',
          color: 'primary',
          ml: 2,
        },
      },
    },
    '.apply-button': {
      mt: 3,
      width: ['100%', '170px'],
      'span, svg': {
        ml: 'auto',
      },
    },
  })
);

const DestinationsSection = styled.div(
  sx2CssThemeFn({
    display: ['flex', 'block'],
    flexWrap: 'wrap',
    maxHeight: [null, '454px'],
    overflowY: [null, 'auto'],
    gap: [2, 0],
  })
);

interface ICheckedDestinationsProps {
  checked: TDestination[];
  unCheckDestination?: (destination: TDestination) => void;
  applyButton?: React.ReactNode;
}

export default function CheckedDestinations({
  checked,
  unCheckDestination,
  applyButton,
}: ICheckedDestinationsProps): React.ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();
  return (
    <CheckedAreaWrapper>
      <h4>{t('selected.destinations.title')}</h4>
      <DestinationsSection>
        {!checked.length ? (
          <p className={mobile ? 'destination-tag' : 'destination-line'}>
            {t('any.destination')}
          </p>
        ) : (
          <>
            {checked.map((destination, index) => {
              return (
                <>
                  <button
                    key={`${destination}-${index}`}
                    className={mobile ? 'destination-tag' : 'destination-line'}
                    onClick={() => {
                      unCheckDestination(destination);
                    }}
                  >
                    <span>{destination.name}</span>
                  </button>
                </>
              );
            })}
          </>
        )}
      </DestinationsSection>
      {applyButton}
    </CheckedAreaWrapper>
  );
}
