import { refetchQueries } from 'components/domain/wishlist/utils';
import { useRemoveFromActiveWishlistMutation } from 'graphql/wishlist/mutations/RemoveFromActiveWishlist.generated';
import useWishlistCacheManage from './useWishlistCacheManage';

export default function useRemoveFromActiveWishlist() {
  const { update } = useWishlistCacheManage();

  const [mutation, { loading }] = useRemoveFromActiveWishlistMutation({
    refetchQueries,
  });

  async function removeFromActiveWishlist(offerId: string) {
    update(offerId, false);

    await mutation({
      variables: {
        offerId,
      },
    });
  }

  return {
    removeFromActiveWishlist,
    loading,
  };
}
