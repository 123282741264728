import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { FeatureList } from 'config/pageConfig.constants';
import { useGetWishlistHeaderLinkQuery } from 'graphql/wishlist/queries/GetWishlistHeaderLink.generated';

export default function useWishlistHeaderButton() {
  const { isEnabled } = useFeatureToggle();
  const [, { language }] = useTranslation();
  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  const { data: headerLinkData, loading } = useGetWishlistHeaderLinkQuery({
    ssr: false,
  });

  const myaccountWishlistLink = getRouteByPageTypeLocale(
    PageType.Wishlist,
    language
  );

  const myaccountWishlistRouterProps = myaccountWishlistLink
    ? getTargetPageData({
        targetPageType: myaccountWishlistLink.pageType,
        uri: myaccountWishlistLink.builder({}),
      })
    : null;

  const legacyWishlistLink = headerLinkData
    ? getTargetPageData(headerLinkData?.wishlist.url)
    : null;

  return useMemo(
    () => ({
      routerLinkProps: isMeinKontoEnabled
        ? myaccountWishlistRouterProps
        : legacyWishlistLink,
      wishlistProductsAmount: headerLinkData?.wishlist.productsAmount,
      loading,
    }),
    [headerLinkData, loading]
  );
}
