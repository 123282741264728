import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType, PageType, Status } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { useWishlistOverview } from 'components/domain/wishlist/hooks';
import { refetchQueries } from 'components/domain/wishlist/utils';
import { useAddSharedWishlistMutation } from 'graphql/wishlist/mutations/AddSharedWishlist.generated';
import { LoadingModal } from './WishlistShareContent.styled';
import WishlistShareNotAvailableModal from './WishlistShareNotAvailableModal';
import WishlistSharingModal from './WishlistSharingModal';

export default function WishlistShareContent(): React.ReactElement {
  const { push } = useRouter();
  const { id } = useObjectId();
  const [, { language }] = useTranslation();
  const { channelType } = useAuthentication();

  const [isAvailable, setAvailable] = useState(true);
  const [isTransferring, setTransferring] = useState(false);
  const [hasOverwritten, setHasOverwritten] = useState(false);

  const isAuthenticated = channelType !== AuthChannelType.B2CAnonymous;
  const { wishlists, loading: wishlistsLoading } = useWishlistOverview();
  const [addSharedWishlist, { loading: shareMutationLoading }] =
    useAddSharedWishlistMutation({
      variables: { id },
      refetchQueries,
      awaitRefetchQueries: true,
      onCompleted: () => {
        setHasOverwritten(true);
      },
    });

  const { loading, overwrite } = useMemo(
    () => ({
      loading:
        shareMutationLoading ||
        isAuthenticated ||
        !wishlists?.length ||
        isTransferring,
      overwrite:
        !shareMutationLoading &&
        !wishlistsLoading &&
        (isAuthenticated || !wishlists?.length),
    }),
    [
      isAuthenticated,
      isTransferring,
      wishlistsLoading,
      shareMutationLoading,
      wishlists?.length,
    ]
  );

  async function handleOverwrite() {
    const { data } = await addSharedWishlist();

    if (!data) return;

    const { status, message, sharedAvailable } = data?.addSharedWishlist;

    if (!sharedAvailable) {
      setAvailable(false);
    } else if (status === Status.Success) {
      redirectToListView();
    } else {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  }

  function redirectToListView() {
    const route = getRouteByPageTypeLocale(PageType.WishlistList, language);

    if (!route) return;

    setTransferring(true);

    const uri = route.builder({});

    const routerProps = getTargetPageData({
      targetPageType: route.pageType,
      uri,
    });

    push(route.page, uri, {
      shallow: false,
      scroll: true,
      locale: routerProps.locale,
    }).then(() => {
      setTransferring(false);
    });
  }

  useEffect(() => {
    if (overwrite && !hasOverwritten) handleOverwrite();
  }, [isAuthenticated, wishlists]);

  useEffect(() => {
    if (!id) redirectToListView();
  }, [id]);

  return (
    <>
      {isAvailable ? (
        <>
          {loading ? (
            <LoadingModal />
          ) : (
            <>
              <WishlistSharingModal
                onOverwrite={handleOverwrite}
                onClose={redirectToListView}
              />
            </>
          )}
        </>
      ) : (
        <WishlistShareNotAvailableModal onClose={redirectToListView} />
      )}
    </>
  );
}
