import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const EmptySectionWrapper = styled.div(
  sx2CssThemeFn({
    width: ['50%', `${100 / 3}%`],
    mx: [2, 2],
    overflow: 'hidden',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: 'borderGray',
  })
);

export const EmptySectionMessageWrapper = styled.div(
  sx2CssThemeFn({
    p: 2,
    borderTop: '1px solid',
    borderColor: 'borderGray',
    fontFamily: '"Helvetica Neue", sans-serif',
    '.message-title': {
      fontSize: ['14px', '20px'],
      color: 'secondary',
      mb: 1,
    },
    '.message-text': {
      fontSize: ['14px', '16'],
      color: 'textGray',
      mb: 2,
    },
  })
);
