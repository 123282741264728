import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import {
  FeedbackMobileBoxWrapper,
  FeedbackMobileButton,
} from 'components/domain/feedback-box/styles';

interface IFeedbackMobileBoxProps {
  onClick?(): void;
}

export default function FeedbackMobileBox({
  onClick,
}: IFeedbackMobileBoxProps): ReactElement {
  const [t] = useTranslation('common');

  return (
    <FeedbackMobileBoxWrapper>
      <h2 className="title">{t('common:feedback.mobile.title')}</h2>
      <span className="icon-wrapper">
        <Icon className="feedback-icon" name="message-with-star" size="md" />
      </span>
      <FeedbackMobileButton className="form-submit-button" onClick={onClick}>
        <span className="button-text">
          {t('common:feedback.mobile.button')}
        </span>
      </FeedbackMobileButton>
    </FeedbackMobileBoxWrapper>
  );
}
