import get from 'lodash/get';
import { IFooterConfig } from './FooterConfig.types';

export const FOOTER_CONFIG_PROP = `_fc` as const;

export function createFooterConfigProps({
  hideNewsletterBlock = false,
}: IFooterConfig) {
  return {
    [FOOTER_CONFIG_PROP]: {
      hideNewsletterBlock,
    },
  } as const;
}

export function getFooterConfigPageProps<TProps = any>(
  pageProps?: TProps
): IFooterConfig | null {
  return get(
    pageProps,
    [`props`, FOOTER_CONFIG_PROP],
    get(pageProps, [FOOTER_CONFIG_PROP], null)
  );
}
