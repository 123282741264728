import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useMemo, useRef } from 'react';
import { SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH } from '@hotelplan/libs.auth-next';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useHover } from 'libs';
import HeaderButtonTooltip from './header-button-tooltip/HeaderButtonTooltip';
import {
  MyAccountButton,
  MyAccountHeaderButtonWrapper,
  MyAccountIcon,
} from './HeaderSection.styles';
import MyAccountHeaderMenu from './MyAccountHeaderMenu';

interface IMyAccountHeaderButtonProps {
  isAuthenticated: boolean;
  isMenuOpened: boolean;
  toggleMenu?(): void;
  closeMenu?(): void;
}

export default function MyAccountHeaderButton({
  isAuthenticated,
  isMenuOpened,
  toggleMenu,
  closeMenu,
}: IMyAccountHeaderButtonProps): React.ReactElement {
  const { push } = useRouter();
  const { mobile } = useDeviceType();
  const [t, { language }] = useTranslation();

  const headerButtonRef = useRef<HTMLButtonElement>(null);
  const [isHovered, enter, leave] = useToggleState(false);
  useHover(headerButtonRef, enter, leave);

  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLButtonElement | HTMLDivElement>(
    menuRef,
    closeMenu,
    !mobile
  );

  const iconName = useMemo(
    () =>
      isAuthenticated
        ? 'person'
        : {
            de: 'mein-konto-de',
            fr: 'mein-konto-fr',
          }[language],
    [language, isAuthenticated]
  );

  function handleMyAccountButtonClick() {
    const loginPath = `${SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH}?lang=${language}`;
    isAuthenticated ? toggleMenu() : push(loginPath);
  }

  return (
    <MyAccountHeaderButtonWrapper
      isAuthenticated={isAuthenticated}
      ref={menuRef}
    >
      <MyAccountButton
        isMenuOpened={isMenuOpened}
        isAuthenticated={isAuthenticated}
        onClick={handleMyAccountButtonClick}
        ref={headerButtonRef}
      >
        <MyAccountIcon name={iconName} />
      </MyAccountButton>
      {!mobile && isAuthenticated && isHovered ? (
        <HeaderButtonTooltip
          text={t('myaccount.header.tooltip')}
          className="arrow-top"
        />
      ) : null}
      <MyAccountHeaderMenu
        isOpened={!mobile && isMenuOpened}
        onMenuItemClick={closeMenu}
      />
    </MyAccountHeaderButtonWrapper>
  );
}
