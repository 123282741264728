import 'routes.config';
import compose from 'lodash/fp/compose';
import { AppProps } from 'next/app';
import React from 'react';
import { createBootstrapApp } from '@hotelplan/boot.web';
import { appWithClarity } from '@hotelplan/libs.clarity';
import {
  setSentryFeaturesConfig,
  useAppSentryConfig,
} from '@hotelplan/libs.error-tracking';
import { appWithFeatureToggle } from '@hotelplan/libs.feature-toggle';
import {
  appWithGss,
  mapFormStateToGlobalSearchState,
  mapGlobalSearchStateToFormState,
} from '@hotelplan/libs.gss';
import { appWithI18Next } from '@hotelplan/libs.i18n';
import appWithFooterConfig from 'components/domain/footer/appWithFooterConfig';
import { getLayoutConfigPageProps, Layout } from 'components/domain/layout';
import { logoutRedirect } from 'components/domain/my-account/MyAccount.utils';
import { appWithSavedSearchParam } from 'components/domain/search-query';
import { apolloPossibleTypes, apolloTypePolicies } from 'config/apollo';
import { FeatureList } from 'config/pageConfig.constants';
import i18nextConfig from 'next-i18next.config.js';
import logo from 'public/images/logo-migros-ferien.svg';
import theme from 'theme';
import { GlobalStyle } from 'theme/globalStyles';
import '@hotelplan/libs.router-config';
import 'public/css/fonts.css';
import 'react-day-picker/lib/style.css';

const Application = compose(
  appWithFeatureToggle({
    featureUpdatesEndpoint: `/api/feature-toggle/updates`,
    onGetConfig(config) {
      setSentryFeaturesConfig(config);
    },
  }),
  appWithFooterConfig,
  appWithSavedSearchParam,
  appWithGss({
    gssEndpoint: `/api/gss`,
    mapAppGssToIntermediateGss: mapFormStateToGlobalSearchState,
    mapIntermediateGssToAppGss: mapGlobalSearchStateToFormState,
  }),
  appWithClarity
)(
  createBootstrapApp({
    version: process.env.VERSION,
    GlobalStyle,
    apolloTypePolicies,
    apolloPossibleTypes,
    theme,
    i18nextConfig,
    pageViewOptions: {
      exposeFeatures: true,
      splitPageViewEventFeatureToggle: FeatureList.SPLIT_PAGEVIEW_EVENT,
    },
    useSideEffects({ user }) {
      useAppSentryConfig({
        user,
      });
    },
    getLayoutComponent() {
      return <Layout />;
    },
    openGraphOptions: {
      enable: true,
      fallback: locale => ({
        title: {
          ['de']: `Ferien mit Mirgosferien`,
          ['fr']: `Vacances avec Mirgosferien`,
        }[locale],
        image: logo.src,
      }),
    },
    authNextOptions: {
      logoutCallback: logoutRedirect,
    },
    metaOptions: {
      favIconDefault: `/images/favicon.ico`,
      favIconSvg: `/images/favicon.svg`,
      appleTouchIcon: `/images/apple-touch-icon.png`,
      webManifest: `/manifest.webmanifest`,
      favIconColor: theme.colors.secondary,
    },
  })
);

const SimplifiedApplication = compose(appWithI18Next(i18nextConfig))(function ({
  Component,
  pageProps,
}: AppProps) {
  return <Component {...pageProps} />;
});

// prevent unnecessary renders
export default React.memo<AppProps>(
  function checkApplicationLayout(pageProps) {
    const simplifiedLayout = getLayoutConfigPageProps(
      pageProps.pageProps
    )?.simplified;

    const AppComponent = simplifiedLayout ? SimplifiedApplication : Application;
    return <AppComponent {...pageProps} />;
  },
  (prev, next) => {
    return (
      prev.Component === next.Component &&
      prev.pageProps === next.pageProps &&
      prev.router.route === next.router.route
    );
  }
);
