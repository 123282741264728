import { useTranslation } from 'next-i18next';
import React from 'react';
import { ALL_FILTERS_OPTIONS_ID } from './Filters.common';
import { enhanceWithChooseAll } from './Filters.mappers';
import { DepartureAirportsWrap } from './Filters.styles';
import { IFilterOption } from './Filters.types';
import UnionCheckboxesFilterField from './UnionCheckboxFilterField';
import useDepartureAirportsDefaultValue from './useDepartureAirportsDefaultValue';

interface IDepartureAirportsFilter {
  filterCaption: string;
  filterOptions: IFilterOption[];
}

export function DepartureAirportsFilter({
  filterCaption,
  filterOptions,
}: IDepartureAirportsFilter): React.ReactElement {
  const [t] = useTranslation('filters');

  // If user started using web page with HOTEL travel type and then switch to PACKAGE
  // we need to request default values for departure airports, because there are no
  // departure airports for HOTEL
  const { departureAirports, loading } = useDepartureAirportsDefaultValue();

  if (loading) return null;

  return (
    <DepartureAirportsWrap className="departure-airports">
      <UnionCheckboxesFilterField
        formFieldName={'departureAirports'}
        filterCaption={filterCaption}
        options={enhanceWithChooseAll(
          filterOptions,
          t('departure.airport.select.all')
        )}
        filterTitle={t('departure.airport.title')}
        allOptionId={ALL_FILTERS_OPTIONS_ID}
        icon={{ name: 'flug' }}
        defaultValue={departureAirports}
      />
    </DepartureAirportsWrap>
  );
}
