import { TFilterOptionName } from 'components/domain/filters/Filters.types';
import { WishlistTabs } from './wishlist.types';

export const refetchQueries = [
  `GetWishlistOverview`,
  `GetActiveWishlist`,
  `GetWishlistComponents`,
  'GetWishlistHeaderLink',
];

export const COMPARE_ITEMS_COUNT_MOBILE = 2;
export const COMPARE_ITEMS_COUNT_DESKTOP = 3;
export const COMPARE_ITEMS_MAX_COUNT = COMPARE_ITEMS_COUNT_DESKTOP;

export const displayingFilters: Array<TFilterOptionName> = [
  'departureAirports',
  'maxPrice',
  'roomTypes',
  'boardTypes',
  'flightOptions',
];

export const WISHLIST_DESKTOP_TABS = [
  WishlistTabs.List,
  WishlistTabs.Compare,
  WishlistTabs.Map,
];
export const WISHLIST_MOBILE_TABS = [WishlistTabs.List, WishlistTabs.Compare];
