import {
  FilterComponent,
  SearchControlComponent,
} from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { useGSSContext } from '@hotelplan/libs.gss';
import { useSearchState } from '@hotelplan/libs.search-state';
import useSearchParam from 'components/core/search/useSearchParam';
import { mapMainFilterComponentValuesToFiltersFormState } from 'components/domain/filters/Filters.mappers';
import { mapORLSearchControlComponentToORLControlsFormState } from 'components/domain/orl/Orl.mappers';
import type { IOrlState } from 'components/domain/orl/Orl.types';
import type { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { useGetOrlSearchControlValuesQuery } from 'graphql/orl/GetOrlSearchControlValues.generated';
import { useGetPdpSearchControlValuesQuery } from 'graphql/pdp/GetPdpSearchControlValues.generated';

function usePDPSearchValues() {
  const { id } = useObjectId();
  const searchParam = useSearchParam();

  const { state, loading: stateLoading } = useSearchState<IOrlState>();

  const isORLStateExists = !(
    state?.searchControl === undefined && !stateLoading
  );

  const { gss } = useGSSContext<ISearchControlState>();

  const {
    data: orlSearchControlValuesData,
    loading: orlSearchControlValuesLoading,
  } = useGetOrlSearchControlValuesQuery({
    skip: isORLStateExists,
    variables: {
      id,
      urlParameter: searchParam,
    },
  });

  const { data: pdpSearchControlData, loading: pdpSearchControlDataLoading } =
    useGetPdpSearchControlValuesQuery({
      skip: isORLStateExists,
      variables: { id },
    });

  return {
    searchControl: {
      ...(isORLStateExists
        ? state?.searchControl
        : mapORLSearchControlComponentToORLControlsFormState(
            searchParam
              ? (orlSearchControlValuesData?.orl
                  .searchControl as SearchControlComponent)
              : (pdpSearchControlData?.pdp
                  .searchControl as SearchControlComponent)
          )),
      // NOTE: We should skip GSS if a search query param is not empty by the requirement.
      ...(!searchParam ? gss : {}),
    },
    filters: isORLStateExists
      ? state?.filters
      : mapMainFilterComponentValuesToFiltersFormState(
          orlSearchControlValuesData?.orl.filters as FilterComponent
        ),
    loading: isORLStateExists
      ? stateLoading
      : searchParam
      ? orlSearchControlValuesLoading
      : pdpSearchControlDataLoading,
  };
}

export default usePDPSearchValues;
