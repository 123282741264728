import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type InternalLinkFragmentFragment = {
  __typename?: 'InternalLink';
  caption?: string | null;
  uri: string;
  targetPageType: Types.PageType;
};

export const InternalLinkFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'internalLinkFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalLink' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetPageType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
