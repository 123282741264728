import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const MapProductCardSkeleton = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 412, h: 150 }
    : { w: 600, h: 201 };

  return (
    <PlaceholderLoader
      width={width}
      height={height}
      uid="cardSkeleton"
      style={{ height: '100%', width: '100%', display: 'block' }}
    >
      {mobile ? (
        <>
          <rect x="4" y="5" width="213" height="196" fill="#D9D9D9" />
          <rect width="1" height="205" fill="#D9D9D9" />
          <rect x="414" width="1" height="205" fill="#D9D9D9" />
          <rect width="415" height="1" fill="#D9D9D9" />
          <rect y="204" width="415" height="1" fill="#D9D9D9" />
          <rect x="228" y="9" width="15" height="15" rx="7" fill="#D9D9D9" />
          <rect x="248" y="9" width="16" height="15" rx="7" fill="#D9D9D9" />
          <rect x="267" y="9" width="15" height="15" rx="7" fill="#D9D9D9" />
          <rect x="288" y="9" width="15" height="15" rx="7" fill="#D9D9D9" />
          <rect x="265" y="62" width="18" height="19" rx="9" fill="#D9D9D9" />
          <rect x="285" y="62" width="18" height="19" rx="9" fill="#D9D9D9" />
          <rect x="306" y="62" width="18" height="19" rx="9" fill="#D9D9D9" />
          <rect x="326" y="62" width="18" height="19" rx="9" fill="#D9D9D9" />
          <rect x="347" y="62" width="19" height="19" rx="9.5" fill="#D9D9D9" />
          <path
            d="M388 12C388 8.13401 391.134 5 395 5H403C406.866 5 410 8.13401 410 12V18C410 21.866 406.866 25 403 25H395C391.134 25 388 21.866 388 18V12Z"
            fill="#D9D9D9"
          />
          <rect x="227" y="62" width="32" height="20" rx="7" fill="#D9D9D9" />
          <rect x="245" y="181" width="74" height="17" rx="7" fill="#D9D9D9" />
          <rect x="227" y="31" width="154" height="17" rx="7" fill="#D9D9D9" />
          <rect x="228" y="134" width="132" height="20" rx="7" fill="#D9D9D9" />
          <rect x="227" y="90" width="154" height="12" rx="6" fill="#D9D9D9" />
          <rect x="227" y="115" width="178" height="12" rx="6" fill="#D9D9D9" />
          <rect x="227" y="162" width="89" height="12" rx="6" fill="#D9D9D9" />
          <rect x="227" y="186" width="16" height="12" rx="6" fill="#D9D9D9" />
          <rect x="326" y="178" width="84" height="23" fill="#D9D9D9" />
        </>
      ) : (
        <>
          <rect x="4" y="5" width="279" height="192" />
          <rect width="1" height="201" />
          <rect x="599" width="1" height="201" />
          <rect width="600" height="1" />
          <rect y="200" width="600" height="1" />
          <rect x="300" y="9" width="15" height="15" rx="7" />
          <rect x="320" y="9" width="15" height="15" rx="7" />
          <rect x="338" y="9" width="15" height="15" rx="7" />
          <rect x="359" y="9" width="15" height="15" rx="7" />
          <rect x="336" y="61" width="18" height="18" rx="9" />
          <rect x="356" y="61" width="18" height="18" rx="9" />
          <rect x="376" y="61" width="18" height="18" rx="9" />
          <rect x="396" y="61" width="18" height="18" rx="9" />
          <rect x="416" y="61" width="18" height="18" rx="9" />
          <path d="M569.5 15C569.5 11.134 572.634 8 576.5 8H584.5C588.366 8 591.5 11.134 591.5 15V20C591.5 23.866 588.366 27 584.5 27H576.5C572.634 27 569.5 23.866 569.5 20V15Z" />
          <rect x="299" y="61" width="31" height="19" rx="7" />
          <rect x="317" y="177" width="72" height="17" rx="7" />
          <rect x="299" y="30" width="187" height="17" rx="7" />
          <rect x="300" y="131" width="161" height="20" rx="7" />
          <rect x="299" y="88" width="187" height="12" rx="6" />
          <rect x="299" y="113" width="217" height="12" rx="6" />
          <rect x="299" y="159" width="87" height="12" rx="6" />
          <rect x="299" y="182" width="16" height="12" rx="6" />
          <rect x="486" y="159" width="107" height="35" />
        </>
      )}
    </PlaceholderLoader>
  );
};

export default MapProductCardSkeleton;
