import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import usePatchRouteWithSearchQuery from 'components/core/search/usePatchRouteWithSearchQuery';
import { ITabsItem } from 'components/core/tabs/Tabs.types';
import {
  WISHLIST_DESKTOP_TABS,
  WISHLIST_MOBILE_TABS,
  WishlistTabs,
} from 'components/domain/wishlist/utils';
import { GetWishlistLinksQuery } from 'graphql/wishlist/queries/GetWishlistLinks.generated';

export default function useWishlistTabs(
  wishlistLinks: GetWishlistLinksQuery['wishlist']
) {
  const [t] = useTranslation('wishlist');
  const { mobile } = useDeviceType();
  const { query } = useRouter();

  const { s } = query as {
    s?: string | undefined;
  };

  const wishlistCompareLink = usePatchRouteWithSearchQuery(
    wishlistLinks?.compareView,
    ['s']
  );

  const availableTabs: Array<WishlistTabs> = mobile
    ? WISHLIST_MOBILE_TABS
    : WISHLIST_DESKTOP_TABS;

  return useMemo<ITabsItem<WishlistTabs>[]>(() => {
    return [
      {
        tab: WishlistTabs.List,
        routerProps: getTargetPageData(wishlistLinks?.listView, { s }),
        label: t('list.view'),
      },
      {
        tab: WishlistTabs.Compare,
        routerProps: getTargetPageData(wishlistCompareLink),
        label: t('compare.view'),
      },
      {
        tab: WishlistTabs.Map,
        routerProps: getTargetPageData(wishlistLinks?.mapView, { s }),
        label: t('map.view'),
      },
    ]
      .filter(item => !!item.routerProps)
      .filter(item => availableTabs.includes(item.tab));
  }, [wishlistLinks, s]);
}
