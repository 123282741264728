import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { CloseButton } from 'components/domain/filters/SearchFilterDesktopDropdown';

export const DisrupterWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    p: 2,
    display: 'flex',
    gap: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    bg: 'secondary',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    mb: -3,
    fontSize: ['14px', 1],
  })
);

export const AcceptChangesButton = styled(CloseButton)(
  sx2CssThemeFn({
    color: 'white',
  })
);
