import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const NewsletterMobileBlockWrapper = styled.ul(
  sx2CssThemeFn({
    position: 'relative',
    margin: '50px 0 20px',
    padding: '10px 10px 20px',
    backgroundColor: 'backgroundGray',
    borderTop: '1px solid #bbb',
    borderBottom: '1px solid #bbb',
    textAlign: 'center',
    '.title': {
      fontSize: '18px',
      maxWidth: '300px',
      margin: '15px auto',
    },
    '.icon-wrapper': {
      position: 'absolute',
      top: '-15px',
      right: '46%',
      width: '30px',
      height: '30px',
      backgroundColor: ['grayMiddle', '#9f9f9f'],
      borderRadius: '50%',
      display: 'flex',
    },
    '.icon': {
      color: 'white',
      margin: 'auto',
      width: '16px',
      height: '16px',
    },
  })
);

const NewsletterMobileLink = styled(Link).attrs({
  icon: { name: 'chevron-long-right' },
})(
  ({ theme: { buttons } }) => buttons.primary,
  ({ theme: { buttons } }) => buttons.btnWithIcon,
  sx2CssThemeFn({
    svg: {
      width: '14px',
      height: '14px',
    },
    textTransform: 'uppercase',
  })
);

const NewsletterMobileBlock: React.FC = () => {
  const [t, { language }] = useTranslation('newsletter');

  const newsletterRoute = getRouteByPageTypeLocale(
    PageType.NewsletterSubscription,
    language
  );

  const newsletterRouterProps = getTargetPageData({
    targetPageType: newsletterRoute.pageType,
    uri: newsletterRoute.builder({}),
  });

  return (
    <NewsletterMobileBlockWrapper>
      <h2 className="title">{t('newsletterFooter.title')}</h2>
      <span className="icon-wrapper">
        <Icon name="envelope" size="md" />
      </span>
      <RouterLink {...newsletterRouterProps} passHref>
        <NewsletterMobileLink className="form-submit-button">
          <span className="button-text">{t('newsletterFooter.button')}</span>
        </NewsletterMobileLink>
      </RouterLink>
    </NewsletterMobileBlockWrapper>
  );
};

export default NewsletterMobileBlock;
