import React, { useContext } from 'react';
import type { IFooterConfig } from './FooterConfig.types';

export const FooterConfigContext = React.createContext<IFooterConfig>({
  hideNewsletterBlock: undefined,
});

export function useFooterConfig(): IFooterConfig {
  return useContext(FooterConfigContext);
}
