import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { FeaturesSummary } from '@hotelplan/components.common.feature';
import { Icon } from '@hotelplan/components.common.icon';
import { Image } from '@hotelplan/components.common.image';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { ProductClass } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { track } from '@hotelplan/libs.tracking-events';
import { formatPrice } from '@hotelplan/libs.utils';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';
import ProductRating from 'components/core/product/ProductRating';
import ProductTripAdvisorInfo from 'components/core/product/ProductTripAdvisorInfo';
import ProductTripInfoAndTransferGroup from 'components/core/product/ProductTripInfoAndTransferGroup';
import useProductFeatures from 'components/domain/srl/useProductFeatures';
import { SrlMapProductItemFragment } from 'graphql/srl/map/SrlMapProductItem.generated';

const ProductCardWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    backgroundColor: 'white',
    color: 'textGray',
    border: '1px solid',
    borderColor: '#ccc',
    p: 1,
  })
);

const ProductCardImage = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: ['160px', '320px'],
    img: {
      height: '100%',
      objectFit: 'cover',
    },
  })
);

const FeaturesSummaryWrap = styled(FeaturesSummary)(
  sx2CssThemeFn({
    position: 'absolute',
    bottom: 0,
    p: 1,

    '.featureGroupWrapper': {
      mr: 0,
      ml: 1,
      mb: 1,
    },

    '.feature-summary-item-icon': {
      variant: ['icons.sm', 'icons.xlg'],
      color: 'secondary',
      stroke: 'white',
      strokeWidth: '4px',
      paintOrder: 'stroke',
    },
  })
);

const ProductCardDetails = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minWidth: ['160px', '270px'],
    pl: [2, 3],
    pt: [1, 2],
  })
);

const ProductCardTitle = styled.h4(
  sx2CssThemeFn({
    color: ['secondary', 'primary'],
    mb: 2,
    fontSize: [0, '18px'],
  })
);

const ProductOffer = styled.div(
  sx2CssThemeFn({
    mt: 'auto',
    display: 'flex',
    flexDirection: 'column',

    '.cta': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '.travel-type, .from-to': {
      fontSize: ['13px', 0],
      mb: 1,
    },
    '.from-to': {
      fontSize: ['13px', 0],
      mb: 3,
    },
  })
);

const ProductOfferPrice = styled.div(
  sx2CssThemeFn({
    fontSize: ['10px', 0],
    fontFamily: '"Helvetica Neue", sans-serif',
    alignSelf: 'flex-start',

    '.title': {
      textTransform: 'uppercase',
      fontSize: 0,
    },

    '.currency': {
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'secondary',
      fontSize: [0, 1],
    },

    '.price': {
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'secondary',
      fontSize: [1, 2],
    },
  })
);

const CloseCardButton = styled(Button)(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',

    '.icon': {
      color: 'textGray',
    },
  })
);

const trackOfferDetailsClick = (language: string) => {
  track({
    event: 'navigate_to_offer',
    action: 'navigate',
    label: 'Details',
    category: undefined,
    component: 'map',
    position: 'offer-card',
    language,
    _clear: true,
  });
};

interface IMapProductCardProps {
  product: SrlMapProductItemFragment;
  onClose: () => void;
  className?: string;
}

const MapProductCard: React.FC<IMapProductCardProps> = (
  props: IMapProductCardProps
) => {
  const {
    product: {
      image,
      name,
      hpRating,
      taRating,
      taReviews,
      duration,
      mealType,
      price,
      link,
      featureSummary,
      type,
      transferIncluded,
      transferPrice,
      travelType,
      fromTo,
      productClass,
    },
    onClose,
    className,
  } = props;

  const [t, { language }] = useTranslation();
  const { mobile } = useDeviceType();

  const { features } = useProductFeatures(featureSummary);

  const isRoundtrip = productClass === ProductClass.Roundtrip;

  return (
    <ProductCardWrapper className={className}>
      <ProductCardImage>
        <Image {...image} />
        <FeaturesSummaryWrap groups={features} />
      </ProductCardImage>
      <ProductCardDetails>
        {!isRoundtrip && <ProductRating rating={hpRating} />}
        <ProductCardTitle>{name}</ProductCardTitle>
        {!mobile && (
          <ProductTripAdvisorInfo taRating={taRating} taReviews={taReviews} />
        )}
        {mobile && (
          <ProductTripInfoAndTransferGroup
            type={type}
            duration={duration}
            mealType={mealType}
            transferIncluded={transferIncluded}
            transferPrice={transferPrice}
            productClass={productClass}
          />
        )}
        <ProductOffer>
          {!mobile && (
            <ProductTripInfoAndTransferGroup
              type={type}
              duration={duration}
              mealType={mealType}
              transferIncluded={transferIncluded}
              transferPrice={transferPrice}
              productClass={productClass}
            />
          )}
          {travelType && <span className="travel-type">{travelType}</span>}
          {fromTo ? <span className="from-to">{fromTo}</span> : null}
          <div className="cta">
            <ProductOfferPrice>
              <p className="title">{t('common:perPerson')}</p>
              <p>
                <span className="currency">
                  {t('common:priceFrom')}&nbsp;{price.currency}&nbsp;
                </span>
                <span className="price">
                  {formatPrice(price, { currency: false })}
                </span>
              </p>
            </ProductOfferPrice>
            <div>
              <RouterLink {...getTargetPageData(link)} passHref>
                <PrimaryButtonLink
                  target="_blank"
                  onClick={() => {
                    trackOfferDetailsClick(language);
                  }}
                >
                  {t('common:btn.details')}
                </PrimaryButtonLink>
              </RouterLink>
            </div>
          </div>
        </ProductOffer>
      </ProductCardDetails>
      <CloseCardButton onClick={onClose}>
        <Icon name="close" size={mobile ? 'sm' : 'md'} />
      </CloseCardButton>
    </ProductCardWrapper>
  );
};

export default MapProductCard;
