import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistMapContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  })
);

export const WishlistMapTextBlock = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    mb: 3,
    '.title': {
      p: '8px 13px',
      bg: 'secondary',
      fontSize: '22px',
      fontWeight: 700,
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'white',
      mr: 3,
    },
    '.description': {
      fontSize: 0,
    },
  })
);
