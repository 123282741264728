import {
  refetchQueries,
  TPossibleMutation,
} from 'components/domain/wishlist/utils';
import {
  AddToActiveWishlistWithSourceMutationVariables as TAddMutationVars,
  useAddToActiveWishlistWithSourceMutation as useAddMutation,
} from 'graphql/wishlist/mutations/AddToActiveWishlistWithSource.generated';

export default function useAddToWishlist(
  offerId: string,
  onCompleted: (data: TPossibleMutation) => void
): [() => ReturnType<typeof add>, boolean] {
  const variables: TAddMutationVars = { offerId: { offerId } };

  const [mutation, { loading: adding }] = useAddMutation({
    variables,
    refetchQueries,
    onCompleted,
  });

  async function add() {
    await mutation({ variables });
  }

  return [add, adding];
}
