const { registerPageRoutes } = require('@hotelplan/libs.router-config');
const { PageType } = require('@hotelplan/graphql.types');
const { domainDE, domainFR } = require('./next.domains.config');

const routes = registerPageRoutes(
  [
    // Home Page
    {
      pageType: PageType.Home,
      match: '/',
      page: '/',
      locale: 'de',
    },
    {
      pageType: PageType.Home,
      match: '/',
      page: '/',
      locale: 'fr',
    },
    // Geo Overview
    {
      pageType: PageType.GeoOverview,
      match: `/reiseziele`,
      page: '/geo-overview',
      locale: 'de',
    },
    {
      pageType: PageType.GeoOverview,
      match: `/destinations`,
      page: '/geo-overview',
      locale: 'fr',
    },
    // Geo SRL
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgcrdf]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgcrdf]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoSrl,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    // Geo (Object)
    // Link created in CMS with GEO_OBJECT page type should open GEO_SRL page
    // TODO: Remove this after cms will be updated
    {
      pageType: PageType.GeoObject,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgcrdf]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoObject,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgcrdf]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoObject,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoObject,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoObject,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)`,
      page: '/geo-srl',
      locale: 'de',
    },
    {
      pageType: PageType.GeoObject,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)`,
      page: '/geo-srl',
      locale: 'fr',
    },
    // Geo Children for Continent and Country Group
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'kg',
      match: `/:geopart/:id([kg]-\\d+)/laender`,
      page: '/geo-children',
      locale: 'de',
    },
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'kg',
      match: `/:geopart/:id([kg]-\\d+)/pays`,
      page: '/geo-children',
      locale: 'fr',
    },
    // Geo Children for Country and Region
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'c',
      match: `/:geopart/:id([c]-\\d+)/region`,
      page: '/geo-children',
      locale: 'de',
    },
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'c',
      match: `/:geopart/:id([c]-\\d+)/region`,
      page: '/geo-children',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'r',
      match: `/:geopart1/:geopart2/:id([r]-\\d+)/region`,
      page: '/geo-children',
      locale: 'de',
    },
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'r',
      match: `/:geopart1/:geopart2/:id([r]-\\d+)/region`,
      page: '/geo-children',
      locale: 'fr',
    },
    // Geo Children for Destination
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'd',
      match: `/:geopart1/:geopart2/:id([d]-\\d+)/ferienorte`,
      page: '/geo-children',
      locale: 'de',
    },
    {
      pageType: PageType.GeoChildrenOffers,
      pageVariant: 'd',
      match: `/:geopart1/:geopart2/:id([d]-\\d+)/lieux-de-vacances`,
      page: '/geo-children',
      locale: 'fr',
    },
    // Geo Facts
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgc]-\\d+)/facts`,
      page: '/geo-facts',
      locale: 'de',
    },
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'kgc',
      match: `/:geopart/:id([kgc]-\\d+)/faits`,
      page: '/geo-facts',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)/facts`,
      page: '/geo-facts',
      locale: 'de',
    },
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'rd',
      match: `/:geopart1/:geopart2/:id([rd]-\\d+)/faits`,
      page: '/geo-facts',
      locale: 'fr',
    },
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)/facts`,
      page: '/geo-facts',
      locale: 'de',
    },
    {
      pageType: PageType.GeoFacts,
      pageVariant: 'f',
      match: `/:geopart1/:geopart2/:geopart3/:id([f]-\\d+)/faits`,
      page: '/geo-facts',
      locale: 'fr',
    },
    // Geo Climate
    {
      pageType: PageType.GeoClimate,
      match: `/:geopart1/:geopart2/:id([d]-\\d+)/klima`,
      page: '/geo-climate',
      locale: 'de',
    },
    {
      pageType: PageType.GeoClimate,
      match: `/:geopart1/:geopart2/:id([d]-\\d+)/climat`,
      page: '/geo-climate',
      locale: 'fr',
    },
    // Geo Map
    {
      pageType: PageType.GeoMap,
      match: `/:destination*/:id([kgcrdf]-\\d+)/karte`,
      page: '/geo-map',
      locale: 'de',
    },
    {
      pageType: PageType.GeoMap,
      match: `/:destination*/:id([kgcrdf]-\\d+)/carte`,
      page: '/geo-map',
      locale: 'fr',
    },
    // PDP ORL (price + date)
    {
      pageType: PageType.Orl,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/preise`,
      page: '/pdp-orl',
      locale: 'de',
    },
    {
      pageType: PageType.Orl,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/prix`,
      page: '/pdp-orl',
      locale: 'fr',
    },
    // PDP Description
    {
      pageType: PageType.Pdp,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)`,
      page: '/pdp-description',
      locale: 'de',
    },
    {
      pageType: PageType.Pdp,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)`,
      page: '/pdp-description',
      locale: 'fr',
    },
    // PDP Description shortlinks
    {
      pageType: PageType.Pdp,
      pageVariant: 'shortlink',
      match: `/:id([ho]-\\d+)`,
      page: '/pdp-description',
      locale: 'de',
    },
    {
      pageType: PageType.Pdp,
      pageVariant: 'shortlink',
      match: `/:id([ho]-\\d+)`,
      page: '/pdp-description',
      locale: 'fr',
    },
    // PDP Rating
    {
      pageType: PageType.PdpRating,
      match: `/:country/:destination/:hotel/:id([h]-\\d+)/tripadvisor-bewertungen`,
      page: '/pdp-rating',
      locale: 'de',
    },
    {
      pageType: PageType.PdpRating,
      match: `/:country/:destination/:hotel/:id([h]-\\d+)/evaluations-tripadvisor`,
      page: '/pdp-rating',
      locale: 'fr',
    },
    // PDP Map
    {
      pageType: PageType.PdpMap,
      match: `/:country/:destination/:hotel/:id([h]-\\d+)/lage`,
      page: '/pdp-map',
      locale: 'de',
    },
    {
      pageType: PageType.PdpMap,
      match: `/:country/:destination/:hotel/:id([h]-\\d+)/situation`,
      page: '/pdp-map',
      locale: 'fr',
    },
    // PDP Resort
    {
      pageType: PageType.PdpResort,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/ferienort`,
      page: '/pdp-resort',
      locale: 'de',
    },
    {
      pageType: PageType.PdpResort,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/lieux-de-vacances`,
      page: '/pdp-resort',
      locale: 'fr',
    },
    // PDP Region
    {
      pageType: PageType.PdpRegion,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/region`,
      page: '/pdp-region',
      locale: 'de',
    },
    {
      pageType: PageType.PdpRegion,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/region`,
      page: '/pdp-region',
      locale: 'fr',
    },
    // PDP Climate
    {
      pageType: PageType.PdpClimate,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/klima`,
      page: '/pdp-climate',
      locale: 'de',
    },
    {
      pageType: PageType.PdpClimate,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/climat`,
      page: '/pdp-climate',
      locale: 'fr',
    },
    // PDP Itinerary
    {
      pageType: PageType.PdpItinerary,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/reiseprogramm`,
      page: '/pdp-itinerary',
      locale: 'de',
    },
    {
      pageType: PageType.PdpItinerary,
      match: `/:country/:destination/:hotel/:id([ho]-\\d+)/programm-de-voyage`,
      page: '/pdp-itinerary',
      locale: 'fr',
    },
    // SRL
    {
      pageType: PageType.Srl,
      match: `/suche`,
      page: '/search',
      locale: 'de',
    },
    {
      pageType: PageType.Srl,
      match: `/chercher`,
      page: '/search',
      locale: 'fr',
    },
    // Newsletter subscription
    {
      pageType: PageType.NewsletterSubscription,
      match: `/newsletter/anmeldung`,
      page: '/newsletter-subscription',
      locale: 'de',
    },
    {
      pageType: PageType.NewsletterSubscription,
      match: `/newsletter/abonnement`,
      page: '/newsletter-subscription',
      locale: 'fr',
    },
    {
      pageType: PageType.NewsletterConfirmation,
      match: `/newsletter/anmeldung-bestaetigen`,
      page: '/newsletter-subscription-confirm',
      locale: 'de',
    },
    {
      pageType: PageType.NewsletterConfirmation,
      match: `/newsletter/abonnement-confirmer`,
      page: '/newsletter-subscription-confirm',
      locale: 'fr',
    },
    {
      pageType: PageType.NewsletterFinalization,
      match: `/newsletter/anmeldung-erfolgreich`,
      page: '/newsletter-subscription-success',
      locale: 'de',
    },
    {
      pageType: PageType.NewsletterFinalization,
      match: `/newsletter/abonnement-succes`,
      page: '/newsletter-subscription-success',
      locale: 'fr',
    },
    // Newsletter unsubscription
    {
      pageType: PageType.NewsletterUnsubscription,
      match: `/newsletter/abmeldung`,
      page: '/newsletter-unsubscription',
      locale: 'de',
    },
    {
      pageType: PageType.NewsletterUnsubscription,
      match: `/newsletter/desabonnement`,
      page: '/newsletter-unsubscription',
      locale: 'fr',
    },
    {
      pageType: PageType.NewsletterUnsubscriptionFinalization,
      match: `/newsletter/abmeldung-erfolgreich`,
      page: '/newsletter-unsubscription-success',
      locale: 'de',
    },
    {
      pageType: PageType.NewsletterUnsubscriptionFinalization,
      match: `/newsletter/desabonnement-succes`,
      page: '/newsletter-unsubscription-success',
      locale: 'fr',
    },
    // Catalogs
    {
      pageType: PageType.CatalogOverview,
      match: `/kataloge`,
      page: '/catalogs',
      locale: 'de',
    },
    {
      pageType: PageType.CatalogOverview,
      match: `/catalogues`,
      page: '/catalogs',
      locale: 'fr',
    },
    // Static Page
    {
      pageType: PageType.Static,
      match: '/:path*',
      page: '/static',
      locale: 'de',
      fallback: true,
    },
    {
      pageType: PageType.Static,
      match: '/:path*',
      page: '/static',
      locale: 'fr',
      fallback: true,
    },
    // Theme Page
    {
      pageType: PageType.ThemeSrl,
      match: '/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'de',
    },
    {
      pageType: PageType.ThemeSrl,
      match: '/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'fr',
    },
    // Link created in CMS with THEME_OBJECT page type should open THEME_SRL page
    // TODO: Remove this after cms will be updated
    {
      pageType: PageType.ThemeObject,
      match: '/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'de',
    },
    {
      pageType: PageType.ThemeObject,
      match: '/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'fr',
    },
    {
      pageType: PageType.ThemeOverview,
      match: '/ferienideen',
      page: '/theme-overview',
      locale: 'de',
    },
    {
      pageType: PageType.ThemeOverview,
      match: '/idees-de-vacances',
      page: '/theme-overview',
      locale: 'fr',
    },
    // Email Revoke
    {
      pageType: PageType.EmailAdvertisingRevocation,
      match: '/email-permission/widerrufen',
      page: '/email-revoke',
      locale: 'de',
    },
    {
      pageType: PageType.EmailAdvertisingRevocation,
      match: '/email-permission/retracter',
      page: '/email-revoke',
      locale: 'fr',
    },
    {
      pageType: PageType.EmailAdvertisingRevocationConfirmation,
      match: '/email-permission/widerruf-erfolgreich',
      page: '/email-revoke-success',
      locale: 'de',
    },
    {
      pageType: PageType.EmailAdvertisingRevocationConfirmation,
      match: '/email-permission/retractation-succes',
      page: '/email-revoke-success',
      locale: 'fr',
    },
    // My Account Profile
    {
      pageType: PageType.MyaccountMyprofile,
      match: '/konto/mein-konto',
      page: '/my-account-profile',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountMyprofile,
      match: '/compte/mon-compte',
      page: '/my-account-profile',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountMybookings,
      match: '/konto/meine-reisen',
      page: '/my-account-mybookings',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountMybookings,
      match: '/compte/mes-voyages',
      page: '/my-account-mybookings',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountBookingDetails,
      match: '/konto/meine-reisen/buchung/:id',
      page: '/my-account-mybooking-details',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountBookingDetails,
      match: '/compte/mes-voyages/reservation/:id',
      page: '/my-account-mybooking-details',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountRegistration,
      match: '/konto/registrieren',
      page: '/my-account-registration',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountRegistration,
      match: '/compte/enregistrer',
      page: '/my-account-registration',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountLogin,
      match: '/konto/login',
      page: '/my-account-login',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountLogin,
      match: '/compte/login',
      page: '/my-account-login',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountConfirmation,
      match: '/konto/login-bestaetigen',
      page: '/my-account-confirmation',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountConfirmation,
      match: '/compte/login-confirmer',
      page: '/my-account-confirmation',
      locale: 'fr',
    },
    {
      pageType: PageType.MyaccountResetPassword,
      match: '/konto/passwort-zuruecksetzen',
      page: '/my-account-reset',
      locale: 'de',
    },
    {
      pageType: PageType.MyaccountResetPassword,
      match: '/compte/reinitialiser-mot-de-passe',
      page: '/my-account-reset',
      locale: 'fr',
    },
    // Wishlist Page
    {
      pageType: PageType.Wishlist,
      match: '/merkliste',
      page: '/wishlist-list',
      locale: 'de',
    },
    {
      pageType: PageType.Wishlist,
      match: '/liste-de-favoris',
      page: '/wishlist-list',
      locale: 'fr',
    },
    // Wishlist List view Page
    {
      pageType: PageType.WishlistList,
      match: '/merkliste/listenansicht',
      page: '/wishlist-list',
      locale: 'de',
    },
    {
      pageType: PageType.WishlistList,
      match: '/liste-de-favoris/vue-liste',
      page: '/wishlist-list',
      locale: 'fr',
    },
    // Wishlist Compare view Page
    {
      pageType: PageType.WishlistCompare,
      match: '/merkliste/hotels-vergleichen',
      page: '/wishlist-compare',
      locale: 'de',
    },
    {
      pageType: PageType.WishlistCompare,
      match: '/liste-de-favoris/comparer-les-hotels',
      page: '/wishlist-compare',
      locale: 'fr',
    },
    // Wishlist Map view Page
    {
      pageType: PageType.WishlistMap,
      match: '/merkliste/kartenansicht',
      page: '/wishlist-map',
      locale: 'de',
    },
    {
      pageType: PageType.WishlistMap,
      match: '/liste-de-favoris/emplacement',
      page: '/wishlist-map',
      locale: 'fr',
    },
    // Wishlist Share Page
    {
      pageType: PageType.Wishlist,
      match: '/merkliste/teilen/:id',
      page: '/wishlist-share',
      locale: 'de',
    },
    {
      pageType: PageType.Wishlist,
      match: '/liste-de-favoris/partager/:id',
      page: '/wishlist-share',
      locale: 'fr',
    },
    // B2B Login
    {
      pageType: PageType.B2BLogin,
      match: '/b2b/login',
      page: '/b2b-login',
      locale: 'de',
    },
    {
      pageType: PageType.B2BLogin,
      match: '/b2b/login',
      page: '/b2b-login',
      locale: 'fr',
    },
    // RAIL
    {
      pageType: PageType.Static,
      match: '/rail/header-footer-direct',
      page: '/simplified/header-footer-direct',
      locale: 'de',
    },
    {
      pageType: PageType.Static,
      match: '/rail/header-footer-direct',
      page: '/simplified/header-footer-direct',
      locale: 'fr',
    },
    // CRUISE
    {
      pageType: PageType.Static,
      match: '/cruisehost/header-direct',
      page: '/simplified/header-direct',
      locale: 'de',
    },
    {
      pageType: PageType.Static,
      match: '/cruisehost/header-direct',
      page: '/simplified/header-direct',
      locale: 'fr',
    },
    {
      pageType: PageType.Static,
      match: '/cruisehost/footer-direct',
      page: '/simplified/footer-direct',
      locale: 'de',
    },
    {
      pageType: PageType.Static,
      match: '/cruisehost/footer-direct',
      page: '/simplified/footer-direct',
      locale: 'fr',
    },
  ].map(config => ({
    ...config,
    domain: { de: domainDE, fr: domainFR }[config.locale],
  }))
);

module.exports = routes;
