import { useTranslation } from 'next-i18next';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import HeaderButtonTooltip from 'components/domain/my-account/header-section/header-button-tooltip/HeaderButtonTooltip';
import { useWishlistHeaderButton } from 'components/domain/wishlist/hooks';
import { WishlistIcon } from 'components/domain/wishlist/ui';
import { useHover } from 'libs';

export const WishlistHeaderLink = styled(Link)<{ isActive?: boolean }>(
  ({ isActive }) =>
    sx2CssThemeFn({
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      gap: 2,
      position: isActive ? 'fixed' : 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      px: 2,
      py: [1, 0],
      border: ['none', '1px solid'],
      borderTop: ['none', 'none'],
      borderRadius: ['0 0 50% 50%', '0 0 5px 5px'],
      borderColor: isActive ? ['white', '#f60'] : ['borderGray', 'borderGray'],
      zIndex: 3,
      color: isActive ? 'white' : 'textGray',
      background: isActive
        ? ['white', '#f60']
        : ['transparent', 'linear-gradient(to bottom,#fff 0,#eee 100%)'],
      boxShadow: isActive ? '0px 0px 8px rgb(0 0 0 / 40%)' : '',
      '&:hover': {
        background: !isActive && [
          'transparent',
          'linear-gradient(to bottom,#fff 0,#ccc 100%)',
        ],
      },
    })
);

export const WishlistCounter = styled.div<{ isActive?: boolean }>(
  ({ isActive }) =>
    sx2CssThemeFn({
      border: '1px solid',
      borderColor: isActive ? 'white' : 'borderGray',
      borderRadius: '3px',
      bg: isActive ? 'white' : '#e7e7e7',
      px: '5px',
      py: '2px',
      fontSize: '12px',
      fontFamily: 'Arial',
      fontWeight: 700,
      color: 'textGray',
    })
);

export const WishlistTitle = styled.span(
  sx2CssThemeFn({
    fontWeight: 700,
    fontSize: 0,
    fontFamily: 'Arial',
  })
);

export const WishlistIconWrapper = styled(WishlistIcon)<{
  isHovered?: boolean;
  isActive?: boolean;
}>(({ isActive, isHovered }) => {
  const heartColor = isActive
    ? 'secondary'
    : isHovered
    ? ['backgroundDarkGray', 'white']
    : '#eee';

  const heartColorBg = isActive
    ? 'white'
    : isHovered
    ? ['backgroundDarkGray', '#f60']
    : 'linear-gradient(to bottom,#aaa 0,backgroundDarkGray 100%)';

  return sx2CssThemeFn({
    '.icon': {
      zIndex: '1',
      color: heartColor,
    },
    width: '30px',
    height: '30px',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      background: heartColorBg,
      borderRadius: '50%',
      zIndex: '0',
    },
  });
});

export const PinsLoader = styled.div(
  sx2CssThemeFn({
    width: ['30px', '140px'],
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '.pins-loader': {
      width: '40px',
      height: '40px',
    },
  })
);

// TODO: Remove this component after mein-konto release
export default function LegacyWishlistHeaderButton(): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const { mobile } = useDeviceType();

  const [isHovered, enter, leave] = useToggleState(false);
  const headerButtonRef = useRef<HTMLAnchorElement>(null);

  useHover(headerButtonRef, enter, leave);

  const { routerLinkProps, wishlistProductsAmount, loading } =
    useWishlistHeaderButton();

  const isActive = useMemo(
    () => wishlistProductsAmount > 0,
    [wishlistProductsAmount]
  );

  if (loading) {
    return <WishlistHeaderButtonSkeleton />;
  }

  return (
    <RouterLink {...routerLinkProps} passHref>
      <WishlistHeaderLink
        isActive={wishlistProductsAmount > 0}
        ref={headerButtonRef}
      >
        {!mobile && isHovered ? (
          <HeaderButtonTooltip text={t('wishlistHeader.tooltip')} />
        ) : null}
        {!mobile ? (
          <>
            <WishlistCounter isActive={isActive}>
              {wishlistProductsAmount}
            </WishlistCounter>
            <WishlistTitle>{t('wishlist')}</WishlistTitle>
          </>
        ) : null}
        <WishlistIconWrapper
          isHovered={isHovered}
          isActive={isActive}
          count={mobile && wishlistProductsAmount}
        />
      </WishlistHeaderLink>
    </RouterLink>
  );
}

function WishlistHeaderButtonSkeleton(): React.ReactElement {
  return (
    <WishlistHeaderLink>
      <PinsLoader>
        <Image
          resized={[]}
          src="/images/spinner-button.svg"
          alt="LOADING"
          className="pins-loader"
        />
      </PinsLoader>
    </WishlistHeaderLink>
  );
}
