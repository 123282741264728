import React from 'react';
import { SelectInput } from '@hotelplan/components.common.select-input';
import { WishlistProductItem } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import { useWishlistCompareOptions } from 'components/domain/wishlist/hooks';
import {
  COMPARE_ITEMS_COUNT_DESKTOP,
  COMPARE_ITEMS_COUNT_MOBILE,
} from 'components/domain/wishlist/utils';
import {
  SelectInputWrapper,
  WishlistCompareItemsWrapper,
} from './WishlistCompareSelect.styled';

export default function WishlistCompareSelect(): React.ReactElement {
  const { mobile } = useDeviceType();
  const { items, selectedItems, updateItem } =
    useWishlistSelectedItemsContext();

  const options = useWishlistCompareOptions(items);

  function handleItemSelectChange(value: string, index: number) {
    const selectedWishlistItem = items?.find(wI => wI.product.id === value);
    updateItem(
      selectedWishlistItem
        ? (selectedWishlistItem as WishlistProductItem)
        : null,
      index
    );
  }

  return (
    <WishlistCompareItemsWrapper>
      {Array.from({
        length: mobile
          ? COMPARE_ITEMS_COUNT_MOBILE
          : COMPARE_ITEMS_COUNT_DESKTOP,
      }).map((_, i) => {
        return (
          <React.Fragment key={`select-${i}`}>
            <SelectInputWrapper>
              <SelectInput
                hideLabel
                options={options[i]}
                value={selectedItems[i]?.product.id || ''}
                onChange={value => handleItemSelectChange(value, i)}
              />
            </SelectInputWrapper>
          </React.Fragment>
        );
      })}
    </WishlistCompareItemsWrapper>
  );
}
