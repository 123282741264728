import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { useField } from '@hotelplan/components.common.forms';
import { ALL_FILTERS_OPTIONS_ID } from './Filters.common';
import {
  CheckboxesWrap,
  DepartureAirportsCheckboxesWrap,
} from './Filters.styles';
import { IFilterOption } from './Filters.types';
import { CheckboxContent } from './SearchFilterCheckboxesGroup';
import useDepartureAirportsDefaultValue from './useDepartureAirportsDefaultValue';

const excludedFilterOptions = ['FDH', 'MXP'];

interface IDepartureAirportsCheckboxesFilterProps {
  filterOptions: IFilterOption[];
}

export function DepartureAirportsCheckboxesFilter({
  filterOptions,
}: IDepartureAirportsCheckboxesFilterProps): React.ReactElement {
  const [t] = useTranslation('filters');

  const { departureAirports, loading } = useDepartureAirportsDefaultValue();

  const [value, onChange] = useField<string[]>('departureAirports');
  const [checkedOptions, setCheckedOptions] = useState<string[]>(
    value.length ? value : departureAirports || [ALL_FILTERS_OPTIONS_ID]
  );
  const options = filterOptions.filter(
    ({ id }) => !excludedFilterOptions.includes(id)
  );

  useEffect(
    function updateFormState() {
      onChange(
        checkedOptions.length ? checkedOptions : [ALL_FILTERS_OPTIONS_ID]
      );
    },
    [checkedOptions]
  );

  if (loading) return null;

  return (
    <DepartureAirportsCheckboxesWrap>
      {t('filters:departure.airport.label')}
      <CheckboxesWrap>
        {options.map((option, index) => {
          const checked = checkedOptions?.includes(option.id);
          return (
            <Checkbox
              key={`${option.id}-${index}`}
              value={option.id}
              checked={checked}
              onChange={nextValue => {
                if (checkedOptions.includes(nextValue)) {
                  checkedOptions.length > 1
                    ? setCheckedOptions(
                        checkedOptions.filter(item => item !== nextValue)
                      )
                    : setCheckedOptions([ALL_FILTERS_OPTIONS_ID]);
                } else {
                  setCheckedOptions(
                    [...checkedOptions, nextValue].filter(
                      optionValue => optionValue !== ALL_FILTERS_OPTIONS_ID
                    )
                  );
                }
              }}
              content={<CheckboxContent caption={option.caption} />}
            />
          );
        })}
      </CheckboxesWrap>
    </DepartureAirportsCheckboxesWrap>
  );
}
