import React from 'react';
import styled from 'styled-components';
import { ProductClass, ProductType } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import OfferTransfer from 'components/domain/orl/offerItem/OfferTransfer';
import TripInfo from 'components/domain/orl/offerItem/TripInfo';
import useShowTransferInfo from 'components/domain/orl/useShowTransferInfo';
import { PriceFragment } from 'graphql/fragments/Price.generated';

const Wrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    '.trip-info': {
      fontSize: ['10px', '13px'],
      color: ['primary', 'textGray'],
      mb: [2, 3],
    },
    '.offer-transfer': {
      alignSelf: ['flex-start', 'flex-start'],
      '.price': {
        ml: 1,
      },
      p: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
);

interface IProductTripInfoAndTransferGroupProps {
  type: ProductType;
  duration: number;
  mealType: string;
  transferIncluded: boolean;
  transferPrice: PriceFragment;
  className?: string;
  productClass: ProductClass;
}

const ProductTripInfoAndTransferGroup: React.FC<
  IProductTripInfoAndTransferGroupProps
> = (props: IProductTripInfoAndTransferGroupProps) => {
  const {
    duration,
    mealType,
    transferIncluded,
    transferPrice,
    className,
    type,
    productClass,
  } = props;

  const showTransferInfo = useShowTransferInfo({
    transferIncluded,
    price: transferPrice,
  });

  return (
    <Wrapper className={className}>
      <TripInfo
        className="trip-info"
        type={type}
        duration={duration}
        mealType={mealType}
        productClass={productClass}
      />
      {showTransferInfo && (
        <OfferTransfer
          transferIncluded={transferIncluded}
          price={transferPrice}
        />
      )}
    </Wrapper>
  );
};

export default ProductTripInfoAndTransferGroup;
