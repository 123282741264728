import { Dispatch, useReducer } from 'react';

type TMenuState = {
  headerMenuOpen: boolean;
  meinKontoMenuOpen: boolean;
};

export enum HeaderMenuAction {
  TOGGLE_HEADER_MENU = 'TOGGLE_HEADER_MENU',
  CLOSE_HEADER_MENU = 'CLOSE_HEADER_MENU',
  TOGGLE_MEINKONTO_MENU = 'TOGGLE_MEINKONTO_MENU',
  CLOSE_MEINKONTO_MENU = 'CLOSE_MEINKONTO_MENU',
}

const initialState: TMenuState = {
  headerMenuOpen: false,
  meinKontoMenuOpen: false,
};

function reducer(prevState: TMenuState, action: { type: HeaderMenuAction }) {
  switch (action.type) {
    case HeaderMenuAction.TOGGLE_HEADER_MENU: {
      return {
        ...prevState,
        headerMenuOpen: !prevState.headerMenuOpen,
        meinKontoMenuOpen: !prevState.headerMenuOpen
          ? false
          : prevState.meinKontoMenuOpen,
      };
    }

    case HeaderMenuAction.TOGGLE_MEINKONTO_MENU: {
      return {
        ...prevState,
        meinKontoMenuOpen: !prevState.meinKontoMenuOpen,
        headerMenuOpen: !prevState.meinKontoMenuOpen
          ? false
          : prevState.headerMenuOpen,
      };
    }

    case HeaderMenuAction.CLOSE_HEADER_MENU: {
      return {
        ...prevState,
        headerMenuOpen: false,
      };
    }

    case HeaderMenuAction.CLOSE_MEINKONTO_MENU: {
      return {
        ...prevState,
        meinKontoMenuOpen: false,
      };
    }

    default:
      return prevState;
  }
}

type THeaderMenuReducer = [TMenuState, Dispatch<{ type: HeaderMenuAction }>];

export function useHeaderMenuReducer(): THeaderMenuReducer {
  return useReducer(reducer, initialState);
}
