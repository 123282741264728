import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type TextComponentFragment = {
  __typename?: 'TextComponent';
  title: string;
  text: string;
};

export const TextComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'textComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
