import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Icon } from '@hotelplan/components.common.icon';
import { PageType } from '@hotelplan/graphql.types';
import { SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH } from '@hotelplan/libs.auth-next';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';
import { LogoutButtonElement } from './LogoutLink.styled';
import { logoutRedirect } from './MyAccount.utils';

interface ILogoutLinkProps {
  className?: string;
}

export default function LogoutLink({
  className,
}: ILogoutLinkProps): React.ReactElement {
  const pageType = usePageType<PageType>();
  const { logout } = useAuthentication();
  const { push } = useRouter();
  const { isEnabled } = useFeatureToggle();
  const [t] = useTranslation('myaccount');
  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  const logoutHandler = useCallback(async () => {
    if (isMeinKontoEnabled) {
      push(SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH);
    } else {
      await logout();
      logoutRedirect(pageType);
    }
  }, [isMeinKontoEnabled, logout, pageType]);

  return (
    <LogoutButtonElement
      className={className}
      onClick={logoutHandler}
      data-id="logout-btn"
    >
      {t('logout')}
      <Icon name="logout" size="lg" />
    </LogoutButtonElement>
  );
}
