import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapOpeningHoursToSchedules } from '@hotelplan/libs.schedule';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';
import { useGetHeaderQuery } from 'graphql/layout/GetHeader.generated';

const ContactInfoWrapper = styled.div(
  sx2CssThemeFn({
    ml: [null, 'auto'],
    mt: [null, '15px'],
    display: 'flex',
    flexDirection: 'column',
    fontFamily: '"Helvetica Neue", sans-serif',
    lineHeight: '1',
    alignItems: 'end',
    '.phone-title': {
      color: 'primary',
      fontSize: '15px',
      letterSpacing: '0.2px',
    },
    '.phone': {
      color: ['primary', 'secondary'],
      fontSize: ['0px', '29px'],
      pt: [null, '2px'],
      pb: [null, '4px'],
      letterSpacing: '0.7px',
      mr: [3, 0],
    },
    '.opening-hours': {
      color: '#797979',
      textTransform: 'uppercase',
      fontSize: '15px',
    },
  })
);

export const ContactInfo: React.FC = () => {
  const { mobile } = useDeviceType();

  const { data } = useGetHeaderQuery();
  const { data: dataAgency } = useGetAgencyContactQuery();
  const [t] = useTranslation(['common', 'agency']);

  const contactData = data?.pageHeader.contactInformation;

  return (
    <ContactInfoWrapper>
      {!mobile && contactData?.phoneTitle ? (
        <span className="phone-title">{contactData?.phoneTitle}</span>
      ) : null}
      <a className="phone" href={`tel:${contactData?.phoneNumber}`}>
        {contactData?.phoneNumber}
        {mobile ? <Icon className="phone-icon" name="phone" size="lg" /> : null}
      </a>

      {!mobile && dataAgency ? (
        <span className="opening-hours">
          {mapOpeningHoursToSchedules(
            t,
            dataAgency?.agencyContact.openingHours
          ).join(' | ')}
        </span>
      ) : null}
    </ContactInfoWrapper>
  );
};
