import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Tooltip } from '@hotelplan/components.common.tooltip';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import FilterCounts from './FilterCounts';
import { useMainAmenitiesDescription } from './Filters.common';
import { IFilterOption, TFilterCountsOptions } from './Filters.types';

interface IProductFeaturesProps {
  options: IFilterOption[];
  formValue: string[];
  formOnChange: (value: string[]) => void;
  counts: TFilterCountsOptions;
}

const FeaturesWrapper = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    rowGap: 3,
    columnGap: [2, 0],
    py: [2, 0],
    px: [3, 0],
  })
);

const FeatureButton = styled(Button)<{ isSelected: boolean }>(
  ({ isSelected }) =>
    sx2CssThemeFn({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '11px',
      fontWeight: '300',
      bg: 'transparent',
      p: 0,
      '&:disabled': {
        opacity: 0.5,
        background: 'white',
        '&:hover': {
          cursor: 'initial',
        },
      },
      '.icon': {
        border: '1px solid',
        borderColor: isSelected ? 'secondary' : '#bbb',
        background: isSelected ? '#f60' : 'none',
        pt: '5px',
        pb: '7px',
        px: '0px',
        width: '100%',
        height: ['80px', '60px'],
        borderRight: [null, 'none'],
        color: isSelected ? 'white' : 'textGray',
        borderRadius: ['default', '0px'],
      },
      '&:first-child .icon, &:nth-child(5) .icon': {
        borderRadius: ['default', '5px 0 0 5px'],
      },
      '&:last-child .icon, &:nth-child(4) .icon': {
        borderRadius: ['default', '0 5px 5px 0'],
        borderRight: '1px solid #bbb',
      },
      '.feature-title': {
        mt: 3,
        color: 'darkGrey',
        fontFamily: 'Arial',
        lineHeight: '1',
      },
    })
);

export default function ProductFeatures({
  options,
  formValue,
  formOnChange,
  counts,
}: IProductFeaturesProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [showDescription, show, hide] = useToggleState(false);
  const [hoveredFeature, setHoveredFeature] = React.useState<string | null>(
    null
  );

  const { options: countsOptions, loading: countsLoading } = counts;
  const descriptions = useMainAmenitiesDescription(options);

  return (
    <FeaturesWrapper className={'product-features-wrapper'}>
      {options.map((option, index) => {
        return (
          <FeatureButton
            key={`${option.id}-${index}`}
            isSelected={formValue.includes(option.id)}
            onMouseEnter={() => {
              show();
              setHoveredFeature(option.id);
            }}
            onMouseLeave={() => {
              hide();
              setHoveredFeature(null);
            }}
            onClick={() => {
              formValue.includes(option.id)
                ? formOnChange(formValue.filter(v => v !== option.id))
                : formOnChange([...formValue, option.id]);
            }}
            icon={{
              name: `${mapFeatureNameToIcon(option.id)}`,
            }}
            disabled={
              !Boolean(
                counts.options?.find(count => count.id === option.id)
                  ?.productCount
              )
            }
          >
            <FilterCounts
              options={countsOptions}
              loading={countsLoading}
              filterOptionId={option.id}
            />
            <span
              dangerouslySetInnerHTML={{ __html: option.caption }}
              className="feature-title"
            />
            {showDescription && hoveredFeature === option.id && !mobile ? (
              <ProductFeatureDescriptionTooltip
                title={descriptions[hoveredFeature].title}
                text={descriptions[hoveredFeature].description}
                itemIndex={index}
              />
            ) : null}
          </FeatureButton>
        );
      })}
    </FeaturesWrapper>
  );
}

const TooltipWrapper = styled.div<{ itemIndex?: number }>(({ itemIndex }) => {
  const isLeftAligned = itemIndex % 4 === 3;
  const isRightAligned = itemIndex % 4 === 0;

  return sx2CssThemeFn({
    position: 'absolute',
    zIndex: 10,
    bottom: '0',
    top: '0',
    left: isRightAligned ? '0' : 'auto',
    right: isLeftAligned ? '0' : 'auto',
    '.tooltip, .tooltipArrow': {
      visibility: 'visible',
      p: '0px',
      bottom: 'auto',
      top: '-9px',
      left: isRightAligned ? '0' : 'auto',
      right: isLeftAligned ? '0' : 'auto',
      transform: isLeftAligned
        ? 'translateY(-100%)'
        : isRightAligned
        ? 'translateY(-100%)'
        : 'translate(-50%, -100%)',
      border: 'none',
      '&:before': {
        bottom: '-7px',
        right: isLeftAligned ? '0' : 'auto',
        left: isRightAligned ? '15px' : 'auto',
      },
      '&:after': {
        bottom: '-6px',
        right: isLeftAligned ? '0' : 'auto',
        left: isRightAligned ? '15px' : 'auto',
      },
    },
    '.tooltipArrow': {
      display: 'none',
    },
  });
});

interface IProductFeatureDescriptionTooltipProps {
  title: string;
  text: string;
  itemIndex?: number;
}
function ProductFeatureDescriptionTooltip({
  text,
  title,
  itemIndex,
}: IProductFeatureDescriptionTooltipProps): React.ReactElement {
  return (
    <TooltipWrapper itemIndex={itemIndex}>
      <Tooltip>
        <h2 className={'title'} dangerouslySetInnerHTML={{ __html: title }} />
        <p className={'text'} dangerouslySetInnerHTML={{ __html: text }} />
      </Tooltip>
    </TooltipWrapper>
  );
}
