import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const BestsellerProductIcon = styled(Icon).attrs({
  name: 'map-bestseller',
})({});

const BestsellerProductPinIcon = styled(Icon).attrs({
  name: 'map-pin-bestseller',
  size: 'xlg',
})(
  sx2CssThemeFn({
    display: 'inline-block',
    pr: 2,
    my: -4,
  })
);

export default BestsellerProductPinIcon;
