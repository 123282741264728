import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterOption.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { RadiobuttonGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterOption.generated';
import { RadiusFilterFragmentDoc } from 'graphql/searchFilter/RadiusFilter.generated';
export type SrlFilterOptionsFragment = {
  __typename?: 'FilterComponent';
  departureAirports: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  hpRating: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  taRating: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  roomTypes: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  boardTypes: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  stopOvers: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  mainFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  childrenFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  beachFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  cityFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  winterSportFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  summerSportFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  waterSportFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  wellnessFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  sustainableFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  hotelEntertainmentFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  hotelFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  nationalParkFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  nightlifeFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  cultureFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  resortNatureFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  wlanFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  petsFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  ECarChargingFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  resortFlairFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  resortShoppingFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  luxuryFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  clubHotelsFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  adultsFeatures: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  radius?: {
    __typename?: 'RadiusFilterComponent';
    label?: string | null;
    radius: number;
    center: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
};

export const SrlFilterOptionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SRLFilterOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hpRating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taRating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childrenFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'beachFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'winterSportFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summerSportFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waterSportFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellnessFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sustainableFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelEntertainmentFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalParkFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nightlifeFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cultureFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resortNatureFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wlanFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'petsFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ECarChargingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resortFlairFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resortShoppingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'luxuryFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clubHotelsFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adultsFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'radius' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'radiusFilter' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterOptionFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...RadiobuttonGroupFilterOptionFragmentDoc.definitions,
    ...RadiusFilterFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
