import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ToolsWrap } from 'components/domain/sitetools';
import {
  WishlistIcon,
  WishlistItemRemoveIconContainer,
} from 'components/domain/wishlist/ui';

export const WishlistOverviewWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    bg: 'white',
    borderRadius: ['0px', '5px 5px 0 0'],
    p: [2, 5],
    '.wishlist-overview-section': {
      pt: 0,
      borderTop: '0px',
    },
  })
);

export const WishlistOverviewSectionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    gap: [2, '20px'],
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'borderGray',
    py: 4,
    overflowX: 'auto',
  })
);

export const WishlistCardWrapper = styled.div<{ isAuthenticated?: boolean }>(
  ({ isAuthenticated }) =>
    sx2CssThemeFn({
      border: '1px solid',
      borderColor: 'borderGray',
      height: ['140px', '170px'],
      width: ['100%', 'calc(20% - 22px)'],
      minWidth: ['calc(50% - 4px)', 'calc(20% - 16px)'],
      display: 'flex',
      flexDirection: 'column',
      '.trash-icon': {
        display: isAuthenticated ? 'block' : 'none',
        right: 0,
      },
    })
);

export const WishlistCardContentWrapper = styled.div(
  sx2CssThemeFn({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    px: 2,
    '.primary-button': {
      width: ['fit-content', '100%'],
      m: 0,
    },
    '.wishlist-name': {
      textAlign: 'center',
      color: 'textGray',
      fontSize: ['14px', 1],
      fontFamily: '"Helvetica Neue",sans-serif',
      '&.active': {
        color: 'secondary',
      },
    },
    '.wishlist-selected': {
      color: 'green',
      display: 'flex',
      alignItems: 'center',
      gap: [1, 2],
      fontSize: ['12px', '14px'],
      '.icon': {
        height: ['30px', '35px'],
      },
    },
  })
);

export const WishlistCardContainer = styled(WishlistItemRemoveIconContainer)(
  sx2CssThemeFn({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    '.trash-icon': {
      borderRight: 'none',
      borderTop: 'none',
    },
  })
);

export const WishlistCardHeartIconWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    py: [2, '15px'],
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderColor: 'borderGray',
    '.icon': {
      width: '33px',
      height: '33px',
      color: 'white',
      zIndex: 1,
    },
  })
);

export const WishlistOverviewFooter = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column-reverse', 'row'],
    justifyContent: ['center', 'space-between'],
    pt: [3, 7],
    pb: [2, 0],
    borderBottom: [null, '1px solid'],
    borderColor: [null, 'borderGray'],
  })
);

export const ShareSiteToolWrapper = styled(ToolsWrap)(
  sx2CssThemeFn({
    border: ['none', 'none'],
    borderBottom: ['1px solid', 'none'],
    borderColor: 'borderGray',
    boxShadow: 'none',
    borderRadius: 0,
    mb: [3, 0],
    '.sitetool-item': {
      border: '1px solid',
      borderBottom: 'none',
      borderRadius: '5px 5px 0 0',
      borderColor: 'borderGray',
    },
  })
);

export const WishlistIconWrap = styled(WishlistIcon)(
  sx2CssThemeFn({
    '&:after': {
      width: '40px',
      height: '40px',
    },
  })
);
