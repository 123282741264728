import React, { useEffect, useState } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  MyAccountHeaderButton,
  MyAccountHeaderMenu,
} from 'components/domain/my-account/header-section';
import { WishlistHeaderButton } from 'components/domain/wishlist';
import { scrollToTop } from 'libs';
import { HeaderActionButtonsWrapper } from './Header.styled';

interface IHeaderActionButtonsSectionProps {
  isMenuOpened?: boolean;
  closeMenu?(): void;
  toggleMenu?(): void;
}

export default function HeaderActionButtonsSection({
  isMenuOpened,
  closeMenu,
  toggleMenu,
}: IHeaderActionButtonsSectionProps): React.ReactElement {
  const { mobile } = useDeviceType();

  const { channelType } = useAuthentication();

  const isAuthenticated = channelType !== AuthChannelType.B2CAnonymous;
  const isB2B = channelType === AuthChannelType.B2B;

  function handleToggleMenu() {
    toggleMenu();
    !isMenuOpened && mobile && scrollToTop('auto');
  }

  const [wishlistPosition, setWishlistPosition] = useState('sticky');
  const listenScrollEvent = () => {
    window.scrollY > 35
      ? setWishlistPosition('fixed')
      : setWishlistPosition('sticky');
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  return (
    <>
      <HeaderActionButtonsWrapper
        isAuthenticated={isAuthenticated}
        wishlistPosition={wishlistPosition}
      >
        {!isB2B ? (
          <MyAccountHeaderButton
            isAuthenticated={isAuthenticated}
            isMenuOpened={isMenuOpened}
            closeMenu={closeMenu}
            toggleMenu={handleToggleMenu}
          />
        ) : null}
        <WishlistHeaderButton isAuthenticated={isAuthenticated} />
      </HeaderActionButtonsWrapper>
      <MyAccountHeaderMenu
        isOpened={mobile && isMenuOpened}
        onMenuItemClick={closeMenu}
      />
    </>
  );
}
