import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { getNavigationLinks } from '@hotelplan/components.common.menu';
import { Navigable } from '@hotelplan/graphql.types';
import { useGetHeaderQuery } from 'graphql/layout/GetHeader.generated';
import logoFallback from 'public/images/logo-migros-ferien.svg';

export function useHeaderNavigation() {
  const [t] = useTranslation('common');
  const { data, loading } = useGetHeaderQuery();

  return useMemo(
    () => ({
      loading,
      logo:
        !loading && !data
          ? {
              alt: t('copyright.text'),
              resized: [
                { id: '1x_desktop', url: logoFallback },
                { id: '1x_mobile', url: logoFallback },
              ],
            }
          : data?.pageHeader.logo,
      navigationLinks: getNavigationLinks(
        (data?.pageHeader.navigationMenu || []) as Array<Navigable>
      ),
    }),
    [data, loading, t]
  );
}
