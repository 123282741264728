import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';
import React, { useRef } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  ITravelDatesState,
  TravelDatesConfiguration,
  TravelDatesCustomConfiguration,
  TravelDatesDesktopDropdown,
  TravelDatesField,
  TravelDatesHandlers,
} from '@hotelplan/components.common.travel-dates';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const DepartureFieldWrapper = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: [null, '1fr 145px'],
    alignItems: 'center',
    mr: [null, 4],
    mb: [null, '20px'],
    '.label': {
      fontSize: ['12px', '14px'],
      mb: [1, 0],
      fontWeight: 'bold',
    },
    '.absolute-dropdown': {
      position: 'absolute',
    },
  })
);

const today = new Date();

interface ICustomTravelDatesFieldProps {
  travelDatesState: ITravelDatesState;
  travelDatesHandlers: TravelDatesHandlers;
  customConfiguration: TravelDatesCustomConfiguration;
  initialDepartureDate: Date;
  initialReturnDate: Date;
  initialMonth: Date;
  fieldDate: string;
  fieldLabel: string;
  onSaveTravelDates(input: ITravelDatesState): void;
  className?: string;
}

export default function CustomTravelDatesField({
  travelDatesState,
  travelDatesHandlers,
  initialReturnDate,
  initialMonth,
  onSaveTravelDates,
  customConfiguration,
  fieldDate,
  fieldLabel,
  className,
}: ICustomTravelDatesFieldProps): React.ReactElement {
  const { mobile } = useDeviceType();

  const fieldRef = useRef<HTMLInputElement>(null);
  const datePickerDropdownRef = useRef<HTMLDivElement>(null);
  const datePickerContainerRef = useRef<HTMLDivElement>(null);
  const [showDatePicker, , close, toggle] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLInputElement>(
    [datePickerContainerRef, fieldRef],
    close,
    showDatePicker
  );

  const configuration: TravelDatesConfiguration = {
    hasApplyBtn: !mobile,
    hasNavbar: true,
    hasCustomWeekDayElement: false,
    numberOfMonthsToShow: 1,
    canChangeMonth: true,
    changeYears: {
      enabled: true,
      minYear: subYears(today, 1).getFullYear(), // 1 year before
      maxYear: addYears(today, 1).getFullYear(), // 1 year after
    },
    initialMonth,
  };

  const customTravelDatesHandlers: TravelDatesHandlers = {
    ...travelDatesHandlers,
    setDepartureDate(day: Date) {
      travelDatesHandlers.setDepartureDate(day);
      customConfiguration.closeOnSelection && close();
    },
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      customConfiguration.closeOnSelection && close();
    },
  };

  useDeepCompareEffect(() => {
    if (mobile) onSaveTravelDates(travelDatesState);
  }, [travelDatesState]);

  return (
    <DepartureFieldWrapper className={className}>
      <label className="label">{fieldLabel}</label>
      <TravelDatesField
        isDropdownOpened={false}
        translatedShortInformation={fieldDate}
        textInputRef={fieldRef}
        onClick={toggle}
        className="customTravelDatesField"
      >
        <TravelDatesDesktopDropdown
          containerRef={datePickerContainerRef}
          dropdownRef={datePickerDropdownRef}
          isShown={showDatePicker}
          side={'right'}
          travelDatesState={travelDatesState}
          travelDatesHandlers={customTravelDatesHandlers}
          initialReturnDate={initialReturnDate}
          customConfiguration={customConfiguration}
          configuration={configuration}
          onSaveTravelDates={onSaveTravelDates}
        />
      </TravelDatesField>
    </DepartureFieldWrapper>
  );
}
