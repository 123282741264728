import { useCallback, useEffect, useState } from 'react';
import { GeoObject } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { ISrlState } from 'components/domain/srl/SRL.types';

enum SearchMapEvents {
  OPEN_MAP = 'open-map',
  CLOSE_MAP = 'close-map',
}

export function listenOpenSearchMap(
  callback: (e: CustomEvent<TSearchMapGeoObjects>) => void
) {
  window.addEventListener(SearchMapEvents.OPEN_MAP, callback);
  return () => window.removeEventListener(SearchMapEvents.OPEN_MAP, callback);
}

export function listenCloseSearchMap(
  callback: (e: CustomEvent<TSearchMapGeoObjects>) => void
) {
  window.addEventListener(SearchMapEvents.CLOSE_MAP, callback);
  return () => window.removeEventListener(SearchMapEvents.CLOSE_MAP, callback);
}

type TSearchMapGeoObjects = {
  activeGeoObject: GeoObject | null;
  searchedObjects: Array<GeoObject> | null;
};

export function openSearchMap(
  searchMapGeoObjects: TSearchMapGeoObjects | null = null
) {
  window.dispatchEvent(
    new CustomEvent(SearchMapEvents.OPEN_MAP, {
      detail: searchMapGeoObjects,
    })
  );
}
export function closeSearchMap() {
  window.dispatchEvent(new CustomEvent(SearchMapEvents.CLOSE_MAP));
}

interface IUseSearchMapEvents {
  openMap?(): void;
  closeMap?(): void;
}

export default function useSearchMapEvents({
  openMap,
  closeMap,
}: IUseSearchMapEvents): TSearchMapGeoObjects | null {
  const [searchMapGeoObjects, setSearchMapGeoObjects] =
    useState<TSearchMapGeoObjects>(null);
  const { setState } = useSearchState<ISrlState>();

  const handleSearchMapEvent = useCallback<
    (callback: () => void, reRender: boolean) => void
  >(
    (callback, reRender) => {
      if (callback) {
        callback();
        setState(prev => ({
          ...prev,
          formConfig: {
            ...prev.formConfig,
            reRender,
          },
        }));
      }
    },
    [setState]
  );

  useEffect(() => {
    listenOpenSearchMap(e => {
      setSearchMapGeoObjects(e.detail);
      handleSearchMapEvent(openMap, true);
    });
  }, [openMap, handleSearchMapEvent]);

  useEffect(() => {
    listenCloseSearchMap(() => handleSearchMapEvent(closeMap, false));
  }, [closeMap, handleSearchMapEvent]);

  return searchMapGeoObjects;
}
