import { mapTravelDatesModalToTrackableTravelDates } from '@hotelplan/components.common.travel-dates';
import { mapCanonicalDestinationToTrackableDestination } from '@hotelplan/components.common.travel-destination';
import {
  SrlGeoGroupResult,
  SrlGeoItem,
  SrlGroupSorting,
  SrlProductSorting,
  SrlSingleItem,
  TravelType,
} from '@hotelplan/graphql.types';
import {
  IFiltersTrackableData,
  IRunSearchData,
  ISearchControlTrackableData,
  SRLResultsEventMetaData,
  TravelType as TrackingTravelType,
} from '@hotelplan/libs.tracking';
import {
  SRLGeoMetaData,
  SRLNudgeEventMetaData,
  SRLProductEventMetaData,
} from '@hotelplan/libs.tracking/trackingEvents/trackSRLResult';
import { IFiltersSearchState } from 'components/domain/filters/Filters.types';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { ISrlState } from 'components/domain/srl/SRL.types';
import {
  GetSrlResultsQuery,
  SrlSingleResultFragment,
} from 'graphql/srl/GetSRLResults.generated';

function mapSRLGroupResultsToTrackableData(
  groupData: SrlGeoGroupResult,
  state: ISrlState,
  queryTime = 0
): SRLResultsEventMetaData {
  const type = 'groups';
  const success = true;
  const price = groupData.cheapestGroup.perPersonPrice.amount;
  const perfomance = { time: queryTime };

  const groups = groupData.groups.map(group => {
    return {
      geoObject: {
        id: group.geoObject.id,
        name: group.geoObject.name,
        type: group.geoObject.type as string,
      },
    };
  });
  const results = groupData.productsTotal;

  return {
    type,
    success,
    price,
    perfomance,
    groups,
    results,
    sort: SrlGroupSorting.GroupAlphabetic,
  };
}

export function mapSRLEmptyResultsToTrackableSRLResultsEventMetaData(
  state: ISrlState & { productSorting?: SrlProductSorting },
  queryTime = 0
): SRLResultsEventMetaData {
  const type = 'products';
  const success = false;
  const perfomance = { time: queryTime };
  const price = '';
  const results = 0;
  const sort = '' as SrlProductSorting;
  const products = [];

  return {
    type,
    success,
    perfomance,
    price,
    results,
    sort,
    products,
    page: 0,
  };
}

export function mapSRLResultsToTrackableData(
  data: GetSrlResultsQuery,
  state: ISrlState,
  queryTime = 0
): SRLResultsEventMetaData {
  if (data?.srl.search.group.__typename === 'SrlGeoGroupResult') {
    const groupData = data.srl.search.group as SrlGeoGroupResult;
    return mapSRLGroupResultsToTrackableData(
      groupData as SrlGeoGroupResult,
      state,
      queryTime
    );
  }

  if (data?.srl.search.group.__typename === 'SrlEmptyResult') {
    return mapSRLEmptyResultsToTrackableSRLResultsEventMetaData(
      state,
      queryTime
    );
  }

  return;
}

const travelTypeToTrackingType: {
  [K in TravelType]: TrackingTravelType;
} = {
  [TravelType.Flight]: 'flight',
  [TravelType.Hotel]: 'hotel',
  [TravelType.Package]: 'package',
};

const mapTravelTypeToTrackableTravelType = (travelType: TravelType) => {
  return travelTypeToTrackingType[travelType];
};

export function mapSRLStateToTrackableData(state: ISrlState): IRunSearchData {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFiltersStateToTrackableData(state.filters),
  };
}

export function mapSearchControlFormStateToTrackableData(
  state?: ISearchControlState | null
): ISearchControlTrackableData | null | undefined {
  if (!state) return;

  return {
    type: mapTravelTypeToTrackableTravelType(
      state?.travelType || TravelType.Package
    ),
    travelPeriod: state?.travelDates
      ? mapTravelDatesModalToTrackableTravelDates(state.travelDates)
      : undefined,
    text: (state?.travelDestination?.destinations || []).map(
      mapCanonicalDestinationToTrackableDestination
    ),
    travellers: state?.travelRooms
      ? {
          adults: state.travelRooms.adults,
          children: state.travelRooms.childrenDobs,
          rooms: state.travelRooms.rooms,
          travellersDistribution: state.travelRooms.travellersDistribution,
        }
      : undefined,
  };
}

export function mapFiltersStateToTrackableData(
  state?: IFiltersSearchState | null
): IFiltersTrackableData | null | undefined {
  if (!state) return;

  return {
    attributes: {
      keys:
        state?.productFeatures?.length > 0 ? state.productFeatures : undefined,
    },
    flightOptions: {
      departure: state?.flightOptions.directFlightDepartureTime ?? undefined,
      arrival: state?.flightOptions.returnFlightDepartureTime ?? undefined,
      stopOvers: state?.flightOptions.stopOvers ?? undefined,
      airLines: undefined,
      stopOverTime: undefined,
    },
    hotelPlanRating: state?.hpRating ?? undefined,
    tripAdvisorRating: state?.taRating ?? undefined,
    price: {
      max: state?.maxPrice.toString() ?? undefined,
    },
    room: {
      boards: state?.boardTypes ?? undefined,
      types: state?.roomTypes ?? undefined,
    },
  };
}

export const mapSRLSingleResultsToTrackableSRLResultsEventMetaData = (
  singleData: SrlSingleResultFragment,
  state: ISrlState & { productSorting?: SrlProductSorting },
  queryTime = 0
): SRLResultsEventMetaData => {
  const type = 'products';
  const success = true;
  const price = singleData.cheapestProduct.price.amount;
  const perfomance = { time: queryTime };
  const { products, nudges } =
    mapSrlSingleItemsToNudgesAndProductsEventMetaData(
      singleData.items as SrlSingleItem[]
    );
  const results = singleData.productsTotal;

  return {
    type,
    success,
    price,
    perfomance,
    products,
    nudges,
    results,
    sort: state.productSorting,
    page: singleData.page.pageNumber,
  };
};

export const mapSrlSingleItemsToNudgesAndProductsEventMetaData = (
  items: (SrlSingleItem | SrlGeoItem)[]
) => {
  const nudges: SRLNudgeEventMetaData[] = [];
  const products: SRLProductEventMetaData[] = [];

  items.forEach((item, i) => {
    if (item.__typename === 'NudgeItem') {
      nudges.push({ nudge: item.text, pos: i + 1 });
    }

    if (item.__typename === 'SrlProductItem') {
      const from = item.departureDate;
      const to = item.returnDate;
      const los = item.duration;

      let geo;
      item.geoBreadcrumbs.forEach(geoBr => {
        geo = {
          ...geo,
          [geoBr.type.toLowerCase()]: {
            id: geoBr.id,
            label: geoBr.name,
          },
        };
      });

      products.push({
        id: item.giata,
        price: item.price.amount,
        types: item.featureSummary.map(feature => feature.id),
        category: item.hpRating,
        disruptor: item.disrupter,
        date: {
          from,
          to,
          los,
        },
        rooms: item.rooms.map(room => ({
          board: room.boardType,
          room: room.roomType,
          bookingCode: room.bookingCode,
        })),
        source: item.provider,
        code: item.productCode,
        rating: item.taRating,
        geo,
      });
    }

    if (item.__typename === 'SrlGeoItem') {
      const geo = {
        [item.geoObject.type.toLowerCase()]: {
          id: item.geoObject.id,
          label: item.geoObject.name,
        },
      } as unknown as SRLGeoMetaData;

      products.push({
        price: item.perPersonPrice.amount,
        disruptor: item.disrupter,
        geo,
      });
    }
  });

  return { nudges, products };
};
