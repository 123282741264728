import { useRenameWishlistMutation } from 'graphql/wishlist/mutations/RenameWishlist.generated';

export default function useRenameWishlist() {
  const [mutation] = useRenameWishlistMutation({
    refetchQueries: [`GetWishlistOverview`],
  });

  async function renameWishlist(newName: string, id: string) {
    await mutation({
      variables: {
        newName,
        id,
      },
    });
  }

  return {
    renameWishlist,
  };
}
