import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistEditInputWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    fontFamily: '"Helvetica Neue", sans-serif',
    h1: {
      fontSize: ['20px', '26px'],
      color: 'secondary',
      mr: 2,
    },
    '.name': {
      fontSize: ['24px', '26px'],
      color: 'textGray',
    },
    '.section': {
      display: 'flex',
      mt: [2, 0],
    },
  })
);

export const EditButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: {
    name: 'edit',
  },
})(sx2CssThemeFn({}));

export const EditInput = styled(BaseInput)(
  sx2CssThemeFn({
    '.label': {
      display: 'none',
    },
    '.input': {
      bg: '#ffc',
      boxShadow: 'inset 1px 1px 3px rgba(0,0,0,0.35)',
      borderRadius: '5px 0 0 5px',
      height: ['40px', '27px'],
    },
  })
);

export const SubmitButton = styled(Button)(
  sx2CssThemeFn({
    height: ['40px', '27px'],
    p: ['0 12px', '0 8px'],
    borderRadius: '0 5px 5px 0',
    bg: 'secondary',
  })
);
