import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PageFragmentDoc } from './Page.generated';
import { DestinationFragmentDoc } from './Destination.generated';
export type AutocompleteDestinationsFragment = {
  __typename?: 'AutocompleteComponent';
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsTotal?: number | null;
    resultsPerPage?: number | null;
  };
  destinations: Array<{
    __typename?: 'TravelDestination';
    id: string;
    name: string;
    type: Types.TravelDestinationType;
    mythosCode?: string | null;
    location?: {
      __typename?: 'GeoLocation';
      airport?: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      } | null;
      continent?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      countryGroup?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      country?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      region?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      destination?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      resort?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
    } | null;
  }>;
};

export const AutocompleteDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'autocompleteDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AutocompleteComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'destination' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PageFragmentDoc.definitions,
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
