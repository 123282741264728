import React, { useRef } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import useSRLFilterOptions from 'components/domain/srl/useSRLFilterOptions';
import { mapSubAmenityNameToIcon } from './Filters.mappers';
import { IFilterOption, TFilterOptionName } from './Filters.types';
import SubAmenitiesFilterField from './SubAmenitiesFilterFileld';
import SubAmenitiesGroup from './SubAmenitiesGroup';
import { useSearchFilters } from './useSearchFilters';

type TFilterFieldProps = {
  formFieldName: string;
  filterCaption: string;
  options: IFilterOption[];
  filter: string;
};

interface ISearchFiltersProps {
  includedOptions?: Array<TFilterOptionName>;
  excludedOptions?: Array<TFilterOptionName>;
  showSubAmenities?: boolean;
  allowMapFiltersAccess?: boolean;
  mapFilterAccess?: React.ReactNode | null;
  isWishlist?: boolean;
}

export default function SearchFilters({
  includedOptions,
  excludedOptions,
  showSubAmenities = true,
  allowMapFiltersAccess = true,
  mapFilterAccess,
  isWishlist = false,
}: ISearchFiltersProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const subAmenities = useRef<Array<TFilterFieldProps>>([]);

  const {
    options,
    optionCaptions,
    loading: optionsLoading,
    finalCounts,
  } = useSRLFilterOptions(isWishlist);

  const filters = useSearchFilters();

  if (optionsLoading || !options) return null;

  return (
    <>
      {Object.keys(options).map((filter, index) => {
        if (
          (includedOptions &&
            !includedOptions.includes(filter as TFilterOptionName)) ||
          (excludedOptions &&
            excludedOptions?.includes(filter as TFilterOptionName))
        )
          return;

        const searchFilter = filters[filter];
        if (!searchFilter) {
          const currentSubAmenities = subAmenities.current;
          const subAmenitiesFilter: TFilterFieldProps = {
            filterCaption: optionCaptions[filter],
            formFieldName: filter,
            options: options[filter],
            filter,
          };

          !currentSubAmenities.some(f => f.formFieldName === filter) &&
            currentSubAmenities.push(subAmenitiesFilter);
          return;
        }

        const renderedFilter = searchFilter.render(
          {
            filterCaption: optionCaptions[filter],
            [filter === 'flightOptions' ? 'flightOptions' : 'filterOptions']:
              options[filter],
            counts: {
              ...finalCounts.mainFilters[filter],
              loading: finalCounts.mainFilters.loading,
            },
          },
          index
        );

        if (filter === 'productFeatures' && allowMapFiltersAccess) {
          return (
            <>
              {mapFilterAccess}
              {renderedFilter}
            </>
          );
        }

        return renderedFilter;
      })}

      {subAmenities.current.length && !mobile && showSubAmenities ? (
        <SubAmenitiesFilterField>
          {subAmenities.current.map((filterProps, index) => (
            <SubAmenitiesGroup
              key={`subAmenities-${index}`}
              {...filterProps}
              formFieldName={'productFeatures'}
              counts={{
                ...finalCounts.subAmenities[filterProps.formFieldName],
                loading: finalCounts.subAmenities.loading,
              }}
              icon={{
                name: mapSubAmenityNameToIcon(filterProps.formFieldName),
                size: 'xxlg',
              }}
            />
          ))}
        </SubAmenitiesFilterField>
      ) : null}
    </>
  );
}
