import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import {
  IRouterLinkProps,
  RouterLink,
} from '@hotelplan/components.common.router-link';
import { TabControls, TabControl } from '@hotelplan/components.common.tabs';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ITabsItem } from 'components/core/tabs/Tabs.types';

interface IPageTabControlProps<T> {
  tabKey: T;
  routerProps?: IRouterLinkProps;
  onClick?(): void;
  children: ReactNode;
}

const AuxiliaryTabControl = styled(TabControls)(
  sx2CssThemeFn(() => ({
    flex: 0,
  }))
);

const TabLink = styled(Link)(
  sx2CssThemeFn(() => ({
    display: [null, 'table-cell'],
    flex: ['1 1 auto', 'auto'],
    '.tabControls': {
      px: ['10px', 1],
    },
    '&:last-child': {
      '.tabControls': {
        mr: [null, '-1px'],
      },
    },
  }))
);

const PageTabControl = <T,>(props: IPageTabControlProps<T>) => {
  const { tabKey, routerProps, children, onClick } = props;

  return (
    <TabControl tabKey={tabKey}>
      {context => {
        const checked = context.activeTab === tabKey;
        return (
          <RouterLink
            {...routerProps}
            passHref
            isExternal={false}
            shallow
            scroll={false}
          >
            <TabLink testId={`${tabKey}-tab`} onClick={onClick}>
              <TabControls checked={checked}>{children}</TabControls>
            </TabLink>
          </RouterLink>
        );
      }}
    </TabControl>
  );
};

interface IPageTabControlAuxiliaryProps<T> {
  keys: T[];
  children: ReactNode;
}

const PageTabControlAuxiliary = <T,>(
  props: IPageTabControlAuxiliaryProps<T>
) => {
  const { keys, children } = props;
  const [isMenuOpened, , , toggleMenu] = useToggleState(false);

  return (
    <div className="auxiliary-list">
      <TabControl tabKey={keys.join('/')}>
        {context => {
          const checked = keys.includes(context.activeTab as T);

          return (
            <>
              <AuxiliaryTabControl checked={checked} onClick={toggleMenu}>
                <div>
                  <Icon name="dots-vertical" size="lg" />
                </div>
              </AuxiliaryTabControl>
              {isMenuOpened && children}
            </>
          );
        }}
      </TabControl>
    </div>
  );
};

const TabList = styled.ul(
  sx2CssThemeFn({
    position: 'relative',
    display: ['flex', 'table'],
    width: '100%',
    my: '-1px',
    mr: '-1px',
    '.auxiliary-list': {
      display: 'table-cell',
      width: '40px',
      position: 'relative',
      '.auxiliary-list-tab': {
        color: 'primary',
      },
      '.auxiliary-list-tab:after': {
        transform: 'rotate(-90deg)',
        content: '"..."',
        display: 'block',
        fontSize: '30px',
        position: 'relative',
        top: '8px',
        left: '2px',
        width: '19px',
        height: '34px',
      },
      '.tabControls': {
        borderRightColor: 'white',
        px: 2,
      },
    },
  })
);

const AuxiliaryList = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '44px',
    right: '0px',
    width: '120px',
    background: 'white',
    zIndex: '1',
    '.tabControls': {
      height: '40px',
      pl: 2,
      alignItems: 'start',
      borderBottom: '1px solid #bbb',
      mb: '-1px',
    },
  })
);

interface ITabControlsProps<T> {
  tabItems: ITabsItem<T>[];
}

const PageTabControls = <T,>(props: ITabControlsProps<T>) => {
  const { mobile } = useDeviceType();
  const { tabItems } = props;

  const mainItems = useMemo<ITabsItem<T>[]>(
    () => tabItems.filter(i => !i.auxiliary),
    [tabItems]
  );
  const auxiliaryItems = useMemo<ITabsItem<T>[]>(
    () => tabItems.filter(i => i.auxiliary),
    [tabItems]
  );
  const auxiliaryKeys = useMemo<T[]>(
    () => auxiliaryItems.map(i => i.tab),
    [auxiliaryItems]
  );

  return (
    <TabList>
      {mainItems.map(item => {
        const { label, ...other } = item;
        return (
          <PageTabControl<T>
            key={item.tab as string}
            tabKey={item.tab}
            {...other}
          >
            {label}
          </PageTabControl>
        );
      })}
      {auxiliaryItems.length > 0 && mobile && (
        <PageTabControlAuxiliary keys={auxiliaryKeys}>
          <AuxiliaryList>
            {auxiliaryItems.map(item => {
              const { label, ...other } = item;
              return (
                <PageTabControl<T>
                  key={item.tab as string}
                  tabKey={item.tab}
                  {...other}
                >
                  {label}
                </PageTabControl>
              );
            })}
          </AuxiliaryList>
        </PageTabControlAuxiliary>
      )}
    </TabList>
  );
};

export default React.memo(PageTabControls) as {
  <T>(props: ITabControlsProps<T>): JSX.Element | null;
};
