import React, { useRef } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Tooltip } from '@hotelplan/components.common.tooltip';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import useSearchControlTooltip from 'components/domain/search-control/hooks/useSearchControlTooltip';
import { useHover } from 'libs';

const Wrapper = styled.div({
  position: 'relative',
});

const InfoIcon = styled(Icon).attrs({
  name: 'info-white',
  size: 'sm',
})(sx2CssThemeFn({}));

const TooltipWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    '.tooltip': {
      position: 'relative',
      maxWidth: '500px',
      visibility: 'visible',
      zIndex: 2,
      top: '50%',
      transform: 'translateY(-58%)',
      left: '28px',
      p: '0px',
      border: 'none',
    },
  })
);

export default function SearchControlInfoIcon(): React.ReactElement {
  const { title, description } = useSearchControlTooltip();

  const infoIconRef = useRef<HTMLDivElement>(null);
  const [showTooltip, open, close] = useToggleState(false);

  useHover(infoIconRef, open, close);

  return (
    <Wrapper ref={infoIconRef}>
      <InfoIcon />
      {showTooltip ? (
        <TooltipWrapper>
          <Tooltip className="arrow-left">
            <h2 className={'title'}>{title}</h2>
            <p className={'text'}>{description}</p>
          </Tooltip>
        </TooltipWrapper>
      ) : null}
    </Wrapper>
  );
}
