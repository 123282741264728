import React, { PropsWithChildren } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { TabContent, Tabs } from '@hotelplan/components.common.tabs';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { Container } from 'components/core/container';
import PageTabControls from 'components/core/tabs/TabControls';
import { ITabsItem } from 'components/core/tabs/Tabs.types';
import SRLProductsSkeleton from 'components/domain/srl/SRLProducts.skeleton';
import {
  EmptyWishlist,
  LoginLinkOverlay,
  WishlistOverviewSection,
} from 'components/domain/wishlist/components';
import { WishlistSelectedItemsProvider } from 'components/domain/wishlist/context';
import { useWishlistSharingUrl } from 'components/domain/wishlist/hooks';
import { WishlistTabs } from 'components/domain/wishlist/utils';
import { useGetActiveWishlistQuery } from 'graphql/wishlist/queries/GetActiveWishlist.generated';
import LegacyDefaultEmptyWishlist from './LegacyDefaultEmptyWishlist';
import WishlistContainer from './WishlistContainer';
import {
  SRLProductsSkeletonWrap,
  WishlistContentWrapper,
} from './WishlistPageLayout.styled';

interface IWishlistPageLayoutProps {
  tabKey: WishlistTabs;
  tabItems: Array<ITabsItem<WishlistTabs>>;
}

export default function WishlistPageLayout({
  tabKey,
  tabItems,
  children,
}: PropsWithChildren<IWishlistPageLayoutProps>): React.ReactElement {
  const { channelType } = useAuthentication();
  const isAuthenticated = channelType === AuthChannelType.B2C;

  const { data, loading: wishlistLoading } = useGetActiveWishlistQuery({
    ssr: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const wishlistItemsCount = data?.wishlist.active?.items.length || 0;
  const isEmptyWishlist = wishlistItemsCount === 0;

  useWishlistSharingUrl();

  if (!isAuthenticated && isEmptyWishlist) {
    return (
      <Container>
        <WishlistContainer isLegacyDesign={true}>
          {wishlistLoading ? (
            <h2>Loading...</h2>
          ) : (
            <LegacyDefaultEmptyWishlist />
          )}
        </WishlistContainer>
      </Container>
    );
  }

  return (
    <Container>
      <WishlistContainer isLegacyDesign={true}>
        <LoginLinkOverlay>
          <WishlistOverviewSection />
        </LoginLinkOverlay>
        <Tabs<WishlistTabs> defaultKey={tabKey}>
          <PageTabControls<WishlistTabs> tabItems={tabItems} />
          <TabContent<WishlistTabs> tabKey={tabKey}>
            {wishlistLoading ? (
              <SRLProductsSkeletonWrap>
                <SRLProductsSkeleton count={3} />
              </SRLProductsSkeletonWrap>
            ) : (
              <>
                {!isEmptyWishlist ? (
                  <WishlistSelectedItemsProvider>
                    <WishlistContentWrapper>{children}</WishlistContentWrapper>
                  </WishlistSelectedItemsProvider>
                ) : (
                  <WishlistContentWrapper additionalClassname="empty">
                    <EmptyWishlist />
                  </WishlistContentWrapper>
                )}
              </>
            )}
          </TabContent>
        </Tabs>
      </WishlistContainer>
    </Container>
  );
}
