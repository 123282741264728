import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  GetGeoDepartureAirportsQuery,
  useGetGeoDepartureAirportsQuery,
} from 'graphql/geo/GetGeoDepartureAirports.generated';
import {
  GetOrlDepartureAirportsQuery,
  useGetOrlDepartureAirportsQuery,
} from 'graphql/orl/GetOrlDepartureAirports.generated';
import {
  GetDefaultDepartureAirportsValueQuery,
  useGetDefaultDepartureAirportsValueQuery,
} from 'graphql/srl/GetDefaultDepartureAirportsValue.generated';
import {
  GetThemeDepartureAirportsQuery,
  useGetThemeDepartureAirportsQuery,
} from 'graphql/theme/GetThemeDepartureAirports.generated';
import {
  GetWishlistDepartureAirportsQuery,
  useGetWishlistDepartureAirportsQuery,
} from 'graphql/wishlist/queries/GetWishlistDepartureAirports.generated';

const defaultQuery = useGetDefaultDepartureAirportsValueQuery;
const defaultMapper = function (data: GetDefaultDepartureAirportsValueQuery) {
  return data?.srl.searchControls.mainFilter.departureAirports.selected;
};

const departureAirportsQueriesWithMappers = {
  [PageType.Srl]: {
    query: defaultQuery,
    mapper: defaultMapper,
  },
  // As home page do not have any filters, we should use it as default from srl
  [PageType.Home]: {
    query: defaultQuery,
    mapper: defaultMapper,
  },
  [PageType.ThemeSrl]: {
    query: useGetThemeDepartureAirportsQuery,
    mapper: function (data: GetThemeDepartureAirportsQuery) {
      return data?.theme.filters.mainFilter.departureAirports.selected;
    },
  },
  [PageType.GeoSrl]: {
    query: useGetGeoDepartureAirportsQuery,
    mapper: function (data: GetGeoDepartureAirportsQuery) {
      return data?.geo.filters.mainFilter.departureAirports.selected;
    },
  },
  [PageType.Orl]: {
    query: useGetOrlDepartureAirportsQuery,
    mapper: function (data: GetOrlDepartureAirportsQuery) {
      return data?.orl.filters.departureAirports.selected;
    },
  },
  [PageType.WishlistCompare]: {
    query: useGetWishlistDepartureAirportsQuery,
    mapper: function (data: GetWishlistDepartureAirportsQuery) {
      return data?.wishlist.filters.departureAirports.selected;
    },
  },
};

export default function useDepartureAirportsDefaultValue() {
  const pageType = usePageType<PageType>();
  const { id } = useObjectId();

  const variables = id ? { variables: { id } } : null;

  const { data, loading } =
    departureAirportsQueriesWithMappers[pageType].query(variables);

  return {
    loading,
    departureAirports:
      departureAirportsQueriesWithMappers[pageType].mapper(data),
  };
}
