import React from 'react';
import { HeaderButtonTooltipWrapper } from './HeaderButtonTooltip.styled';

interface IWishlistButtonTooltipProps {
  text: string;
  className?: string;
}
export default function HeaderButtonTooltip({
  text,
  className,
}: IWishlistButtonTooltipProps): React.ReactElement {
  return (
    <HeaderButtonTooltipWrapper className={className}>
      {text}
    </HeaderButtonTooltipWrapper>
  );
}
