import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlToSrlUrlQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  productSorting?: Types.InputMaybe<Types.SrlProductSorting>;
  groupSorting?: Types.InputMaybe<Types.SrlGroupSorting>;
  forceSingleView?: Types.InputMaybe<Types.Scalars['Boolean']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlToSrlUrlQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
  };
};

export const GetSrlToSrlUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSrlToSrlUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlProductSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlGroupSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceSingleView' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'forceSingleView' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'forceSingleView' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productSorting' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'groupSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupSorting' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'internalLinkFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlToSrlUrlQuery__
 *
 * To run a query within a React component, call `useGetSrlToSrlUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlToSrlUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlToSrlUrlQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      productSorting: // value for 'productSorting'
 *      groupSorting: // value for 'groupSorting'
 *      forceSingleView: // value for 'forceSingleView'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlToSrlUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >(GetSrlToSrlUrlDocument, options);
}
export function useGetSrlToSrlUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >(GetSrlToSrlUrlDocument, options);
}
export type GetSrlToSrlUrlQueryHookResult = ReturnType<
  typeof useGetSrlToSrlUrlQuery
>;
export type GetSrlToSrlUrlLazyQueryHookResult = ReturnType<
  typeof useGetSrlToSrlUrlLazyQuery
>;
export type GetSrlToSrlUrlQueryResult = Apollo.QueryResult<
  GetSrlToSrlUrlQuery,
  GetSrlToSrlUrlQueryVariables
>;
