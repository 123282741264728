import React, { useMemo } from 'react';
import { Form } from '@hotelplan/components.common.forms';
import { PageType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { usePageType } from '@hotelplan/libs.context.page-type';
import SearchFilters from 'components/domain/filters/SearchFilters';
import useSearchControlFormEssentials from 'components/domain/search-control/hooks/useSearchControlForm';
import useSearchControlPageConfig from 'components/domain/search-control/hooks/useSearchControlPageConfig';
import { SrlSearchControlFormWrapper } from 'components/domain/srl/SRL.common';
import SRLMapFilterAccess from 'components/domain/srl-map/components/SRLMapFilterAccess/SRLMapFilterAccess';
import TravelDatesContainer from 'components/domain/travel-dates/TravelDatesContainer';
import BaseTravelDestinationField from 'components/domain/travel-destination/BaseTravelDestinationField';
import TravelRoomsContainer from 'components/domain/travel-rooms/TravelRoomsContainer';
import TravelTypeField from 'components/domain/travel-type/TravelTypeField';
import { ISearchControlFormProps } from './SearchControl.types';

const PAGES_WITH_MAP_SEARCH_ACCESS = [
  PageType.Srl,
  PageType.ThemeSrl,
  PageType.GeoSrl,
];
export default function SearchControlForm({
  closeMobileOverlay,
  filtersComponent,
  hideTravelDestination = false,
  headerComponent,
  submitButton,
}: ISearchControlFormProps): React.ReactElement {
  const pageType = usePageType<PageType>();
  const { mobile } = useDeviceType();

  const { autocompleteQueryHandlers, onReset } = useSearchControlPageConfig();
  const { formUpdateKey, formApiRef, initialValues, onChange, onSubmit } =
    useSearchControlFormEssentials({
      updateOnSubmit: !!submitButton,
    });

  const showMapAccess = useMemo(
    () => !mobile && PAGES_WITH_MAP_SEARCH_ACCESS.includes(pageType),
    [pageType, mobile]
  );

  return (
    <Form
      key={formUpdateKey}
      initialValues={initialValues}
      formApiRef={formApiRef}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      <SrlSearchControlFormWrapper
        travelTypes={<TravelTypeField />}
        travelDestination={
          hideTravelDestination ? null : (
            <BaseTravelDestinationField
              queryHandlers={autocompleteQueryHandlers}
            />
          )
        }
        travelDates={<TravelDatesContainer />}
        travelRooms={<TravelRoomsContainer />}
        filters={
          filtersComponent || (
            <SearchFilters
              mapFilterAccess={showMapAccess ? <SRLMapFilterAccess /> : null}
            />
          )
        }
        closeMobileOverlay={closeMobileOverlay}
        resetForm={onReset}
        className={'search-control-form-wrapper'}
        submitButton={submitButton}
        customConfig={{
          headerComponent,
        }}
      />
    </Form>
  );
}
