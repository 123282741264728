import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { WishlistCheckoutProductItemFragmentDoc } from 'graphql/wishlist/fragments/WishlistProductItemFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetWishlistActiveOfferQueryVariables = Types.Exact<{
  productId: Types.Scalars['String'];
  searchCriteria?: Types.InputMaybe<Types.WishlistSearchCriteriaInput>;
  context: Types.RequestContextInput;
}>;

export type GetWishlistActiveOfferQuery = {
  __typename?: 'Query';
  wishlist: {
    __typename?: 'WishlistContext';
    activeOffer: {
      __typename?: 'WishlistActiveComponent';
      message?: string | null;
      status: Types.Status;
      items: Array<{
        __typename?: 'WishlistProductItem';
        type: Types.WishlistProductItemType;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        offer: {
          __typename?: 'WishlistOffer';
          id: string;
          duration: number;
          returnDate: string;
          travelType: Types.TravelType;
          departureDate: string;
          transferIncluded: boolean;
          waitingScreenImage?: string | null;
          provider?: string | null;
          productCode?: string | null;
          flightAlternativesAvailable?: boolean | null;
          rooms: Array<{
            __typename?: 'WishlistOfferRoom';
            adults: number;
            children: number;
            boardCode: string;
            boardDescription: string;
            roomCode: string;
            roomDescription: string;
          }>;
          inboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          outboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        };
        changedOffer?: {
          __typename?: 'WishlistOffer';
          id: string;
          duration: number;
          returnDate: string;
          travelType: Types.TravelType;
          departureDate: string;
          transferIncluded: boolean;
          waitingScreenImage?: string | null;
          provider?: string | null;
          productCode?: string | null;
          flightAlternativesAvailable?: boolean | null;
          rooms: Array<{
            __typename?: 'WishlistOfferRoom';
            adults: number;
            children: number;
            boardCode: string;
            boardDescription: string;
            roomCode: string;
            roomDescription: string;
          }>;
          inboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          outboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        } | null;
      }>;
    };
  };
};

export const GetWishlistActiveOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWishlistActiveOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchCriteria' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WishlistSearchCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeOffer' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'wishlistCheckoutProductItem',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...WishlistCheckoutProductItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetWishlistActiveOfferQuery__
 *
 * To run a query within a React component, call `useGetWishlistActiveOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishlistActiveOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishlistActiveOfferQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      searchCriteria: // value for 'searchCriteria'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetWishlistActiveOfferQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetWishlistActiveOfferQuery,
    GetWishlistActiveOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetWishlistActiveOfferQuery,
    GetWishlistActiveOfferQueryVariables
  >(GetWishlistActiveOfferDocument, options);
}
export function useGetWishlistActiveOfferLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWishlistActiveOfferQuery,
    GetWishlistActiveOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetWishlistActiveOfferQuery,
    GetWishlistActiveOfferQueryVariables
  >(GetWishlistActiveOfferDocument, options);
}
export type GetWishlistActiveOfferQueryHookResult = ReturnType<
  typeof useGetWishlistActiveOfferQuery
>;
export type GetWishlistActiveOfferLazyQueryHookResult = ReturnType<
  typeof useGetWishlistActiveOfferLazyQuery
>;
export type GetWishlistActiveOfferQueryResult = Apollo.QueryResult<
  GetWishlistActiveOfferQuery,
  GetWishlistActiveOfferQueryVariables
>;
