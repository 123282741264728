import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { OrlItemFragmentDoc } from 'graphql/fragments/ORLItem.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetOrlSingleOffersQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  searchControl: Types.PdpSearchControlCriteriaInput;
  filters: Types.PdpFilterCriteriaInput;
  pageNumber: Types.Scalars['Int'];
  resultsPerPage: Types.Scalars['Int'];
  sort: Types.OrlSortField;
  groupId?: Types.InputMaybe<Types.Scalars['String']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetOrlSingleOffersQuery = {
  __typename?: 'Query';
  orl: {
    __typename?: 'OrlContext';
    searchResult: {
      __typename?: 'OrlSearchContainer';
      single: {
        __typename?: 'OrlSingleListComponent';
        status: Types.Status;
        message: string;
        altTravelType: Types.TravelType;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
        offers: Array<{
          __typename?: 'SingleOrlItem';
          id: string;
          departureDate: string;
          returnDate: string;
          duration: number;
          transferIncluded: boolean;
          waitingScreenImage: string;
          flightAlternativesAvailable: boolean;
          giataId?: number | null;
          provider?: string | null;
          productCode?: string | null;
          lastMinute: boolean;
          productClass: Types.ProductClass;
          travelType: Types.TravelType;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          rooms: Array<{
            __typename?: 'OrlRoom';
            adults: number;
            children: number;
            roomType: {
              __typename?: 'RoomType';
              description: string;
              code: string;
            };
            boardType: {
              __typename?: 'BoardType';
              description: string;
              code: string;
            };
          }>;
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          outboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          inboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        }>;
        alternativeSearchUrl?: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        } | null;
      };
    };
  };
};

export const GetOrlSingleOffersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetORLSingleOffers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PdpSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PdpFilterCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultsPerPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OrlSortField' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchResult' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pageNumber' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resultsPerPage',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sort' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'field' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sort' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'single' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'groupId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'page' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageNumber' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'resultsPerPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'resultsTotal',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ORLItem' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'altTravelType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'alternativeSearchUrl',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'internalLinkFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrlItemFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetOrlSingleOffersQuery__
 *
 * To run a query within a React component, call `useGetOrlSingleOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrlSingleOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrlSingleOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchControl: // value for 'searchControl'
 *      filters: // value for 'filters'
 *      pageNumber: // value for 'pageNumber'
 *      resultsPerPage: // value for 'resultsPerPage'
 *      sort: // value for 'sort'
 *      groupId: // value for 'groupId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetOrlSingleOffersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOrlSingleOffersQuery,
    GetOrlSingleOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetOrlSingleOffersQuery,
    GetOrlSingleOffersQueryVariables
  >(GetOrlSingleOffersDocument, options);
}
export function useGetOrlSingleOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrlSingleOffersQuery,
    GetOrlSingleOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetOrlSingleOffersQuery,
    GetOrlSingleOffersQueryVariables
  >(GetOrlSingleOffersDocument, options);
}
export type GetOrlSingleOffersQueryHookResult = ReturnType<
  typeof useGetOrlSingleOffersQuery
>;
export type GetOrlSingleOffersLazyQueryHookResult = ReturnType<
  typeof useGetOrlSingleOffersLazyQuery
>;
export type GetOrlSingleOffersQueryResult = Apollo.QueryResult<
  GetOrlSingleOffersQuery,
  GetOrlSingleOffersQueryVariables
>;
