import { useCallback, useEffect, useState } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { SrlMapGeoPinFragment } from 'graphql/srl/map/SrlMapGeoPin.generated';
import { SrlMapProductPinFragment } from 'graphql/srl/map/SrlMapProductPin.generated';

const MAP_BOUNDS_PADDING = 30;

interface IUseMapFitBounds {
  map: google.maps.Map;
  pins: Array<SrlMapGeoPinFragment | SrlMapProductPinFragment>;
}

export default function useMapFitBounds({ map, pins }: IUseMapFitBounds): void {
  const count = pins?.length;
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fitBounds = useCallback(() => {
    if (!map || !pins || count <= 1) return;

    const bounds = new google.maps.LatLngBounds();
    pins.forEach(pin => {
      bounds.extend(mapCoordinatesToLatLng(pin.coordinates));
    });

    map.fitBounds(bounds, MAP_BOUNDS_PADDING);
  }, [map, pins, count]);

  useEffect(() => {
    if (!map || !isInitialLoad) return;

    function handleTilesLoaded() {
      fitBounds();
      setIsInitialLoad(false);
    }

    map.addListener('tilesloaded', handleTilesLoaded);
    return () => {
      // Remove the listener when the component unmounts
      google.maps.event.clearListeners(map, 'tilesloaded');
    };
  }, [map, fitBounds, isInitialLoad]);

  useEffect(() => {
    if (isInitialLoad) {
      fitBounds();
    }
  }, [fitBounds, isInitialLoad]);
}
