import {
  refetchQueries,
  TPossibleMutation,
} from 'components/domain/wishlist/utils';
import {
  RemoveFromActiveWishlistMutationVariables as TRemoveMutationVars,
  useRemoveFromActiveWishlistMutation as useRemoveMutation,
} from 'graphql/wishlist/mutations/RemoveFromActiveWishlist.generated';

export default function useRemoveFromWishlist(
  offerId: string,
  onCompleted: (data: TPossibleMutation) => void
): [() => ReturnType<typeof remove>, boolean] {
  const variables: TRemoveMutationVars = { offerId };

  const [mutation, { loading: deleting }] = useRemoveMutation({
    variables,
    refetchQueries,
    onCompleted,
  });

  async function remove() {
    await mutation({ variables });
  }

  return [remove, deleting];
}
