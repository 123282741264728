import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Icon } from '@hotelplan/components.common.icon';
import {
  createUrlWithUtmParameters,
  useShareUrl,
} from '@hotelplan/components.common.share-form';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import PrimaryButton from 'components/core/buttons/PrimaryButton';
import { ShareSiteTool } from 'components/domain/sitetools';
import {
  useCreateWishlist,
  useRemoveWishlist,
  useSetActiveWishlist,
  useWishlistOverview,
} from 'components/domain/wishlist/hooks';
import AddNewWishlistSection from './AddNewWishlistSection';
import WishlistCardsSkeleton from './WishlistCards.skeleton';
import WishlistEditInput from './WishlistEditInput';
import {
  ShareSiteToolWrapper,
  WishlistCardContainer,
  WishlistCardContentWrapper,
  WishlistCardHeartIconWrapper,
  WishlistCardWrapper,
  WishlistIconWrap,
  WishlistOverviewFooter,
  WishlistOverviewSectionWrapper,
} from './WishlistOverview.styled';

const B2B_EMAIL_BCC = 'info@migros-ferien.ch';

export default function WishlistOverviewSection(): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const { channelType } = useAuthentication();
  const shareUrl = useShareUrl();
  const url = createUrlWithUtmParameters(
    shareUrl,
    channelType,
    'share-button-mail'
  );

  const { setActiveWishlist } = useSetActiveWishlist();
  const { removeWishlist } = useRemoveWishlist();
  const { createWishlist } = useCreateWishlist();

  const { wishlists, loading } = useWishlistOverview();

  const { isAuthenticated, isB2B } = useMemo(
    () => ({
      isAuthenticated: channelType !== AuthChannelType.B2CAnonymous,
      isB2B: channelType === AuthChannelType.B2B,
    }),
    [channelType]
  );

  return (
    <>
      <WishlistOverviewSectionWrapper className="wishlist-overview-section">
        {loading ? (
          <WishlistCardsSkeleton />
        ) : (
          <>
            {wishlists?.map(wishlist => (
              <WishlistCardWrapper
                key={wishlist.id}
                isAuthenticated={isAuthenticated}
              >
                <WishlistCardContainer
                  onRemove={async function () {
                    await removeWishlist(wishlist.id);
                  }}
                >
                  <WishlistCardHeartIconWrapper>
                    <WishlistIconWrap
                      isActive={wishlist.active}
                      count={wishlist.productsAmount}
                    />
                  </WishlistCardHeartIconWrapper>
                  <WishlistCardContentWrapper>
                    <span
                      className={`wishlist-name ${wishlist.active && 'active'}`}
                    >
                      {wishlist.name}
                    </span>
                    {wishlist.active ? (
                      <span className="wishlist-selected">
                        <Icon name="check-solid" size="sm" />
                        {t('wishlist.selected')}
                      </span>
                    ) : (
                      <PrimaryButton
                        onClick={async function () {
                          await setActiveWishlist(wishlist.id);
                        }}
                      >
                        <span>{t('wishlist.select')}</span>
                      </PrimaryButton>
                    )}
                  </WishlistCardContentWrapper>
                </WishlistCardContainer>
              </WishlistCardWrapper>
            ))}
            <AddNewWishlistSection
              isAuthenticated={isAuthenticated}
              addNewWishlist={async function (name) {
                await createWishlist(name);
              }}
            />
          </>
        )}
      </WishlistOverviewSectionWrapper>
      <WishlistOverviewFooter>
        <WishlistEditInput
          currentWishlistId={wishlists.find(wishlist => wishlist.active)?.id}
          currentName={wishlists.find(wishlist => wishlist.active)?.name}
        />

        <ShareSiteToolWrapper>
          <ShareSiteTool
            updatedEmailData={
              isB2B
                ? {
                    bcc: B2B_EMAIL_BCC,
                    body: t('b2b.email.body', { url }),
                  }
                : undefined
            }
          />
        </ShareSiteToolWrapper>
      </WishlistOverviewFooter>
    </>
  );
}
