import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { MenuEntryFragmentDoc } from './MenuEntry.generated';
import { TextMenuEntryFragmentDoc } from './TextMenuEntry.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHeaderQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetHeaderQuery = {
  __typename?: 'Query';
  pageHeader: {
    __typename?: 'PageHeaderData';
    selectedPhone?: string | null;
    navigationMenu: Array<
      | {
          __typename?: 'Menu';
          fontColor?: string | null;
          caption: string;
          target?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              }
            | null;
          entries: Array<{
            __typename?: 'TextMenuItem';
            fontColor?: string | null;
            caption: string;
            iconType?: Types.IconType | null;
            link:
              | {
                  __typename?: 'ExternalLink';
                  openMethod: Types.OpenLinkMethod;
                  url: string;
                  caption?: string | null;
                }
              | {
                  __typename?: 'InternalLink';
                  caption?: string | null;
                  uri: string;
                  targetPageType: Types.PageType;
                };
          }>;
        }
      | {
          __typename?: 'TextMenuItem';
          fontColor?: string | null;
          caption: string;
          iconType?: Types.IconType | null;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
        }
    >;
    logo: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    contactInformation?: {
      __typename?: 'ContactInformation';
      openingHours?: string | null;
      phoneNumber?: string | null;
      phoneTitle?: string | null;
    } | null;
  };
};

export type HeaderFragment = {
  __typename?: 'PageHeaderData';
  selectedPhone?: string | null;
  navigationMenu: Array<
    | {
        __typename?: 'Menu';
        fontColor?: string | null;
        caption: string;
        target?:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            }
          | null;
        entries: Array<{
          __typename?: 'TextMenuItem';
          fontColor?: string | null;
          caption: string;
          iconType?: Types.IconType | null;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
        }>;
      }
    | {
        __typename?: 'TextMenuItem';
        fontColor?: string | null;
        caption: string;
        iconType?: Types.IconType | null;
        link:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
      }
  >;
  logo: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  contactInformation?: {
    __typename?: 'ContactInformation';
    openingHours?: string | null;
    phoneNumber?: string | null;
    phoneTitle?: string | null;
  } | null;
};

export const HeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'header' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageHeaderData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'selectedPhone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'navigationMenu' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Menu' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'menuEntry' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TextMenuItem' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textMenuEntry' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '204' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '64' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '102' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '32' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '332' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '110' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '166' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '55' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneTitle' } },
              ],
            },
          },
        ],
      },
    },
    ...MenuEntryFragmentDoc.definitions,
    ...TextMenuEntryFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageHeader' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'header' },
                },
              ],
            },
          },
        ],
      },
    },
    ...HeaderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetHeaderQuery__
 *
 * To run a query within a React component, call `useGetHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetHeaderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHeaderQuery,
    GetHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetHeaderQuery, GetHeaderQueryVariables>(
    GetHeaderDocument,
    options
  );
}
export function useGetHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHeaderQuery,
    GetHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetHeaderQuery, GetHeaderQueryVariables>(
    GetHeaderDocument,
    options
  );
}
export type GetHeaderQueryHookResult = ReturnType<typeof useGetHeaderQuery>;
export type GetHeaderLazyQueryHookResult = ReturnType<
  typeof useGetHeaderLazyQuery
>;
export type GetHeaderQueryResult = Apollo.QueryResult<
  GetHeaderQuery,
  GetHeaderQueryVariables
>;
