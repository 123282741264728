import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { EDisrupterPlacement } from './Disrupter.types';
import { Icon } from '@hotelplan/components.common.icon';

export const DisrupterSection = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'transparent',
    pointerEvents: 'none',
  })
);

export const DisrupterContainer = styled.div<{
  fontColor?: string;
  bgColor?: string;
  placement?: EDisrupterPlacement;
}>(({ fontColor, bgColor, placement }) => {
  const mainTitleFontSize = {
    [EDisrupterPlacement.LEAD]: ['18px', '32px'],
    [EDisrupterPlacement.SLIDER]: ['18px', '32px'],
    [EDisrupterPlacement.CARD]: ['18px', '14px'],
  }[placement];

  const subTitleFontSize = {
    [EDisrupterPlacement.LEAD]: ['7px', '13px'],
    [EDisrupterPlacement.SLIDER]: ['7px', '13px'],
    [EDisrupterPlacement.CARD]: ['7px', '5px'],
  }[placement];

  const width = placement === EDisrupterPlacement.SLIDER ? '25%' : '30%';

  return sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    width: width,
    pt: width,
    height: 0,
    borderRadius: '50%',
    bg: bgColor || 'white',
    color: fontColor || 'black',

    '.disrupter-text': {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '45%',
      transform: 'translate(-50%, -50%)',
    },

    '.main-title': {
      fontSize: mainTitleFontSize,
      fontWeight: 'bold',
      fontFamily: '"Arial", sans-serif',
    },

    '.sub-title': {
      fontSize: subTitleFontSize,
      fontWeight: 'bold',
      fontFamily: '"Arial", sans-serif',
    },
  });
});

export const CumulusIconContainer = styled(Icon)<{
  placement?: EDisrupterPlacement;
}>(({ placement }) => {
  const width = {
    [EDisrupterPlacement.LEAD]: ['60px', '110px'],
    [EDisrupterPlacement.SLIDER]: ['60px', '110px'],
    [EDisrupterPlacement.CARD]: ['60px', '50px'],
  }[placement];

  const height = {
    [EDisrupterPlacement.LEAD]: ['37px', '67px'],
    [EDisrupterPlacement.SLIDER]: ['37px', '67px'],
    [EDisrupterPlacement.CARD]: ['37px', '30px'],
  }[placement];

  const top = {
    [EDisrupterPlacement.LEAD]: ['-15px', '-30px'],
    [EDisrupterPlacement.SLIDER]: ['-15px', '-30px'],
    [EDisrupterPlacement.CARD]: ['-15px', '-15px'],
  }[placement];

  const right = {
    [EDisrupterPlacement.LEAD]: ['-15px', '-15px'],
    [EDisrupterPlacement.SLIDER]: ['-15px', '-15px'],
    [EDisrupterPlacement.CARD]: ['-10px', '-10px'],
  }[placement];

  return sx2CssThemeFn({
    position: 'absolute',
    top,
    right,
    width,
    height,
  });
});
