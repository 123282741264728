import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import { getResultUri } from './patchRouteWithSearchQuery.utils';

export default function usePatchRouteWithSearchQuery(
  link: InternalLinkFragmentFragment | null,
  params: string[]
): InternalLinkFragmentFragment {
  const { query } = useRouter();

  const { uri } = link || {};

  const resultUri = useMemo(
    () => getResultUri(query, uri, params),
    [query, uri, params]
  );

  return link ? { ...link, uri: resultUri } : null;
}
