import { ParsedUrlQuery } from 'querystring';
import { FAKE_BASE_URL } from 'components/domain/search-query';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';

export const getResultUri = (
  query: ParsedUrlQuery,
  uri: string | undefined,
  params: string[]
): string => {
  const url = new URL(uri, FAKE_BASE_URL);
  const searchParams = new URLSearchParams(url.search);

  params.forEach(param => {
    const value = query[param];
    if (value) {
      searchParams.set(param, String(value));
    }
  });

  url.search = searchParams.toString();
  return url.pathname + url.search;
};

export const patchRouteWithSearchQuery = (
  query: ParsedUrlQuery,
  link: InternalLinkFragmentFragment | null,
  params: string[]
): InternalLinkFragmentFragment => {
  const { uri } = link || {};

  const resultUri = getResultUri(query, uri, params);

  return link ? { ...link, uri: resultUri } : null;
};
