import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { AirportFragmentFragmentDoc } from './Airport.generated';
export type FlightItemFragment = {
  __typename?: 'Flight';
  departureTime: any;
  arrivalTime: any;
  duration: string;
  arrivalDate: any;
  departureDate: any;
  overnight: boolean;
  departure: {
    __typename?: 'Airport';
    iata?: string | null;
    name?: string | null;
  };
  arrival: {
    __typename?: 'Airport';
    iata?: string | null;
    name?: string | null;
  };
  segments: Array<{
    __typename?: 'FlightSegment';
    airportWaiting?: string | null;
    arrivalDate: any;
    arrivalTime: any;
    departureDate: any;
    departureTime: any;
    flightClass: Types.FlightClass;
    flightNumber: string;
    segmentDuration: string;
    airline: {
      __typename?: 'Airline';
      airlineLogo: string;
      iata: string;
      name: string;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
  }>;
};

export const FlightItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Flight' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'airportFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrival' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'airportFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'departureTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arrivalTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arrivalDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnight' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'segments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'airline' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'airlineLogo' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'airportWaiting' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arrival' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'airportFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'arrivalDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'arrivalTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'airportFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departureDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departureTime' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'flightClass' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flightNumber' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'segmentDuration' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AirportFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
