import { useSetActiveWishlistMutation } from 'graphql/wishlist/mutations/SetActiveWishlist.generated';

export default function useSetActiveWishlist() {
  const [mutation, { loading }] = useSetActiveWishlistMutation({
    refetchQueries: [`GetWishlistOverview`, `GetActiveWishlist`],
  });

  async function setActiveWishlist(id: string) {
    await mutation({
      variables: {
        id,
      },
    });
  }

  return {
    setActiveWishlist,
    loading,
  };
}
