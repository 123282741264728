import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const CurrentHotelPinIcon = styled(Icon).attrs({
  name: 'map-pin-hotel',
  size: 'xlg',
})(
  sx2CssThemeFn({
    color: 'primary',
  })
);

export default CurrentHotelPinIcon;
