export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Link: ['ExternalLink', 'InternalLink'],
    AgencyDynamicComponent: [
      'AgencyRecommendationGroup',
      'MediaGallery',
      'TextComponent',
    ],
    AgencyRecommendationItemDeprecated: [
      'BD4TravelRecommendation',
      'BlogArticleRecommendationItem',
      'GeoRecommendationItem',
      'MarketingRecommendationItem',
      'ProductRecommendationItem',
      'ThemeRecommendationItem',
    ],
    Media: [
      'ExternalMediaItem',
      'ImageMediaItem',
      'MusicMediaItem',
      'PdfMediaItem',
      'VideoMediaItem',
    ],
    RecommendationTracking: ['BD4TravelRecommendationTracking'],
    TransferPointInfoUnion: ['TransferFlightInfo', 'TransferHotelInfo'],
    AgencyAutocompleteSuggestion: ['Agency', 'MapSuggestion'],
    DynamicComponent: [
      'AccordionComponent',
      'AgencyContactComponent',
      'AgencyRecommendation',
      'BD4TravelRecommendationGroup',
      'BenefitsComponent',
      'BlogArticleRecommendation',
      'BrandsComponent',
      'ClimateComponent',
      'ContactModuleComponent',
      'EmployeesComponent',
      'FaqComponent',
      'FlightWithoutPriceRecommendation',
      'FlightWithPriceRecommendation',
      'GeoChildrenComponent',
      'GeoGalleryComponent',
      'GeoRecommendation',
      'HeroMediaGallery',
      'ImageTextComponent',
      'LinkListComponent',
      'MarketingRecommendation',
      'MarketingTeaserComponent',
      'MediaGallery',
      'NewsArticlesComponent',
      'NewsletterSignupComponent',
      'OfferButtonComponent',
      'OffersMapPlaceholderComponent',
      'ProductGalleryWithMapComponent',
      'ProductRecommendation',
      'RequestButtonComponent',
      'ResortTopHotelsComponent',
      'SrlButtonPlaceholderComponent',
      'SrlMapPinsComponent',
      'StaticBlogRecommendation',
      'StaticGeoRecommendation',
      'StaticProductRecommendation',
      'StaticThemeRecommendation',
      'TextComponent',
      'ThemeRecommendation',
      'UspBoxesComponent',
    ],
    ContactTypeUnion: ['ContactType', 'ContactTypePhone'],
    HeroMedia: ['ImageMediaItem', 'VideoMediaItem'],
    ContactDynamicComponent: [
      'ContactRecommendationGroup',
      'MediaGallery',
      'TextComponent',
    ],
    ContactRecommendationItem: ['MarketingRecommendationItem'],
    EmailAdvertisingDynamicComponent: ['EmailAdvertisingRecommendationGroup'],
    EmailAdvertisingRecommendationItem: [
      'GeoRecommendationItem',
      'ThemeRecommendationItem',
    ],
    FlightHomeDynamicComponent: [
      'FlightHomeRecommendationGroup',
      'MediaGallery',
      'TextComponent',
    ],
    FlightHomeRecommendationItem: [
      'BD4TravelRecommendation',
      'FlightRecommendationWithoutPriceItem',
      'FlightRecommendationWithPriceItem',
      'GeoRecommendationItem',
      'ThemeRecommendationItem',
    ],
    SearchControlUnion: [
      'FlightSearchControlComponent',
      'SearchControlComponent',
    ],
    TravelPeriodUnion: ['ExactTravelPeriod', 'FlexibleTravelPeriod'],
    FlightSrlDynamicComponent: ['FlightSrlRecommendationGroup'],
    FlightSrlRecommendationItem: ['BD4TravelRecommendation'],
    GeoChildrenComponentInterface: [
      'GeoChildrenComponent',
      'ResortTopHotelsComponent',
    ],
    GeoObjectPageComponent: ['GeoObjectRecommendationGroupComponent'],
    GeoObjectRecommendationItem: [
      'BD4TravelRecommendation',
      'BlogArticleRecommendationItem',
      'GeoRecommendationItem',
      'MarketingRecommendationItem',
      'ProductRecommendationItem',
      'ThemeRecommendationItem',
    ],
    GeoOverviewPageComponent: ['GeoOverviewRecommendationGroupComponent'],
    GeoOverviewRecommendationItem: [
      'GeoRecommendationItem',
      'ThemeRecommendationItem',
    ],
    HelpOverlayBoxBase: [
      'HelpOverlayBox',
      'HelpOverlayBoxChat',
      'HelpOverlayBoxContact',
    ],
    HistoryRecordUnion: ['HistoryFlightRecord', 'HistorySrlRecord'],
    HomeDynamicComponent: [
      'BrandsComponent',
      'EmployeesComponent',
      'FaqComponent',
      'HeroMediaGallery',
      'HomeRecommendationGroup',
      'LinkListComponent',
      'MediaGallery',
      'NewsArticlesComponent',
      'TextComponent',
      'UspBoxesComponent',
    ],
    HomeRecommendationItem: [
      'BD4TravelRecommendation',
      'GeoRecommendationItem',
      'MarketingRecommendationItem',
      'ProductRecommendationItem',
      'ThemeRecommendationItem',
    ],
    StaticPageComponent: [
      'BrandsComponent',
      'EmployeesComponent',
      'FaqComponent',
      'HeroMediaGallery',
      'LinkListComponent',
      'MediaGallery',
      'NewsArticlesComponent',
      'StaticRecommendationGroup',
      'TextComponent',
      'UspBoxesComponent',
    ],
    StaticRecommendationItem: [
      'BD4TravelRecommendation',
      'MarketingRecommendationItem',
    ],
    NewsletterDynamicComponent: ['NewsletterRecommendationGroup'],
    NewsletterRecommendationItem: [
      'GeoRecommendationItem',
      'ThemeRecommendationItem',
    ],
    Navigable: ['Menu', 'TextMenuItem'],
    PdpPageComponent: ['PdpRecommendationGroup'],
    PdpRecommendationItem: [
      'BD4TravelRecommendation',
      'ProductRecommendationItem',
    ],
    SrlPageComponent: ['SrlRecommendationGroupComponent'],
    SrlRecommendationItem: [
      'BD4TravelRecommendation',
      'GeoRecommendationItem',
      'MarketingRecommendationItem',
      'ProductRecommendationItem',
      'ThemeRecommendationItem',
    ],
    SrlAnyResult: ['SrlEmptyResult', 'SrlGeoGroupResult', 'SrlSingleResult'],
    SrlSingleItem: ['NudgeItem', 'SrlProductItem'],
    SrlGroupItem: ['NudgeItem', 'SrlGeoItem'],
    SrlGroupOrEmptyResult: ['SrlEmptyResult', 'SrlGeoGroupResult'],
    SrlSingleOrEmptyResult: ['SrlEmptyResult', 'SrlSingleResult'],
    ThemePageDynamicComponent: [
      'MediaGallery',
      'TextComponent',
      'ThemePageRecommendationGroup',
    ],
    ThemePageRecommendationItem: [
      'BD4TravelRecommendation',
      'BlogArticleRecommendationItem',
      'GeoRecommendationItem',
      'MarketingRecommendationItem',
      'ProductRecommendationItem',
      'ThemeRecommendationItem',
    ],
    BookingCancellationPayloadUnion: ['BookingCancellationConfirmationPayload'],
  },
};
export default result;
