import { useMemo } from 'react';
import { FilterComponent } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import {
  mapMainFilterComponentOptionsToLocalFilterOptionCaptions,
  mapMainFilterComponentOptionsToLocalFilterOptions,
  mapSrlFilterValuesToSrlFilterCriteriaInput,
} from 'components/domain/filters/Filters.mappers';
import { IFilterOptionsResult } from 'components/domain/filters/Filters.types';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { useGetSrlFilterOptionsQuery } from 'graphql/srl/GetSRLFilterOptions.generated';
import { useGetSrlMainFeaturesFilterCountsQuery } from 'graphql/srl/GetSRLMainFeaturesFilterCounts.generated';
import { useGetSrlSubAmenitiesFilterCountsQuery } from 'graphql/srl/GetSRLSubAmenitiesFilterCounts.generated';
import { useGetWishlistFilterOptionsQuery } from 'graphql/wishlist/queries/GetWishlistFilterOptions.generated';
import { ISrlState } from './SRL.types';

export default function useSRLFilterOptions(
  isWishlist = false
): IFilterOptionsResult {
  const {
    state,
    loading: stateLoading,
    searchQuery,
  } = useSearchState<ISrlState>();

  const { data, loading: optionsLoading } = useGetSrlFilterOptionsQuery({
    variables: { searchQuery: searchQuery || '' },
  });

  const { data: wishlistCounts } = useGetWishlistFilterOptionsQuery({
    variables: { searchQuery: searchQuery || '' },
    skip: !isWishlist,
  });

  const countsVariables = {
    filters: mapSrlFilterValuesToSrlFilterCriteriaInput(state.filters),
    searchControl: mapSearchControlToCriteria(state.searchControl),
  };

  const {
    data: productFeaturesCountsData,
    loading: productFeaturesCountsLoading,
  } = useGetSrlMainFeaturesFilterCountsQuery({
    variables: countsVariables,
  });

  const { data: subAmenitiesCountsData, loading: subAmenitiesCountsLoading } =
    useGetSrlSubAmenitiesFilterCountsQuery({
      variables: countsVariables,
    });

  const options = useMemo(
    () =>
      data?.srl.searchControls.mainFilter
        ? mapMainFilterComponentOptionsToLocalFilterOptions(
            data.srl.searchControls.mainFilter as FilterComponent
          )
        : null,
    [data]
  );

  const optionCaptions: IFilterOptionsResult['optionCaptions'] = data?.srl
    .searchControls.mainFilter
    ? mapMainFilterComponentOptionsToLocalFilterOptionCaptions(
        data.srl.searchControls.mainFilter as FilterComponent
      )
    : null;

  return useMemo(
    () => ({
      loading: optionsLoading || stateLoading,
      options,
      optionCaptions,
      finalCounts: {
        mainFilters: {
          ...productFeaturesCountsData?.srl.searchControls.mainFilter,
          ...(isWishlist ? wishlistCounts?.wishlist.filters : {}),
          productFeatures:
            productFeaturesCountsData?.srl.searchControls.mainFilter
              .mainFeatures,
          loading: productFeaturesCountsLoading,
        },
        subAmenities: {
          ...subAmenitiesCountsData?.srl.searchControls.mainFilter,
          loading: subAmenitiesCountsLoading,
        },
      },
    }),
    [
      options,
      optionCaptions,
      optionsLoading,
      stateLoading,
      productFeaturesCountsData,
      subAmenitiesCountsData,
      productFeaturesCountsLoading,
      subAmenitiesCountsLoading,
    ]
  );
}
