import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const SearchControlFormWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    minWidth: [null, '250px'],
    pt: [2, 0],
    '.absolute-dropdown': {
      position: ['static', 'absolute'],
      p: '15px',
      border: [null, '1px solid #999'],
      borderRadius: 'default',
      boxShadow: [null, '0 1px 7px rgb(0 0 0 / 50%)'],
      top: '5px',
      '.apply-button': {
        mt: '15px',
        '.icon': {
          width: '14px',
          height: '14px',
        },
      },
    },
    '.autocomplete-dropdown': {
      zIndex: 100,
      p: 0,
      boxShadow: 'none',
      top: '0px',
      border: 'none',
      position: 'absolute',
      width: ['calc(100% - 41px)', 'calc(100% - 31px)'],
      '.autocomplete-suggestions': {
        flexDirection: 'column',
        maxHeight: ['350px', '450px'],
        overflowY: 'scroll',
        cursor: 'default',
        borderRadius: '0 0 5px 5px',
        boxShadow: '0 0 3px rgb(0 0 0 / 35%)',
        '.autocomplete-option': {
          px: 2,
          gap: 2,
          flexDirection: 'row-reverse',
          '.-icon': {
            ml: 'auto',
            mr: 0,
          },
          '.-label': {
            fontSize: 0,
          },
        },
        button: {
          fontSize: 0,
          mr: 2,
        },
        'li:hover': {
          backgroundColor: '#e7e7e7',
        },
        'ul + div': {
          pb: 2,
        },
      },
    },
    '.multiple-autocomplete': {
      '.base-input-wrapper': {
        input: {
          background: 'white',
          position: 'relative',
        },
        '.icon': {
          right: '1px',
          width: ['39px', '29px'],
          height: ['38px', '28px'],
          padding: ['9px', '6px'],
          borderRadius: '0 4px 4px 0',
          cursor: 'pointer',
        },
      },
    },
  })
);

export const SearchControlFormHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: ['center', 'space-between'],
    alignItems: 'center',
    px: 3,
    py: 2,
    bg: ['inherit', 'secondary'],
  })
);
export const SearchControlTitle = styled.h2(
  sx2CssThemeFn({
    color: ['secondary', 'white'],
    fontSize: '22px',
  })
);

export const SearchControlFields = styled.div(
  sx2CssThemeFn({
    bg: 'backgroundGray',
    p: 2,
    display: 'grid',
    gap: '11px',
    '.room-types, .board-types': {
      '.absolute-dropdown.search': {
        width: [null, '330px'],
      },
    },
    '.departure-airports': {
      gridRow: '2/3',
    },
    '.base-input-wrapper': {
      input: {
        fontSize: [1, 0],
        fontWeight: 'bold',
      },
      '.input': {
        pl: '8px',
      },
      'input:hover': {
        cursor: 'pointer',
      },
    },
  })
);
