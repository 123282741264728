import { useGoogleMap } from '@react-google-maps/api';
import noop from 'lodash/noop';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  MapClusterer,
  mapCoordinatesToLatLng,
} from '@hotelplan/components.common.map-pin';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { ProductPin } from 'components/domain/map/Map.types';
import MapClustererRedraw from 'components/domain/map/MapClustererRedraw';
import renderPinContent from 'components/domain/map/MapClusterPin';
import MapProductPin from 'components/domain/map/MapProductPin/MapProductPin';
import useMapFitBounds from 'components/domain/map/useMapFitBounds';
import useMapForm from 'components/domain/map/useMapForm';
import useSelectMapPinHandler from 'components/domain/map/useSelectMapPinHandler';
import PdpMapProductCardsDesktop from './PdpMapProductCardsDesktop';
import PdpMapProductCardsMobile from './PdpMapProductCardsMobile';

interface IPdpMapProductsProps {
  products: ProductPin[];
  cardContainerRef?: React.MutableRefObject<HTMLDivElement>;
}

const PdpMapProducts: React.FC<IPdpMapProductsProps> = (
  props: IPdpMapProductsProps
) => {
  const { products, cardContainerRef } = props;
  const { id } = useObjectId();
  const map = useGoogleMap();
  const { mobile } = useDeviceType();

  const { selectedObjects, deselect, onSelectObjects } =
    useMapForm<ProductPin>();

  const selectProductHandler = useSelectMapPinHandler<ProductPin>(
    map,
    onSelectObjects
  );

  useMapFitBounds({
    map,
    pins: products,
  });

  return (
    <>
      <MapClusterer renderPinContent={renderPinContent} onClusterClick={noop}>
        <MapClustererRedraw>
          {products.map((product, index) => {
            const isCurrentProduct = product.id === id;
            return (
              <MapProductPin
                key={`${index}-${mapCoordinatesToLatLng(
                  product.coordinates
                ).toString()}`}
                product={product}
                onClick={isCurrentProduct ? noop : selectProductHandler}
                onClickOnSelectedPin={deselect}
                isCurrentProduct={isCurrentProduct}
                isSelected={product.id === selectedObjects[0]?.id}
              />
            );
          })}
        </MapClustererRedraw>
      </MapClusterer>
      {mobile && cardContainerRef ? (
        ReactDOM.createPortal(
          <PdpMapProductCardsMobile
            selectedProduct={selectedObjects[0]}
            onCloseCard={deselect}
          />,
          cardContainerRef.current
        )
      ) : (
        <PdpMapProductCardsDesktop
          selectedProduct={selectedObjects[0]}
          onCloseCard={deselect}
        />
      )}
    </>
  );
};

export default PdpMapProducts;
