import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SearchControlFragmentDoc } from 'graphql/searchControl/SearchControl.generated';
import { MainFilterComponentFragmentDoc } from 'graphql/fragments/MainFilterComponent.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetThemeSearchControlValuesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  urlParameter?: Types.InputMaybe<Types.Scalars['String']>;
  travelType?: Types.InputMaybe<Types.TravelType>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetThemeSearchControlValuesQuery = {
  __typename?: 'Query';
  theme: {
    __typename?: 'ThemeContext';
    searchControl:
      | { __typename?: 'FlightSearchControlComponent' }
      | {
          __typename?: 'SearchControlComponent';
          travelType: Types.TravelType;
          rooms: number;
          period: {
            __typename?: 'TravelPeriodComponent';
            type: Types.SearchPeriodType;
            exactPeriod: {
              __typename?: 'ExactTravelPeriod';
              departureDate: string;
              returnDate: string;
            };
            flexiblePeriod: {
              __typename?: 'FlexibleTravelPeriod';
              departureDate: string;
              maxDuration: number;
              minDuration: number;
              returnDate: string;
            };
          };
          destinations?: Array<{
            __typename?: 'TravelDestination';
            id: string;
            name: string;
            type: Types.TravelDestinationType;
            mythosCode?: string | null;
            location?: {
              __typename?: 'GeoLocation';
              airport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              continent?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              countryGroup?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              country?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              region?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              destination?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              resort?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
            } | null;
          }> | null;
          travellers: {
            __typename?: 'Travellers';
            adults: number;
            childrenDobs?: Array<string> | null;
          };
          travellersDistribution?: {
            __typename?: 'TravellersDistribution';
            rooms: Array<{
              __typename?: 'RoomDistribution';
              adultsAmount: number;
              childrenIdx?: Array<number> | null;
            }>;
          } | null;
        };
    filters: {
      __typename?: 'MainFilterComponent';
      mainFilter: {
        __typename?: 'FilterComponent';
        departureAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        roomTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hpRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        taRating: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        boardTypes: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        maxPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        directFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        returnFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        stopOvers: {
          __typename?: 'RadiobuttonFilterComponent';
          selected: string;
        };
        mainFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        childrenFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        beachFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        cityFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        winterSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        summerSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        waterSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        wellnessFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hotelEntertainmentFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        hotelFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        nightlifeFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        cultureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        restaurantFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        wlanFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        sustainableFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        nationalParkFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        resortNatureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        petsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        ECarChargingFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        resortFlairFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        resortShoppingFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        luxuryFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        clubHotelsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        adultsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
        radius?: {
          __typename?: 'RadiusFilterComponent';
          label?: string | null;
          radius: number;
          center: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
      };
    };
  };
};

export const GetThemeSearchControlValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThemeSearchControlValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TravelType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlParameter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'travelType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SearchControl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlParameter' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'mainFilterComponent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SearchControlFragmentDoc.definitions,
    ...MainFilterComponentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetThemeSearchControlValuesQuery__
 *
 * To run a query within a React component, call `useGetThemeSearchControlValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeSearchControlValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeSearchControlValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      urlParameter: // value for 'urlParameter'
 *      travelType: // value for 'travelType'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetThemeSearchControlValuesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >(GetThemeSearchControlValuesDocument, options);
}
export function useGetThemeSearchControlValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >(GetThemeSearchControlValuesDocument, options);
}
export type GetThemeSearchControlValuesQueryHookResult = ReturnType<
  typeof useGetThemeSearchControlValuesQuery
>;
export type GetThemeSearchControlValuesLazyQueryHookResult = ReturnType<
  typeof useGetThemeSearchControlValuesLazyQuery
>;
export type GetThemeSearchControlValuesQueryResult = Apollo.QueryResult<
  GetThemeSearchControlValuesQuery,
  GetThemeSearchControlValuesQueryVariables
>;
