import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';

export default function usePreventBodyScroll(prevent = true) {
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!prevent) return;
    // Get original body overflow
    const originalOverflow = window.getComputedStyle(document.body).overflow;

    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [prevent]);
}
