import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const GeoDestinationsOverlaySkeleton = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 300, h: 572 }
    : { w: 965, h: 412 };

  return (
    <PlaceholderLoader
      width={width}
      height={height}
      uid="cardSkeleton"
      style={{ width: '100%', display: 'block' }}
    >
      {mobile ? (
        <>
          <rect x="10" y="8" width="137" height="27" />
          <rect x="154" y="8" width="137" height="27" />
          <rect x="10" y="536" width="137" height="27" />
          <rect x="154" y="536" width="137" height="27" />
          <rect x="4" y="43" width="294" height="1" />
          <rect x="4" width="294" height="1" />
          <rect y="528" width="294" height="1" />
          <rect y="571" width="294" height="1" />
          <rect x="10" y="58" width="69" height="12" rx="3" />
          <rect x="10" y="78" width="92" height="18" rx="3" />
          <rect x="10" y="113" width="278" height="23" rx="3" />
          <rect x="10" y="477" width="69" height="12" rx="3" />
          <rect x="10" y="497" width="92" height="18" rx="3" />
          <rect x="10" y="417" width="278" height="24" rx="3" />
          <rect x="10" y="385" width="278" height="24" rx="3" />
          <rect x="10" y="353" width="278" height="24" rx="3" />
          <rect x="10" y="321" width="278" height="24" rx="3" />
          <rect x="10" y="253" width="278" height="24" rx="3" />
          <rect x="10" y="221" width="278" height="24" rx="3" />
          <rect x="10" y="189" width="278" height="24" rx="3" />
          <rect x="10" y="157" width="278" height="24" rx="3" />
          <rect x="10" y="287" width="278" height="24" rx="3" />
        </>
      ) : (
        <>
          <path d="M8 6C8 2.68629 10.6863 0 14 0H152C155.314 0 158 2.68629 158 6V30H8V6Z" />
          <path d="M395 6C395 2.68629 397.686 0 401 0H539C542.314 0 545 2.68629 545 6V30H395V6Z" />
          <path d="M666 6C666 2.68629 668.686 0 672 0H810C813.314 0 816 2.68629 816 6V30H666V6Z" />
          <path d="M163 6C163 2.68629 165.686 0 169 0H268C271.314 0 274 2.68629 274 6V30H163V6Z" />
          <path d="M279 6C279 2.68629 281.686 0 285 0H384C387.314 0 390 2.68629 390 6V30H279V6Z" />
          <path d="M550 6C550 2.68629 552.686 0 556 0H655C658.314 0 661 2.68629 661 6V30H550V6Z" />
          <path d="M821 6C821 2.68629 823.686 0 827 0H926C929.314 0 932 2.68629 932 6V30H821V6Z" />
          <rect y="30" width="946" height="1" />
          <rect x="37" y="56" width="121" height="18" rx="6" />
          <rect x="60" y="84" width="121" height="15" rx="6" />
          <rect x="11" y="56" width="18" height="18" rx="5" />
          <rect x="36" y="82" width="18" height="18" rx="5" />
          <rect x="60" y="132" width="121" height="15" rx="6" />
          <rect x="36" y="130" width="18" height="18" rx="5" />
          <rect x="60" y="108" width="121" height="15" rx="6" />
          <rect x="36" y="106" width="18" height="18" rx="5" />
          <rect x="60" y="157" width="121" height="15" rx="6" />
          <rect x="36" y="155" width="18" height="18" rx="5" />
          <rect x="60" y="205" width="121" height="15" rx="6" />
          <rect x="36" y="203" width="18" height="18" rx="5" />
          <rect x="60" y="181" width="121" height="15" rx="6" />
          <rect x="36" y="179" width="18" height="18" rx="5" />
          <rect x="37" y="247" width="121" height="18" rx="6" />
          <rect x="60" y="275" width="121" height="15" rx="6" />
          <rect x="11" y="247" width="18" height="18" rx="5" />
          <rect x="36" y="273" width="18" height="18" rx="5" />
          <rect x="60" y="323" width="121" height="15" rx="6" />
          <rect x="36" y="321" width="18" height="18" rx="5" />
          <rect x="60" y="299" width="121" height="15" rx="6" />
          <rect x="36" y="297" width="18" height="18" rx="5" />
          <rect x="60" y="348" width="121" height="15" rx="6" />
          <rect x="36" y="346" width="18" height="18" rx="5" />
          <rect x="60" y="396" width="121" height="15" rx="6" />
          <rect x="36" y="394" width="18" height="18" rx="5" />
          <rect x="60" y="372" width="121" height="15" rx="6" />
          <rect x="36" y="370" width="18" height="18" rx="5" />
          <rect x="486" y="55" width="121" height="18" rx="6" />
          <rect x="509" y="83" width="121" height="15" rx="6" />
          <rect x="460" y="55" width="18" height="18" rx="5" />
          <rect x="485" y="81" width="18" height="18" rx="5" />
          <rect x="509" y="131" width="121" height="15" rx="6" />
          <rect x="485" y="129" width="18" height="18" rx="5" />
          <rect x="509" y="107" width="121" height="15" rx="6" />
          <rect x="485" y="105" width="18" height="18" rx="5" />
          <rect x="509" y="156" width="121" height="15" rx="6" />
          <rect x="485" y="154" width="18" height="18" rx="5" />
          <rect x="509" y="204" width="121" height="15" rx="6" />
          <rect x="485" y="202" width="18" height="18" rx="5" />
          <rect x="509" y="180" width="121" height="15" rx="6" />
          <rect x="746" y="55" width="186" height="23" rx="6" />
          <rect x="746" y="226" width="186" height="23" rx="6" />
          <rect x="746" y="113" width="186" height="15" rx="6" />
          <rect x="746" y="89" width="186" height="15" rx="6" />
          <rect x="746" y="138" width="186" height="15" rx="6" />
          <rect x="746" y="186" width="186" height="15" rx="6" />
          <rect x="746" y="162" width="186" height="15" rx="6" />
          <rect x="485" y="178" width="18" height="18" rx="5" />
          <rect x="486" y="246" width="121" height="18" rx="6" />
          <rect x="509" y="274" width="121" height="15" rx="6" />
          <rect x="460" y="246" width="18" height="18" rx="5" />
          <rect x="485" y="272" width="18" height="18" rx="5" />
          <rect x="509" y="322" width="121" height="15" rx="6" />
          <rect x="485" y="320" width="18" height="18" rx="5" />
          <rect x="509" y="298" width="121" height="15" rx="6" />
          <rect x="485" y="296" width="18" height="18" rx="5" />
          <rect x="509" y="347" width="121" height="15" rx="6" />
          <rect x="485" y="345" width="18" height="18" rx="5" />
          <rect x="509" y="395" width="121" height="15" rx="6" />
          <rect x="485" y="393" width="18" height="18" rx="5" />
          <rect x="509" y="371" width="121" height="15" rx="6" />
          <rect x="485" y="369" width="18" height="18" rx="5" />
          <rect x="265" y="55" width="121" height="18" rx="6" />
          <rect x="288" y="83" width="121" height="15" rx="6" />
          <rect x="239" y="55" width="18" height="18" rx="5" />
          <rect x="264" y="81" width="18" height="18" rx="5" />
          <rect x="288" y="131" width="121" height="15" rx="6" />
          <rect x="264" y="129" width="18" height="18" rx="5" />
          <rect x="288" y="107" width="121" height="15" rx="6" />
          <rect x="264" y="105" width="18" height="18" rx="5" />
          <rect x="288" y="156" width="121" height="15" rx="6" />
          <rect x="264" y="154" width="18" height="18" rx="5" />
          <rect x="288" y="204" width="121" height="15" rx="6" />
          <rect x="264" y="202" width="18" height="18" rx="5" />
          <rect x="288" y="180" width="121" height="15" rx="6" />
          <rect x="264" y="178" width="18" height="18" rx="5" />
          <rect x="288" y="228" width="121" height="15" rx="6" />
          <rect x="264" y="226" width="18" height="18" rx="5" />
          <rect x="288" y="276" width="121" height="15" rx="6" />
          <rect x="264" y="274" width="18" height="18" rx="5" />
          <rect x="288" y="252" width="121" height="15" rx="6" />
          <rect x="264" y="250" width="18" height="18" rx="5" />
        </>
      )}
    </PlaceholderLoader>
  );
};

export default GeoDestinationsOverlaySkeleton;
