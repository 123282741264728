import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistCompareContentWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'flex-start',
    gap: [3, 0],
    '.mobile-filters-button': {
      px: 3,
      width: '100%',

      button: {
        width: '100%',
        pr: '44%',
        '.icon': {
          mr: 'auto',
        },
      },
    },
  })
);

export const WishlistCompareSection = styled.div(
  sx2CssThemeFn({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    px: 2,
  })
);
