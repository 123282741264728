import styled from 'styled-components';

export const MapContainer = styled.div.attrs({
  'data-id': `mapContainer`,
})({
  background: 'bgLightGrey',
  position: 'relative',
  width: '100%',
  height: '600px',
});

export const MapContainerWrapper = styled.div.attrs({
  'data-id': `mapContainerWrapper`,
})({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  border: '1px #666 solid',
});
