import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { AirportFragmentFragmentDoc } from './Airport.generated';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
export type LocationFragment = {
  __typename?: 'GeoLocation';
  airport?: {
    __typename?: 'Airport';
    iata?: string | null;
    name?: string | null;
  } | null;
  continent?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
  countryGroup?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
  country?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
  region?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
  destination?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
  resort?: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  } | null;
};

export const LocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'airportFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AirportFragmentFragmentDoc.definitions,
    ...GeoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
