import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import {
  COMPARE_ITEMS_COUNT_DESKTOP,
  COMPARE_ITEMS_COUNT_MOBILE,
} from 'components/domain/wishlist/utils';
import WishlistItemChangedOverlay from './WishlistItemChangedOverlay';

export const WishlistCompareItemsWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    mt: [2, 3],
    'div:last-of-type': {
      '.item': {
        borderBottom: '1px solid',
        borderColor: 'borderGray',
      },
    },
  })
);

export const Flex = styled.div(
  sx2CssThemeFn({
    display: 'flex',
  })
);

export const ProductHead = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    '.product-title': {
      color: 'secondary',
      fontSize: [1, '18px'],
      pt: [1, 2],
      px: 2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: ['160px', '195px'],
    },
    '.trash-icon': {
      top: '-1px',
      right: '-1px',
    },
    '.breadcrumbs': {
      fontSize: ['12px', '14px'],
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: ['160px', '195px'],
      mb: [1, 2],
      px: 2,
    },
    '.item': {
      borderTop: '1px solid',
      borderColor: 'borderGray',
      px: 0,
    },
  })
);

export const WishlistCompareItemsColumn = styled.div.attrs({
  className: 'item',
})(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: ['50%', `${100 / 3}%`],
    mx: 2,
    overflow: 'hidden',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: 'borderGray',
    px: 2,
    fontSize: ['12px', 0],
    '.product-details-title': {
      fontSize: [0, 0],
      fontWeight: 'bold',
      mb: 2,
    },
    '.product-details-title.type': {
      mb: 3,
    },
    '.wishlist-rooms': {
      fontWeight: 'bold',
      mb: 1,
      fontSize: [0, '12px'],
      mt: 2,
    },
    '.price-title': {
      color: '#999',
      fontSize: [0, 1],
      fontFamily: 'Helvetica Neue',
      mt: 2,
      mb: 1,
      mx: 2,
      '.price': {
        fontSize: [0, '26px'],
        mx: 1,
      },
    },
    '.transfer-title': {
      fontSize: [0, 0],
      mb: 2,
      '.price': {
        mx: 1,
      },
      '.transfer-total, .transfer': {
        mr: 1,
      },
    },
    '.tripadvisor-info': {
      justifyContent: [null, 'center'],
      gap: 1,
      mb: 3,
      '.tripadvisorLogo': {
        width: ['28px', '34px'],
        height: ['18px', '24px'],
        mr: 1,
      },
      '.rating .icon': {
        mr: '2px',
      },
    },
    '.primary-button-link': {
      height: ['auto', '35px'],
      flexWrap: ['wrap', 'nowrap'],
      ml: 0,
      mb: 3,
      pl: '10px',
      '.link-button-total-from': {
        ml: ['0', 'auto'],
        '.link-button-total': {
          mr: 2,
          '&:empty': {
            mr: '0',
          },
        },
        '.link-button-from': {
          whiteSpace: 'nowrap',
        },
      },
    },
  })
);

export const FlexWrapFullWidth = styled(Flex)(
  sx2CssThemeFn({
    '.item': {
      px: 0,
    },
  })
);

export const RatingWrapper = styled.div(
  sx2CssThemeFn({
    pt: [2, 3],
    px: 2,
    height: '33px',
  })
);

export const WishlistProductImage = styled(Image)(
  sx2CssThemeFn({
    mb: [2, 3],
    height: ['96px', '140px'],
    objectFit: 'cover',
  })
);

export const WishlistCompareFeaturesSectionWrapper = styled.div(
  sx2CssThemeFn({
    '.accordionButtonWrap': {
      p: 0,
      '.icon.chevron': {
        mr: 1,
      },
    },
    '.accordionButton': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'none',
      color: 'textGray',
      py: 2,
    },
  })
);

export const WishlistItemChangedOverlayWrapper = styled(
  WishlistItemChangedOverlay
)<{
  index: number;
}>(({ index }) =>
  sx2CssThemeFn({
    mx: 2,
    left: [
      `calc(${(index / COMPARE_ITEMS_COUNT_MOBILE) * 100}%)`,
      `calc(${(index / COMPARE_ITEMS_COUNT_DESKTOP) * 100}%)`,
    ],
    right: [
      `calc(${
        ((COMPARE_ITEMS_COUNT_MOBILE - (index + 1)) /
          COMPARE_ITEMS_COUNT_MOBILE) *
        100
      }%)`,
      `calc(${
        ((COMPARE_ITEMS_COUNT_DESKTOP - (index + 1)) /
          COMPARE_ITEMS_COUNT_DESKTOP) *
        100
      }%)`,
    ],
    '.flights': {
      flexDirection: 'column',
    },
  })
);

export const IncludedInPriceWrapper = styled.div(
  sx2CssThemeFn({
    marginBottom: 3,
    '.included-in-price-text-block': {
      ul: {
        listStyle: 'disc',
      },
      '.text-block-text': {
        border: 'none',
        m: 0,
        p: 2,
        pl: 0,
      },
    },
  })
);

export const IncludedInPriceHeader = styled.span(
  sx2CssThemeFn({
    pt: 2,
    ml: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontWeight: 'bold',
    '.icon': {
      width: '16px',
      height: '16px',
    },
  })
);
