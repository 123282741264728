import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatPrice } from '@hotelplan/libs.utils';
import { PriceFragment } from 'graphql/fragments/Price.generated';

const PriceWrapper = styled.div(
  sx2CssThemeFn({
    lineHeight: '1',
    '.title': {
      textTransform: 'uppercase',
      fontSize: 0,
      fontWeight: 'bold',
    },
    '.value': {
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: ['10px', 1],
    },
    '.amount': {
      fontSize: ['14px', '26px'],
    },
  })
);

interface IProductPriceProps {
  title: string;
  price: PriceFragment;
  className?: string;
}

const ProductPrice: React.FC<IProductPriceProps> = ({
  title,
  price,
  className,
}: IProductPriceProps) => {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  return (
    <PriceWrapper className={className}>
      {!mobile && <div className="title">{title}</div>}
      <div className="value">
        <span className="currency">
          {t('priceFrom')} {price.currency}
        </span>
        &nbsp;
        <span className="amount">
          {formatPrice(price, { currency: false })}
        </span>
      </div>
    </PriceWrapper>
  );
};
export default ProductPrice;
