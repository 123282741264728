import styled from 'styled-components';

const Container = styled.div(({ theme: { space, media } }) => ({
  maxWidth: '1028px',
  margin: 'auto',
  [media.tablet]: {
    paddingLeft: space[4],
    paddingRight: space[4],
  },
}));

export default Container;
