import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { useField } from '@hotelplan/components.common.forms';
import {
  AbsoluteDropdown,
  DropDownWrapper,
} from '@hotelplan/components.common.modals';
import {
  getMinDurationInDays,
  ITravelDatesState,
  SHORT_INFO_FORMAT,
  TravelDatesControllers,
  TravelDatesField,
  useTravelDatesState,
} from '@hotelplan/components.common.travel-dates';
import { SearchPeriodType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { addDays, formatDate, getTodaysDate } from '@hotelplan/libs.utils';
import {
  CloseButton,
  DropdownHeader,
} from 'components/domain/filters/SearchFilterDesktopDropdown';
import { calcFormFieldInputValue } from 'components/domain/srl/CurrentSearchContext';
import CustomTravelDatesField from './CustomTravelDatesField';

const DEFAULT_DURATION_DAYS = 14;

const TravelDatesAccordion = styled.div(
  sx2CssThemeFn({
    '.header': {
      position: 'static',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 2,
      px: 2,
      py: 3,
    },
    '.switch-button label, .base-input-wrapper': {
      fontSize: '14px',
    },
  })
);

const ApplyButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
    size: 'xs',
  },
})(({ theme }) =>
  sx2CssThemeFn({
    ...theme.buttons.btnWithIcon,
    textTransform: 'uppercase',
  })
);

const TravelDatesWrapper = styled.div(
  sx2CssThemeFn({
    '.absolute-dropdown': {
      width: [null, '360px'],
    },
    '.travel-dates-dropdown': {
      position: 'absolute',
      width: '315px',
      backgroundColor: '#fff',
      boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
      border: '1px solid #999',
      borderRadius: '5px',
      p: 2,
      pt: [null, '10px'],
      '.body': {
        mt: [1, 0],
      },
    },
    '.returnDate': {
      '.travel-dates-dropdown': {
        right: ['0', 'unset'],
      },
    },
  })
);

const dateLabelTranslations = {
  [SearchPeriodType.Flexible]: {
    departureDate: 'search:departureDatePicker.flexible.label',
    arrivalDate: 'search:arrivalDatePicker.flexible.label',
  },
  [SearchPeriodType.Exact]: {
    departureDate: 'search:departureDatePicker.exact.label',
    arrivalDate: 'search:arrivalDatePicker.exact.label',
  },
};

interface ITravelDatesContainerProps {
  label?: React.ReactNode;
}

export default function TravelDatesContainer({
  label,
}: ITravelDatesContainerProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation(['search', 'filters']);
  const [showTravelDates, , close, toggle] = useToggleState(false);

  const textInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const travelDatesDropdownRef = useRef<HTMLDivElement>(null);
  const [travelDatesData, setTravelDatesData] =
    useField<ITravelDatesState | null>('travelDates');

  const {
    state,
    setDepartureDate,
    setReturnDate,
    clearDateRange,
    setDuration,
    setSearchType,
  } = useTravelDatesState({
    departureDate: travelDatesData.departureDate,
    duration: travelDatesData.duration,
    returnDate: travelDatesData.returnDate,
    searchType: travelDatesData.searchType,
    extraDurations: travelDatesData.extraDurations,
    defaults: travelDatesData.defaults,
  });

  const translatedShortInformation = calcFormFieldInputValue(
    'travelDates',
    state,
    t
  );

  useOnClickOutside<HTMLDivElement | HTMLInputElement>(
    [containerRef, textInputRef],
    close,
    showTravelDates
  );

  const customSetDepartureDate = (departureDate: Date) => {
    setDepartureDate(departureDate);
    setReturnDate(addDays(departureDate, DEFAULT_DURATION_DAYS));
  };

  const customTravelDatesFieldProps = {
    travelDatesState: state,
    onSaveTravelDates: setTravelDatesData,
    initialDepartureDate: travelDatesData.departureDate,
    initialReturnDate: travelDatesData.returnDate,
    travelDatesHandlers: {
      setDepartureDate: customSetDepartureDate,
      setReturnDate,
      clearDateRange,
    },
  };

  const minReturnDate =
    state.searchType === SearchPeriodType.Exact
      ? addDays(state.departureDate, 1)
      : addDays(state.departureDate, getMinDurationInDays(state.duration));

  function renderTravelDates(): React.ReactNode {
    return (
      <>
        <TravelDatesControllers
          travelDatesState={state}
          setDuration={setDuration}
          setSearchType={setSearchType}
          hasCustomWeekDayElement={false}
        >
          <CustomTravelDatesField
            {...customTravelDatesFieldProps}
            customConfiguration={{
              onlyDepartureDate: true,
              closeOnSelection: true,
              maxDate: addDays(getTodaysDate(), 365),
              minDate: getTodaysDate(),
            }}
            initialMonth={state.departureDate}
            fieldLabel={t(
              dateLabelTranslations[state.searchType].departureDate
            )}
            fieldDate={formatDate(state.departureDate, SHORT_INFO_FORMAT)}
          />
          <CustomTravelDatesField
            {...customTravelDatesFieldProps}
            customConfiguration={{
              onlyReturnDate: true,
              closeOnSelection: true,
              minDate: minReturnDate,
            }}
            initialMonth={state.returnDate}
            fieldLabel={t(dateLabelTranslations[state.searchType].arrivalDate)}
            fieldDate={formatDate(state.returnDate, SHORT_INFO_FORMAT)}
            className="returnDate"
          />
        </TravelDatesControllers>
        {!mobile ? (
          <ApplyButton
            onClick={() => {
              close();
              setTravelDatesData(state);
            }}
          >
            {t('filters:apply.button')}
          </ApplyButton>
        ) : null}
      </>
    );
  }

  return (
    <TravelDatesWrapper>
      <TravelDatesField
        textInputRef={textInputRef}
        isDropdownOpened={showTravelDates}
        translatedShortInformation={translatedShortInformation}
        onClick={toggle}
        customLabel={label}
      >
        <>
          {mobile ? (
            <>
              {showTravelDates ? (
                <TravelDatesAccordion>
                  {renderTravelDates()}
                </TravelDatesAccordion>
              ) : null}
            </>
          ) : (
            <DropDownWrapper ref={containerRef} className={'dropdown-wrapper'}>
              <AbsoluteDropdown
                isShown={showTravelDates}
                ref={travelDatesDropdownRef}
                className="absolute-dropdown search"
              >
                <DropdownHeader>
                  <h3 className="title">{t('search:travelDates.title')}</h3>
                  <CloseButton onClick={close} />
                </DropdownHeader>
                {renderTravelDates()}
              </AbsoluteDropdown>
            </DropDownWrapper>
          )}
        </>
      </TravelDatesField>
    </TravelDatesWrapper>
  );
}
