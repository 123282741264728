import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useRef } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useWishlistOffer } from 'components/domain/wishlist/hooks';
import { useHover } from 'libs';
import {
  WishlistButtonTooltipWrapper,
  WishlistFavContainerWrapper,
  WishlistFavIcon,
} from './WishlistFavContainer.styled';

interface IWishlistFavContainerProps {
  offerId: string;
  inWishlist: boolean;
}
export default function WishlistFavContainer(
  props: PropsWithChildren<IWishlistFavContainerProps>
): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const { offerId, inWishlist, children } = props;

  const { mobile } = useDeviceType();
  const wishlistIconRef = useRef<HTMLDivElement>(null);

  const [isHovered, enter, leave] = useToggleState(false);
  const { isActive, onToggle } = useWishlistOffer(offerId, inWishlist);

  useHover(wishlistIconRef, enter, leave);

  const tooltipText = isActive
    ? t('wishlistSRL.tooltip')
    : t('wishlistSRLEnactive.tooltip');

  return (
    <WishlistFavContainerWrapper>
      <div
        ref={wishlistIconRef}
        style={{
          position: 'relative',
        }}
      >
        <WishlistFavIcon
          name={isActive ? 'wishlist-active' : 'wishlist-inactive'}
          onClick={onToggle}
        />
        {!mobile && isHovered ? (
          <WishlistButtonTooltipWrapper
            text={tooltipText}
            className="tooltip arrow-right"
          />
        ) : null}
      </div>

      {children}
    </WishlistFavContainerWrapper>
  );
}
