import { useTranslation } from 'next-i18next';
import React, { useMemo, useRef } from 'react';
import { Image } from '@hotelplan/components.common.image';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import HeaderButtonTooltip from 'components/domain/my-account/header-section/header-button-tooltip/HeaderButtonTooltip';
import { useWishlistHeaderButton } from 'components/domain/wishlist/hooks';
import { useHover } from 'libs';
import {
  PinsLoader,
  WishlistHeaderLink,
  WishlistIconWrapper,
} from './WishlistHeaderButton.styled';

interface IWishlistHeaderButtonProps {
  isAuthenticated: boolean;
}

export default function WishlistHeaderButton({
  isAuthenticated,
}: IWishlistHeaderButtonProps): React.ReactElement {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const headerWishlistButtonRef = useRef<HTMLAnchorElement>(null);
  const [isHovered, enter, leave] = useToggleState(false);
  useHover(headerWishlistButtonRef, enter, leave);

  const { routerLinkProps, wishlistProductsAmount, loading } =
    useWishlistHeaderButton();

  const isActive = useMemo(
    () => wishlistProductsAmount > 0,
    [wishlistProductsAmount]
  );

  if (loading) {
    return <WishlistHeaderButtonSkeleton />;
  }

  return (
    <RouterLink {...routerLinkProps} passHref>
      <WishlistHeaderLink
        isActive={wishlistProductsAmount > 0}
        ref={headerWishlistButtonRef}
      >
        <WishlistIconWrapper
          isActive={isActive}
          isAuthenticated={isAuthenticated}
          count={wishlistProductsAmount}
        />
        {!mobile && isHovered ? (
          <HeaderButtonTooltip
            text={t('wishlist.header.tooltip')}
            className="arrow-top"
          />
        ) : null}
      </WishlistHeaderLink>
    </RouterLink>
  );
}

function WishlistHeaderButtonSkeleton(): React.ReactElement {
  return (
    <WishlistHeaderLink>
      <PinsLoader>
        <Image
          resized={[]}
          src="/images/spinner-button.svg"
          alt="LOADING"
          className="pins-loader"
        />
      </PinsLoader>
    </WishlistHeaderLink>
  );
}
