import { useTranslation } from 'next-i18next';
import React from 'react';
import { Greetings } from '@hotelplan/components.common.greetings';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import GreetingsSkeleton from 'components/domain/my-account/GreetingsSkeleton';
import { useGetAccountPersonalDataQuery } from 'graphql/my-account/GetAccountPersonalData.generated';
import { mapPageTypeTableToLinksList } from 'libs';
import {
  MenuItemsWrapper,
  MenuLogoutLink,
  MyAccountHeaderMenuWrapper,
} from './HeaderSection.styles';

const menuPagesWithLabels = {
  [PageType.MyaccountMybookings]: 'myaccount:myBooking',
  [PageType.Wishlist]: 'myaccount:myWishlist',
  [PageType.MyaccountMyprofile]: 'myaccount:myAccount',
};

interface IMyAccountHeaderMenuProps {
  isOpened: boolean;
  onMenuItemClick?(): void;
}

export default function MyAccountHeaderMenu({
  isOpened,
  onMenuItemClick,
}: IMyAccountHeaderMenuProps): React.ReactElement {
  const [t, { language }] = useTranslation(['common', 'myaccount']);
  const { data, loading } = useGetAccountPersonalDataQuery({
    skip: !isOpened,
  });

  const { firstName, lastName } = data?.account.personalData || {};
  const menuItems = mapPageTypeTableToLinksList(menuPagesWithLabels, language);

  if (!isOpened) return null;

  return (
    <MyAccountHeaderMenuWrapper>
      <Greetings
        greetingsTextTranslation={t('common:greetings')}
        firstName={firstName}
        lastName={lastName}
        loading={loading}
        placeholder={<GreetingsSkeleton />}
      />
      <MenuItemsWrapper>
        {menuItems.map(({ label, routerProps }, index) => (
          <RouterLink {...routerProps} key={`${label}-${index}`} passHref>
            <Link className="menu-item" onClick={onMenuItemClick}>
              {t(label)}
            </Link>
          </RouterLink>
        ))}
      </MenuItemsWrapper>
      <MenuLogoutLink />
    </MyAccountHeaderMenuWrapper>
  );
}
