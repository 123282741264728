import { useCallback } from 'react';
import { ISelectableItem } from '@hotelplan/components.common.map-pin';
import { GeoCoordinates } from '@hotelplan/graphql.types';

export default function useSelectMapPinHandler<
  TPin extends { coordinates: GeoCoordinates }
>(
  map: google.maps.Map,
  onSelectObjects: (
    objects: Array<TPin>,
    selectableMapItem: ISelectableItem
  ) => void
) {
  return useCallback(
    (pin: TPin, selectableItem: ISelectableItem) => {
      const { latitude, longitude } = pin.coordinates;

      const frameID = requestAnimationFrame(() => {
        map.panTo({
          lat: latitude,
          lng: longitude,
        });
      });

      onSelectObjects([pin], selectableItem);

      return function cleanupAnimationFrame() {
        cancelAnimationFrame(frameID);
      };
    },
    [map, onSelectObjects]
  );
}
