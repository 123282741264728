import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type SingleValueFilterFragment = {
  __typename?: 'SingleValueFilterComponent';
  id: string;
  caption: string;
};

export const SingleValueFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'singleValueFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SingleValueFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
