import { useTranslation } from 'next-i18next';
import React from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { track } from '@hotelplan/libs.tracking-events';
import { openSearchMap } from 'components/domain/srl-map/hooks/useSearchMapEvents';
import {
  MapAccessBg,
  MapAccessTitle,
  SRLMapFilterAccessWrapper,
} from './SRLMapFilterAccess.styled';

const trackMapOpen = (language: string) => {
  track({
    event: 'open_map',
    action: 'open',
    label: 'Karte öffnen auf SRL Seite',
    category: undefined,
    component: 'map',
    position: 'filter',
    language,
    _clear: true,
  });
};

export default function SRLMapFilterAccess(): React.ReactElement {
  const [t, { language }] = useTranslation('search');
  const pageType = usePageType<PageType>();

  return (
    <SRLMapFilterAccessWrapper
      onClick={() => {
        openSearchMap();
        if (pageType === PageType.Srl) trackMapOpen(language);
      }}
    >
      <MapAccessTitle>{t('map.button')}</MapAccessTitle>
      <MapAccessBg resized={[]} src="/images/map-bg.png" alt="map-bg" />
    </SRLMapFilterAccessWrapper>
  );
}
