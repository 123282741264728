import { useCreateWishlistMutation } from 'graphql/wishlist/mutations/CreateWishlist.generated';

export default function useCreateWishlist() {
  const [mutation, { loading }] = useCreateWishlistMutation({
    refetchQueries: [`GetWishlistOverview`, `GetActiveWishlist`],
  });

  async function createWishlist(name: string) {
    await mutation({
      variables: {
        name,
      },
    });
  }

  return { createWishlist, loading };
}
