import get from 'lodash/get';

const LAYOUT_CONFIG_PROP = `_ly` as const;
interface ILayoutConfig {
  simplified?: boolean;
}

export function createLayoutConfigProps({ simplified = false }: ILayoutConfig) {
  return {
    [LAYOUT_CONFIG_PROP]: {
      simplified,
    },
  } as const;
}

export function getLayoutConfigPageProps<TProps = any>(
  pageProps?: TProps
): ILayoutConfig | null {
  return get(
    pageProps,
    [`props`, LAYOUT_CONFIG_PROP],
    get(pageProps, [LAYOUT_CONFIG_PROP], null)
  );
}
