import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const HeaderButtonTooltipWrapper = styled.div(
  ({ theme, theme: { colors } }) =>
    sx2CssThemeFn({
      ...theme.tooltip,
      visibility: 'visible',
      top: '119%',
      width: 'max-content',
      bg: 'textGray',
      border: 'none',
      fontSize: 0,
      color: 'white',
      '&:before': {
        borderColor: `${colors.textGray} transparent transparent transparent`,
      },
      '&:after': {
        content: 'none',
      },
    })
);
