import { useRouter } from 'next/router';
import React from 'react';
import {
  MobileNavigationMenu,
  IMenuProps,
} from '@hotelplan/components.common.menu';
import LanguageControl from 'components/domain/language-control/LanguageControl';

const MobileMenu: React.FC<IMenuProps> = ({
  links,
  highlightActiveItem,
  onClose,
}) => {
  const { route } = useRouter();

  return (
    <>
      <LanguageControl />
      <MobileNavigationMenu
        links={links}
        highlightActiveItem={!!highlightActiveItem}
        route={route}
        isListView
        onClick={() => {
          onClose?.();
        }}
      />
    </>
  );
};

export default MobileMenu;
