import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type CreateWishlistMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CreateWishlistMutation = {
  __typename?: 'Mutation';
  createWishlist: {
    __typename?: 'WishlistMutationResponse';
    status: Types.Status;
    message?: string | null;
    activeChanged: boolean;
  };
};

export const CreateWishlistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeChanged' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateWishlistMutationFn = Apollo.MutationFunction<
  CreateWishlistMutation,
  CreateWishlistMutationVariables
>;

/**
 * __useCreateWishlistMutation__
 *
 * To run a mutation, you first call `useCreateWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWishlistMutation, { data, loading, error }] = useCreateWishlistMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateWishlistMutation,
    CreateWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateWishlistMutation,
    CreateWishlistMutationVariables
  >(CreateWishlistDocument, options);
}
export type CreateWishlistMutationHookResult = ReturnType<
  typeof useCreateWishlistMutation
>;
export type CreateWishlistMutationResult =
  Apollo.MutationResult<CreateWishlistMutation>;
export type CreateWishlistMutationOptions = Apollo.BaseMutationOptions<
  CreateWishlistMutation,
  CreateWishlistMutationVariables
>;
