import { useTranslation } from 'next-i18next';
import React from 'react';
import SearchFilters from 'components/domain/filters/SearchFilters';
import SearchControlForm from 'components/domain/search-control/components/SearchControlForm/SearchControlForm';
import { displayingFilters } from 'components/domain/wishlist/utils';
import {
  SearchControlFormWrapper,
  SubmitButton,
} from './WishlistCompareSearchControl.styled';

export default function WishlistCompareSearchControlFormDesktop(): React.ReactElement {
  const [t] = useTranslation('wishlist');

  return (
    <SearchControlFormWrapper>
      <SearchControlForm
        hideTravelDestination
        submitButton={
          <SubmitButton type="submit" className={'submit-button'}>
            {t('wishlist:search.form.submit')}
          </SubmitButton>
        }
        filtersComponent={
          <SearchFilters
            includedOptions={displayingFilters}
            showSubAmenities={false}
            isWishlist
          />
        }
      />
    </SearchControlFormWrapper>
  );
}
