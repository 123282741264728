import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const WishlistCardsSkeleton = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 398, h: 140 }
    : { w: 357, h: 170 };

  return (
    <PlaceholderLoader
      width={width}
      height={height}
      uid="WishlistCardsSkeleton"
      style={{ height: height, width: width, display: 'block' }}
    >
      {mobile ? (
        <>
          <ellipse cx="98" cy="31" rx="20" ry="19" />
          <ellipse cx="182.5" cy="12" rx="7.5" ry="8" />
          <rect x="62" y="72" width="74" height="15" rx="7.5" />
          <rect x="76" y="108" width="74" height="15" rx="7.5" />
          <rect x="58" y="108" width="15" height="15" rx="7.5" />
          <rect x="300" y="61" width="2" height="16" rx="1" />
          <rect
            x="292.999"
            y="69.9929"
            width="2"
            height="16"
            rx="1"
            transform="rotate(-89.9493 292.999 69.9929)"
          />
          <rect x="203" width="195" height="1" />
          <rect x="204" y="139" width="194" height="1" />
          <rect x="203" width="1" height="140" />
          <rect x="397" width="1" height="140" />
          <rect width="195" height="1" />
          <rect y="60" width="195" height="1" />
          <rect y="139" width="195" height="1" />
          <rect width="1" height="140" />
          <rect x="194" width="1" height="140" />
        </>
      ) : (
        <>
          <circle cx="84.5" cy="38.5" r="21.5" />
          <circle cx="148" cy="17" r="13" />
          <rect x="47" y="90" width="74" height="15" rx="7.5" />
          <rect x="59" y="135" width="74" height="15" rx="7.5" />
          <rect x="37" y="135" width="15" height="15" rx="7.5" />
          <rect x="273" y="77" width="2" height="16" rx="1" />
          <rect
            x="265.999"
            y="85.9929"
            width="2"
            height="16"
            rx="1"
            transform="rotate(-89.9493 265.999 85.9929)"
          />
          <rect x="187" width="169" height="1" />
          <rect x="187" y="169" width="169" height="1" />
          <rect x="187" width="1" height="170" />
          <rect width="170" height="1" />
          <rect y="73" width="170" height="1" />
          <rect width="1" height="170" />
          <rect x="356" width="1" height="170" />
          <rect x="1" y="169" width="169" height="1" />
          <rect x="169" width="1" height="170" />
        </>
      )}
    </PlaceholderLoader>
  );
};

export default WishlistCardsSkeleton;
