import { ApolloCache, ApolloClient } from '@apollo/client';
import { MutableRefObject } from 'react';
import {
  WishlistItemFragment,
  WishlistItemFragmentDoc,
} from 'graphql/wishlist/fragments/WishlistItemFragment.generated';
import { AddToActiveWishlistWithSourceMutation } from 'graphql/wishlist/mutations/AddToActiveWishlistWithSource.generated';
import { IWishlistOfferManaging, TPossibleMutation } from './wishlist.types';

export function isInWishlist(wishlistItem: WishlistItemFragment, id: string) {
  return wishlistItem?.id === id && wishlistItem?.inWishlist;
}

const isAddResponse = (
  data: TPossibleMutation
): data is AddToActiveWishlistWithSourceMutation =>
  'addToActiveWishlistWithSource' in data;

export const getResult = (data: TPossibleMutation) => {
  return isAddResponse(data)
    ? data.addToActiveWishlistWithSource
    : data.removeFromActiveWishlist;
};

export const updateOffer = (
  data: TPossibleMutation,
  cache: MutableRefObject<IWishlistOfferManaging>,
  id: string
) => {
  if (isAddResponse(data)) {
    const offer = data.addToActiveWishlistWithSource.offer;

    if (!offer) return;

    cache.current.select(offer.id);
    if (offer.id !== id) {
      cache.current.select(id);
    }
  } else {
    cache.current.update(id, false);
  }
};

export function updateWishlistItemCache(
  client: ApolloClient<object>,
  offerId: string | undefined,
  inWishlist: boolean
): void {
  if (!offerId) return;

  const fragment = client.readFragment<WishlistItemFragment>({
    fragment: WishlistItemFragmentDoc,
    id: `WishlistItem:${offerId}`,
  });

  if (fragment?.inWishlist === inWishlist) return;

  try {
    client.writeFragment<WishlistItemFragment>({
      fragment: WishlistItemFragmentDoc,
      id: `WishlistItem:${offerId}`,
      data: {
        __typename: `WishlistItem`,
        id: offerId,
        inWishlist,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Could not update wishlist offer`, e);
  }
}

export function removeWishlistActiveCache<
  TCache extends Record<string, any> = object
>(cache: ApolloCache<TCache>): void {
  const cacheData: TCache = (cache as any).data.data;
  const wishlistKeys: string[] = Object.keys(cacheData).filter(
    cacheKey =>
      /wishlist/i.test(cacheKey) && /(\.active\(|\.overview)/i.test(cacheKey)
  );
  wishlistKeys.forEach(cacheKey => {
    delete cacheData[cacheKey];
  });
}
