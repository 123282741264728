import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type PersonalDataFragment = {
  __typename?: 'AccountPersonalDataResponse';
  firstName?: string | null;
  lastName?: string | null;
  salutation?: Types.Salutation | null;
  email?: string | null;
  dateOfBirth?: any | null;
  nationality?: string | null;
};

export const PersonalDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'personalData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccountPersonalDataResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
