import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Image } from '@hotelplan/components.common.image';
import {
  CommonModal,
  WhiteBoxModal,
} from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const LoadingIcon = styled(Image).attrs({
  resized: [],
  src: '/images/spinner-button.svg',
  alt: 'LOADING',
})({
  height: '16px',
});

export const LoadingModal = styled(CommonModal).attrs({
  title: '',
  name: '',
  children: <LoadingIcon />,
})({});

export const SharingModal = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      maxWidth: [null, '770px'],
    },
    '.modal-header': {
      px: 4,
      '.modal-title': {
        fontSize: ['20px', '26px'],
        fontFamily: '"Helvetica Neue", sans-serif',
        color: 'secondary',
      },
    },
  })
);

export const SharingModalControls = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    width: '100%',
    flexDirection: ['column', 'row'],
    justifyContent: [null, 'space-between'],
    px: 4,
    button: {
      minWidth: '150px',
    },
    pb: 4,

    'button:not(:last-child)': {
      mb: [2, 0],
    },
  })
);

export const OverwriteButton = styled(Button)(
  sx2CssThemeFn({
    alignSelf: 'flex-start',
    px: '12px',
    textAlign: 'center',
    width: ['100%', 'auto'],
    bg: 'gray',
  })
);

export const SharingModalBody = styled.div(
  sx2CssThemeFn({
    p: 4,
    '.sharing-text': { fontSize: 1, mb: 3 },
  })
);
