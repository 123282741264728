import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const WishlistCompareItemsSkeletonItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 180, h: 785 }
    : { w: 214, h: 785 };

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: height,
      }}
    >
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        <>
          <rect x="1" width="213" height="28" rx="4" />
          <rect x="9" y="61" width="80" height="16" rx="4" />
          <rect x="9" y="89" width="175" height="16" rx="4" />
          <rect x="9" y="109" width="139" height="14" rx="4" />
          <rect y="44" width="214" height="1" />
          <rect x="1" y="133" width="213" height="140" />
          <rect y="44" width="1" height="740" />
          <rect x="213" y="44" width="1" height="740" />
          <rect x="10" y="660" width="194" height="35" />
          <rect x="44" y="713" width="118" height="20" rx="4" />
          <rect x="10" y="631" width="152" height="20" rx="4" />
          <rect x="10" y="571" width="152" height="13" rx="4" />
          <rect x="10" y="588" width="152" height="13" rx="4" />
          <rect x="10" y="605" width="85" height="13" rx="4" />
          <rect x="10" y="554" width="80" height="13" rx="4" />
          <rect x="10" y="528" width="140" height="13" rx="4" />
          <rect x="10" y="506" width="92" height="13" rx="4" />
          <rect x="10" y="416" width="107" height="13" rx="4" />
          <rect x="9" y="291" width="92" height="13" rx="4" />
          <rect x="9" y="322" width="111" height="13" rx="4" />
          <rect x="9" y="346" width="71" height="13" rx="4" />
          <rect x="10" y="467" width="172" height="13" rx="4" />
          <rect x="9" y="394" width="172" height="13" rx="4" />
          <rect x="9" y="437" width="93" height="18" rx="4" />
          <rect x="9" y="367" width="93" height="18" rx="4" />
          <rect x="10" y="742" width="189" height="15" rx="4" />
          <rect y="784" width="214" height="1" />
        </>
      </PlaceholderLoader>
    </div>
  );
};

const Wrapper = styled.div(
  sx2CssThemeFn({
    mt: [4, 2],
    display: 'flex',
  })
);

const WishlistCompareItemsSkeleton: React.FC = () => {
  const { mobile } = useDeviceType();

  const counts = mobile ? 2 : 3;
  return (
    <Wrapper>
      {Array.from({ length: counts }, (_, i) => (
        <div
          key={i}
          style={{
            width: '100%',
          }}
        >
          <WishlistCompareItemsSkeletonItem />
        </div>
      ))}
    </Wrapper>
  );
};

export default WishlistCompareItemsSkeleton;
