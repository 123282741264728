import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const ToolsWrap = styled.div.attrs({
  className: 'sitetool-wrap',
})(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    '.button-text': {
      fontSize: '0px',
    },
    flex: '0 0 auto',
    width: 'auto',
    mb: 0,
    border: '1px solid #ccc',
    borderTop: 'none',
    borderRight: [null, 'none'],
    borderRadius: ['0 0 5px 5px', '0 0 0 5px'],
    boxShadow: '0 1px 2px rgb(203 200 194 / 25%)',
    overflow: 'hidden',
    padding: '0px',
    '& .sitetool-item:hover:after': {
      backgroundColor: 'secondary',
    },
    '.sitetool-item:not(:last-child)': {
      borderRight: '1px solid',
      borderColor: 'borderGray',
    },
  })
);

export const SiteToolsButtonsWrapper = styled.div(
  sx2CssThemeFn({
    width: ['40px', '50px'],
    height: ['40px', '40px'],
    background: 'linear-gradient(to bottom,#fff 0,#eee 100%)',
    display: 'flex',
    borderRadius: '0px',
    color: 'white',
    '&:not(:last-child)': {
      mr: [null, 0],
      borderRight: [null, '1px solid #ccc'],
    },
    '.icon': {
      variant: 'icons.md',
      margin: 'auto',
      zIndex: 2,
    },
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '5px',
      left: ['5px', '10px'],
      width: '30px',
      height: '30px',
      backgroundColor: ['grayMiddle', '#a1a1a1'],
      display: 'block',
      zIndex: 1,
      borderRadius: '50%',
    },
    '&:hover': {
      background: [null, 'linear-gradient(to bottom,#fff 0,#ccc 100%)'],
      cursor: 'pointer',
    },
  })
);

export const SiteToolTooltipWrapper = styled.div(
  ({ theme, theme: { colors } }) =>
    sx2CssThemeFn({
      position: 'absolute',
      bottom: '-7px',
      left: '50%',
      transform: 'translateX(-50%)',
      '.tooltip': {
        ...theme.tooltip,
        top: '0',
        maxWidth: 'fit-content',
        whiteSpace: 'nowrap',
        visibility: 'visible',
        fontSize: 0,
        bg: 'textGray',
        border: 'none',
        '&:before': {
          borderColor: `${colors.textGray} transparent transparent transparent`,
        },
        '&:after': {
          content: 'none',
        },
      },
      '.tooltipArrow': {
        display: 'none',
      },
    })
);
