import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const LogoutButtonElement = styled(Button)(
  sx2CssThemeFn({
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    color: 'primary',
  })
);
