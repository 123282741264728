import React from 'react';
import styled from 'styled-components';
import { CookiesDisclaimer } from '@hotelplan/components.common.cookies-disclaimer';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { useGetCookieDisclaimer } from './useGetCookieDisclaimer';

const CookiesDisclaimerWrap = styled(CookiesDisclaimer)(
  sx2CssThemeFn({
    bg: 'textGray',
    color: 'white',
    alignItems: ['flex-start', 'center'],
    position: ['fixed', 'static'],
    bottom: [0, 'auto'],
    zIndex: [4, 0],
    '@media screen and (max-width: 1000px) and (orientation: landscape)': {
      left: '0',
      px: 6,
    },
  })
);

const CookiesDisclaimerBanner: React.FC = () => {
  const { cookieDisclaimerText } = useGetCookieDisclaimer();

  return cookieDisclaimerText ? (
    <CookiesDisclaimerWrap text={cookieDisclaimerText} />
  ) : null;
};

export default CookiesDisclaimerBanner;
