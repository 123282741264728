import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { FilterCountsOptionFragment } from 'graphql/srl/GetSRLSubAmenitiesFilterCounts.generated';

const CountsWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: ['70px', '50px'],
    left: '50%',
    transform: 'translateX(-50%)',
    border: '1px solid',
    borderColor: 'borderGray',
    borderRadius: 'default',
    p: '2px 5px',
    bg: 'white',
    height: '19px',
    color: 'textGray',
    fontWeight: 'bold',
  })
);

interface IFilterCountsProps {
  loading?: boolean;
  options: FilterCountsOptionFragment[];
  filterOptionId: string;
  className?: string;
}

export default function FilterCounts({
  options,
  filterOptionId,
  loading,
  className,
}: IFilterCountsProps): React.ReactElement {
  return (
    <CountsWrapper className={`${className} filter-count`}>
      {loading ? (
        <p>...</p>
      ) : (
        <span>
          {options?.find(option => option.id === filterOptionId)?.productCount}
        </span>
      )}
    </CountsWrapper>
  );
}
