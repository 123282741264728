import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { TripAdvisorInfo } from '@hotelplan/components.common.tripadvisor-rating';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const TripAdvisorInfoWrapper = styled(TripAdvisorInfo)(
  sx2CssThemeFn({
    mb: 2,
    flexDirection: 'column',
    alignItems: ['flex-start', 'flex-start'],
    '.tripAdvisorInfoText': {
      fontSize: ['14px', 0],
      width: ['100%', 'auto'],
      mt: [2, 0],
    },
    '.rating .icon, .remainRating .icon, .halfWayRating .icon': {
      width: '17px',
      height: '17px',
      mr: '2px',
    },
    '.tripadvisorLogo': {
      width: '30px',
      height: '20px',
      mr: 2,
    },
  })
);

const ProductTripAdvisorInfo = ({
  taRating,
  taReviews,
}: {
  taRating: number;
  taReviews: number;
}) => {
  const [t] = useTranslation('common');

  return (
    <>
      {taRating > 0 && (
        <TripAdvisorInfoWrapper
          rating={taRating}
          reviewCount={taReviews || 0}
          label={t('common:tripAdvisor.reviewsCount')}
          className="card-trip-advisor"
        />
      )}
    </>
  );
};

export default ProductTripAdvisorInfo;
