import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterValueFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterValue.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { RadiobuttonGroupFilterValueFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterValue.generated';
import { UrlFilterValuesFragmentDoc } from 'graphql/searchFilter/URLFilterValues.generated';
export type OrlFilterValuesFragment = {
  __typename?: 'FilterComponent';
  roomTypes: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  boardTypes: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  departureAirports: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  stopOvers: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  departureWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  arrivalWeekdays: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  arrivalAirports: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  minPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  productCode?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  provider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  flightProvider?: {
    __typename?: 'SingleValueFilterComponent';
    id: string;
    caption: string;
  } | null;
  refundableFeatures: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  radius?: {
    __typename?: 'RadiusFilterComponent';
    label?: string | null;
    radius: number;
    center: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
};

export const OrlFilterValuesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ORLFilterValues' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'URLFilterValues' },
          },
        ],
      },
    },
    ...CheckboxGroupFilterValueFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...RadiobuttonGroupFilterValueFragmentDoc.definitions,
    ...UrlFilterValuesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
