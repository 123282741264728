import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type RadiobuttonGroupFilterValueFragment = {
  __typename?: 'RadiobuttonFilterComponent';
  selected: string;
};

export const RadiobuttonGroupFilterValueFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RadiobuttonFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
