import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SrlFilterValuesFragmentDoc } from 'graphql/srl/SRLFilterValues.generated';
export type MainFilterComponentFragment = {
  __typename?: 'MainFilterComponent';
  mainFilter: {
    __typename?: 'FilterComponent';
    departureAirports: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    roomTypes: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    hpRating: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
    taRating: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
    boardTypes: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    maxPrice: {
      __typename?: 'SliderFilterComponent';
      caption: string;
      selected: string;
    };
    directFlightDepartureTime: {
      __typename?: 'RadiobuttonFilterComponent';
      selected: string;
    };
    returnFlightDepartureTime: {
      __typename?: 'RadiobuttonFilterComponent';
      selected: string;
    };
    stopOvers: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
    mainFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    childrenFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    beachFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    cityFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    winterSportFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    summerSportFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    waterSportFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    wellnessFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    hotelEntertainmentFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    hotelFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    nightlifeFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    cultureFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    restaurantFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    wlanFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    sustainableFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    nationalParkFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    resortNatureFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    petsFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    ECarChargingFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    resortFlairFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    resortShoppingFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    luxuryFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    clubHotelsFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    adultsFeatures: {
      __typename?: 'CheckboxFilterComponent';
      selected: Array<string>;
    };
    radius?: {
      __typename?: 'RadiusFilterComponent';
      label?: string | null;
      radius: number;
      center: {
        __typename?: 'GeoCoordinates';
        latitude: number;
        longitude: number;
      };
    } | null;
  };
};

export const MainFilterComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'mainFilterComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MainFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFilter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SRLFilterValues' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlFilterValuesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
