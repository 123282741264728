import { DocumentNode } from 'graphql';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  IAutocompleteContext,
  TTravelDestinationAutocompleteConfig,
} from '@hotelplan/components.common.autocomplete';
import { Button } from '@hotelplan/components.common.buttons';
import {
  GenericTravelDestinationField,
  ICanonicalTravelDestination,
  IDestinationAutocompleteVariables,
  IDestinationData,
  mapCanonicalDestinationToTrackableDestination,
} from '@hotelplan/components.common.travel-destination';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { trackSelectSuggestion } from '@hotelplan/libs.tracking';
import { trackMoreDestinationsClick } from 'components/domain/search-control/tracking/searchControlTracking';
import GeoDestinationsOverlay from 'components/domain/travel-destination/GeoDestinationOverlay';
import useGeoDestination from 'components/domain/travel-destination/useGeoDestination';
import GeoDestinationsContent from './GeoDestinationsContent';

const MultipleDestinationsButton = styled(Button)(({ theme: { colors } }) =>
  sx2CssThemeFn({
    color: colors.primary,
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: colors.interactionPrimary,
      color: colors.interactionPrimary,
    },
  })
);

export const DESTINATIONS_LIMIT = 1;
const DEFAULT_MAX_ITEMS = 1;

interface IBaseTravelDestinationFieldProps<TData, TVariables> {
  disabled?: boolean;
  queryHandlers: {
    queryDocument: DocumentNode;
    dataMapper: (data: TData | undefined) => IDestinationData;
    variablesMapper: (
      variables: IDestinationAutocompleteVariables
    ) => TVariables;
  };
  label?: React.ReactNode;
  isSeparateBlock?: boolean;
  onDestinationChange?: (value: boolean) => void;
}

export default function BaseTravelDestinationField<
  TData extends object,
  TVariables extends { text: string }
>({
  disabled,
  queryHandlers: { queryDocument, variablesMapper, dataMapper },
  label,
  isSeparateBlock,
  onDestinationChange,
}: IBaseTravelDestinationFieldProps<TData, TVariables>): React.ReactElement {
  const [t, { language }] = useTranslation('search');
  const [showDestinationsOverlay, show, close] = useToggleState(false);

  const { continentGroups, getDestinationGroups, loading } =
    useGeoDestination();

  const autocompleteContextRef =
    useRef<IAutocompleteContext<ICanonicalTravelDestination>>(null);

  const config: TTravelDestinationAutocompleteConfig = {
    autocompleteContextRef,
    disableMobileOverlay: true,
    disableAutocompleteTags: true,
    closePopupAfterSelect: true,
    maxItems: DEFAULT_MAX_ITEMS,
    iconProps: {
      role: 'button',
      clickable: true,
      onClick() {
        show();
        getDestinationGroups();
      },
    },
    suggestionsExtraButton: {
      show: isSeparateBlock,
      button: (
        <MultipleDestinationsButton
          data-id="multiple-destinations"
          variant="linkBtn"
          onClick={() => {
            show();
            getDestinationGroups();
            trackMoreDestinationsClick(language);
          }}
        >
          {t('search:travelDestination.multipleDestinations.label')}
        </MultipleDestinationsButton>
      ),
    },
  };

  return (
    <>
      <GenericTravelDestinationField<TData, TVariables>
        queryDocument={queryDocument}
        disabled={disabled}
        dataMapper={dataMapper}
        variablesMapper={variablesMapper}
        limit={DESTINATIONS_LIMIT}
        config={config}
        customLabel={label}
        onSelect={(item, text) => {
          trackSelectSuggestion(
            mapCanonicalDestinationToTrackableDestination(item),
            text
          );
          onDestinationChange?.(false);
        }}
      />
      <GeoDestinationsOverlay
        show={showDestinationsOverlay}
        close={close}
        loading={loading}
      >
        {continentGroups?.length ? (
          <GeoDestinationsContent
            autocompleteContextRef={autocompleteContextRef}
            continentGroups={continentGroups}
            closeOverlay={close}
            onDestinationChange={onDestinationChange}
          />
        ) : null}
      </GeoDestinationsOverlay>
    </>
  );
}
