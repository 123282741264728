import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionMode,
} from '@hotelplan/components.common.accordion';
import { Icon } from '@hotelplan/components.common.icon';
import { WishlistProductItem } from '@hotelplan/graphql.types';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import { useCommonFeatureGroups } from 'components/domain/wishlist/hooks';
import {
  AccordionItemTitle,
  FeatureItem,
} from './WishlistCompareFeatures.styled';

interface IWishlistCompareFeaturesSectionProps {
  wishlistItem: WishlistProductItem;
}

export default function WishlistCompareFeaturesSection({
  wishlistItem,
}: IWishlistCompareFeaturesSectionProps): React.ReactElement {
  const { selectedItems } = useWishlistSelectedItemsContext();
  const commonFeatureGroups = useCommonFeatureGroups(selectedItems);

  return (
    <Accordion mode={AccordionMode.MULTIPLE}>
      {commonFeatureGroups.map(({ id, name, features }, i) => {
        return (
          <AccordionItem
            key={`feature-group-${i}`}
            title={
              <AccordionItemTitle>
                <Icon
                  name={mapFeatureNameToIcon(id)}
                  className="accordion-icon"
                />
                <span>{name}</span>
              </AccordionItemTitle>
            }
          >
            <ul>
              {features.map((feature, idx) => {
                const isEnabled = wishlistItem
                  ? wishlistItem.product.featureGroups.some(g =>
                      g.features.some(f => f.id === feature.id && f.enabled)
                    )
                  : false;

                return (
                  <FeatureItem key={`feature-${idx}`}>
                    <div className="icon-wrap">
                      <Icon
                        name={isEnabled ? 'check-solid' : 'close'}
                        className={`wishlist-feature-icon ${
                          isEnabled ? 'enabled' : null
                        }`}
                      />
                    </div>
                    <div className="wishlist-feature-name">{feature.name}</div>
                  </FeatureItem>
                );
              })}
            </ul>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
