import { useCallback, useMemo, useRef } from 'react';
import { IFormApi } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useSearchState } from '@hotelplan/libs.search-state';
import { ISRLFormState, ISrlState } from 'components/domain/srl/SRL.types';
import useUpdateSearchState from 'components/domain/srl/useUpdateSearchState';

type TSearchControlEssentialsOptions = {
  updateOnSubmit?: boolean;
};

const useSearchControlFormEssentials = ({
  updateOnSubmit,
}: TSearchControlEssentialsOptions) => {
  const { mobile } = useDeviceType();

  const {
    state: { formConfig },
    setState,
  } = useSearchState<ISrlState>();

  const formApiRef = useRef<IFormApi<ISRLFormState>>(null);
  const { initialValues, updateSearchState } = useUpdateSearchState();

  const onChange = useCallback(
    (nextValues: ISRLFormState) => {
      formApiRef?.current?.setValues({
        ...nextValues,
      });
      // On mobile state should be updated on submit
      !mobile && !updateOnSubmit && updateSearchState(nextValues);
    },
    [mobile, updateOnSubmit, updateSearchState]
  );

  const onSubmit = useCallback(
    (nextValues: ISRLFormState) => {
      updateSearchState(nextValues);
    },
    [updateSearchState]
  );

  const formUpdateKey = useMemo(() => {
    // Create new key for search form, every time if reRender field is true
    if (formConfig?.reRender) {
      return JSON.stringify(initialValues);
    }

    // Create new key for search form, every time after form was reset
    if (formConfig?.reset) {
      setState(prevState => ({
        ...prevState,
        formConfig: {
          ...prevState.formConfig,
          reset: false,
        },
      }));

      return JSON.stringify(initialValues);
    }

    // Create new key for search form, every time if travelType or travelDestination was changed
    return JSON.stringify({
      travelType: initialValues.travelType,
      travelDestination: initialValues.travelDestination,
    });
  }, [formConfig?.reRender, formConfig?.reset, initialValues, setState]);

  return { formUpdateKey, formApiRef, initialValues, onChange, onSubmit };
};

export default useSearchControlFormEssentials;
