import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import {
  ITransferInfoProps,
  TransferInfo,
} from '@hotelplan/components.common.transfer';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const Wrapper = styled.div(
  sx2CssThemeFn(({ theme: { colors } }) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px ${colors.borderColor} solid`,
    borderRadius: '3px',
    background: '#f2f2f2',
    height: '20px',
    mb: 2,

    '.icon-wrapper': {
      background: '#dedede',
      px: 1,
      display: 'flex',
      alignItems: 'center',
      width: '21px',
      height: '18px',
      borderRight: '1px solid #ccc',
      borderRadius: '2px',
      '.icon': {
        width: '13px',
        height: '13px',
      },
    },

    '.transfer': {
      display: 'inline-block',
      pl: '6px',
      pr: '2px',
      color: '#474747',
      fontSize: '11px',
    },

    '.price': {
      display: 'inline-block',
      background: 'white',
      px: '5px',
      borderLeft: '1px solid #ccc',
      borderRadius: '3px',
      fontSize: '11px',
      lineHeight: '18px',
    },
  }))
);

const OfferTransfer: React.FC<ITransferInfoProps> = props => {
  return (
    <Wrapper className="offer-transfer">
      <div className="icon-wrapper">
        <Icon name="transfer" size="sm" />
      </div>
      <TransferInfo {...props} />
    </Wrapper>
  );
};

export default OfferTransfer;
