import { TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useContext, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getShortInformation as getShortTravelDatesInformation,
  ITravelDatesState,
} from '@hotelplan/components.common.travel-dates';
import {
  getShortInformation as getShortTravelDestinationInformation,
  ITravelDestinationState,
} from '@hotelplan/components.common.travel-destination';
import {
  getShortInformation as getShortTravelRoomsInformation,
  ITravelRoomsState,
} from '@hotelplan/components.common.travel-rooms';
import { useSearchState } from '@hotelplan/libs.search-state';
import {
  priceOptions,
  ratingsIdsToNumberDict,
} from 'components/domain/filters/Filters.common';
import {
  IFilterOption,
  IFilterOptions,
  IFlightOptionsState,
  RadiusFilter,
  TFilterOptionName,
} from 'components/domain/filters/Filters.types';
import { TSearchControlInput } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { DESTINATIONS_LIMIT } from 'components/domain/travel-destination/BaseTravelDestinationField';
import { ISrlState } from './SRL.types';
import useSRLFilterOptions from './useSRLFilterOptions';

export type TSearchFormInput = TFilterOptionName | TSearchControlInput;

export type TFiltersInputValues = {
  [key in TSearchFormInput]: string;
};

export const CurrentSearchContext =
  React.createContext<TFiltersInputValues | null>(null);

type TPossibleInputValues =
  | string
  | number
  | string[]
  | IFlightOptionsState
  | ITravelRoomsState
  | ITravelDatesState
  | ITravelDestinationState
  | RadiusFilter;

export function calcFormFieldInputValue(
  field: TSearchFormInput,
  value: TPossibleInputValues,
  t?: TFunction,
  fieldOptions?: IFilterOption[]
): string | null {
  switch (field) {
    case 'departureAirports':
    case 'boardTypes':
    case 'roomTypes':
    case 'productFeatures': {
      return fieldOptions
        ?.map(({ id, caption }) => {
          if ((value as string[]).includes(id)) return caption;
        })
        .filter(Boolean)
        .join(', ');
    }
    case 'hpRating':
    case 'taRating': {
      const rating = ratingsIdsToNumberDict[value as string];
      return rating ? `min., ${rating}` : null;
    }
    case 'maxPrice': {
      return value
        ? `${t('Max')}. ${priceOptions.find(o => o.value === +value)?.caption}`
        : null;
    }
    case 'flightOptions': {
      return (
        Object.entries(value as IFlightOptionsState)
          .map(([filterName, filterValue]) => {
            return fieldOptions?.[filterName].find(o => o.id === filterValue)
              ?.caption;
          })
          .filter(Boolean)
          .join(', ') || null
      );
    }
    case 'travelDestination': {
      return (
        getShortTravelDestinationInformation(
          t,
          (value as ITravelDestinationState)?.destinations || [],
          DESTINATIONS_LIMIT
        ) || null
      );
    }
    case 'travelRooms': {
      return (
        getShortTravelRoomsInformation(t, value as ITravelRoomsState) || null
      );
    }
    case 'travelDates': {
      const {
        departureDate,
        returnDate,
        duration: durationData,
      } = value as ITravelDatesState;

      return (
        getShortTravelDatesInformation(t, {
          departureDate,
          returnDate,
          durationData,
          showDuration: true,
          infoFormat: `dd.MM.yy`,
        }).travelDates || null
      );
    }
    default: {
      return null;
    }
  }
}

function mapSearchStateToInputValues(
  state: ISrlState,
  options: IFilterOptions,
  t: TFunction
): TFiltersInputValues {
  return Object.entries({ ...state.searchControl, ...state.filters }).reduce(
    (result, [field, value]) => {
      const fieldOptions = options?.[field];

      const fieldValue = calcFormFieldInputValue(
        field as TSearchFormInput,
        value,
        t,
        fieldOptions
      );

      if (fieldValue) {
        result[field] = fieldValue;
      }

      return result;
    },
    {} as TFiltersInputValues
  );
}

export default function CurrentSearchContextProvider({
  children,
}: PropsWithChildren): React.ReactElement {
  const [t] = useTranslation(['search', 'filters']);
  const { state } = useSearchState<ISrlState>();
  const { options, loading: optionsLoading } = useSRLFilterOptions();

  const [filtersInputValue, setFiltersInputValue] =
    useState<TFiltersInputValues>(
      mapSearchStateToInputValues(state, options, t)
    );

  useDeepCompareEffect(() => {
    if (optionsLoading) return;
    setFiltersInputValue(mapSearchStateToInputValues(state, options, t));
  }, [state, optionsLoading]);

  return (
    <CurrentSearchContext.Provider value={filtersInputValue}>
      {children}
    </CurrentSearchContext.Provider>
  );
}

export function useCurrentSearchContext() {
  return useContext(CurrentSearchContext);
}
