import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { TravelPeriodFragmentDoc } from './TravelPeriod.generated';
import { DestinationFragmentDoc } from 'graphql/fragments/Destination.generated';
export type SearchControlFragment = {
  __typename?: 'SearchControlComponent';
  travelType: Types.TravelType;
  rooms: number;
  period: {
    __typename?: 'TravelPeriodComponent';
    type: Types.SearchPeriodType;
    exactPeriod: {
      __typename?: 'ExactTravelPeriod';
      departureDate: string;
      returnDate: string;
    };
    flexiblePeriod: {
      __typename?: 'FlexibleTravelPeriod';
      departureDate: string;
      maxDuration: number;
      minDuration: number;
      returnDate: string;
    };
  };
  destinations?: Array<{
    __typename?: 'TravelDestination';
    id: string;
    name: string;
    type: Types.TravelDestinationType;
    mythosCode?: string | null;
    location?: {
      __typename?: 'GeoLocation';
      airport?: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      } | null;
      continent?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      countryGroup?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      country?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      region?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      destination?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      resort?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
    } | null;
  }> | null;
  travellers: {
    __typename?: 'Travellers';
    adults: number;
    childrenDobs?: Array<string> | null;
  };
  travellersDistribution?: {
    __typename?: 'TravellersDistribution';
    rooms: Array<{
      __typename?: 'RoomDistribution';
      adultsAmount: number;
      childrenIdx?: Array<number> | null;
    }>;
  } | null;
};

export const SearchControlFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchControl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchControlComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TravelPeriod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'destination' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childrenDobs' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellersDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rooms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adultsAmount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childrenIdx' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TravelPeriodFragmentDoc.definitions,
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
