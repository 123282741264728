import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ContinentGroupItemFragment } from 'graphql/srl/GetSRLDestinationGroups.generated';

const ContinentTabs = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    px: [2, 4],
    pt: [1, 4],
    overflowX: ['auto', 'hidden'],
    mb: '-1px',
    flexShrink: 0,
    backgroundColor: ['#f2f2f2', 'white'],
    position: [null, 'sticky'],
    top: [null, '0'],
    zIndex: [null, 1],
  })
);

const ContinentTab = styled.div<{ active?: boolean }>(
  sx2CssThemeFn({
    backgroundColor: 'white',
    px: 2,
    py: 1,
    whiteSpace: 'nowrap',
    boxShadow: '4px 4px 5px 0 rgb(0 0 0 / 23%)',
    border: '1px solid',
    borderColor: '#dedede',
    borderRadius: '8px 8px 0 0',
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: '"Helvetica Neue",sans-serif',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  ({ active }) =>
    active &&
    sx2CssThemeFn({
      position: 'relative',
      color: 'primary',
      borderBottom: '1px solid #fff',
    })
);

interface IContinentGroupTabsProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  continentGroups: ContinentGroupItemFragment[];
  checkedDestinations?: React.ReactNode;
  actionButtons?: React.ReactNode;
}

export default function ContinentGroupTabs({
  activeTab,
  setActiveTab,
  continentGroups,
  checkedDestinations,
  actionButtons,
  children,
}: PropsWithChildren<IContinentGroupTabsProps>): React.ReactElement {
  return (
    <>
      {actionButtons}
      {checkedDestinations}
      <ContinentTabs>
        {continentGroups.map((continentGroup, index) => {
          return (
            <ContinentTab
              key={`${continentGroup.name}-${index}`}
              active={activeTab === continentGroup.name}
              onClick={() => setActiveTab(continentGroup.name)}
            >
              {continentGroup.name}
            </ContinentTab>
          );
        })}
      </ContinentTabs>
      {children}
      {checkedDestinations}
      {actionButtons}
    </>
  );
}
