import noop from 'lodash/noop';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DeviceType,
  PageLanguage,
  WishlistProductItem,
} from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { useSearchState } from '@hotelplan/libs.search-state';
import { ISrlState } from 'components/domain/srl/SRL.types';
import { useActiveWishlistId } from 'components/domain/wishlist/hooks';
import {
  COMPARE_ITEMS_COUNT_DESKTOP,
  COMPARE_ITEMS_COUNT_MOBILE,
  COMPARE_ITEMS_MAX_COUNT,
  mapSearchControlToWishlistCriteria,
  mapWishlistFilterValuesToWishlistFiltersCriteriaInput,
  SelectedProductItems,
} from 'components/domain/wishlist/utils';
import { useGetActiveWishlistQuery } from 'graphql/wishlist/queries/GetActiveWishlist.generated';

export interface IWishlistSelectedItemsContext {
  itemsLoading: boolean;
  items?: WishlistProductItem[] | null;
  selectedItems: SelectedProductItems;
  updateItem: (newItem: WishlistProductItem | null, index?: number) => void;
  saveAll: (newItems: SelectedProductItems) => void;
}

const WishlistSelectedItemsContext =
  createContext<IWishlistSelectedItemsContext>({
    items: [],
    selectedItems: [],
    itemsLoading: false,
    updateItem: noop,
    saveAll: noop,
  });

export const WishlistSelectedItemsProvider: React.FC = ({ children }) => {
  const id = useActiveWishlistId();
  const { mobile } = useDeviceType();
  const context = useRequestContext<DeviceType, PageLanguage>();
  const { query } = useRouter();

  const hasSearchQueryParam = Boolean('s' in query && query['s']);

  const [activeWishlistId, setActiveWishlistId] = useState<string | null>(id);

  const [items, setItems] = useState<WishlistProductItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<
    (WishlistProductItem | null)[]
  >(Array.from({ length: COMPARE_ITEMS_MAX_COUNT }).map(() => null));

  const { state } = useSearchState<ISrlState>();

  const queryVariables =
    hasSearchQueryParam && !!state
      ? {
          context,
          searchCriteria: {
            searchControl: mapSearchControlToWishlistCriteria(
              state?.searchControl
            ),
            filters: mapWishlistFilterValuesToWishlistFiltersCriteriaInput(
              state?.filters
            ),
          },
        }
      : { context };

  const { loading: itemsLoading } = useGetActiveWishlistQuery({
    ssr: false,
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    onCompleted: activeWishlist => {
      // NOTE: If the item is not selected, then just select first COMPARE_ITEMS_COUNT_XXX from the wishlist.
      const firstSelectedItems = activeWishlist.wishlist.active.items.slice(
        0,
        mobile ? COMPARE_ITEMS_COUNT_MOBILE : COMPARE_ITEMS_COUNT_DESKTOP
      );

      setSelectedItems(
        selectedItems.map((item, itemIndex) => {
          if (!item) return firstSelectedItems[itemIndex];

          const updatedItem = activeWishlist.wishlist.active.items.find(
            it => it.product.id === item.product.id
          );

          return updatedItem
            ? { ...updatedItem }
            : firstSelectedItems[itemIndex];
        }) as SelectedProductItems
      );

      setItems(activeWishlist.wishlist.active.items as WishlistProductItem[]);
    },
  });

  useEffect(() => {
    if (itemsLoading || !id || id === activeWishlistId) return;

    setActiveWishlistId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, itemsLoading]);

  const updateItem = (
    newItem: WishlistProductItem | null,
    index?: number
  ): void => {
    setSelectedItems(
      selectedItems.map((item, itemIndex) => {
        if (typeof index !== 'undefined') {
          return itemIndex === index ? newItem : item;
        }

        return item?.product.id === newItem?.product.id && newItem
          ? newItem
          : item;
      })
    );
    setItems(
      items.map(item =>
        item?.product.id === newItem?.product.id && newItem ? newItem : item
      )
    );
  };

  const saveAll = (newItems: (WishlistProductItem | null)[]): void => {
    setSelectedItems(newItems);
  };

  return (
    <WishlistSelectedItemsContext.Provider
      value={{
        items,
        selectedItems,
        itemsLoading,
        updateItem,
        saveAll,
      }}
    >
      {children}
    </WishlistSelectedItemsContext.Provider>
  );
};

export function useWishlistSelectedItemsContext() {
  return useContext(WishlistSelectedItemsContext);
}
