import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
export type RadiusFilterFragment = {
  __typename?: 'RadiusFilterComponent';
  label?: string | null;
  radius: number;
  center: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
};

export const RadiusFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'radiusFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RadiusFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'radius' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'center' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
