import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import {
  ProductType,
  TravelType,
  WishlistProductItemType,
} from '@hotelplan/graphql.types';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';
import SrlProductItem, {
  ISrlProductItemProps,
} from 'components/domain/srl/SrlProductItem';
import { WishlistItemPriceChangesDisrupter } from 'components/domain/wishlist/components';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import {
  useRemoveFromActiveWishlist,
  useSRLRoute,
} from 'components/domain/wishlist/hooks';
import { WishlistItemRemoveIconContainer } from 'components/domain/wishlist/ui';
import { WishlistListContentWrapper } from './WishlistListContent.styled';

export default function WishlistListContent(): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const searchRouterProps = useSRLRoute();

  const { items } = useWishlistSelectedItemsContext();
  const { removeFromActiveWishlist } = useRemoveFromActiveWishlist();

  async function handleRemoveFromWishlist(offerId: string) {
    await removeFromActiveWishlist(offerId);
  }

  return (
    <WishlistListContentWrapper>
      {items.map(wishlistItem => {
        const { product, link, type, offer } = wishlistItem;

        const srlItemProps: ISrlProductItemProps = {
          link,
          featureSummary: product.featureSummary,
          name: product.name,
          duration: offer.duration,
          geoBreadcrumbs: product.breadcrumbs,
          taRating: product.taRating,
          taReviews: product.taReviews,
          hpRating: product.hpRating,
          image: product.image,
          price: offer.pricePerPerson,
          totalPrice: offer.totalPrice,
          transferPrice: offer.transferPrice,
          transferIncluded: offer.transferIncluded,
          productClass: product.productClass,
          mealType: offer.rooms[0].boardDescription,
          type:
            offer.travelType === TravelType.Package
              ? ProductType.Package
              : ProductType.Hotel,
          detailsButtonText: 'wishlist:button.hotel.showDetails',
          fromTo: product.fromTo,
          travelType: product.travelType,
        };

        return (
          <React.Fragment key={product.id}>
            {(type === WishlistProductItemType.PriceChanged ||
              type === WishlistProductItemType.FlightAndPriceChanged) && (
              <WishlistItemPriceChangesDisrupter wishlistItem={wishlistItem} />
            )}
            <WishlistItemRemoveIconContainer
              onRemove={() => handleRemoveFromWishlist(offer.id)}
            >
              <SrlProductItem {...srlItemProps} />
            </WishlistItemRemoveIconContainer>
          </React.Fragment>
        );
      })}
      <RouterLink {...searchRouterProps} passHref>
        <PrimaryButtonLink>{t('empty.message.link')}</PrimaryButtonLink>
      </RouterLink>
    </WishlistListContentWrapper>
  );
}
