import { useTranslation } from 'next-i18next';
import { ReactElement, useRef } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { Tooltip } from '@hotelplan/components.common.tooltip';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FeedbackButtonWrapper } from 'components/domain/feedback-box/styles';
import { SiteToolTooltipWrapper } from 'components/domain/sitetools';
import { useHover } from 'libs';

interface IFeedbackButtonProps {
  onClick?(): void;
}

export default function FeedbackSiteTool({
  onClick,
}: IFeedbackButtonProps): ReactElement {
  const [t] = useTranslation('common');

  const [showTooltip, show, hide] = useToggleState(false);
  const feedbackButtonRef = useRef<HTMLDivElement>(null);

  useHover(feedbackButtonRef, show, hide);

  return (
    <FeedbackButtonWrapper onClick={onClick} ref={feedbackButtonRef}>
      <Icon className="feedback-icon" name="message-with-star" />
      {showTooltip ? (
        <SiteToolTooltipWrapper>
          <Tooltip className="arrow-top">
            {t('common:feedback.tooltip')}
          </Tooltip>
        </SiteToolTooltipWrapper>
      ) : null}
    </FeedbackButtonWrapper>
  );
}
