import { useTranslation } from 'next-i18next';
import React from 'react';
import { WishlistMap } from 'components/domain/wishlist/components';
import {
  WishlistMapContentWrapper,
  WishlistMapTextBlock,
} from './WishlistMap.styled';

export default function WishlistMapContent(): React.ReactElement {
  const [t] = useTranslation('wishlist');

  return (
    <WishlistMapContentWrapper>
      <WishlistMapTextBlock>
        <h4 className={'title'}>{t('wishlist:map.title')}</h4>
        <p className={'description'}>{t('wishlist:map.description')}</p>
      </WishlistMapTextBlock>
      <WishlistMap />
    </WishlistMapContentWrapper>
  );
}
