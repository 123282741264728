import { PageType } from '@hotelplan/graphql.types';
import { MY_ACCOUNT_PAGES } from './MyAccount.constants';

export const logoutRedirect = (pageType: PageType) => {
  if (MY_ACCOUNT_PAGES.includes(pageType)) {
    window.location.pathname = '/';
    return;
  }
  window.location.reload();
};
