import { useMemo } from 'react';
import { ProductFeatureGroup } from '@hotelplan/graphql.types';

const useProductFeatures = (featureSummary: ProductFeatureGroup[]) => {
  const features = useMemo(
    () => featureSummary.filter(feature => feature.id !== 'bestseller'),
    [featureSummary]
  );
  const isBestseller = useMemo(
    () => featureSummary.some(feature => feature.id === 'bestseller'),
    [featureSummary]
  );

  return { features, isBestseller };
};

export default useProductFeatures;
