import {
  mapSearchControlTravelPeriodToFormTravelDates,
  mapTravelDatesModelToTravelPeriodCriteria,
  mergeTravelDatesState,
} from '@hotelplan/components.common.travel-dates';
import {
  mapFormTravelDestinationToSearchCriteriaInputTravelDestination,
  mapSearchControlTravelDestinationsToFormTravelDestination,
} from '@hotelplan/components.common.travel-destination';
import {
  mapFormTravelRoomsToSearchCriteriaInputRooms,
  mapFormTravelRoomsToSearchCriteriaInputTravellers,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import { TravelType, SearchControlComponent } from '@hotelplan/graphql.types';
import { getSearchStateFromProps } from '@hotelplan/libs.search-state';
import { ISRLFormState, ISrlState } from 'components/domain/srl/SRL.types';
import {
  ISearchControlState,
  ISearchCriteriaInput,
} from './SearchControl.types';

export const getSearchControlValuesToFormStateMapper =
  <TControl, TFormState>(
    get: (from: Omit<TControl, '__typename'>) => TFormState | null
  ) =>
  (obj: Omit<TControl, '__typename'>) => {
    return get(obj);
  };

export function mapSearchControlValuesToFormState(
  searchControl: SearchControlComponent
): ISearchControlState {
  return {
    travelType: searchControl.travelType,
    travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
      searchControl.travellers,
      searchControl.rooms,
      searchControl.travellersDistribution
    ),
    travelDates: mapSearchControlTravelPeriodToFormTravelDates(
      searchControl.period
    ),
    travelDestination:
      mapSearchControlTravelDestinationsToFormTravelDestination(
        searchControl.destinations
      ),
  };
}

export function mapSearchControlToCriteria<
  S extends ISearchControlState | undefined | null
>(formState: S): ISearchCriteriaInput {
  return {
    travellers: mapFormTravelRoomsToSearchCriteriaInputTravellers(
      formState?.travelRooms
    ),
    rooms: mapFormTravelRoomsToSearchCriteriaInputRooms(formState?.travelRooms),
    travelPeriod: mapTravelDatesModelToTravelPeriodCriteria(
      formState?.travelDates
    ),
    travelType: formState?.travelType,
    travelDestinations:
      mapFormTravelDestinationToSearchCriteriaInputTravelDestination(
        formState?.travelDestination
      ),
    travellersDistribution: formState?.travelRooms?.travellersDistribution,
  };
}

export function mergeSearchControlState(
  searchControlState?: ISearchControlState | null,
  globalSearchState?: ISearchControlState | null
): ISearchControlState | null {
  if (!searchControlState) return null;

  return {
    ...searchControlState,
    ...globalSearchState,
    travelDates: mergeTravelDatesState(
      searchControlState?.travelDates,
      globalSearchState?.travelDates
    ),
  };
}

export function mapSearchStatePayloadToCriteria<
  S extends { searchControl: ISearchControlState }
>(searchStatePayload): ISearchCriteriaInput {
  const searchState = getSearchStateFromProps(searchStatePayload?.props)
    .state as S;
  return mapSearchControlToCriteria(searchState.searchControl);
}

export function mapSearchControlFormValuesToSearchState(
  formValues: ISRLFormState
): ISrlState {
  const {
    travelRooms,
    travelType,
    travelDestination,
    travelDates,
    ...filterValues
  } = formValues;

  const finalFilters =
    travelType === TravelType.Hotel
      ? {
          ...filterValues,
          departureAirports: [],
          flightOptions: {},
        }
      : { ...filterValues };

  return {
    searchControl: {
      travelType,
      travelRooms,
      travelDates,
      travelDestination,
    },
    filters: finalFilters,
  };
}
