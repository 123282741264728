import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { IAutocompleteContext } from '@hotelplan/components.common.autocomplete';
import { Button } from '@hotelplan/components.common.buttons';
import { useField } from '@hotelplan/components.common.forms';
import {
  ICanonicalTravelDestination,
  ITravelDestinationState,
  mapTravelDestinationObjectToCanonical,
} from '@hotelplan/components.common.travel-destination';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import ActionButtons from 'components/domain/search-control/components/SearchControlFormLayout/MobileSearchControlActionButtons';
import { ContinentGroupItemFragment } from 'graphql/srl/GetSRLDestinationGroups.generated';
import CheckedDestinations from './CheckedDestinations';
import ContinentGroupTabs from './ContinentGroupTabs';
import CountryGroupCheckboxesArea from './CountryGroupCheckboxesArea';
import { TDestination } from './TravelDestination.types';

const DEFAULT_CONTINENT_ID = 'k-45632'; // Continent Europe id

const ApplyButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
    size: 'xs',
  },
})(({ theme }) =>
  sx2CssThemeFn({
    ...theme.buttons.btnWithIcon,
  })
);

interface IGeoDestinationsContentProps {
  continentGroups: ContinentGroupItemFragment[];
  closeOverlay?: () => void;
  autocompleteContextRef?: MutableRefObject<
    IAutocompleteContext<ICanonicalTravelDestination>
  >;
  onDestinationChange?: (value: boolean) => void;
}

export default function GeoDestinationsContent({
  continentGroups,
  closeOverlay,
  autocompleteContextRef,
  onDestinationChange,
}: IGeoDestinationsContentProps): React.ReactElement {
  const { mobile } = useDeviceType();

  const [formValue, onChange] = useField<ITravelDestinationState | null>(
    'travelDestination'
  );

  const defaultContinentTab =
    continentGroups.find(({ id }) => id === DEFAULT_CONTINENT_ID)?.name ||
    continentGroups[0].name;

  const [activeTab, setActiveTab] = React.useState<string>(defaultContinentTab);

  const [checkedDestinations, setCheckedDestinations] = React.useState<
    TDestination[]
  >([
    ...formValue.destinations?.map(d => ({
      id: d.id,
      type: d.type,
      name: d.name,
    })),
  ]);

  function handleApply() {
    const destinations = checkedDestinations.map(destination =>
      mapTravelDestinationObjectToCanonical(destination)
    );
    onDestinationChange?.(true);

    onChange({ destinations });
    autocompleteContextRef?.current?.setSelected(destinations, true);
    closeOverlay?.();
  }

  function resetForm() {
    onChange({
      destinations: [],
    });
    closeOverlay?.();
  }

  function handleCheckedDestinationsChange(
    nextValue: string,
    currentCountry: TDestination,
    currentCities: TDestination[]
  ): void {
    // If the destination is already checked, remove it from the list
    // If the country is checked, remove all current cities from the list

    if (checkedDestinations.some(destination => destination.id === nextValue)) {
      setCheckedDestinations(prevDestinations =>
        prevDestinations.filter(destination => destination.id !== nextValue)
      );
      return;
    }

    if (nextValue === currentCountry.id) {
      setCheckedDestinations(prevDestinations => {
        return [
          ...prevDestinations.filter(
            destination =>
              !currentCities.some(city => city.id === destination.id)
          ),
          currentCountry,
        ];
      });
      return;
    }

    setCheckedDestinations(prevDestinations => {
      return [
        ...prevDestinations.filter(d => d.id !== currentCountry.id),
        currentCities.find(city => city.id === nextValue),
      ];
    });
  }

  return (
    <ContinentGroupTabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      continentGroups={continentGroups}
      actionButtons={
        mobile ? (
          <ActionButtons onApply={handleApply} onReset={resetForm} />
        ) : null
      }
      checkedDestinations={
        mobile ? (
          <CheckedDestinations
            checked={checkedDestinations}
            unCheckDestination={destination => {
              setCheckedDestinations(
                checkedDestinations.filter(
                  checkedDestination => checkedDestination.id !== destination.id
                )
              );
            }}
          />
        ) : null
      }
    >
      <CountryGroupCheckboxesArea
        activeCountryGroupItems={
          continentGroups.find(
            continentGroup => continentGroup.name === activeTab
          )?.countryGroupItems
        }
        checkedDestinations={checkedDestinations}
        checkedDestinationsComponent={
          !mobile ? (
            <CheckedDestinations
              checked={checkedDestinations}
              applyButton={
                <ApplyButton className={'apply-button'} onClick={handleApply}>
                  <span>{'OK'}</span>
                </ApplyButton>
              }
              unCheckDestination={destination => {
                setCheckedDestinations(
                  checkedDestinations.filter(
                    checkedDestination =>
                      checkedDestination.id !== destination.id
                  )
                );
              }}
            />
          ) : null
        }
        onChangeDestinations={handleCheckedDestinationsChange}
      />
    </ContinentGroupTabs>
  );
}
