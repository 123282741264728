import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { Icon } from '@hotelplan/components.common.icon';
import { Rating } from '@hotelplan/components.common.rating';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { FilterRatingIcons, ISearchFilterGroupProps } from './Filters.types';
import { getMaxRating } from './Filters.utils';

const FilterCheckbox = styled(Checkbox)(
  ({ checked, disabled, theme: { colors } }) =>
    sx2CssThemeFn({
      borderColor: checked && `${colors.primary}!important`,
      ':hover input ~ .customCheckboxInput': {
        backgroundColor: ['white', 'lightGreySecond'],
      },
      ...(disabled
        ? {
            pointerEvents: 'none',
            border: ['none', `1px solid ${colors.borderGray}`],
            backgroundColor: [null, colors.lightGreySixth],
            opacity: 0.4,
            '.filter-count': {
              backgroundColor: colors.backgroundGray,
            },
            '.customCheckboxInput': {
              backgroundColor: colors.backgroundGray,
            },
          }
        : {}),
    })
);

const CheckboxesAreaWrapper = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: ['5px', '10px'],
    my: [3, 0],
    '.label': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      p: ['8px 5px', '5px'],
      mb: '0px',
      fontWeight: [null, 'bold'],
      border: [null, `1px solid ${colors.borderGray}`],
      borderRadius: [null, 'default'],
      fontSize: '14px',
      color: ['textGray', 'black'],
      '&:hover': {
        background: ['white', colors.hoverGraySecond],
        cursor: 'pointer',
        '.checkbox-text': {
          textDecoration: ['none', 'underline'],
        },
      },
      '&:has(input:checked)': {
        background: ['backgroundGray', 'transparent'],
        color: ['primary', 'black'],
        fontWeight: 'bold',
      },
      '.rating, .remainRating, .halfWayRating': {
        ml: ['2px', 1],
        '.icon': {
          width: '17px',
          height: '17px',
          color: 'secondary',
        },
      },
    },
  })
);

export default function SearchFilterCheckboxesGroup({
  options,
  checkedOptions,
  onChange,
  className,
  getCounts,
  checkIfDisabled,
}: ISearchFilterGroupProps): React.ReactElement {
  const maxRating = getMaxRating(options);

  return (
    <CheckboxesAreaWrapper
      className={`checkboxes-area-wrapper ${className ? className : null}`}
    >
      {options.map((option, index) => {
        const checked = checkedOptions?.includes(option.id);
        return (
          <FilterCheckbox
            key={`${option.id}-${index}`}
            value={option.id}
            checked={checked}
            onChange={onChange}
            disabled={checkIfDisabled?.(option.id)}
            content={
              <CheckboxContent
                caption={option.caption}
                maxRating={maxRating}
                rating={option.rating}
                ratingIcons={option.ratingIcons}
                counts={getCounts?.(option.id)}
              />
            }
          />
        );
      })}
    </CheckboxesAreaWrapper>
  );
}

const IconWrapper = styled(Icon)({});
const CheckboxContentWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: 1,
  })
);

interface ICheckboxContentProps {
  caption: string;
  maxRating?: number;
  rating?: number;
  ratingIcons?: FilterRatingIcons;
  counts?: React.ReactNode;
}

export function CheckboxContent({
  caption,
  maxRating,
  rating,
  ratingIcons,
  counts,
}: ICheckboxContentProps): React.ReactElement {
  return (
    <CheckboxContentWrapper>
      <div className="checkbox-text">{caption}</div>
      {rating ? (
        <Rating
          maxRating={maxRating}
          rating={rating}
          ratingContent={<IconWrapper name={ratingIcons.coloredIcon.name} />}
          remainRatingContent={
            <IconWrapper name={ratingIcons.emptyIcon.name} />
          }
        />
      ) : null}
      {counts ? <>{counts}</> : null}
    </CheckboxContentWrapper>
  );
}
