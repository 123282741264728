import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatDate } from '@hotelplan/libs.utils';
import { IFlightSectionProps } from './FlightItem';

export const FlightShortInfoWrapper = styled.div(
  sx2CssThemeFn({
    pb: 2,
    '&:last-child': {
      mb: 3,
    },
  })
);

export default function FlightShortInfo(
  props: IFlightSectionProps
): React.ReactElement {
  const {
    departureAirportPlaceholder,
    departureAirport,
    airlineLogo,
    departureDate,
    departureTime,
    arrivalTime,
    className,
  } = props;

  const [, { language }] = useTranslation();
  const { mobile } = useDeviceType();

  return (
    <FlightShortInfoWrapper className={className}>
      <div>{`${departureAirportPlaceholder} (${departureAirport})`}</div>
      <Image src={airlineLogo} resized={[]} className="airport-logo" />
      <div className="flight-time">
        {formatDate(
          departureDate,
          mobile ? 'dd.MM.yyyy' : 'EE dd.MM.yyyy',
          language
        )}
        , {`${departureTime} - ${arrivalTime}`}
      </div>
    </FlightShortInfoWrapper>
  );
}
