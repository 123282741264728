import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAgencyContactQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetAgencyContactQuery = {
  __typename?: 'Query';
  agencyContact: {
    __typename?: 'AgencyContact';
    openingHours: Array<{
      __typename?: 'OpeningHours';
      open: boolean;
      shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
    }>;
  };
};

export const GetAgencyContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'from' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'to' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAgencyContactQuery__
 *
 * To run a query within a React component, call `useGetAgencyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyContactQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetAgencyContactQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export function useGetAgencyContactLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export type GetAgencyContactQueryHookResult = ReturnType<
  typeof useGetAgencyContactQuery
>;
export type GetAgencyContactLazyQueryHookResult = ReturnType<
  typeof useGetAgencyContactLazyQuery
>;
export type GetAgencyContactQueryResult = Apollo.QueryResult<
  GetAgencyContactQuery,
  GetAgencyContactQueryVariables
>;
