import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const PrimaryButtonLink = styled(Link).attrs({
  icon: { name: 'chevron-long-right' },
  className: 'primary-button-link',
})(
  ({ theme: { buttons } }) => buttons.primary,
  ({ theme: { buttons } }) => buttons.btnWithIcon,
  sx2CssThemeFn({
    p: ['9px 6px', '9px 10px'],
    pl: ['10px', 3],
    textTransform: 'uppercase',
  })
);

export default PrimaryButtonLink;
