import { useMemo } from 'react';
import { ITransferInfoProps } from '@hotelplan/components.common.transfer/TransferInfo';

const useShowTransferInfo = (transferParams: ITransferInfoProps) => {
  return useMemo(
    () => !!transferParams?.price || transferParams?.transferIncluded,
    [transferParams?.price, transferParams?.transferIncluded]
  );
};

export default useShowTransferInfo;
