import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
export type SrlMapEmptyResultFragment = {
  __typename?: 'SrlEmptyResult';
  status: Types.Status;
  message?: string | null;
  altTravelType: Types.TravelType;
  alternativeSearchUrl?: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  } | null;
};

export const SrlMapEmptyResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapEmptyResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlEmptyResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTravelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternativeSearchUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
