import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const PrimaryButton = styled(Button).attrs({
  icon: { name: 'chevron-long-right' },
  className: 'primary-button',
})(
  ({ theme: { buttons } }) => buttons.primary,
  ({ theme: { buttons } }) => buttons.btnWithIcon,
  sx2CssThemeFn({
    p: ['4px 6px', '9px 10px'],
    pl: ['10px', 3],
    textTransform: 'uppercase',
  })
);

export default PrimaryButton;
