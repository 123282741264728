import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterValueFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterValue.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { RadiobuttonGroupFilterValueFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterValue.generated';
export type WishlistFilterValuesFragment = {
  __typename?: 'FilterComponent';
  departureAirports: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  roomTypes: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  boardTypes: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  stopOvers: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
};

export const WishlistFilterValuesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistFilterValues' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterValueFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...RadiobuttonGroupFilterValueFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
