import {
  mapSearchControlTravelPeriodToFormTravelDates,
  mapTravelDatesModelToTravelPeriodCriteria,
} from '@hotelplan/components.common.travel-dates';
import {
  mapFormTravelDestinationToSearchCriteriaInputTravelDestination,
  mapSearchControlTravelDestinationsToFormTravelDestination,
} from '@hotelplan/components.common.travel-destination';
import {
  ITravelRoomsState,
  mapFormTravelRoomsToSearchCriteriaInputRooms,
  mapFormTravelRoomsToSearchCriteriaInputTravellers,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import {
  Flight,
  PdpSearchControlCriteriaInput,
} from '@hotelplan/graphql.types';
import { IRunSearchData } from '@hotelplan/libs.tracking';
import {
  ORLOfferMetaData,
  ORLResultsEventMetaData,
} from '@hotelplan/libs.tracking/trackingEvents/trackORLResults';
import { parseDateStr } from '@hotelplan/libs.utils';
import { getSearchControlValuesToFormStateMapper } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import {
  mapFiltersStateToTrackableData,
  mapSearchControlFormStateToTrackableData,
} from 'components/domain/srl/tracking/SRLTracking.mappers';
import { CheckoutOrlItemFragment } from 'graphql/orl/CheckoutORLItem.generated';
import { GetOrlSingleOffersQuery } from 'graphql/orl/GetORLSingleOffers.generated';
import { SearchControlFragment } from 'graphql/searchControl/SearchControl.generated';
import { IFlightSectionProps } from './offerItem/FlightItem';
import { IOrlState, TRoom } from './Orl.types';

export const mapORLSearchControlComponentToORLControlsFormState =
  getSearchControlValuesToFormStateMapper<
    SearchControlFragment | undefined,
    ISearchControlState
  >((searchControl: SearchControlFragment | undefined) => {
    if (!searchControl) return null;

    return {
      travelType: searchControl.travelType,
      travelDates: mapSearchControlTravelPeriodToFormTravelDates(
        searchControl.period
      ),
      travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
        searchControl.travellers,
        searchControl.rooms,
        searchControl.travellersDistribution
      ),
      travelDestination:
        mapSearchControlTravelDestinationsToFormTravelDestination(
          searchControl.destinations
        ),
    };
  });

export const mapSearchControlStateToPdpSearchCriteriaInput = (
  formState: ISearchControlState | null | undefined
): PdpSearchControlCriteriaInput => {
  return {
    travellersCriteria: mapFormTravelRoomsToSearchCriteriaInputTravellers(
      formState?.travelRooms
    ),
    rooms: mapFormTravelRoomsToSearchCriteriaInputRooms(formState?.travelRooms),
    travelPeriodCriteria: mapTravelDatesModelToTravelPeriodCriteria(
      formState?.travelDates
    ),
    travelType: formState?.travelType,
    travelDestinations:
      mapFormTravelDestinationToSearchCriteriaInputTravelDestination(
        formState?.travelDestination
      ),
    travellersDistribution: formState?.travelRooms?.travellersDistribution,
  };
};

export function mapSearchControlRoomsToOfferRooms(
  travelRooms: ITravelRoomsState
): TRoom {
  return {
    adults: travelRooms?.adults || 0,
    children: travelRooms?.childrenDobs.length || 0,
  };
}

export const mapOrlFlightToIFlightSectionProps = (
  flight: Flight
): IFlightSectionProps => {
  const stops = flight?.segments ? flight?.segments : null;
  const detailedInfo = flight?.segments?.[0];
  const airlineLogoUrl = `https://assets.hotelplan.com/content/airline-logos/${detailedInfo?.airline.iata}_2x.png`;

  return {
    segments: flight?.segments,
    departureAirport: flight.departure.iata || '',
    departureAirportPlaceholder: flight.departure.name || '',
    departureTime: flight.departureTime,
    arrivalTime: flight.arrivalTime,
    flightDuration: flight.duration,
    stopLocations: stops.map(stop => stop.departure.iata || ''),
    arrivalAirport: flight.arrival.iata || '',
    arrivalAirportPlaceholder: flight.arrival.name || '',
    departureDate: parseDateStr(flight.departureDate),
    airlineLogo: airlineLogoUrl,
  };
};

export const mapSingleOrlItemToTrackableOfferMetaData = (
  offer: CheckoutOrlItemFragment,
  productId?: string
): ORLOfferMetaData => {
  return {
    productId,
    id: offer.id,
    price: offer.pricePerPerson.amount,
    duration: offer.duration,
    transferIncluded: offer.transferIncluded,
    departureDate: offer.departureDate,
    returnDate: offer.returnDate,
    rooms: offer.rooms.length,
    adults: offer.rooms.reduce((acc, room) => {
      return acc + room.adults;
    }, 0),
    children: offer.rooms.reduce((acc, room) => {
      return acc + room.children;
    }, 0),
  };
};

export const mapORLSingleResultsToTrackableResultsEventMetaData = (
  data: GetOrlSingleOffersQuery,
  queryTime = 0
): ORLResultsEventMetaData => {
  const type = 'offers';
  const success = true;
  const perfomance = { time: queryTime };
  const results = data.orl.searchResult.single.page.resultsTotal || 0;

  return {
    type,
    success,
    perfomance,
    results,
    page: data.orl.searchResult.single.page.pageNumber,
    products: data.orl.searchResult.single.offers.map(offer =>
      mapSingleOrlItemToTrackableOfferMetaData(offer)
    ),
  };
};

export const mapORLStateToTrackableData = (
  state: IOrlState
): IRunSearchData => {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFiltersStateToTrackableData(state.filters),
  };
};
