import { refetchQueries } from 'components/domain/wishlist/utils';
import { useRemoveWishlistMutation } from 'graphql/wishlist/mutations/RemoveWishlist.generated';

export default function useRemoveWishlist() {
  const [mutation, { loading }] = useRemoveWishlistMutation({
    refetchQueries,
  });

  async function removeWishlist(id: string) {
    await mutation({
      variables: {
        id,
      },
    });
  }

  return {
    removeWishlist,
    loading,
  };
}
