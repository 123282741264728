import { DocumentNode } from 'graphql';
import {
  IDestinationAutocompleteVariables,
  IDestinationData,
} from '@hotelplan/components.common.travel-destination';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useDefaultSearchStateByPageType } from 'components/domain/srl/useDefaultSearchState';
import { GetGeoDestinationAutocompleteDocument } from 'graphql/geo/GetGeoDestinationAutocomplete.generated';
import { GetPdpDestinationAutocompleteDocument } from 'graphql/pdp/GetPDPDestinationAutocomplete.generated';
import { GetSrlDestinationAutocompleteDocument } from 'graphql/srl/GetSrlDestinationAutocomplete.generated';
import { GetThemeDestinationAutocompleteDocument } from 'graphql/theme/GetThemeDestinationAutocomplete.generated';

type TAutocompleteQueryHandler = {
  queryDocument: DocumentNode;
  dataMapper(data: any): IDestinationData;
  variablesMapper(variables: IDestinationAutocompleteVariables): any;
};

function getAutocompleteQueryHandlersByPageType(
  id?: string
): Partial<Record<PageType, TAutocompleteQueryHandler>> {
  return {
    [PageType.Srl]: {
      queryDocument: GetSrlDestinationAutocompleteDocument,
      dataMapper: data =>
        data
          ? {
              page: data.srl.autocomplete.page,
              destinations: data.srl.autocomplete.destinations,
            }
          : undefined,
      variablesMapper: variables => ({
        ...variables,
      }),
    },
    [PageType.GeoSrl]: {
      queryDocument: GetGeoDestinationAutocompleteDocument,
      dataMapper: data =>
        data
          ? {
              page: data.geo.autocomplete.page,
              destinations: data.geo.autocomplete.destinations,
            }
          : undefined,
      variablesMapper: variables => ({
        ...variables,
        id,
      }),
    },
    [PageType.ThemeSrl]: {
      queryDocument: GetThemeDestinationAutocompleteDocument,
      dataMapper: data =>
        data
          ? {
              page: data.theme.autocomplete.page,
              destinations: data.theme.autocomplete.destinations,
            }
          : undefined,
      variablesMapper: variables => ({
        ...variables,
        id,
      }),
    },
    [PageType.Orl]: {
      queryDocument: GetPdpDestinationAutocompleteDocument,
      dataMapper: data =>
        data
          ? {
              page: data.pdp.autocomplete.page,
              destinations: data.pdp.autocomplete.destinations,
            }
          : undefined,
      variablesMapper: variables => ({
        ...variables,
        id,
      }),
    },
  };
}

export default function useSearchControlPageConfig() {
  const pageType = usePageType<PageType>();
  const { id } = useObjectId();

  const { onReset } = useDefaultSearchStateByPageType(pageType)(id || null);

  const autocompleteQueryHandlers =
    getAutocompleteQueryHandlersByPageType(id)[pageType];

  return {
    autocompleteQueryHandlers,
    onReset,
  };
}
