import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import {
  WishlistOfferFragmentDoc,
  WishlistCheckoutOfferFragmentDoc,
} from 'graphql/wishlist/fragments/WishlistOfferFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddToActiveWishlistWithSourceMutationVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  offerId: Types.WishlistOfferIdInput;
}>;

export type AddToActiveWishlistWithSourceMutation = {
  __typename?: 'Mutation';
  addToActiveWishlistWithSource: {
    __typename?: 'WishlistAddToWishlistResponse';
    status: Types.Status;
    message?: string | null;
    offer?: {
      __typename?: 'WishlistOffer';
      id: string;
      duration: number;
      returnDate: string;
      travelType: Types.TravelType;
      departureDate: string;
      transferIncluded: boolean;
      waitingScreenImage?: string | null;
      rooms: Array<{
        __typename?: 'WishlistOfferRoom';
        adults: number;
        children: number;
        boardCode: string;
        boardDescription: string;
        roomCode: string;
        roomDescription: string;
      }>;
      inboundFlight?: {
        __typename?: 'Flight';
        departureTime: any;
        arrivalTime: any;
        duration: string;
        arrivalDate: any;
        departureDate: any;
        overnight: boolean;
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        segments: Array<{
          __typename?: 'FlightSegment';
          airportWaiting?: string | null;
          arrivalDate: any;
          arrivalTime: any;
          departureDate: any;
          departureTime: any;
          flightClass: Types.FlightClass;
          flightNumber: string;
          segmentDuration: string;
          airline: {
            __typename?: 'Airline';
            airlineLogo: string;
            iata: string;
            name: string;
          };
          arrival: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
        }>;
      } | null;
      outboundFlight?: {
        __typename?: 'Flight';
        departureTime: any;
        arrivalTime: any;
        duration: string;
        arrivalDate: any;
        departureDate: any;
        overnight: boolean;
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        segments: Array<{
          __typename?: 'FlightSegment';
          airportWaiting?: string | null;
          arrivalDate: any;
          arrivalTime: any;
          departureDate: any;
          departureTime: any;
          flightClass: Types.FlightClass;
          flightNumber: string;
          segmentDuration: string;
          airline: {
            __typename?: 'Airline';
            airlineLogo: string;
            iata: string;
            name: string;
          };
          arrival: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
          departure: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          };
        }>;
      } | null;
      totalPrice: { __typename?: 'Price'; amount: string; currency: string };
      pricePerPerson: {
        __typename?: 'Price';
        amount: string;
        currency: string;
      };
      wishlistItem?: {
        __typename?: 'WishlistItem';
        id: string;
        inWishlist: boolean;
      } | null;
      transferPrice?: {
        __typename?: 'Price';
        amount: string;
        currency: string;
      } | null;
    } | null;
  };
};

export const AddToActiveWishlistWithSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddToActiveWishlistWithSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offerId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WishlistOfferIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addToActiveWishlistWithSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'wishlistOffer' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...WishlistOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export type AddToActiveWishlistWithSourceMutationFn = Apollo.MutationFunction<
  AddToActiveWishlistWithSourceMutation,
  AddToActiveWishlistWithSourceMutationVariables
>;

/**
 * __useAddToActiveWishlistWithSourceMutation__
 *
 * To run a mutation, you first call `useAddToActiveWishlistWithSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToActiveWishlistWithSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToActiveWishlistWithSourceMutation, { data, loading, error }] = useAddToActiveWishlistWithSourceMutation({
 *   variables: {
 *      context: // value for 'context'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAddToActiveWishlistWithSourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToActiveWishlistWithSourceMutation,
    AddToActiveWishlistWithSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddToActiveWishlistWithSourceMutation,
    AddToActiveWishlistWithSourceMutationVariables
  >(AddToActiveWishlistWithSourceDocument, options);
}
export type AddToActiveWishlistWithSourceMutationHookResult = ReturnType<
  typeof useAddToActiveWishlistWithSourceMutation
>;
export type AddToActiveWishlistWithSourceMutationResult =
  Apollo.MutationResult<AddToActiveWishlistWithSourceMutation>;
export type AddToActiveWishlistWithSourceMutationOptions =
  Apollo.BaseMutationOptions<
    AddToActiveWishlistWithSourceMutation,
    AddToActiveWishlistWithSourceMutationVariables
  >;
