import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import {
  IB2BAuthPanelProps,
  useAuthentication,
} from '@hotelplan/components.common.auth';
import { BurgerButton } from '@hotelplan/components.common.burger';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { MobileMenuModal } from '@hotelplan/components.common.menu';
import RouterLink from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import useB2BAuthOverlay from 'components/domain/auth/useB2BAuthOverlay';
import LanguageControl from 'components/domain/language-control/LanguageControl';
import MobileMenu from 'components/domain/menu/MobileMenu';
import LegacyWishlistHeaderButton from 'components/domain/wishlist/modules/WishlistHeaderButton/LegacyWishlistHeaderButton';
import { FeatureList } from 'config/pageConfig.constants';
import { usePreventBodyScroll } from 'libs';
import { ContactInfo } from './ContactInfo';
import {
  ACTIVE_LINK_COLOR,
  DesktopMenuWrapper,
  HeaderLayoutWrapper,
  MenuWrap,
  ToolsWrapper,
} from './Header.styled';
import HeaderActionButtonsSection from './HeaderActionButtonsSection';
import { HeaderMenuAction, useHeaderMenuReducer } from './useHeaderMenuReducer';
import { useHeaderNavigation } from './useHeaderNavigation';
import useHomePageRouterProps from './useHomePageRouterProps';

const B2BAuthPanelIdle = dynamic<IB2BAuthPanelProps>(
  () =>
    import('@hotelplan/components.common.auth').then(
      module => module.B2BAuthPanelIdle
    ),
  {
    ssr: false,
  }
);

const Header: React.FC = () => {
  const { isEnabled } = useFeatureToggle();
  const { events } = useRouter();
  const { navigationLinks, logo } = useHeaderNavigation();

  const [state, dispatch] = useHeaderMenuReducer();

  const { channelType, logout } = useAuthentication();
  const { authB2BOverlay, close } = useB2BAuthOverlay();

  const { asPath } = useRouter();
  const { mobile } = useDeviceType();
  const pageType = usePageType<PageType>();

  const homePageRouterProps = useHomePageRouterProps();

  const pathWithoutQuery = useMemo(() => asPath.split('?')[0], [asPath]);
  const linksWithActiveItem = useMemo(() => {
    return navigationLinks.map(menuLink => {
      return pathWithoutQuery === menuLink.link.as
        ? { ...menuLink, fontColor: ACTIVE_LINK_COLOR }
        : menuLink;
    });
  }, [navigationLinks, pathWithoutQuery]);

  function handleChangeRoute() {
    dispatch({ type: HeaderMenuAction.CLOSE_HEADER_MENU });
    dispatch({ type: HeaderMenuAction.CLOSE_MEINKONTO_MENU });
  }

  useEffect(function () {
    events.on('routeChangeStart', handleChangeRoute);
    return () => {
      events.off('routeChangeStart', handleChangeRoute);
    };
  }, []);

  usePreventBodyScroll(mobile && state.meinKontoMenuOpen);

  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  return (
    <HeaderLayoutWrapper logo={logo} logoRouterProps={homePageRouterProps}>
      <ToolsWrapper>
        {!mobile ? <LanguageControl /> : null}
        <B2BAuthPanelIdle
          authB2BOverlay={authB2BOverlay}
          channelType={channelType}
          close={close}
          logout={logout}
        />
      </ToolsWrapper>

      {isMeinKontoEnabled ? (
        <HeaderActionButtonsSection
          isMenuOpened={state.meinKontoMenuOpen}
          closeMenu={() =>
            dispatch({ type: HeaderMenuAction.CLOSE_MEINKONTO_MENU })
          }
          toggleMenu={() =>
            dispatch({ type: HeaderMenuAction.TOGGLE_MEINKONTO_MENU })
          }
        />
      ) : (
        <LegacyWishlistHeaderButton />
      )}

      <ContactInfo />
      {!mobile ? (
        <MenuWrap>
          <RouterLink {...homePageRouterProps} passHref>
            <Link
              className={
                pageType === PageType.Home
                  ? 'home-link home-link-active'
                  : 'home-link'
              }
            >
              <Icon name="home-menu" size="lg" />
            </Link>
          </RouterLink>
          {linksWithActiveItem.length ? (
            <DesktopMenuWrapper
              links={linksWithActiveItem}
              isSubMenuBlocked={false}
            />
          ) : null}
        </MenuWrap>
      ) : null}
      {mobile ? (
        <>
          <BurgerButton
            onClick={() =>
              dispatch({ type: HeaderMenuAction.TOGGLE_HEADER_MENU })
            }
            icon={{
              name: state.headerMenuOpen ? 'navi-open' : 'navi',
              size: 'xlg',
            }}
          />
          {state.headerMenuOpen ? (
            <MobileMenuModal isScrollEnable={false}>
              <MobileMenu
                links={navigationLinks}
                onClose={() =>
                  dispatch({ type: HeaderMenuAction.CLOSE_HEADER_MENU })
                }
              />
            </MobileMenuModal>
          ) : null}
        </>
      ) : null}
    </HeaderLayoutWrapper>
  );
};

export default Header;
