import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import ProductFeatures from '@hotelplan/components.common.product/ProductFeatures';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistProductItemWrapper = styled(Link)(
  sx2CssThemeFn({
    display: 'flex',
    border: '1px solid',
    borderColor: 'borderGray',
    '.hotel-name': {
      width: ['initial', '360px'],
      color: 'secondary',
      fontSize: ['14px', '28px'],
      fontFamily: '"Helvetica Neue", sans-serif',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.product-content': {
      position: 'relative',
      overflow: 'hidden',
      pt: [1, 3],
      pl: [2, 3],
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '.product-image-wrapper': {
      position: 'relative',
      flex: ['45% 0 0', '314px 0 0'],
    },
    '.product-image': {
      borderRadius: ['0px', '0 5px 0 5px'],
      height: ['115px', '100%'],
    },
    '.breadcrumbs': {
      fontSize: ['12px', '14px'],
      fontWeight: 'bold',
      mb: 2,
      mr: 2,
    },
  })
);

export const ProductImage = styled(Image)(
  sx2CssThemeFn({
    width: ['100%', '314px'],
    height: ['auto', '205px'],
    objectFit: 'cover',
    display: 'block',
  })
);

export const ProductFeaturesWrapper = styled(ProductFeatures)(
  sx2CssThemeFn({
    position: 'absolute',
    bottom: 0,
    pb: 1,
    pl: 2,
    '.featureGroupWrapper': {
      ml: 0,
      mr: 2,
      mb: 1,
    },
    '.feature-summary-item-icon': {
      color: 'secondary',
      stroke: 'white',
      strokeWidth: '4px',
      paintOrder: 'stroke',
      width: [null, '30px'],
      height: [null, '30px'],
    },
  })
);

export const ShowDetailsButton = styled(Button)(
  ({ theme: { buttons, media } }) => ({
    [media.mobile]: {
      ...buttons.iconBtn,
    },
    [media.tablet]: {
      ...buttons.primary,
      ...buttons.btnWithIcon,
    },
  }),
  sx2CssThemeFn({
    p: ['0px 6px', '1px 8px'],
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    right: 0,
    transition: [null, 'fast'],
    '.icon': {
      color: ['primary', 'inherit'],
      mr: 0,
    },
  })
);

export const RemoveIconContainer = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    '.trash-icon': {
      position: 'absolute',
      top: 0,
      right: [0, '-1px'],
      width: ['25px', '37px'],
      height: ['25px', '33px'],
      p: [1, '6px'],
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: '0 0 0 5px',
      zIndex: 1,
      cursor: 'pointer',
      background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
      '&:hover': {
        background: 'linear-gradient(to bottom,#fefefe 0,#cecece 100%)',
      },
      '.icon': {
        variant: ['icons.xs', 'icons.sm'],
      },
    },
    '.product-card': {
      '.hotel-name': {
        width: [null, '400px'],
      },
      '.trip-info, .breadcrumbs': {
        whiteSpace: ['nowrap', 'unset'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.product-image': {
        borderRadius: '0',
      },
    },
  })
);
