import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type RemoveWishlistMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type RemoveWishlistMutation = {
  __typename?: 'Mutation';
  deleteWishlist: {
    __typename?: 'WishlistMutationResponse';
    activeChanged: boolean;
    status: Types.Status;
    message?: string | null;
  };
};

export const RemoveWishlistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wishlistId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeChanged' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveWishlistMutationFn = Apollo.MutationFunction<
  RemoveWishlistMutation,
  RemoveWishlistMutationVariables
>;

/**
 * __useRemoveWishlistMutation__
 *
 * To run a mutation, you first call `useRemoveWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWishlistMutation, { data, loading, error }] = useRemoveWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveWishlistMutation,
    RemoveWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveWishlistMutation,
    RemoveWishlistMutationVariables
  >(RemoveWishlistDocument, options);
}
export type RemoveWishlistMutationHookResult = ReturnType<
  typeof useRemoveWishlistMutation
>;
export type RemoveWishlistMutationResult =
  Apollo.MutationResult<RemoveWishlistMutation>;
export type RemoveWishlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveWishlistMutation,
  RemoveWishlistMutationVariables
>;
