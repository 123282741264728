import React from 'react';
import {
  EmptyWishlist,
  EmptyWishlistSectionWrapper,
} from 'components/domain/wishlist/components';

export default function DefaultEmptyWishlist(): React.ReactElement {
  return (
    <EmptyWishlistSectionWrapper>
      <EmptyWishlist />
    </EmptyWishlistSectionWrapper>
  );
}
