import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const AccordionItemTitle = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    px: 2,
    textAlign: 'left',
  })
);

export const FeatureItem = styled.li(
  sx2CssThemeFn({
    display: 'flex',
    gap: 2,
    minHeight: '24px',
    alignItems: 'center',
    pl: 2,
    '&:nth-child(odd)': {
      bg: '#F2F2F2',
    },
    '.icon-wrap': {
      width: '13px',
    },
    '.icon': {
      variant: 'icons.xs',
      strokeWidth: '2px',
      stroke: 'textGray',
    },
    '& .enabled': {
      color: 'primary',
      variant: 'icons.sm',
    },
  })
);
