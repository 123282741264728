import { ReactElement } from 'react';
import { DisrupterObject } from '@hotelplan/graphql.types';
import {
  CumulusIconContainer,
  DisrupterContainer,
  DisrupterSection,
} from './Disrupter.styles';
import type { EDisrupterPlacement } from './Disrupter.types';

interface IDisrupterProps extends DisrupterObject {
  placement: EDisrupterPlacement;
}

export default function Disrupter({
  mainTitle,
  subTitle,
  fontColor,
  backgroundColor,
  iconType,
  placement,
}: IDisrupterProps): ReactElement {
  if (!mainTitle) return null;

  return (
    <DisrupterSection>
      <DisrupterContainer
        placement={placement}
        fontColor={fontColor}
        bgColor={backgroundColor}
      >
        {!!iconType ? (
          <CumulusIconContainer placement={placement} name="5x_cumulus" />
        ) : null}
        <div className="disrupter-text">
          <div
            className="main-title"
            dangerouslySetInnerHTML={{ __html: mainTitle }}
          />
          <div
            className="sub-title"
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        </div>
      </DisrupterContainer>
    </DisrupterSection>
  );
}
