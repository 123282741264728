import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { WishlistCardWrapper } from './WishlistOverview.styled';

export const AddWishlistCardWrapper = styled(WishlistCardWrapper)(
  sx2CssThemeFn({
    bg: '#f2f2f2',
    justifyContent: 'center',
    '.register-button, .add-wishlist-btn': {
      width: '100%',
      height: '100%',
      border: 'none',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'textGray',
    },
    '.login-link-text': {
      display: 'none',
    },
  })
);
