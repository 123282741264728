import { useTranslation } from 'next-i18next';
import { IRouterLinkProps } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';

export default function useHomePageRouterProps(): IRouterLinkProps {
  const [, { language }] = useTranslation();
  const pageType = usePageType<PageType>();

  // We want to use a shallow route for the homepage, so that the page is not
  // re-rendered and doesn't trigger url replace.
  const shallowRoute = pageType === PageType.Home;

  const homepageRoute = getRouteByPageTypeLocale(PageType.Home, language);
  const homepageRouteProps = getTargetPageData({
    targetPageType: PageType.Home,
    uri: homepageRoute ? homepageRoute.builder({}) : '/',
  });

  return shallowRoute
    ? { ...homepageRouteProps, shallow: true }
    : homepageRouteProps;
}
