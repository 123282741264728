import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistCompareItemsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flex: '0 1',
  })
);

export const SelectInputWrapper = styled.div(
  sx2CssThemeFn({
    width: ['50%', `${100 / 3}%`],
    mx: [2, 2],
    overflow: 'hidden',
    '.chevron': {
      bottom: '11px',
      right: '8px',
    },
    '.input': {
      pr: 4,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
);
