import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { PriceFragmentDoc } from './Price.generated';
import { FlightItemFragmentDoc } from './FlightItem.generated';
export type OrlItemFragment = {
  __typename?: 'SingleOrlItem';
  id: string;
  departureDate: string;
  returnDate: string;
  duration: number;
  transferIncluded: boolean;
  waitingScreenImage: string;
  flightAlternativesAvailable: boolean;
  giataId?: number | null;
  provider?: string | null;
  productCode?: string | null;
  lastMinute: boolean;
  productClass: Types.ProductClass;
  travelType: Types.TravelType;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  rooms: Array<{
    __typename?: 'OrlRoom';
    adults: number;
    children: number;
    roomType: { __typename?: 'RoomType'; description: string; code: string };
    boardType: { __typename?: 'BoardType'; description: string; code: string };
  }>;
  pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  outboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  inboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export const OrlItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ORLItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SingleOrlItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
                { kind: 'Field', name: { kind: 'Name', value: 'children' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roomType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boardType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outboundFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inboundFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waitingScreenImage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAlternativesAvailable' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'giataId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMinute' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...PriceFragmentDoc.definitions,
    ...FlightItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
