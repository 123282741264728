import defaultTo from 'lodash/fp/defaultTo';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import { useRouter } from 'next/router';

const getQuerySearchParam = pipe(get(`s`), defaultTo(''), String);

const useSearchParam = () => {
  const { query } = useRouter();
  return getQuerySearchParam(query);
};

export default useSearchParam;
