import { PageType } from '@hotelplan/graphql.types';
import {
  getRouteByPageNameLocale,
  NextHistoryState,
} from '@hotelplan/libs.router-config';
import {
  FAKE_BASE_URL,
  LOCAL_STORAGE_QUERY_PARAMS,
  SEARCH_QUERY_ORIGIN_PARAM,
} from './constants';

export function parseURL(url: string): Record<string, string> {
  const routeURL = new URL(url, FAKE_BASE_URL);

  return Array.from(routeURL.searchParams.entries()).reduce(
    (route, [param, value]) => {
      route[param] = value;
      return route;
    },
    { path: routeURL.pathname }
  );
}

export function addQueryParams(
  url: string,
  newParams: Record<string, string>
): string {
  const routeURL = new URL(url, FAKE_BASE_URL);

  Object.entries(newParams).forEach(([newParam, value]) => {
    routeURL.searchParams.set(newParam, value);
  });

  return routeURL.pathname + routeURL.search;
}

export function getLocalStorageParam(
  key: string
): Record<string, string> | null {
  if (typeof localStorage !== 'undefined') {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }
  return null;
}

export function addQueryParamToLocalStorage(
  newParams: Record<string, string>,
  key = LOCAL_STORAGE_QUERY_PARAMS
) {
  const queryParams = getLocalStorageParam(key);
  localStorage.setItem(key, JSON.stringify({ ...queryParams, ...newParams }));
}

export function getPageType(
  historyState: NextHistoryState,
  locale?: string
): PageType | null {
  const {
    url,
    options: { locale: historyStateLocale },
  } = historyState;
  const routeURL = new URL(url, FAKE_BASE_URL);
  const language = locale || historyStateLocale;

  if (language && typeof language === 'string') {
    const route = getRouteByPageNameLocale(routeURL.pathname, language);
    if (route) {
      return route.pageType;
    }
  }

  return null;
}

export function duplicateSearchQueryFromLocalToSessionStorage() {
  if (
    typeof localStorage !== 'undefined' &&
    typeof sessionStorage !== 'undefined'
  ) {
    // search query appears in local storage when the user opens
    // the search page
    const searchQueryOrigin = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_QUERY_PARAMS) || '{}'
    )[SEARCH_QUERY_ORIGIN_PARAM];

    // Check if the key already exists in session storage
    if (
      searchQueryOrigin &&
      !sessionStorage.getItem(SEARCH_QUERY_ORIGIN_PARAM)
    ) {
      // If the key does not exist in session storage, set it
      sessionStorage.setItem(SEARCH_QUERY_ORIGIN_PARAM, searchQueryOrigin);
    }
  }
}
