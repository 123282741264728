import React from 'react';
import { IMapPinProps, MapPin } from '@hotelplan/components.common.map-pin';
import CurrentHotelPinIcon from 'components/domain/map/CurrentHotelPinIcon';
import type { ProductPin } from 'components/domain/map/Map.types';
import MapAlternativeHotelPinContent from './MapAlternativeHotelPinContent';

interface IMapProductPinProps
  extends Omit<IMapPinProps<ProductPin>, 'coordinates' | 'object'> {
  product: ProductPin;
  isCurrentProduct: boolean;
  isSelected: boolean;
}

const MapProductPin: React.FC<IMapProductPinProps> = (
  props: IMapProductPinProps
) => {
  const { product, isCurrentProduct, isSelected, ...mapPinProps } = props;

  return (
    <MapPin<ProductPin>
      coordinates={product.coordinates}
      object={product}
      {...mapPinProps}
    >
      {isCurrentProduct ? (
        <CurrentHotelPinIcon />
      ) : (
        <MapAlternativeHotelPinContent
          isBestseller={product.bestseller}
          price={product.price}
          isSelected={isSelected}
        />
      )}
    </MapPin>
  );
};

export default MapProductPin;
