import { useTranslation } from 'next-i18next';
import { IRouterLinkProps } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';

export default function useSRLRoute(): IRouterLinkProps {
  const [, { language }] = useTranslation();
  const searchRoute = getRouteByPageTypeLocale(PageType.Srl, language);

  return getTargetPageData({
    targetPageType: searchRoute.pageType,
    uri: searchRoute.builder({}),
  });
}
