import {
  FilterComponent,
  PdpFilterCriteriaInput,
  SrlFilterCriteriaInput,
  WishlistFiltersCriteriaInput,
} from '@hotelplan/graphql.types';
import { stripTypename } from '@hotelplan/libs.utils';
import {
  ALL_FILTERS_OPTIONS_ID,
  SUB_AMENITIES_LIST,
  subAmenitiesIconsDictionary,
} from './Filters.common';
import {
  IFilterOption,
  IFilterOptions,
  IFiltersSearchState,
  IFlightOptionsState,
  TFilterOptionCaptions,
} from './Filters.types';

type BackendFilterKeys = keyof Omit<FilterComponent, '__typename'>;

// Mapping between backend filter key names and frontend filter key names
// @ts-ignore, todo: add missing properties
const backendFilterValueKeysToFormStateFilterKey: {
  [K in BackendFilterKeys]: keyof IFiltersSearchState;
} = {
  departureAirports: 'departureAirports',
  roomTypes: 'roomTypes',
  boardTypes: 'boardTypes',
  hpRating: 'hpRating',
  taRating: 'taRating',
  maxPrice: 'maxPrice',
  stopOvers: 'flightOptions',
  radius: 'radius',
};

export function mapFiltersToFormState(filters): IFiltersSearchState {
  const mappedFilters: IFiltersSearchState = {};
  const mainFilters = Object.keys(stripTypename(filters));

  mainFilters.forEach((filter: BackendFilterKeys) => {
    if (!filters[filter]) return;

    switch (filters[filter].__typename) {
      case 'CheckboxFilterComponent':
      case 'RadiobuttonFilterComponent':
      case 'SliderFilterComponent':
        if (
          filter === 'directFlightDepartureTime' ||
          filter === 'returnFlightDepartureTime' ||
          filter === 'stopOvers'
        ) {
          mappedFilters['flightOptions'] = {
            ...mappedFilters['flightOptions'],
            [filter]: filters[filter].selected,
          };
          break;
        }

        if (filter === 'mainFeatures' || SUB_AMENITIES_LIST.includes(filter)) {
          mappedFilters['productFeatures'] = [
            ...(mappedFilters['productFeatures'] || []),
            ...filters[filter].selected,
          ].filter(item => item.length);
          break;
        }

        mappedFilters[filter] = filters[filter].selected;
        break;

      case 'RadiusFilterComponent': {
        if (!filter[filter]) break;
        const { radius, label, center } = filter[filter] || {};
        mappedFilters[filter] = {
          radius,
          label,
          center: {
            latitude: center?.latitude,
            longitude: center?.longitude,
          },
        };
        break;
      }
    }
  });

  return mappedFilters;
}

export function mapMainFilterComponentOptionsToLocalFilterOptions(
  options: FilterComponent
): IFilterOptions {
  const resultOptions = {};
  for (const optionKey in stripTypename(options)) {
    switch (optionKey) {
      case 'directFlightDepartureTime':
      case 'returnFlightDepartureTime':
      case 'stopOvers': {
        resultOptions['flightOptions'] = {
          ...resultOptions['flightOptions'],
          [optionKey]: options[optionKey].options.map(({ id, caption }) => ({
            caption,
            id,
          })),
        };
        break;
      }
      case 'mainFeatures': {
        resultOptions['productFeatures'] = options[optionKey].options.map(
          ({ id, caption }) => ({
            caption,
            id,
          })
        );
        break;
      }
      case 'radius': {
        break;
      }
      default: {
        resultOptions[optionKey] = options[optionKey].options?.map(
          ({ id, caption }) => ({
            caption,
            id,
          })
        );
        break;
      }
    }
  }
  return resultOptions as IFilterOptions;
}

export function mapMainFilterComponentOptionsToLocalFilterOptionCaptions(
  options: FilterComponent
): TFilterOptionCaptions {
  const resultOptions = {};
  for (const optionKey in stripTypename(options)) {
    switch (optionKey) {
      case 'directFlightDepartureTime':
      case 'returnFlightDepartureTime':
      case 'stopOvers': {
        resultOptions['flightOptions'] = {
          ...resultOptions['flightOptions'],
          [optionKey]: options[optionKey].caption,
        };
        break;
      }
      case 'mainFeatures': {
        resultOptions['productFeatures'] = options[optionKey].caption;
        break;
      }
      case 'radius': {
        break;
      }
      default: {
        resultOptions[optionKey] = options[optionKey].caption;
        break;
      }
    }
  }

  return resultOptions as TFilterOptionCaptions;
}

export function enhanceWithChooseAll(
  options: Array<IFilterOption>,
  caption: string
): Array<IFilterOption> {
  const chooseAllOption: IFilterOption = {
    id: ALL_FILTERS_OPTIONS_ID,
    caption,
  };

  return [chooseAllOption, ...options];
}

export function mapSubAmenityNameToIcon(fieldName: string) {
  return subAmenitiesIconsDictionary[fieldName] || 'not-found';
}

const filtersFormStateKeyToSrlFilterCriteriaInputKey: {
  [K in keyof IFiltersSearchState]:
    | keyof SrlFilterCriteriaInput
    | 'flightOptions';
} = {
  boardTypes: 'boardTypes',
  departureAirports: 'departureAirports',
  hpRating: 'hotelplanRating',
  maxPrice: 'maxPricePerPerson',
  roomTypes: 'roomTypes',
  taRating: 'tripAdvisorRating',
  productFeatures: 'productFeatures',
  flightOptions: 'flightOptions',
  radius: 'radius',
};

const filtersFormStateKeyToPdpFilterCriteriaInputKey: {
  [K in keyof IFiltersSearchState]:
    | keyof PdpFilterCriteriaInput
    | 'flightOptions';
} = {
  boardTypes: 'boardTypes',
  departureAirports: 'departureAirports',
  maxPrice: 'maxPricePerPerson',
  roomTypes: 'roomTypes',
  flightOptions: 'flightOptions',
};

const filtersFormStateKeyToWishlistFilterCriteriaInputKey: {
  [K in keyof IFiltersSearchState]:
    | keyof WishlistFiltersCriteriaInput
    | 'flightOptions';
} = {
  boardTypes: 'boardTypes',
  departureAirports: 'departureAirports',
  maxPrice: 'maxPricePerPerson',
  roomTypes: 'roomTypes',
  flightOptions: 'flightOptions',
};

export const mapSrlFilterValuesToSrlFilterCriteriaInput = (
  filterState: IFiltersSearchState | null | undefined
): SrlFilterCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToSrlFilterCriteriaInputKey
  );

export const mapOrlFilterValuesToPdpFilterCriteriaInput = (
  filterState: IFiltersSearchState | null | undefined
): PdpFilterCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToPdpFilterCriteriaInputKey,
    true
  );

export const mapWishlistFilterValuesToWishlistFilterCriteriaInput = (
  filterState: IFiltersSearchState | null | undefined
): WishlistFiltersCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToWishlistFilterCriteriaInputKey
  );

function mapFlightOptionsToFilterCriteriaInput(
  stateValue: IFlightOptionsState,
  departureKey: 'departureTime' | 'departureFlightDepartureTime',
  returnKey: 'returnTime' | 'returnFlightDepartureTime'
):
  | PdpFilterCriteriaInput
  | SrlFilterCriteriaInput
  | WishlistFiltersCriteriaInput {
  const { directFlightDepartureTime, returnFlightDepartureTime, stopOvers } =
    stateValue;
  return {
    ...(directFlightDepartureTime && {
      [departureKey]: directFlightDepartureTime,
    }),
    ...(returnFlightDepartureTime && {
      [returnKey]: returnFlightDepartureTime,
    }),
    ...(stopOvers && {
      flightStopOver: stopOvers,
    }),
  };
}

export const mapFiltersStateToFilterCriteriaInput = <
  TFilterCriteria extends {
    maxPricePerPerson?: string | null | undefined;
    productFeatures?: string[] | null | undefined;
  }
>(
  filters: IFiltersSearchState | null | undefined,
  // Using criteria dict is not efficient because of flightOptions. To make it work we need to
  // use flight options fields separately in IFiltersSearchState to be able to map them to criteria from dict
  // TODO: extract flightOptions fields in IFiltersSearchState to get rid of mapFlightOptionsToFilterCriteriaInput
  formStateToCriteriaDict: {
    [K in keyof IFiltersSearchState]: keyof TFilterCriteria | 'flightOptions';
  },
  isORL = false
): TFilterCriteria => {
  if (!filters) return {} as TFilterCriteria;

  const defaultCriteriaValue = {
    productFeatures: [],
    maxPricePerPerson: undefined,
  } as TFilterCriteria;

  return (
    Object.keys(filters) as Array<keyof IFiltersSearchState>
  ).reduce<TFilterCriteria>((acc, key) => {
    const criteriaKey = formStateToCriteriaDict[key];
    const filterValue = filters[key];

    // parse flightOptions to departureTime, returnTime and flightStopOver
    if (key === 'flightOptions') {
      return {
        ...acc,
        ...mapFlightOptionsToFilterCriteriaInput(
          filterValue as IFlightOptionsState,
          isORL ? 'departureTime' : 'departureFlightDepartureTime',
          isORL ? 'returnTime' : 'returnFlightDepartureTime'
        ),
      };
    }

    if (
      !criteriaKey ||
      !filterValue ||
      (Array.isArray(filterValue) && !filterValue.length)
    )
      return acc;

    return { ...acc, [criteriaKey]: filterValue };
  }, defaultCriteriaValue);
};

export const mapMainFilterComponentValuesToFiltersFormState = (
  filterValues?: FilterComponent | null
): IFiltersSearchState => {
  const filtersState: IFiltersSearchState = {};

  if (!filterValues) return filtersState;

  const backendFilterKeys = Object.keys(
    stripTypename(filterValues)
  ) as BackendFilterKeys[];

  backendFilterKeys.forEach(filter => {
    const filterStateKey = backendFilterValueKeysToFormStateFilterKey[filter];
    const filterValue = filterValues[filter];
    if (!filterValue) return;

    switch (filterValue.__typename) {
      case 'CheckboxFilterComponent':
        if (filterValue.selected.length) {
          (filtersState[filterStateKey] as string[]) = (
            (filtersState[filterStateKey] || []) as string[]
          ).concat(filterValue.selected);
        }
        break;
      case 'RadiobuttonFilterComponent':
      case 'SliderFilterComponent':
        if (filterValue.selected) {
          if (filter === 'directFlightDepartureTime') {
            (filtersState[filterStateKey] as IFlightOptionsState) = {
              ...(filtersState[filterStateKey] as IFlightOptionsState),
              directFlightDepartureTime: filterValue.selected,
            };
          } else if (filter === 'returnFlightDepartureTime') {
            (filtersState[filterStateKey] as IFlightOptionsState) = {
              ...(filtersState[filterStateKey] as IFlightOptionsState),
              returnFlightDepartureTime: filterValue.selected,
            };
          } else if (filter === 'stopOvers') {
            (filtersState[filterStateKey] as IFlightOptionsState) = {
              ...(filtersState[filterStateKey] as IFlightOptionsState),
              stopOvers: filterValue.selected,
            };
          } else {
            (filtersState[filterStateKey] as string) = filterValue.selected;
          }
        }
        break;
      case 'SingleValueFilterComponent':
        (filtersState[filterStateKey] as string) = filterValue.id;
        break;
    }
  });

  return filtersState;
};
