import { useTranslation } from 'next-i18next';
import React from 'react';
import { AccordionStateProvider } from '@hotelplan/components.common.accordion';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import SearchFilters from 'components/domain/filters/SearchFilters';
import SearchControlForm from 'components/domain/search-control/components/SearchControlForm/SearchControlForm';
import {
  FilterButton,
  SrlMobileOverlayWrapper,
} from 'components/domain/srl/SRL.common';
import {
  WishlistCompareSelect,
  WishlistCompareItems,
  WishlistCompareSearchControlFormDesktop,
} from 'components/domain/wishlist/components';
import { displayingFilters } from 'components/domain/wishlist/utils';
import {
  WishlistCompareContentWrapper,
  WishlistCompareSection,
} from './WishlistCompareContent.styled';

export default function WishlistCompareContent(): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');
  const [mobileOverlayOpened, open, close] = useToggleState(false);

  return (
    <WishlistCompareContentWrapper>
      {!mobile ? (
        <WishlistCompareSearchControlFormDesktop />
      ) : (
        <>
          <div className="mobile-filters-button">
            <FilterButton onClick={open}>
              {t('filter.overlay.title')}
            </FilterButton>
          </div>

          <SrlMobileOverlayWrapper
            name={'wishlist-search-mobile-overlay'}
            title={t('filter.overlay.title')}
            show={mobileOverlayOpened}
            fullScreen={true}
            onClose={close}
          >
            <SearchControlForm
              closeMobileOverlay={close}
              hideTravelDestination={true}
              filtersComponent={
                <SearchFilters
                  includedOptions={displayingFilters}
                  showSubAmenities={false}
                  isWishlist
                />
              }
            />
          </SrlMobileOverlayWrapper>
        </>
      )}
      <WishlistCompareSection>
        <WishlistCompareSelect />
        <AccordionStateProvider>
          <WishlistCompareItems />
        </AccordionStateProvider>
      </WishlistCompareSection>
    </WishlistCompareContentWrapper>
  );
}
