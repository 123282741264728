import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import {
  AbsoluteDropdown,
  DropDownWrapper,
} from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const DropdownHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '.title': {
      color: 'secondary',
      fontSize: '26px',
      mb: 3,
      lineHeight: '1',
      maxWidth: '90%',
    },
  })
);

export const CloseButton = styled(Button).attrs({
  icon: {
    name: 'close',
    size: 'sm',
  },
  variant: 'closeButton',
})(
  sx2CssThemeFn({
    bg: 'transparent',
    color: 'black',
  })
);

export const FilterApplyButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
    size: 'sm',
  },
  className: 'apply-button',
})(({ theme }) => ({
  ...theme.buttons.btnWithIcon,
  textTransform: 'uppercase',
}));

interface ISearchFilterDesktopDropdownProps {
  containerRef: React.Ref<HTMLDivElement>;
  dropdownRef: React.Ref<HTMLDivElement>;
  title?: string;
  isShown: boolean;
  onApply(): void;
  onClose?(): void;
}

export default function SearchFilterDesktopDropdown({
  containerRef,
  dropdownRef,
  title,
  isShown,
  onApply,
  onClose,
  children,
}: PropsWithChildren<ISearchFilterDesktopDropdownProps>): React.ReactElement {
  const [t] = useTranslation('filters');

  return (
    <DropDownWrapper ref={containerRef} className={'dropdown-wrapper'}>
      <AbsoluteDropdown
        isShown={isShown}
        ref={dropdownRef}
        className={'search'}
      >
        <DropdownHeader>
          <h3 className="title">{title}</h3>
          <CloseButton onClick={onClose} />
        </DropdownHeader>
        {children}
        <FilterApplyButton onClick={onApply}>
          {t('filters:apply.button')}
        </FilterApplyButton>
      </AbsoluteDropdown>
    </DropDownWrapper>
  );
}
