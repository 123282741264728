import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type PageFragment = {
  __typename?: 'Page';
  pageNumber: number;
  resultsTotal?: number | null;
  resultsPerPage?: number | null;
};

export const PageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'page' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Page' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultsTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultsPerPage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
