import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ISearchFilterGroupProps } from './Filters.types';
import { getMaxRating } from './Filters.utils';
import { CheckboxContent as RadioButtonContent } from './SearchFilterCheckboxesGroup';

const FilterRadioButton = styled(RadioButton)<{ checked: boolean }>(
  ({ checked, disabled, theme: { colors } }) =>
    sx2CssThemeFn({
      border: [
        'none',
        `1px solid ${checked ? colors.primary : colors.borderGray}!important`,
      ],
      '.checkbox-text': {
        color: [checked ? colors.primary : colors.textGray, colors.textGray],
        fontWeight: checked && 700,
        mr: [checked && '-3px', 0],
      },
      '&:hover': {
        background: ['transparent', checked ? 'white' : colors.hoverGraySecond],
        cursor: 'pointer',
        '.checkbox-text': {
          textDecoration: ['none', 'underline'],
        },
      },
      ...(disabled
        ? {
            pointerEvents: 'none',
            border: ['none', `1px solid ${colors.borderGray}`],
            backgroundColor: [null, colors.lightGreySixth],
            opacity: 0.4,
            '.filter-count': {
              backgroundColor: colors.backgroundGray,
            },
          }
        : {}),
    })
);

const RadioButtonsAreaWrapper = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: ['5px', '10px'],
    my: [3, 0],
    '.label': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      p: ['8px 5px', '5px'],
      mb: '0px',
      fontWeight: [null, 'bold'],
      border: [null, `1px solid ${colors.borderGray}`],
      borderRadius: [null, 'default'],
      fontSize: '14px',
      color: ['textGray', 'black'],
      '.rating, .remainRating, .halfWayRating': {
        ml: ['2px', 1],
        '.icon': {
          width: '17px',
          height: '17px',
          color: 'secondary',
        },
      },
    },
  })
);

const FiltersRadioControl = styled.span<{
  checked: boolean;
  disabled: boolean;
}>(({ checked, disabled, theme: { colors, space } }) => ({
  display: 'inline-block',
  height: '16px',
  width: '16px',
  position: 'relative',
  border: checked ? '4px solid' : '1px solid',
  borderColor: checked ? colors.primary : colors.borderGray,
  borderRadius: '50%',
  order: '-1',
  marginRight: space[2],
  backgroundColor: disabled ? colors.backgroundGray : colors.background,
  flexShrink: 0,
}));

export default function SearchFilterRadioGroup({
  options,
  checkedOptions,
  onChange,
  className,
  getCounts,
  checkIfDisabled,
}: ISearchFilterGroupProps): React.ReactElement {
  const maxRating = getMaxRating(options);

  return (
    <RadioButtonsAreaWrapper
      className={`radio-buttons-area-wrapper ${className ? className : null}`}
    >
      {options.map((option, index) => {
        const checked = checkedOptions?.includes(option.id);
        const disabled = checkIfDisabled?.(option.id);

        return (
          <FilterRadioButton
            key={`${option.id}-${index}`}
            value={option.id}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            content={
              <RadioButtonContent
                caption={option.caption}
                maxRating={maxRating}
                rating={option.rating}
                ratingIcons={option.ratingIcons}
                counts={getCounts?.(option.id)}
              />
            }
            renderCustomControl={() => {
              return (
                <FiltersRadioControl
                  className="customCheckboxInput radio-control"
                  checked={checked}
                  disabled={disabled}
                />
              );
            }}
          />
        );
      })}
    </RadioButtonsAreaWrapper>
  );
}
