import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistListContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    width: '100%',
    maxWidth: ['100%', '80%'],
  }),
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      '.primary-button-link': {
        mx: 'auto',
        [media.mobile]: {
          py: 3,
          width: 'calc(100% - 24px)',
        },
      },
    })
);
