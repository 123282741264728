import { Status } from '@hotelplan/graphql.types';
import { useGetWishlistOverviewQuery } from 'graphql/wishlist/queries/GetWishlistOverview.generated';

export default function useWishlistOverview() {
  const { data, previousData } = useGetWishlistOverviewQuery({
    ssr: false,
    onCompleted(completedData) {
      const { status, message } = completedData.wishlist.overview;

      if (status === Status.Failure) {
        // eslint-disable-next-line no-console
        console.error(`GetWishlistOverviewQuery Failure`, message);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const wishlists = (data || previousData)?.wishlist.overview.wishlists;

  return {
    wishlists: wishlists || [],
    loading: !wishlists,
  };
}
