import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type WishlistOfferRoomFragment = {
  __typename?: 'WishlistOfferRoom';
  adults: number;
  children: number;
  boardCode: string;
  boardDescription: string;
  roomCode: string;
  roomDescription: string;
};

export const WishlistOfferRoomFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistOfferRoom' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistOfferRoom' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'children' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boardCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boardDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
