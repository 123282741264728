import styled, { css, keyframes } from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const ProductPinContent = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'secondary',
    borderRadius: 'default',
    px: 1,
    '.price': {
      whiteSpace: 'nowrap',
      lineHeight: '20px',
      fontSize: '12px',
      fontFamily: '"Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      color: 'white',
    },

    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #f60',
      bottom: '-7px',
      left: '50%',
      ml: '-10px',
    },

    '&:before': {
      position: 'absolute',
      content: '""',
      background: 'rgba(102, 102, 102, 0.5)',
      borderRadius: '50%',
      bottom: '-10px',
      width: '20px',
      height: '5px',
      left: '50%',
      ml: '-10px',
    },
  })
);

const animation = keyframes`
  0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
  40%  {transform: translate3d(0,-20%,0) scale3d(.8,1.3,1);}
  100% {transform: translate3d(0,-60%,0) scale3d(1.2,.8,1);}
`;

export const AnimatedWrapper = styled.div`
  animation: ${css`
    ${animation} .3s linear alternate 2;
  `};
`;
