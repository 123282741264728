import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Icon } from '@hotelplan/components.common.icon';
import {
  createUrlWithUtmParameters,
  TEMail,
  useShareUrl,
  useSharingUrlContext,
} from '@hotelplan/components.common.share-form';
import { Tooltip } from '@hotelplan/components.common.tooltip';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useMetaDataContext } from '@hotelplan/libs.meta-data';
import { isAndroid, isIOS } from '@hotelplan/libs.utils';
import ShareOverlay from 'components/domain/share-overlay/ShareOverlay';
import {
  SiteToolsButtonsWrapper,
  SiteToolTooltipWrapper,
} from 'components/domain/sitetools/styles';
import { useHover } from 'libs';

interface IShareSiteToolProps {
  updatedEmailData?: TEMail;
}

function ShareSiteTool({
  updatedEmailData,
}: IShareSiteToolProps): React.ReactElement {
  const [t] = useTranslation('common');
  const { mobile, desktop } = useDeviceType();
  const { channelType } = useAuthentication();

  const shareOverlayRef = useRef<HTMLDivElement>(null);
  const shareButtonRef = useRef<HTMLDivElement>(null);

  const [showTooltip, show, hide] = useToggleState(false);
  const [showShareOverlayModal, openShareOverlayModal, closeShareOverlayModal] =
    useToggleState(false);

  const { setSharingData } = useSharingUrlContext();
  const shareUrl = useShareUrl();
  const url = createUrlWithUtmParameters(shareUrl, channelType);
  const meta = useMetaDataContext();

  function mobileShare(): boolean {
    const windowNavigator = window.navigator as any;
    if (windowNavigator?.share && meta && mobile) {
      windowNavigator.share({
        title: meta.title,
        text: meta.description,
        url,
      });
      return false;
    }
    return true;
  }

  function handleShareSiteToolClick() {
    if (mobileShare()) {
      openShareOverlayModal();
      if (updatedEmailData) {
        setSharingData({
          email: updatedEmailData,
        });
      }
    }
  }

  useOnClickOutside([shareOverlayRef, shareButtonRef], closeShareOverlayModal);
  useHover(shareButtonRef, show, hide);

  return (
    <>
      <SiteToolsButtonsWrapper
        className="sitetool-item"
        onClick={handleShareSiteToolClick}
        ref={shareButtonRef}
      >
        {typeof window !== undefined ? (
          <>
            <Icon
              className="share-icon"
              name={
                isIOS || (desktop && !isAndroid) ? 'share-ios' : 'share-android'
              }
            />
            {showTooltip ? (
              <SiteToolTooltipWrapper>
                <Tooltip className="arrow-top">
                  {t('common:share.tooltip')}
                </Tooltip>
              </SiteToolTooltipWrapper>
            ) : null}
          </>
        ) : null}
        <span className="button-text">{`Teilen`}</span>
      </SiteToolsButtonsWrapper>
      {showShareOverlayModal ? (
        <ShareOverlay
          onClose={closeShareOverlayModal}
          show={showShareOverlayModal}
          overlayRef={shareOverlayRef}
        />
      ) : null}
    </>
  );
}

export default ShareSiteTool;
