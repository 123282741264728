import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ratingSymbols } from './Filters.common';

const RatingSymbol = styled.div<{
  shift: number;
  leftMargin?: number;
  symbol: string;
  color: string;
}>(({ shift, symbol, color, leftMargin }) =>
  sx2CssThemeFn({
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: ['12px', '6px'],
      left: leftMargin
        ? `calc(${leftMargin}px + ${shift}px)`
        : [`calc(50px + ${shift}px)`, `calc(45px + ${shift}px)`],
      content: `"\\${symbol}"`,
      color,
      fontFamily: 'icomoon',
      zIndex: 10,
      cursor: 'pointer',
    },
  })
);

interface IRatingSymbolsProps {
  filterName: string;
  rating: number;
  shift?: number;
  leftMargin?: number;
  className?: string;
  onClick?(): void;
}

export default function RatingSymbols({
  filterName,
  rating,
  shift = 21,
  leftMargin,
  className,
  onClick,
}: IRatingSymbolsProps): React.ReactElement {
  return (
    <>
      {Array.from({ length: rating }).map((_, i) => {
        return (
          <RatingSymbol
            key={`rating(${rating})-${i}`}
            shift={i * shift}
            leftMargin={leftMargin}
            symbol={ratingSymbols[filterName].code}
            color={ratingSymbols[filterName].color}
            onClick={onClick}
            className={className}
          />
        );
      })}
    </>
  );
}
