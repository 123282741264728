import {
  ProductClass,
  ProductType,
  TravelType,
} from '@hotelplan/graphql.types';

export default function getFormattedTravelType(
  currentProductClass: ProductClass,
  currentTravelType: TravelType | ProductType
): string {
  const productMapping = {
    [TravelType.Package]: {
      [ProductClass.Hotel]: 'common:travelTypeDetailsPackage.hotel',
      [ProductClass.Roundtrip]: 'common:travelTypeDetailsPackage.roundtrip',
      [ProductClass.Cruise]: 'common:travelTypeDetailsPackage.cruise',
      [ProductClass.Apartment]: 'common:travelTypeDetailsPackage.apartment',
    },
    [TravelType.Hotel]: {
      [ProductClass.Hotel]: 'common:travelTypeDetails.hotel',
      [ProductClass.Roundtrip]: 'common:travelTypeDetails.roundtrip',
      [ProductClass.Cruise]: 'common:travelTypeDetails.cruise',
      [ProductClass.Apartment]: 'common:travelTypeDetails.apartment',
    },
  };

  if (
    productMapping[currentTravelType] &&
    productMapping[currentTravelType][currentProductClass]
  ) {
    return productMapping[currentTravelType][currentProductClass];
  }
}
