import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { LoginButton } from './LoginLink.styled';

interface ILoginLinkProps {
  iconName?: string;
}
export default function LoginLink({
  iconName,
}: ILoginLinkProps): React.ReactElement {
  const [t, { language }] = useTranslation('wishlist');

  const loginRoute = getRouteByPageTypeLocale(
    PageType.MyaccountLogin,
    language
  );
  const loginRouterProps = getTargetPageData({
    targetPageType: loginRoute.pageType,
    uri: loginRoute.builder({}),
  });

  const icon = iconName ? { name: iconName } : null;

  return (
    <RouterLink {...loginRouterProps} passHref>
      <LoginButton className={`register-button`} icon={icon}>
        <span className={'login-link-text'}>{t('register.button')}</span>
      </LoginButton>
    </RouterLink>
  );
}
