const WISHLIST_MAP_ZOOM = 14;
const WISHLIST_MAP_TYPE_CONTROL_POSITION = 11;
const WISHLIST_MAP_FULLSCREEN_CONTROL_POSITION = 3;

function useWishlistMapConfig() {
  return {
    zoom: WISHLIST_MAP_ZOOM,
    options: {
      mapTypeControlOptions: {
        position: WISHLIST_MAP_TYPE_CONTROL_POSITION,
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: WISHLIST_MAP_FULLSCREEN_CONTROL_POSITION,
      },
    },
  };
}

export default useWishlistMapConfig;
