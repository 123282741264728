import type { DocumentNode } from 'graphql';
import { useQuery } from '@hotelplan/libs.context.req-ctx';
import { normalizeDocumentNode } from '@hotelplan/libs.graphql';
import type { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';

export type SearchControlDataMapper<TGetValuesQuery> = (
  data: TGetValuesQuery
) => ISearchControlState | null;

export default function useSearchControlValues<
  TGetValuesQuery extends {},
  TGetValuesVariables
>(
  query: DocumentNode,
  variables: TGetValuesVariables,
  dataMapper: SearchControlDataMapper<TGetValuesQuery>,
  onCompleted?: (data: TGetValuesQuery) => void
) {
  const { data, loading } = useQuery<TGetValuesQuery, TGetValuesVariables>(
    normalizeDocumentNode(query),
    {
      notifyOnNetworkStatusChange: true,
      variables,
      onCompleted,
    }
  );

  return {
    loading,
    data: data ? dataMapper(data) : undefined,
  };
}
