import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useRenameWishlist } from 'components/domain/wishlist/hooks';
import {
  EditButton,
  EditInput,
  SubmitButton,
  WishlistEditInputWrapper,
} from './WishlistEditInput.styled';

interface IWishlistEditInputProps {
  currentName: string;
  currentWishlistId: string;
}

export default function WishlistEditInput({
  currentWishlistId,
  currentName,
}: IWishlistEditInputProps): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const [editInputValue, setEditInputValue] = useState<string>(currentName);
  const [editMode, startEdit, finishEdit] = useToggleState(false);
  const { renameWishlist } = useRenameWishlist();

  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  useOnClickOutside([inputRef, buttonRef], handleOnClickOutside, editMode);

  useEffect(
    function resetToCurrentName() {
      setEditInputValue(currentName);
    },
    [currentWishlistId]
  );

  function handleOnClickOutside() {
    setEditInputValue(currentName);
    finishEdit();
  }

  async function handleEditSubmit() {
    await renameWishlist(editInputValue, currentWishlistId);
    finishEdit();
  }

  return (
    <WishlistEditInputWrapper>
      <h1>{t('wishlist.current')}</h1>
      {editMode ? (
        <div className="section">
          <EditInput
            inputRef={inputRef}
            value={editInputValue}
            onChange={e => setEditInputValue(e.target.value)}
          />
          <SubmitButton ref={buttonRef} onClick={handleEditSubmit}>
            {'OK'}
          </SubmitButton>
        </div>
      ) : (
        <div className="section">
          <h3 className="name">{currentName}</h3>
          <EditButton onClick={startEdit} />
        </div>
      )}
    </WishlistEditInputWrapper>
  );
}
