import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type ProductFeatureItemFragment = {
  __typename?: 'ProductFeature';
  id: string;
  name: string;
  enabled?: boolean | null;
};

export const ProductFeatureItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFeatureItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductFeature' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
