let domainDE;
let domainFR;

if (typeof window === 'undefined') {
  domainDE = process.env.DOMAIN_DE;
  domainFR = process.env.DOMAIN_FR;
} else {
  const { default: getConfig } = require('next/config');
  const { publicRuntimeConfig: { DOMAIN_DE, DOMAIN_FR } = {} } = getConfig();
  domainDE = DOMAIN_DE;
  domainFR = DOMAIN_FR;
}

module.exports = { domainDE, domainFR };
