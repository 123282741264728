import { useTranslation } from 'next-i18next';
import React from 'react';
import { WishlistProductItemType } from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import FlightShortInfo from 'components/domain/orl/offerItem/FlightShortInfo';
import { mapOrlFlightToIFlightSectionProps } from 'components/domain/orl/Orl.mappers';
import { useReplaceOfferInActiveWishlist } from 'components/domain/wishlist/hooks';
import { WishlistCheckoutProductItemFragment } from 'graphql/wishlist/fragments/WishlistProductItemFragment.generated';
import {
  AcceptButton,
  FlightsWrapper,
  WishlistOverlayWrapper,
} from './WishlistItemChangedOverlay.styled';

export type WishlistOfferProduct = Pick<
  WishlistCheckoutProductItemFragment,
  'type' | 'offer' | 'changedOffer'
>;
interface IWishlistItemChangedOverlayProps<
  T extends WishlistOfferProduct = WishlistOfferProduct
> {
  item: T;
  onAccept?(item: T): void;
  className?: string;
}

export default function WishlistItemChangedOverlay<
  T extends WishlistOfferProduct
>({
  item,
  onAccept,
  className,
}: IWishlistItemChangedOverlayProps<T>): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const { loading, replaceOfferInActiveWishlist } =
    useReplaceOfferInActiveWishlist();

  async function handleAcceptChanges() {
    if (loading || !item.changedOffer) return;

    // proceed mutation offer replacement
    const offer = await replaceOfferInActiveWishlist(
      item.offer.id,
      item.changedOffer.id
    );

    if (!offer) return;

    onAccept?.({
      ...item,
      offer,
      type: WishlistProductItemType.Available,
      changedOffer: null,
    });
  }

  switch (item.type) {
    case WishlistProductItemType.Available:
      return null;
    case WishlistProductItemType.NotAvailable:
      return (
        <WishlistOverlayWrapper className={className} notAvailable>
          <div className="wishlist-overlay-title">
            {t('wishlist:overlay.notAvailable.title')}
          </div>
        </WishlistOverlayWrapper>
      );
    case WishlistProductItemType.FlightChanged:
      return (
        <WishlistOverlayWrapper className={className}>
          <div className="wishlist-overlay-title">
            {t('wishlist:overlay.flightChanged.title')}
          </div>
          <FlightInfo item={item} />
          <AcceptButton onClick={handleAcceptChanges}>
            {loading ? (
              // TODO: Add accept button skeleton
              <div>Loading...</div>
            ) : (
              t('wishlist:overlay.acceptChanges.button')
            )}
          </AcceptButton>
        </WishlistOverlayWrapper>
      );
    case WishlistProductItemType.PriceChanged:
      return (
        <WishlistOverlayWrapper className={className}>
          <div className="wishlist-overlay-title">
            {t('wishlist:overlay.priceChanged.title', {
              price: item.changedOffer?.totalPrice
                ? formatPrice(item.changedOffer.totalPrice)
                : 0,
            })}
          </div>
          <AcceptButton onClick={handleAcceptChanges}>
            {loading ? (
              // TODO: Add accept button skeleton
              <div>Loading...</div>
            ) : (
              t('wishlist:overlay.acceptChanges.button')
            )}
          </AcceptButton>
        </WishlistOverlayWrapper>
      );
    case WishlistProductItemType.FlightAndPriceChanged:
      return (
        <WishlistOverlayWrapper className={className}>
          <div className="wishlist-overlay-title">
            {t('wishlist:overlay.priceChanged.title', {
              price: item.changedOffer?.totalPrice
                ? formatPrice(item.changedOffer.totalPrice)
                : 0,
            })}
          </div>
          <div className="wishlist-overlay-title">
            {t('wishlist:overlay.flightChanged.title')}
          </div>
          <FlightInfo item={item} />
          <AcceptButton onClick={handleAcceptChanges}>
            {loading ? (
              // TODO: Add accept button skeleton
              <div>Loading...</div>
            ) : (
              t('wishlist:overlay.acceptChanges.button')
            )}
          </AcceptButton>
        </WishlistOverlayWrapper>
      );
    default:
      return null;
  }
}

function FlightInfo({
  item,
}: IWishlistItemChangedOverlayProps): React.ReactElement {
  const [t] = useTranslation('wishlist');

  return (
    <FlightsWrapper>
      {item.changedOffer?.outboundFlight && (
        <div>
          <p>{t('wishlist:flight.outward')}</p>
          <FlightShortInfo
            {...mapOrlFlightToIFlightSectionProps(
              item.changedOffer.outboundFlight
            )}
            className="flight-info"
          />
        </div>
      )}
      {item.changedOffer?.inboundFlight && (
        <div>
          <p>{t('wishlist:flight.return')}</p>
          <FlightShortInfo
            {...mapOrlFlightToIFlightSectionProps(
              item.changedOffer.inboundFlight
            )}
            className="flight-info"
          />
        </div>
      )}
    </FlightsWrapper>
  );
}
