import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { SiteToolsButtonsWrapper } from 'components/domain/sitetools';

export const WishlistButton = styled(SiteToolsButtonsWrapper).attrs({
  className: 'sitetool-item',
})<{
  isActive?: boolean;
}>(({ isActive }) =>
  isActive
    ? sx2CssThemeFn({
        '&:after': {
          bg: 'secondary',
        },
      })
    : null
);
