import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CheckboxGroupFilterValueFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterValue.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetGeoDepartureAirportsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetGeoDepartureAirportsQuery = {
  __typename?: 'Query';
  geo: {
    __typename?: 'GeoContext';
    filters: {
      __typename?: 'MainFilterComponent';
      mainFilter: {
        __typename?: 'FilterComponent';
        departureAirports: {
          __typename?: 'CheckboxFilterComponent';
          selected: Array<string>;
        };
      };
    };
  };
};

export const GetGeoDepartureAirportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGeoDepartureAirports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'departureAirports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CheckboxGroupFilterValue',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CheckboxGroupFilterValueFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetGeoDepartureAirportsQuery__
 *
 * To run a query within a React component, call `useGetGeoDepartureAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoDepartureAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoDepartureAirportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetGeoDepartureAirportsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGeoDepartureAirportsQuery,
    GetGeoDepartureAirportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGeoDepartureAirportsQuery,
    GetGeoDepartureAirportsQueryVariables
  >(GetGeoDepartureAirportsDocument, options);
}
export function useGetGeoDepartureAirportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeoDepartureAirportsQuery,
    GetGeoDepartureAirportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGeoDepartureAirportsQuery,
    GetGeoDepartureAirportsQueryVariables
  >(GetGeoDepartureAirportsDocument, options);
}
export type GetGeoDepartureAirportsQueryHookResult = ReturnType<
  typeof useGetGeoDepartureAirportsQuery
>;
export type GetGeoDepartureAirportsLazyQueryHookResult = ReturnType<
  typeof useGetGeoDepartureAirportsLazyQuery
>;
export type GetGeoDepartureAirportsQueryResult = Apollo.QueryResult<
  GetGeoDepartureAirportsQuery,
  GetGeoDepartureAirportsQueryVariables
>;
