export async function fetchHtml(
  url: string,
  origin?: string,
  domain?: string
): Promise<string> {
  const page = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: new Headers({
      Origin: origin,
      Host: domain,
    }),
  });

  return await page.text();
}

// Function to clean elements except those with specific data-ids
export function cleanElements<T extends HTMLElement>(
  elements: Array<T>,
  exceptions: Array<string> = []
): void {
  elements.forEach(el => {
    const dataId = el.getAttribute('data-id');

    // Skip elements with data-id in the exceptions list
    if (exceptions.includes(dataId)) {
      return;
    }
    el.remove();
  });
}

export function cleanupHtml(
  document: Document,
  tags: Array<string>,
  exceptions?: Array<string>
): void {
  // Remove elements with specific tags
  tags.forEach(function (tag) {
    const elements = Array.from(document.querySelectorAll(tag)).filter(
      Boolean
    ) as Array<HTMLElement>;

    cleanElements<HTMLElement>(elements, exceptions);
  });

  // Find the div with id '__next' and its parent
  const nextDiv = document.querySelector('#__next');
  const parentDiv = nextDiv.parentNode as HTMLElement;

  if (nextDiv && parentDiv) {
    // Replace the innerHTML of the parent with the innerHTML of the '__next' div
    parentDiv.innerHTML = nextDiv.innerHTML;
  }
}

export function convertPathToURL(path: string, domain: string): string {
  return `https://${domain}${path}`;
}
