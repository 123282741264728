import React from 'react';
import {
  EmptyWishlist,
  LoginLinkOverlay,
} from 'components/domain/wishlist/components';

// TODO: Remove this component after mein-konto release
export default function LegacyDefaultEmptyWishlist(): React.ReactElement {
  return (
    <LoginLinkOverlay>
      <EmptyWishlist />
    </LoginLinkOverlay>
  );
}
