import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { IMenuLink } from '@hotelplan/components.common.menu';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const MenuWrapper = styled.ul(
  sx2CssThemeFn({
    width: '100%',
    display: 'flex',
    flexFlow: ['row wrap', 'column'],
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    pt: ['12px', 0],
    pl: ['10px', 0],
    pr: ['30px', 0],
  })
);

const MenuSubItem = styled.li.attrs({
  ['data-id']: `footer-sub-link`,
})(
  sx2CssThemeFn({
    fontSize: ['12px', 0],
    color: 'primary',
    marginBottom: [2, '10px'],
    width: [null, '100%'],
    maxWidth: ['80px', '170px'],
    marginRight: [2, 0],
    '.icon': {
      width: '10px',
      height: '8px',
      pl: 1,
    },
  })
);

const DesktopMenuSection = styled.div(
  sx2CssThemeFn({
    width: '100%',
    borderBottom: '1px solid #ccc',
    py: 3,
    px: 2,
  })
);
const MenuMainItem = styled.div(
  sx2CssThemeFn({
    color: 'primary',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: '"Helvetica Neue", sans-serif',
    mb: 3,
  })
);

const SubItemsWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  })
);

const MenuLinkWrapper = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

interface IFooterMenu {
  links: IMenuLink[];
}

export default function FooterMenu(props: IFooterMenu) {
  const { mobile } = useDeviceType();
  const FooterMenuComponent = mobile ? MobileFooterMenu : DesktopFooterMenu;

  return <FooterMenuComponent {...props} />;
}

function DesktopFooterMenu({ links }: IFooterMenu): ReactElement {
  return (
    <MenuWrapper>
      {links.map((link, index) => {
        return (
          <DesktopMenuSection key={`section-${index}`}>
            <MenuMainItem style={{ color: link.fontColor }}>
              <MenuLink {...link} />
            </MenuMainItem>
            <SubItemsWrapper>
              {link.subLinks?.map((subLink, subIndex) => {
                return (
                  <MenuSubItem
                    key={`sub-link-${subIndex}`}
                    style={{ color: subLink.fontColor }}
                  >
                    <MenuLink {...subLink} />
                  </MenuSubItem>
                );
              })}
            </SubItemsWrapper>
          </DesktopMenuSection>
        );
      })}
    </MenuWrapper>
  );
}

function MobileFooterMenu({ links }: IFooterMenu): ReactElement {
  return (
    <MenuWrapper>
      {links.map((link, index) => {
        return (
          <MenuSubItem
            key={`footer-menu-sub-item-${index}`}
            style={{ color: link.fontColor }}
          >
            <MenuLink {...link} icon />
          </MenuSubItem>
        );
      })}
    </MenuWrapper>
  );
}

function MenuLink({
  link,
  target,
  label,
  icon,
}: IMenuLink & { icon?: boolean }): ReactElement {
  return (
    <RouterLink {...link} passHref>
      <MenuLinkWrapper target={target}>
        {label}
        {icon ? <Icon name="chevron-long-right" size="xs" /> : null}
      </MenuLinkWrapper>
    </RouterLink>
  );
}
