import React from 'react';
import { WishlistHeartIcon, WishlistIconWrapper } from './WishlistIcon.styled';

interface IWishlistIconProps {
  count?: number;
  isActive?: boolean;
  className?: string;
}

export default function WishlistIcon(
  props: IWishlistIconProps
): React.ReactElement {
  const { count, isActive, className } = props;

  return (
    <WishlistIconWrapper className={className} isActive={isActive}>
      <WishlistHeartIcon name="heart" isActive={isActive} />
      <span className="count">{count}</span>
    </WishlistIconWrapper>
  );
}
