import { TFunction } from 'i18next';
import { track } from '@hotelplan/libs.tracking-events';
import { TravelType } from '@hotelplan/platform-graphql-api';
import { IHomeSearchState } from 'components/domain/home/Home.types';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';

type TFilterOption = { caption: string; id: string };

const formatDate = (date: Date | null): string => {
  if (date) return date.toLocaleDateString('en-CA');
  return null;
};

const getSearchTerm = (
  t: TFunction,
  items: Array<{ canonicalName: string }>
): string => {
  return items.length > 1
    ? t('search:destination.count', { count: items.length })
    : items[0].canonicalName;
};

const getSearchAirports = (
  filterOptions: TFilterOption[],
  departureAirports: string[]
) => {
  const filterOptionsObject = filterOptions.reduce((acc, { id, caption }) => {
    return {
      ...acc,
      [id]: caption,
    };
  }, {});

  return departureAirports
    .map(value => {
      return filterOptionsObject[value];
    })
    .join(', ');
};

export const trackSwitchTab = (label: string, language: string) => {
  track({
    event: 'switch_tab',
    action: 'switch',
    label,
    category: undefined,
    component: 'tabs',
    position: 'search control',
    language,
    _clear: true,
  });
};

export const trackMoreDestinationsClick = (language: string) => {
  track({
    event: 'load_more',
    action: 'load more',
    label: 'Mehr Reiseziele',
    category: undefined,
    component: 'search-control',
    position: undefined,
    language,
    _clear: true,
  });
};

export const trackSearchSubmit = ({
  t,
  searchState,
  language,
  initialSearchControl,
  filterOptions,
  defaultDepartureAirports,
  isDestinationSelectedFromOverlay,
  gss,
}: {
  t: TFunction;
  searchState: IHomeSearchState;
  language: string;
  initialSearchControl: ISearchControlState;
  filterOptions: TFilterOption[];
  defaultDepartureAirports: string[];
  isDestinationSelectedFromOverlay: boolean;
  gss: ISearchControlState;
}) => {
  const isDefaultPax =
    searchState.searchControl.travelRooms.adults ===
      initialSearchControl.travelRooms.adults &&
    searchState.searchControl.travelRooms.rooms ===
      initialSearchControl.travelRooms.rooms &&
    !searchState.searchControl.travelRooms.childrenDobs.length;

  const isDefaultSearchDateDuration = gss?.travelDates?.duration?.duration
    ? gss?.travelDates?.duration.duration.join('-') ===
      searchState.searchControl.travelDates.duration.duration.join('-')
    : initialSearchControl.travelDates.duration.duration.join('-') ===
      searchState.searchControl.travelDates.duration.duration.join('-');

  const searchDate = searchState.searchControl.travelDates.returnDate
    ? `${formatDate(
        searchState.searchControl.travelDates.departureDate
      )}, ${formatDate(searchState.searchControl.travelDates.returnDate)}`
    : formatDate(searchState.searchControl.travelDates.departureDate);

  const isDefaultAirports = defaultDepartureAirports.every(
    value =>
      searchState.filters.departureAirports.includes(value) &&
      defaultDepartureAirports.length ===
        searchState.filters.departureAirports.length
  );

  track({
    event: 'search',
    action: 'run',
    label: 'Angebote anzeigen',
    category: undefined,
    component: 'search-control',
    position: 'hero-media-gallery',
    language,
    search_traveltype: searchState.searchControl.travelType.toLowerCase(),
    search_term: searchState.searchControl.travelDestination.destinations.length
      ? getSearchTerm(
          t,
          searchState.searchControl.travelDestination.destinations
        )
      : '',
    search_term_default:
      searchState.searchControl.travelDestination.destinations.length === 0,
    search_from_destination_list: isDestinationSelectedFromOverlay,
    search_airports:
      searchState.searchControl.travelType === TravelType.Hotel
        ? ''
        : getSearchAirports(
            filterOptions,
            searchState.filters.departureAirports
          ),
    search_airports_default: isDefaultAirports,
    search_date: searchDate,
    search_date_type:
      searchState.searchControl.travelDates.searchType.toLowerCase(),
    search_date_duration:
      searchState.searchControl.travelDates.duration.duration.join('-'),
    search_date_duration_default: isDefaultSearchDateDuration,
    search_room: searchState.searchControl.travelRooms.rooms.toString(),
    search_pax_adults: searchState.searchControl.travelRooms.adults.toString(),
    search_pax_children:
      searchState.searchControl.travelRooms.childrenDobs.length.toString(),
    search_pax_total: (
      searchState.searchControl.travelRooms.adults +
      searchState.searchControl.travelRooms.childrenDobs.length
    ).toString(),
    search_pax_default: isDefaultPax,
    _clear: true,
  });
};
