import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import SearchFilterInputField from './SearchFilterInputField';

const SubAmenitiesWrapper = styled.div(
  sx2CssThemeFn({
    width: '234px',
    border: '1px solid',
    borderColor: 'borderGray',
    mt: '-15px',
    mb: '-15px',
    '.checkboxes-area-wrapper': {
      gap: '0px',
      pl: '16px',
      '.label': {
        border: 'none',
        background: 'none',
        boxShadow: 'none',
        fontSize: 0,
        color: 'textGray',
        '&:has(input:disabled)': {
          opacity: 0.5,
          '&:hover': {
            cursor: 'initial',
          },
        },
      },
      '.checkbox-text': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '124px',
      },
    },
  })
);

const CloseInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchControlField
);

export default function SubAmenitiesFilterField({
  children,
}: PropsWithChildren): React.ReactElement {
  const [t] = useTranslation('filters');
  const [showSubAmenities, , close, toggle] = useToggleState(false);

  return (
    <>
      <SearchFilterInputField
        name={t('sub-amenities.field.title.open')}
        onClick={toggle}
        icon={{
          name: showSubAmenities ? 'minus' : 'plus',
        }}
      />
      {showSubAmenities ? (
        <>
          <SubAmenitiesWrapper className={'sub-amenities'}>
            {children}
          </SubAmenitiesWrapper>
          <CloseInput
            className={'sub-amenities-close-input'}
            value={t('sub-amenities.field.title.close')}
            onClick={close}
            icon={{
              name: 'minus',
            }}
          />
        </>
      ) : null}
    </>
  );
}
