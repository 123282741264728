import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import usePDPSearchValues from 'components/domain/pdp/usePDPSearchValues';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { useGetSrlMapCardsQuery } from 'graphql/srl/map/GetSrlMapCards.generated';
import { SrlMapProductItemFragment } from 'graphql/srl/map/SrlMapProductItem.generated';
import { SrlMapSingleResultFragment } from 'graphql/srl/map/SrlMapSingleResult.generated';

function cardIsProduct(
  card: SrlMapSingleResultFragment['items'][0]
): card is SrlMapProductItemFragment {
  return card.__typename === 'SrlProductItem';
}

const useMapProductCard = selectedProduct => {
  const {
    loading: searchValuesLoading,
    searchControl,
    filters,
  } = usePDPSearchValues();

  const { data: cardsData, loading: cardsLoading } = useGetSrlMapCardsQuery({
    skip: searchValuesLoading || !selectedProduct,
    variables: {
      searchControl: mapSearchControlToCriteria(searchControl),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
      productIds: [selectedProduct?.id],
    },
  });

  const allCards = cardsData?.srl.search.mapProductCards;
  let cards: SrlMapProductItemFragment[] | null = null;

  if (allCards?.__typename === 'SrlSingleResult') {
    cards = allCards.items.filter(cardIsProduct);
  }

  return { cards, loading: searchValuesLoading || cardsLoading };
};

export default useMapProductCard;
