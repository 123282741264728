import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const SRLMapFilterAccessWrapper = styled.div.attrs({
  className: 'srl-map-filter-access',
})(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    border: '1px solid',
    borderColor: 'borderGray',
    borderRadius: 'default',
    cursor: 'pointer',
  })
);

export const MapAccessTitle = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    p: 2,
    fontSize: 0,
    fontWeight: 'bold',
    color: 'defaultText',
    borderRadius: '5px 0px 0px 5px',
    '&:hover': {
      background: '#e6e6e6',
    },
  })
);

export const MapAccessBg = styled(Image).attrs({
  resized: [],
  src: '/images/map-bg.png',
  alt: 'map-bg',
})(
  sx2CssThemeFn({
    width: '100%',
    objectFit: 'cover',
    borderLeft: '1px solid',
    borderColor: 'borderGray',
    borderRadius: '0px 5px 5px 0px',
  })
);
