import { ReactElement } from 'react';
import { FeedbackModalWrapper } from 'components/domain/feedback-box/styles';

interface IFeedbackModalProps {
  show: boolean;
  href?: string;
  close?(): void;
}

export default function FeedbackModal({
  show,
  href,
  close,
}: IFeedbackModalProps): ReactElement {
  return (
    <FeedbackModalWrapper
      show={show}
      name="feedback-modal"
      title=""
      onClose={close}
    >
      {href ? <iframe src={href} title={href} /> : null}
    </FeedbackModalWrapper>
  );
}
