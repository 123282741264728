import React from 'react';
import styled from 'styled-components';
import { useField } from '@hotelplan/components.common.forms';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import FilterCounts from './FilterCounts';
import { ICommonFilterProps, TFilterCountsOptions } from './Filters.types';
import SearchFilterCheckboxesGroup from './SearchFilterCheckboxesGroup';

const SubAmenityBlock = styled.div(
  sx2CssThemeFn({
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderColor: 'borderGray',
    },
    pb: '10px',
  })
);
const SubAmenityHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    pt: 1,
    color: 'secondary',
  })
);
const SubAmenityIcon = styled(Icon)(
  sx2CssThemeFn({
    p: '6px',
  })
);
const SubAmenityTitle = styled.div(
  sx2CssThemeFn({
    ml: 2,
    fontSize: '15px',
    fontWeight: 'bold',
  })
);

const FilterCountsWrapper = styled(FilterCounts)(
  sx2CssThemeFn({
    position: 'static',
    ml: 'auto',
    transform: 'none',
    mr: 1,
    fontSize: '11px',
    span: {
      color: 'textGray',
      ml: 0,
    },
  })
);

export interface ISubAmenitiesGroupProps extends ICommonFilterProps {
  counts: TFilterCountsOptions;
  filter: string;
}

export default function SubAmenitiesGroup({
  filterCaption,
  formFieldName,
  options,
  icon,
  counts,
  filter,
}: ISubAmenitiesGroupProps): React.ReactElement {
  const [value, onChange] = useField<string[]>(formFieldName);

  if (!options.length) return null;

  return (
    <SubAmenityBlock data-id={filter}>
      <SubAmenityHeader>
        <SubAmenityIcon {...icon} />
        <SubAmenityTitle>{filterCaption}</SubAmenityTitle>
      </SubAmenityHeader>
      <SearchFilterCheckboxesGroup
        options={options}
        checkedOptions={value}
        onChange={(nextValue: string) => {
          value.includes(nextValue)
            ? onChange(value.filter(v => v !== nextValue))
            : onChange([...value, nextValue]);
        }}
        checkIfDisabled={id =>
          !Boolean(counts.options?.find(count => count.id === id)?.productCount)
        }
        getCounts={filterOptionId => (
          <FilterCountsWrapper
            options={counts.options}
            loading={counts.loading}
            filterOptionId={filterOptionId}
          />
        )}
      />
    </SubAmenityBlock>
  );
}
