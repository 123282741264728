import React, { PropsWithChildren } from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { RemoveIconContainer } from './RemoveIconContainer.styled';

interface IWishlistItemRemoveIconContainerProps {
  onRemove?(): void;
  className?: string;
}

export default function WishlistItemRemoveIconContainer({
  children,
  onRemove,
  className,
}: PropsWithChildren<IWishlistItemRemoveIconContainerProps>): React.ReactElement {
  return (
    <RemoveIconContainer className={className}>
      <Button
        variant={'iconBtn'}
        className={'trash-icon'}
        icon={{ name: 'bin', size: 'sm' }}
        onClick={onRemove}
      >
        Remove wishlist item
      </Button>
      {children}
    </RemoveIconContainer>
  );
}
