import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';

export const EmptyWishlistSectionWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    bg: 'white',
    borderRadius: ['0px', '5px 5px 0 0'],
    p: [2, 5],
    pt: 5,
  })
);

export const EmptyWishlistWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    bg: 'backgroundGray',
    border: '1px solid',
    borderColor: 'borderGray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['flex-start', 'center'],
    justifyContent: 'center',
    px: [2, 5],
    py: [5, 8],
    '.text': {
      fontSize: '18px',
      mb: [1, 3],
      fontWeight: 400,
    },
  })
);

export const EmptyWishlistCTALink = styled(PrimaryButtonLink)(
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      mt: 2,
      p: 2,
      width: 'fit-content',
      textTransform: 'uppercase',
      [media.tablet]: {
        mx: 'auto',
      },
    })
);
