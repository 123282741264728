import React, { useEffect } from 'react';

export default function useHover<T extends HTMLElement>(
  ref: React.MutableRefObject<T>,
  enter: () => void,
  leave: () => void
) {
  useEffect(function eventHandlers() {
    const domNode = ref?.current;

    if (domNode) {
      domNode.addEventListener('mouseenter', enter);
      domNode.addEventListener('mouseleave', leave);

      return function removeEventHandlers() {
        domNode.removeEventListener('mouseenter', enter);
        domNode.removeEventListener('mouseleave', leave);
      };
    }
  });
}
