import type { TFunction } from 'i18next';

export default function mapDurationToWeeks(
  duration: number,
  t: TFunction
): string {
  const weekDays = 7;
  return duration % weekDays === 0
    ? t('common:week.count', {
        count: Math.floor(duration / 7),
      })
    : t('common:nights.count', {
        count: duration,
      });
}
