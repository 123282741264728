import { OrlSortField, Status } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { mapFiltersToFormState } from 'components/domain/filters/Filters.mappers';
import usePDPSearchValues from 'components/domain/pdp/usePDPSearchValues';
import { OrlItemFragment } from 'graphql/fragments/ORLItem.generated';
import { useGetOrlSingleOffersQuery } from 'graphql/orl/GetORLSingleOffers.generated';
import { mapSearchControlStateToPdpSearchCriteriaInput } from './Orl.mappers';

interface IGetORLCheapestOffer {
  loading: boolean;
  offer: OrlItemFragment | null;
  status?: Status;
}

export default function useGetORLCheapestOffer(): IGetORLCheapestOffer {
  const { id } = useObjectId();

  const {
    filters,
    searchControl,
    loading: variablesLoading,
  } = usePDPSearchValues();

  const { data, previousData, loading } = useGetOrlSingleOffersQuery({
    variables: {
      id,
      pageNumber: 0,
      resultsPerPage: 50,
      sort: OrlSortField.Price,
      searchControl:
        mapSearchControlStateToPdpSearchCriteriaInput(searchControl),
      filters: mapFiltersToFormState(filters),
    },
    ssr: false,
    skip: variablesLoading,
    notifyOnNetworkStatusChange: true,
  });

  const result =
    data?.orl.searchResult.single || previousData?.orl.searchResult.single;

  const firstOffer = result?.offers?.[0] || null;

  return {
    loading,
    offer: firstOffer,
    status: result?.status,
  };
}
