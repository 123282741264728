import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import { LazyHydrate } from '@hotelplan/components.common.lazy-render';
import { SharingUrlProvider } from '@hotelplan/components.common.share-form';
import Footer from 'components/domain/footer/Footer';
import Header from 'components/domain/header/Header';
import CookiesDisclaimerBanner from 'components/domain/meta-modules/CookiesDisclaimerBanner';

const MetaModules = dynamic(
  () => import('components/domain/meta-modules/MetaModules'),
  { ssr: false }
);

const SVGSprite = () => {
  return (
    <div
      style={{ display: 'none' }}
      dangerouslySetInnerHTML={{
        __html: require('!!raw-loader!/public/images/sprite.svg?include')
          .default,
      }}
    />
  );
};
export default function Layout({
  children,
}: React.PropsWithChildren): React.ReactElement {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
      <SVGSprite />
      <CookiesDisclaimerBanner />
      <LazyHydrate whenIdle whenVisible>
        <MetaModules />
      </LazyHydrate>
      <SharingUrlProvider>
        <Header />
        <main>{children}</main>
        <Footer />
      </SharingUrlProvider>
    </>
  );
}
