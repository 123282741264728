import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ExternalLinkFragmentDoc } from './ExternalLink.generated';
import { InternalLinkFragmentFragmentDoc } from './InternalLink.generated';
export type LinkFragmentExternalLinkFragment = {
  __typename?: 'ExternalLink';
  openMethod: Types.OpenLinkMethod;
  url: string;
  caption?: string | null;
};

export type LinkFragmentInternalLinkFragment = {
  __typename?: 'InternalLink';
  caption?: string | null;
  uri: string;
  targetPageType: Types.PageType;
};

export type LinkFragmentFragment =
  | LinkFragmentExternalLinkFragment
  | LinkFragmentInternalLinkFragment;

export const LinkFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'linkFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Link' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExternalLink' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'externalLink' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InternalLink' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExternalLinkFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
