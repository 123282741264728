import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { WishlistIcon } from 'components/domain/wishlist/ui';

export const WishlistHeaderLink = styled(Link)<{ isActive?: boolean }>(
  sx2CssThemeFn({
    position: 'relative',
    p: '4px',
    height: '40px',
  })
);

export const WishlistIconWrapper = styled(WishlistIcon)<{
  isActive?: boolean;
  isAuthenticated?: boolean;
}>(({ isActive, isAuthenticated }) =>
  sx2CssThemeFn({
    width: '30px',
    height: '30px',
    '.icon': {
      color: 'white',
      zIndex: 1,
      width: '25px',
      height: '25px',
      stroke: isAuthenticated && isActive ? 'secondary' : 'none',
      strokeWidth: '2px',
    },
    '.count': {
      display: isActive ? 'block' : 'none',
      fontSize: '10px',
      fontFamily: 'Arial',
      fontWeight: 400,
      color: 'black',
      top: '47%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      bg: isActive && !isAuthenticated ? 'secondary' : 'backgroundDarkGray',
      borderRadius: '50%',
      zIndex: '0',
    },
    '&:hover': {
      '&:after': {
        bg: 'secondary',
      },
    },
  })
);

export const PinsLoader = styled.div(
  sx2CssThemeFn({
    width: '30px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '.pins-loader': {
      width: '40px',
      height: '40px',
    },
  })
);
