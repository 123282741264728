import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  DOB_INPUT_FORMAT,
  getOldestDOB,
} from '@hotelplan/components.common.dob-input';
import {
  DE_MONTHS,
  DE_WEEKDAYS_SHORT,
  FR_MONTHS,
  FR_WEEKDAYS_SHORT,
  UnionDatePicker,
} from '@hotelplan/components.common.travel-dates';
import { MAX_CHILD_AGE } from '@hotelplan/components.common.travel-rooms';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatDate } from '@hotelplan/libs.utils';

const DayPickerWrapper = styled.div(
  ({ theme }) => theme.travelDates.travelDatesDesktop,
  ({ theme }) => ({
    '.DayPicker-Weekdays': theme.travelDates.weekDaysListCss,
  }),
  sx2CssThemeFn({
    position: 'absolute',
    top: '100%',
    left: [null, '0px'],
    right: ['0px', 'unset'],
    minWidth: '315px',
    backgroundColor: '#fff',
    boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
    border: '1px solid #999',
    borderRadius: '5px',
    p: 2,
    pt: '14px',
    zIndex: '20',
    '.body': {
      mt: 0,
    },
  })
);

const today = new Date();

interface IDayPickerComponentProps {
  defaultDate: Date;
  onChange: (inputValue: string) => void;
  onClose: () => void;
}

export default function DayPickerComponent({
  defaultDate,
  onChange,
  onClose,
}: IDayPickerComponentProps): React.ReactElement {
  const [, { language }] = useTranslation();

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const unionDatePickerRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>([wrapperRef], onClose);

  const weekDaysShortForLanguage =
    language === 'fr' ? FR_WEEKDAYS_SHORT : DE_WEEKDAYS_SHORT;
  const monthsForLanguage = language === 'fr' ? FR_MONTHS : DE_MONTHS;

  return (
    <DayPickerWrapper ref={wrapperRef} className="dayPickerWrapper">
      <UnionDatePicker
        unionDatePickerRef={unionDatePickerRef}
        weekDaysShortForLanguage={weekDaysShortForLanguage}
        monthsForLanguage={monthsForLanguage}
        configuration={{
          hasCustomWeekDayElement: false,
          numberOfMonthsToShow: 1,
          canChangeMonth: true,
          hasNavbar: true,
          hasApplyBtn: true,
          changeYears: {
            enabled: true,
            minYear: getOldestDOB(MAX_CHILD_AGE).getFullYear(), // current year - max child age
            maxYear: today.getFullYear(), // current year
          },
          disablePrevMonth: false,
        }}
        departureDate={defaultDate}
        selectedDays={defaultDate}
        initialMonth={defaultDate}
        maxDate={new Date()}
        minDate={getOldestDOB(MAX_CHILD_AGE)}
        onDayClick={(day, { disabled }) => {
          if (disabled) return;
          onChange(formatDate(day, DOB_INPUT_FORMAT));
          onClose?.();
        }}
      />
    </DayPickerWrapper>
  );
}
