import React, { PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import { IconSize } from '@hotelplan/components.common.icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import SearchFilterDesktopDropdown from './SearchFilterDesktopDropdown';
import SearchFilterInputField from './SearchFilterInputField';

const SearchFilterInputWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
  })
);

interface ISearchFilterFieldProps {
  textInputPlaceholder: string;
  filterTitle?: string;
  onApply?(): void;
  className?: string;
  renderRatingSymbols?: (handlers?: { onClick(): void }) => React.ReactNode;
  icon?: {
    name: string;
    size?: IconSize;
  };
}

export default function SearchFilterField({
  textInputPlaceholder,
  filterTitle,
  onApply,
  children,
  renderRatingSymbols,
  className,
  icon,
}: PropsWithChildren<ISearchFilterFieldProps>): React.ReactElement {
  const { mobile } = useDeviceType();
  const [isFilterOpened, , close, toggle] = useToggleState(false);

  const textInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement | HTMLInputElement>(
    [containerRef, textInputRef],
    close,
    isFilterOpened
  );

  return (
    <SearchFilterInputWrapper className={`${className} filter-input-wrapper`}>
      {renderRatingSymbols?.({ onClick: toggle })}
      <SearchFilterInputField
        textInputRef={textInputRef}
        name={textInputPlaceholder}
        onClick={toggle}
        icon={icon}
      />
      {!mobile ? (
        <SearchFilterDesktopDropdown
          dropdownRef={dropdownRef}
          containerRef={containerRef}
          isShown={isFilterOpened}
          title={filterTitle}
          onClose={close}
          onApply={() => {
            onApply?.();
            close();
          }}
        >
          {children}
        </SearchFilterDesktopDropdown>
      ) : (
        <>{isFilterOpened ? <> {children} </> : null}</>
      )}
    </SearchFilterInputWrapper>
  );
}
