import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { SwitchButton } from '@hotelplan/components.common.buttons';
import { useField } from '@hotelplan/components.common.forms';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { PageType, TravelType } from '@hotelplan/platform-graphql-api';
import { trackSwitchTab } from 'components/domain/search-control/tracking/searchControlTracking';

const SwitchButtonWrapper = styled(SwitchButton)(
  sx2CssThemeFn({
    label: {
      justifyContent: ['center', 'flex-start'],
      borderRadius: 'default',
      background: 'none',
      border: 'none',
    },
  })
);

interface ITravelTypeFieldProps {
  labelFirstSeparator?: string;
}

export default function TravelTypeField({
  labelFirstSeparator,
}: ITravelTypeFieldProps): React.ReactElement {
  const [t, { language }] = useTranslation('search');
  const pageType = usePageType<PageType>();
  const [activeType, setActiveType] = useField<TravelType | null>('travelType');
  return (
    <SwitchButtonWrapper
      testId="travelTypeSwitcher"
      name="travelType"
      className="travel-type-switcher"
      valueFirst={TravelType.Package}
      valueSecond={TravelType.Hotel}
      checkedValue={activeType}
      labelFirst={
        labelFirstSeparator
          ? t('travel.type.package').replace(/\+/g, labelFirstSeparator)
          : t('travel.type.package')
      }
      labelSecond={t('travel.type.hotel')}
      onCheck={value => {
        setActiveType(value as TravelType);
        if (pageType === PageType.Home || pageType === PageType.Srl)
          trackSwitchTab(
            value === TravelType.Package
              ? t('travel.type.package', { lng: 'de' })
              : t('travel.type.hotel', { lng: 'de' }),
            language
          );
      }}
    />
  );
}
