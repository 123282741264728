import { useTranslation } from 'next-i18next';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';

type TTooltipInfo = {
  title: string;
  description: string;
};

export default function useSearchControlTooltip(): TTooltipInfo {
  const [t] = useTranslation(['search', 'wishlist']);
  const pageType = usePageType<PageType>();

  const defaultTooltipInfo: TTooltipInfo = {
    title: t('search:search.control.tooltip.title'),
    description: t('search:search.control.tooltip.text'),
  };

  switch (pageType) {
    case PageType.WishlistCompare:
      return {
        title: t('wishlist:search.control.tooltip.title'),
        description: t('wishlist:search.control.tooltip.text'),
      };
    default:
      return defaultTooltipInfo;
  }
}
