import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import useGetORLCheapestOffer from 'components/domain/orl/useGetORLCheapestOffer';
import {
  useWishlistOffer,
  useWishlistProductActiveOffer,
} from 'components/domain/wishlist/hooks';
import { WishlistButton } from './ProductWishlistButton.styled';

export default function ProductWishlistButton(): React.ReactElement {
  const { id: productId } = useObjectId();

  const { offer: activeOffer, loading: activeLoading } =
    useWishlistProductActiveOffer(productId);

  const { offer: cheapest, loading: cheapestLoading } =
    useGetORLCheapestOffer();

  const offerId = activeOffer?.id || cheapest?.id;

  const {
    isActive,
    onToggle,
    loading: updateLoading,
  } = useWishlistOffer(offerId || '');

  const loading = offerId
    ? activeLoading || updateLoading
    : activeLoading || cheapestLoading;

  if (!loading && !offerId) {
    return null;
  }

  return (
    <WishlistButton
      isActive={isActive}
      onClick={loading ? undefined : onToggle}
    >
      <Icon className="wishlist-icon" name={'heart'} />
    </WishlistButton>
  );
}
