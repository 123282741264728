import { useTranslation } from 'next-i18next';
import React from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { TravelType } from '@hotelplan/graphql.types';
import { DepartureAirportsCheckboxesFilter } from './DepartureAirportsCheckboxesFilter';
import { DepartureAirportsFilter } from './DepartureAirportsFilter';
import {
  ANY_BOARD_TYPE_ID,
  ANY_ROOM_TYPE_ID,
  HP_ANY_RATING,
  TA_ANY_RATING,
} from './Filters.common';
import {
  IFilterOption,
  TFilterCountsOptions,
  TFilterOptionName,
  TFlightOptions,
} from './Filters.types';
import FlightOptionsFilterField from './FlightOptionsFilterField';
import MaxPriceFilterField from './MaxPriceFilterField';
import ProductFeaturesFilterField from './ProductFeaturesFilterField';
import UnionCheckboxesFilterField from './UnionCheckboxFilterField';
import UnionRadioFilterField from './UnionRadioFilterField';

type TRenderFilterProps = {
  filterCaption: string;
  filterOptions?: IFilterOption[];
  flightOptions?: TFlightOptions;
  counts?: TFilterCountsOptions;
  secondVariant?: boolean;
};

interface IUseSearchFilters {
  render: (
    filterProps: TRenderFilterProps,
    index?: number
  ) => React.ReactElement;
}

export function useSearchFilters(): {
  [key in TFilterOptionName]?: IUseSearchFilters;
} {
  const [t] = useTranslation('filters');
  const [travelType] = useField('travelType');

  return {
    departureAirports: {
      render({ filterCaption, filterOptions, secondVariant }, index) {
        if (travelType === TravelType.Hotel) return null;

        if (secondVariant) {
          return (
            <DepartureAirportsCheckboxesFilter
              key={`departureAirports-${index}`}
              filterOptions={filterOptions}
            />
          );
        }

        return (
          <DepartureAirportsFilter
            key={`departureAirports-${index}`}
            filterCaption={filterCaption}
            filterOptions={filterOptions}
          />
        );
      },
    },
    roomTypes: {
      render({ filterCaption, filterOptions, counts }, index) {
        return (
          <UnionCheckboxesFilterField
            key={`roomTypes-${index}`}
            formFieldName={'roomTypes'}
            filterCaption={filterCaption}
            options={filterOptions}
            filterTitle={t('room.types.title')}
            allOptionId={ANY_ROOM_TYPE_ID}
            className={'room-types'}
            icon={{ name: 'room-type' }}
            counts={counts}
          />
        );
      },
    },
    hpRating: {
      render({ filterCaption, filterOptions, counts }, index) {
        return (
          <UnionRadioFilterField
            key={`hpRating-${index}`}
            formFieldName={'hpRating'}
            filterCaption={filterCaption}
            options={filterOptions}
            coloredIcon={{ name: 'star-full' }}
            emptyIcon={{ name: 'star-blank' }}
            filterTitle={t('hp.rating.title')}
            className={'hp-rating'}
            icon={{ name: 'star-full' }}
            allOptionId={HP_ANY_RATING}
            counts={counts}
          />
        );
      },
    },
    taRating: {
      render({ filterCaption, filterOptions, counts }, index) {
        return (
          <UnionRadioFilterField
            key={`taRating-${index}`}
            formFieldName={'taRating'}
            filterCaption={filterCaption}
            options={filterOptions}
            coloredIcon={{ name: 'tripadvisorone' }}
            emptyIcon={{ name: 'tripadvisorempty' }}
            filterTitle={t('ta.rating.title')}
            className={'ta-rating'}
            icon={{ name: 'tripadvisor-bird' }}
            allOptionId={TA_ANY_RATING}
            counts={counts}
          />
        );
      },
    },
    boardTypes: {
      render({ filterCaption, filterOptions, counts }, index) {
        return (
          <UnionCheckboxesFilterField
            key={`boardTypes-${index}`}
            formFieldName={'boardTypes'}
            filterCaption={filterCaption}
            options={filterOptions}
            filterTitle={t('board.types.title')}
            allOptionId={ANY_BOARD_TYPE_ID}
            className={'board-types'}
            icon={{ name: 'utensils-solid' }}
            counts={counts}
          />
        );
      },
    },
    maxPrice: {
      render({ filterCaption }, index) {
        return (
          <MaxPriceFilterField
            key={`maxPrice-${index}`}
            formFieldName={'maxPrice'}
            filterCaption={filterCaption}
            filterTitle={t('max.price.title')}
            className={'max-price'}
            icon={{ name: 'credit-card-regular' }}
          />
        );
      },
    },
    flightOptions: {
      render({ filterCaption: captions, flightOptions }, index) {
        if (travelType === TravelType.Hotel) return null;
        return (
          <FlightOptionsFilterField
            key={`flightOptions-${index}`}
            formFieldName={'flightOptions'}
            options={flightOptions}
            filterTitle={t('flight.options.title')}
            filterCaption={t('flight.options.caption')}
            className={'flight-options'}
            fieldsCaptions={captions as unknown as string[]}
            icon={{ name: 'exchange-alt-solid' }}
          />
        );
      },
    },
    productFeatures: {
      render({ filterCaption, filterOptions, counts }, index) {
        return (
          <ProductFeaturesFilterField
            key={`productFeatures-${index}`}
            formFieldName={'productFeatures'}
            filterCaption={filterCaption}
            options={filterOptions}
            counts={counts}
            filterTitle={t('product.features.title')}
            className={'product-features'}
          />
        );
      },
    },
  };
}
