import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const SRLProductsSkeletonItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 412, h: 115 }
    : { w: 690, h: 205 };

  return (
    <div
      style={{
        marginBottom: '20px',
        backgroundColor: 'white',
        height: height,
      }}
    >
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        {mobile ? (
          <>
            <rect x="2" y="3" width="189" height="118" />
            <rect width="1" height="123" />
            <rect x="413" width="1" height="123" />
            <rect width="414" height="1" />
            <rect y="122" width="414" height="1" />
            <rect x="196" y="3" width="9" height="9" rx="4.5" />
            <rect x="208" y="3" width="9" height="9" rx="4.5" />
            <rect x="219" y="3" width="9" height="9" rx="4.5" />
            <rect x="232" y="3" width="9" height="9" rx="4.5" />
            <rect x="218" y="35" width="11" height="11" rx="5.5" />
            <rect x="230" y="35" width="11" height="11" rx="5.5" />
            <rect x="242" y="35" width="11" height="11" rx="5.5" />
            <rect x="254" y="35" width="11" height="11" rx="5.5" />
            <rect x="267" y="35" width="11" height="11" rx="5.5" />
            <path d="M398 8C398 4.68629 400.686 2 404 2H405C408.314 2 411 4.68629 411 8V8C411 11.3137 408.314 14 405 14H404C400.686 14 398 11.3137 398 8V8Z" />
            <rect x="195" y="35" width="19" height="12" rx="6" />
            <rect x="208" y="108" width="81" height="10" rx="5" />
            <rect x="195" y="20" width="149" height="10" rx="5" />
            <rect x="196" y="80" width="104" height="12" rx="6" />
            <rect x="196" y="56" width="122" height="8" rx="4" />
            <rect x="196" y="69" width="141" height="7" rx="3.5" />
            <rect x="196" y="97" width="85" height="7" rx="3.5" />
            <rect x="197" y="111" width="9" height="7" rx="3.5" />
            <rect x="329" y="107" width="82" height="14" />
          </>
        ) : (
          <>
            <rect x="4" y="5" width="314" height="196" />
            <rect width="1" height="205" />
            <rect x="689" width="1" height="205" />
            <rect width="690" height="1" />
            <rect y="204" width="690" height="1" />
            <rect x="326" y="5" width="15" height="15" rx="7" />
            <rect x="346" y="5" width="16" height="15" rx="7" />
            <rect x="365" y="5" width="15" height="15" rx="7" />
            <rect x="386" y="5" width="15" height="15" rx="7" />
            <rect x="363" y="58" width="18" height="19" rx="9" />
            <rect x="383" y="58" width="18" height="19" rx="9" />
            <rect x="404" y="58" width="18" height="19" rx="9" />
            <rect x="424" y="58" width="18" height="19" rx="9" />
            <rect x="445" y="58" width="19" height="19" rx="9.5" />
            <path d="M663 11C663 7.13401 666.134 4 670 4H678C681.866 4 685 7.13401 685 11V17C685 20.866 681.866 24 678 24H670C666.134 24 663 20.866 663 17V11Z" />
            <rect x="325" y="58" width="32" height="20" rx="7" />
            <rect x="346" y="180" width="136" height="17" rx="7" />
            <rect x="325" y="33" width="249" height="17" rx="7" />
            <rect x="326" y="134" width="174" height="20" rx="7" />
            <rect x="326" y="94" width="204" height="12" rx="6" />
            <rect x="326" y="115" width="235" height="12" rx="6" />
            <rect x="326" y="162" width="143" height="12" rx="6" />
            <rect x="328" y="185" width="16" height="12" rx="6" />
            <rect x="548" y="178" width="137" height="23" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

interface ISRLProductsSkeletonProps {
  count: number;
}

const SRLProductsSkeleton: React.FC<ISRLProductsSkeletonProps> = (
  props: ISRLProductsSkeletonProps
) => {
  const { count } = props;
  return (
    <>
      {Array.from({ length: count }, (_, i) => (
        <div key={i}>
          <SRLProductsSkeletonItem />
        </div>
      ))}
    </>
  );
};

export default SRLProductsSkeleton;
