import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import GeoDestinationsOverlaySkeleton from './GeoDestinationsOverlaySkeleton.skeleton';

const GeoDestinationsModal = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      width: '100%',
      maxWidth: ['100%', '980px'],
      height: ['98vh', 'auto'],
      overflowY: ['auto', 'hidden'],
    },
    '.modal-body': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: [null, '650px'],
      overflowY: ['auto', 'hidden'],
    },
    '.modal-header': {
      border: 'none',
      '.modal-title': {
        fontSize: ['20px', '26px'],
        fontWeight: 'bold',
        color: 'secondary',
      },
    },
    '.content': {
      width: '100%',
      display: ['flex', 'block'],
      flexDirection: 'column',
      height: [null, '100%'],
      overflowY: [null, 'auto'],
      flex: 1,
    },
  })
);

interface IGeoDestinationsOverlayProps {
  show: boolean;
  loading?: boolean;
  close?: () => void;
}

export default function GeoDestinationsOverlay({
  show,
  loading,
  close,
  children,
}: PropsWithChildren<IGeoDestinationsOverlayProps>): React.ReactElement {
  const [t] = useTranslation('search');

  return (
    <GeoDestinationsModal
      title={t('destination.groups.title')}
      name={'geo-destinations-modal'}
      show={show}
      onClose={close}
      fullScreen={true}
    >
      {loading ? (
        <GeoDestinationsOverlaySkeleton />
      ) : (
        <div className={'content'}>{children}</div>
      )}
    </GeoDestinationsModal>
  );
}
