import hoistNonReactStatics from 'hoist-non-react-statics';
import type { AppProps as NextJsAppProps } from 'next/app';
import React, { PropsWithChildren } from 'react';
import { FooterConfigContext } from './FooterConfig.context';
import { getFooterConfigPageProps } from './FooterConfig.prop';

type AppProps = NextJsAppProps;

export default function appWithFooterConfig(
  WrappedAppComponent:
    | React.ComponentType<AppProps>
    | React.ElementType<AppProps>
) {
  function AppWithFooterConfig(props: PropsWithChildren<AppProps>) {
    const footerConfig = getFooterConfigPageProps(props.pageProps);
    const { hideNewsletterBlock } = footerConfig || {};

    return (
      <FooterConfigContext.Provider
        value={{
          hideNewsletterBlock,
        }}
      >
        <WrappedAppComponent {...props} />
      </FooterConfigContext.Provider>
    );
  }

  return hoistNonReactStatics(AppWithFooterConfig, WrappedAppComponent);
}
