import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { WishlistOfferRoomFragmentDoc } from './WishlistOfferRoomFragment.generated';
import { FlightItemFragmentDoc } from 'graphql/fragments/FlightItem.generated';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import { WishlistItemFragmentDoc } from './WishlistItemFragment.generated';
export type WishlistOfferFragment = {
  __typename?: 'WishlistOffer';
  id: string;
  duration: number;
  returnDate: string;
  travelType: Types.TravelType;
  departureDate: string;
  transferIncluded: boolean;
  waitingScreenImage?: string | null;
  rooms: Array<{
    __typename?: 'WishlistOfferRoom';
    adults: number;
    children: number;
    boardCode: string;
    boardDescription: string;
    roomCode: string;
    roomDescription: string;
  }>;
  inboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  outboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
  wishlistItem?: {
    __typename?: 'WishlistItem';
    id: string;
    inWishlist: boolean;
  } | null;
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export type WishlistOfferCheckoutPropsFragment = {
  __typename?: 'WishlistOffer';
  provider?: string | null;
  productCode?: string | null;
  waitingScreenImage?: string | null;
  flightAlternativesAvailable?: boolean | null;
};

export type WishlistCheckoutOfferFragment = {
  __typename?: 'WishlistOffer';
  id: string;
  duration: number;
  returnDate: string;
  travelType: Types.TravelType;
  departureDate: string;
  transferIncluded: boolean;
  waitingScreenImage?: string | null;
  provider?: string | null;
  productCode?: string | null;
  flightAlternativesAvailable?: boolean | null;
  rooms: Array<{
    __typename?: 'WishlistOfferRoom';
    adults: number;
    children: number;
    boardCode: string;
    boardDescription: string;
    roomCode: string;
    roomDescription: string;
  }>;
  inboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  outboundFlight?: {
    __typename?: 'Flight';
    departureTime: any;
    arrivalTime: any;
    duration: string;
    arrivalDate: any;
    departureDate: any;
    overnight: boolean;
    departure: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    arrival: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    };
    segments: Array<{
      __typename?: 'FlightSegment';
      airportWaiting?: string | null;
      arrivalDate: any;
      arrivalTime: any;
      departureDate: any;
      departureTime: any;
      flightClass: Types.FlightClass;
      flightNumber: string;
      segmentDuration: string;
      airline: {
        __typename?: 'Airline';
        airlineLogo: string;
        iata: string;
        name: string;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
    }>;
  } | null;
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
  wishlistItem?: {
    __typename?: 'WishlistItem';
    id: string;
    inWishlist: boolean;
  } | null;
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export const WishlistOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistOfferRoom' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inboundFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outboundFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlistItem' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waitingScreenImage' },
          },
        ],
      },
    },
    ...WishlistOfferRoomFragmentDoc.definitions,
    ...FlightItemFragmentDoc.definitions,
    ...PriceFragmentDoc.definitions,
    ...WishlistItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const WishlistOfferCheckoutPropsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistOfferCheckoutProps' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waitingScreenImage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAlternativesAvailable' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WishlistCheckoutOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistCheckoutOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'wishlistOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'wishlistOfferCheckoutProps' },
          },
        ],
      },
    },
    ...WishlistOfferFragmentDoc.definitions,
    ...WishlistOfferCheckoutPropsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
