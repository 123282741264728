import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistOverlayWrapper = styled.div<{ notAvailable?: boolean }>(
  ({ notAvailable }) =>
    sx2CssThemeFn({
      position: 'absolute',
      top: ['67px', '85px'],
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      p: 3,
      textAlign: 'center',
      bg: notAvailable ? '#CE4B4BD9' : '#FD8300D9',
      '.wishlist-overlay-title': {
        fontWeight: 'bold',
        marginBottom: 3,
        color: 'white',
      },
    })
);

export const AcceptButton = styled(Button)(
  sx2CssThemeFn({
    bg: 'white',
    color: 'primary',
  })
);

export const FlightsWrapper = styled.div.attrs({
  className: 'flights',
})(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: `space-evenly`,
    flexDirection: 'row',
    textAlign: 'left',
    color: 'white',
    mb: 2,
    '.flight-info': {
      mb: 3,
      '.departure-airport, .departure-date, .time-info': {
        fontSize: 2,
      },
    },
  })
);
