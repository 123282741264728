import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SrlMapProductItemFragmentDoc } from './SrlMapProductItem.generated';
export type SrlMapSingleResultFragment = {
  __typename?: 'SrlSingleResult';
  status: Types.Status;
  message?: string | null;
  items: Array<
    | { __typename?: 'NudgeItem' }
    | {
        __typename?: 'SrlProductItem';
        giata: string;
        hpRating?: number | null;
        taRating?: number | null;
        type: Types.ProductType;
        duration: number;
        mealType: string;
        name: string;
        offerId: string;
        transferIncluded?: boolean | null;
        taReviews?: number | null;
        mapZoom: number;
        fromTo?: string | null;
        travelType?: string | null;
        productClass: Types.ProductClass;
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        featureSummary: Array<{
          __typename?: 'ProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'ProductFeature';
            id: string;
            name: string;
            enabled?: boolean | null;
          }>;
        }>;
        price: { __typename?: 'Price'; amount: string; currency: string };
        transferPrice?: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        } | null;
        coordinates: {
          __typename?: 'GeoCoordinates';
          latitude: number;
          longitude: number;
        };
      }
  >;
};

export const SrlMapSingleResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapSingleResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlSingleResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlMapProductItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlMapProductItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
