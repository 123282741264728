import hoistNonReactStatics from 'hoist-non-react-statics';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import {
  LOCAL_STORAGE_QUERY_PARAMS,
  SEARCH_QUERY_DEFAULT_PARAM,
  SEARCH_QUERY_ORIGIN_PARAM,
} from './constants';
import {
  addQueryParamToLocalStorage,
  getPageType,
  parseURL,
} from './search-query.utils';

const PDP_TABS: Array<PageType> = [
  PageType.Pdp,
  PageType.PdpClimate,
  PageType.PdpDescription,
  PageType.PdpMap,
  PageType.Orl,
  PageType.PdpRating,
  PageType.PdpResort,
  PageType.PdpRegion,
];

const PAGES_WITH_SRL: Array<PageType> = [
  PageType.Srl,
  PageType.ThemeSrl,
  PageType.GeoSrl,
];

export default function appWithSavedSearchParam(
  WrappedAppComponent:
    | React.ComponentType<AppProps>
    | React.ElementType<AppProps>
) {
  function AppWithSavedSearchParam(props: AppProps): React.ReactElement {
    const { events, locale } = useRouter();

    useEffect(() => {
      function handleRouteChange(url: string) {
        if (typeof window !== 'undefined' && window.history.state) {
          const pageType = getPageType(window.history.state, locale);

          // remove query params from local storage if page is not SRL or PDP
          if (
            !PAGES_WITH_SRL.includes(pageType) &&
            !PDP_TABS.includes(pageType)
          ) {
            localStorage.removeItem(LOCAL_STORAGE_QUERY_PARAMS);
          }

          // remove origin search query from session storage if page is not PDP
          if (!PDP_TABS.includes(pageType)) {
            sessionStorage.removeItem(SEARCH_QUERY_ORIGIN_PARAM);
          }

          // remove search query from local storage if page is SRL and search query is empty
          if (PAGES_WITH_SRL.includes(pageType)) {
            const params = parseURL(url);

            if (
              !Boolean('s' in params && params['s']) &&
              localStorage.getItem(LOCAL_STORAGE_QUERY_PARAMS)
            ) {
              localStorage.removeItem(LOCAL_STORAGE_QUERY_PARAMS);
            }
          }

          const currentParams = parseURL(url);
          const originSearchQuery =
            currentParams?.[SEARCH_QUERY_DEFAULT_PARAM] || undefined;

          if (PAGES_WITH_SRL.includes(pageType) && originSearchQuery) {
            addQueryParamToLocalStorage({
              [SEARCH_QUERY_ORIGIN_PARAM]: originSearchQuery,
            });
          }
        }
      }

      // handle initial route in case if initial page is SRL
      handleRouteChange(window.location.href);

      events.on('routeChangeComplete', handleRouteChange);
      return () => {
        events.off('routeChangeComplete', handleRouteChange);
      };
    }, []);

    return <WrappedAppComponent {...props} />;
  }

  return hoistNonReactStatics(AppWithSavedSearchParam, WrappedAppComponent);
}
