import findLast from 'lodash/findLast';
import React from 'react';
import styled from 'styled-components';
import { PinCluster } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

interface IClusterPinStyle {
  minThreshold: number;
  imageSize: number;
  imageUrl: string;
}

const clusterPinStyles: IClusterPinStyle[] = [
  {
    minThreshold: 0,
    imageSize: 53,
    imageUrl: '/images/marker-clusterer/m1.png',
  },
  {
    minThreshold: 10,
    imageSize: 56,
    imageUrl: '/images/marker-clusterer/m2.png',
  },
  {
    minThreshold: 100,
    imageSize: 66,
    imageUrl: '/images/marker-clusterer/m3.png',
  },
  {
    minThreshold: 250,
    imageSize: 78,
    imageUrl: '/images/marker-clusterer/m4.png',
  },
  {
    minThreshold: 500,
    imageSize: 90,
    imageUrl: '/images/marker-clusterer/m5.png',
  },
];

const getClusterPinStyle = (size: number) => {
  return findLast(clusterPinStyles, style => style.minThreshold < size);
};

const PinContent = styled.div<{ size: number }>(
  sx2CssThemeFn(({ size }) => {
    const style = getClusterPinStyle(size);
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: style.imageSize,
      height: style.imageSize,
      backgroundImage: `url(${style.imageUrl})`,
    };
  })
);

const PinText = styled.span(
  sx2CssThemeFn({
    fontWeight: 'bold',
    fontSize: '11px',
    color: 'black',
  })
);

function renderPinContent(cluster?: PinCluster): React.ReactNode {
  const size = cluster?.getSize();
  return (
    <PinContent size={size}>
      <PinText>{size}</PinText>
    </PinContent>
  );
}

export default renderPinContent;
