import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { PageType, TravelType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useLazyQuery } from '@hotelplan/libs.context.req-ctx';
import { useGSSContext } from '@hotelplan/libs.gss';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { useSearchState } from '@hotelplan/libs.search-state';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import {
  mapSearchControlFormValuesToSearchState,
  mapSearchControlToCriteria,
} from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { ISRLFormState, ISrlState } from 'components/domain/srl/SRL.types';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import {
  GetSrlToSrlUrlDocument,
  GetSrlToSrlUrlQuery,
  GetSrlToSrlUrlQueryVariables,
} from 'graphql/srl/GetSrlToSrlUrl.generated';
import { useDefaultSearchStateByPageType } from './useDefaultSearchState';

export default function useUpdateSearchState() {
  const { push } = useRouter();
  const { id } = useObjectId();
  const { setGSS } = useGSSContext<ISearchControlState>();
  const pageType = usePageType<PageType>();

  const {
    state: { searchControl, filters },
    setState,
  } = useSearchState<ISrlState>();

  const { initialSearchControl } = useDefaultSearchStateByPageType(pageType)(
    id || null
  );

  const [getSrlUrl] = useLazyQuery<
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >(GetSrlToSrlUrlDocument, {
    onCompleted: urlData => {
      const url = urlData.srl.link;
      const { as, href, locale = null } = getTargetPageData(url);
      push(href, as, {
        locale,
        shallow: true,
        scroll: false,
      });
    },
  });

  const updateSearchState = useCallback(
    function (nextState: ISRLFormState, callback?: () => void) {
      const nextSearchState =
        mapSearchControlFormValuesToSearchState(nextState);

      setGSS(initialSearchControl, nextSearchState.searchControl);

      // If travel destination changed, we need to go to the SRL page in case if we are not on the SRL page
      if (
        !isEqual(
          nextState.travelDestination,
          searchControl.travelDestination
        ) &&
        pageType !== PageType.Srl
      ) {
        const { searchControl: nextSearchControl, filters: nextFilters } =
          nextSearchState;

        getSrlUrl({
          variables: {
            searchControl: mapSearchControlToCriteria(nextSearchControl),
            filters: mapSrlFilterValuesToSrlFilterCriteriaInput(nextFilters),
          },
        });
        return;
      }

      setState(prevState => ({
        ...prevState,
        ...nextSearchState,
      }));

      callback?.();
    },
    [searchControl.travelDestination, pageType]
  );

  const handleAlternativeUrl = useCallback(
    function (altTravelType: TravelType, altUrl: InternalLinkFragmentFragment) {
      if (altUrl) {
        const { as, href, locale = null } = getTargetPageData(altUrl);

        setGSS(initialSearchControl, {
          ...searchControl,
          travelType: altTravelType,
        });

        push(href, as, { locale });
        return;
      }
    },
    [push, setState]
  );

  const initialValues = useMemo(
    () => ({
      ...searchControl,
      ...filters,
    }),
    [searchControl, filters]
  );

  return useMemo(
    () => ({
      updateSearchState,
      handleAlternativeUrl,
      initialValues,
    }),
    [initialValues, updateSearchState]
  );
}
