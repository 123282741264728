import { useTranslation } from 'next-i18next';
import React from 'react';
import { Price } from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import { BestsellerProductIcon } from './BestsellerPinIcon';
import { AnimatedWrapper, ProductPinContent } from './MapProductPin.styled';

interface IMapAlternativeHotelPinContentProps {
  isBestseller?: boolean;
  price?: Omit<Price, 'total'>;
  isSelected?: boolean;
}

const MapAlternativeHotelPinContent: React.FC<
  IMapAlternativeHotelPinContentProps
> = (props: IMapAlternativeHotelPinContentProps) => {
  const { isBestseller = false, price, isSelected = false } = props;
  const [t] = useTranslation('results');

  const content = (
    <ProductPinContent>
      {isBestseller && <BestsellerProductIcon />}
      <span className={'price'}>
        {t('common:priceFrom')}&nbsp;
        {price ? formatPrice(price) : t('common:defaultCurrency')}
      </span>
    </ProductPinContent>
  );

  if (isSelected) {
    return <AnimatedWrapper>{content}</AnimatedWrapper>;
  }

  return content;
};

export default MapAlternativeHotelPinContent;
