import { useEffect } from 'react';
import { useSharingUrlContext } from '@hotelplan/components.common.share-form';
import { useGetWishlistShareUrlQuery } from 'graphql/wishlist/queries/GetWishlistShareUrl.generated';
import useActiveWishlistId from './useActiveWishlistId';

export default function useWishlistSharingUrl() {
  const id = useActiveWishlistId();
  const { setUrl } = useSharingUrlContext();

  const { data, loading } = useGetWishlistShareUrlQuery({
    skip: !id,
    variables: {
      id,
    },
  });

  const sharingUrl = data?.wishlist.shareUrl;

  useEffect(() => {
    if (!loading && sharingUrl) {
      setUrl(`${window.location.origin}${sharingUrl.uri}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sharingUrl]);
}
