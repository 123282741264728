import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  WishlistProductItem,
  WishlistProductItemType,
} from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import { useReplaceOfferInActiveWishlist } from 'components/domain/wishlist/hooks';
import {
  AcceptChangesButton,
  DisrupterWrapper,
} from './WishlistItemPriceChangesDisrupter.styled';

interface IWishlistItemPriceChangesDisrupterProps {
  wishlistItem: WishlistProductItem;
}

export default function WishlistItemPriceChangesDisrupter({
  wishlistItem,
}: IWishlistItemPriceChangesDisrupterProps): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const { updateItem } = useWishlistSelectedItemsContext();
  const { loading, replaceOfferInActiveWishlist } =
    useReplaceOfferInActiveWishlist();

  async function handleAcceptChanges() {
    if (loading || !wishlistItem.changedOffer) return;

    const offer = await replaceOfferInActiveWishlist(
      wishlistItem.offer.id,
      wishlistItem.changedOffer.id
    );

    if (!offer) return;

    updateItem({
      ...wishlistItem,
      offer,
      type: WishlistProductItemType.Available,
      changedOffer: null,
    } as WishlistProductItem);
  }

  return (
    <DisrupterWrapper>
      {loading ? (
        // TODO: Add disrupter skeleton
        <div>Loading...</div>
      ) : (
        <>
          <div>
            {t('wishlist:overlay.priceChanged.title', {
              price: formatPrice(wishlistItem.changedOffer.totalPrice),
            })}
          </div>
          <AcceptChangesButton onClick={handleAcceptChanges} />
        </>
      )}
    </DisrupterWrapper>
  );
}
