import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const SearchControlFormWrapper = styled.div(
  sx2CssThemeFn({
    border: '1px solid',
    borderLeft: 'none',
    borderColor: 'borderGray',
    '.fields': {
      bg: 'backgroundGray',
    },
  })
);

export const SubmitButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
    size: 'sm',
  },
})(
  ({ theme }) => theme.buttons.btnWithIcon,
  () =>
    sx2CssThemeFn({
      mb: 4,
      textTransform: 'uppercase',
    })
);
