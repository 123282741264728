import styled from 'styled-components';
import { HeaderLayout } from '@hotelplan/components.common.header';
import { DesktopMenu } from '@hotelplan/components.common.menu';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const ACTIVE_LINK_COLOR = '#f60';

export const MenuWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '.home-link': {
      display: 'flex',
      color: 'primary',
      '&:hover': {
        color: 'secondary',
      },
      '&-active': {
        color: ACTIVE_LINK_COLOR,
      },
    },
  })
);

export const HeaderLayoutWrapper = styled(HeaderLayout)(
  sx2CssThemeFn({
    borderBottom: 'none',
    position: 'relative',
    header: {
      justifyContent: 'space-between',
    },
    'button[data-id="markup-control"]': {
      display: 'none',
    },
    '.navbar-modal': {
      zIndex: 99,
    },
  })
);

export const DesktopMenuWrapper = styled(DesktopMenu)(
  sx2CssThemeFn({
    p: '0px',
    width: '100%',
  })
);

export const ToolsWrapper = styled.div(
  sx2CssThemeFn({
    marginTop: [null, 3],
    marginLeft: ['auto', 3],
    button: {
      color: 'primary',
      px: 0,
      backgroundColor: 'white',
      fontFamily: 'Arial',
      fontSize: '14px',
      pt: 1,
    },
  })
);

export const HeaderActionButtonsWrapper = styled.div<{
  isAuthenticated: boolean;
  wishlistPosition: string;
}>(({ isAuthenticated, wishlistPosition }) =>
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    position: isAuthenticated ? wishlistPosition : 'absolute',
    top: 0,
    left: ['53%', wishlistPosition === 'fixed' ? 'calc(50% - 1px)' : '50%'],
    transform: 'translateX(-50%)',
    border: '1px solid #cccccc',
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
    bg: '#F1F1F1',
    zIndex: 100,
  })
);
