import React from 'react';
import styled from 'styled-components';
import { IconSize } from '@hotelplan/components.common.icon';
import { BaseInput } from '@hotelplan/components.common.inputs';

interface ISearchFilterInputFieldProps {
  textInputRef?: React.RefObject<HTMLInputElement>;
  onClick(): void;
  name: string;
  icon?: {
    name: string;
    size?: IconSize;
  };
}

const SearchFiltersInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchControlField
);

export default function SearchFilterInputField({
  name,
  textInputRef,
  onClick,
  icon,
}: ISearchFilterInputFieldProps): React.ReactElement {
  return (
    <SearchFiltersInput
      name={name}
      value={name}
      onClick={onClick}
      inputRef={textInputRef}
      icon={icon}
      readOnly
      className={'search-filter-input'}
    />
  );
}
