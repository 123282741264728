import { useApolloClient } from '@apollo/client';
import { Status } from '@hotelplan/graphql.types';
import {
  WishlistOfferFragment,
  WishlistOfferFragmentDoc,
} from 'graphql/wishlist/fragments/WishlistOfferFragment.generated';
import { useAddToActiveWishlistWithSourceMutation } from 'graphql/wishlist/mutations/AddToActiveWishlistWithSource.generated';
import useWishlistCacheManage from './useWishlistCacheManage';

export default function useReplaceOfferInActiveWishlist() {
  const client = useApolloClient();
  const { update } = useWishlistCacheManage();

  const [addMutation, { loading }] = useAddToActiveWishlistWithSourceMutation();

  async function replaceOfferInActiveWishlist(
    prevOfferId: string,
    nextOfferId: string
  ): Promise<WishlistOfferFragment | null> {
    update(prevOfferId, false);
    update(nextOfferId, true);

    const { data } = await addMutation({
      variables: {
        offerId: {
          offerId: nextOfferId,
        },
      },
    });

    if (!data) return null;

    const {
      offer: nextOffer,
      status,
      message,
    } = data.addToActiveWishlistWithSource;

    if (!nextOffer || status === Status.Failure) {
      // eslint-disable-next-line no-console
      console.error(`No offer result`, message);
      return null;
    }

    // If offer id is changed during update
    // Then the old offer should be change to false
    if (nextOffer.id !== nextOfferId) {
      update(nextOfferId, false);
    }

    client.writeFragment<WishlistOfferFragment>({
      fragment: WishlistOfferFragmentDoc,
      fragmentName: `wishlistOffer`,
      id: `WishlistOffer:${nextOffer.id}`,
      data: {
        ...nextOffer,
      },
    });

    return nextOffer;
  }

  return {
    replaceOfferInActiveWishlist,
    loading,
  };
}
