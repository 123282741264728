import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { FilterApplyButton } from 'components/domain/filters/SearchFilterDesktopDropdown';

const ButtonsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    gap: 2,
    p: 2,
    bg: 'backgroundGray',
    borderTop: '1px solid #bbb',
    borderBottom: '1px solid #bbb',
  })
);

const CancelButton = styled(Button)(
  sx2CssThemeFn({
    width: '100%',
    bg: 'white',
    color: 'primary',
    border: '1px solid',
    borderColor: 'borderGray',
  })
);

interface IActionButtonsProps {
  onApply(): void;
  onReset(): void;
  className?: string;
}

export default function ActionButtons({
  onApply,
  onReset,
  className,
}: IActionButtonsProps): React.ReactElement {
  const [t] = useTranslation('filters');

  return (
    <ButtonsWrapper className={className}>
      <CancelButton onClick={onReset}>{t('cancel.button')}</CancelButton>
      <FilterApplyButton onClick={onApply}>
        {t('filters:apply.button')}
      </FilterApplyButton>
    </ButtonsWrapper>
  );
}
