import { PriceFragment } from 'graphql/fragments/Price.generated';
import React from 'react';
import { formatPrice } from '@hotelplan/libs.utils';

export default function getFormattedPrice(
  price: PriceFragment | undefined | null,
  prefix: string | undefined | null
): React.ReactElement | null {
  if (!price) {
    return null;
  }

  const formattedPrice = formatPrice(price);
  if (!formattedPrice) {
    return null;
  }

  const [currency, count] = formattedPrice.split(/\s/);

  return (
    <div>
      <span className="prefix">{prefix}</span>
      <span className="currency">{currency}</span>
      <span className="price-count">{count}</span>
    </div>
  );
}
