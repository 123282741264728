import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import {
  ShareDetails,
  ShareForm,
} from '@hotelplan/components.common.share-form';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const ShareOverlayWrap = styled.div.attrs({
  className: 'sitetool-overlay',
})(({ theme: { colors, space } }) =>
  sx2CssThemeFn({
    maxWidth: [null, '375px'],
    width: '100%',
    position: ['relative', 'absolute'],
    top: [null, 0],
    right: [null, 0],
    backgroundColor: colors.white,
    padding: '20px',
    zIndex: 10,
    textShadow: 'none',
    border: '1px solid #999999',
    borderRadius: [null, 'default'],
    boxShadow: [null, '0px 1px 7px rgba(0, 0, 0, 0.5)'],
    '.tagline-text': {
      marginBottom: space[3],
      color: 'secondary',
      fontSize: '26px',
      lineHeight: '1',
    },
    '.input-wrap .label': {
      fontSize: 0,
      mb: 2,
      color: 'textGray',
      pl: 0,
      span: {
        color: 'inherit',
      },
    },
  })
);

const ModalCloseButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: { name: 'close' },
  'data-id': `close`,
})(
  sx2CssThemeFn({
    position: 'absolute',
    top: [3, '20px'],
    right: [3, 3],
    backgroundColor: 'inherit',
    '.icon': {
      variant: 'icons.sm',
    },
  })
);

const ShareFormWrap = styled(ShareForm)(
  sx2CssThemeFn({
    '.label': {
      fontSize: '0px',
      margin: '0px',
    },
    button: {
      mt: 0,
      alignSelf: 'end',
      mb: '2px',
      '.icon': {
        variant: 'icons.lg',
      },
      '.tooltip': {
        whiteSpace: 'nowrap',
        boxShadow: '0 4px 8px 2px rgba(0,0,0,0.2)',
        fontSize: 0,
      },
    },
    '.input-wrap': {
      mr: 2,
    },
    '.input': {
      border: '1px solid #BBBBBB',
      px: 2,
      fontSize: '14px',
      height: '35px',
      backgroundColor: '#F2F2F2',
      borderRadius: '0px',
    },
  })
);

interface IShareOverlayProps {
  onClose?(): void;
  show?: boolean;
  overlayRef?: React.RefObject<HTMLDivElement>;
}

const ShareOverlay: React.FC<IShareOverlayProps> = ({
  onClose,
  show,
  overlayRef,
}: IShareOverlayProps) => {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const shareFormElement: React.ReactElement = (
    <ShareOverlayWrap ref={overlayRef}>
      <h2 className="tagline-text">{t('common:share.overlay.title')}</h2>
      <ShareDetails />
      <ShareFormWrap />
      <ModalCloseButton onClick={onClose}>{`Close window`}</ModalCloseButton>
    </ShareOverlayWrap>
  );

  return (
    <>
      {mobile ? (
        <WhiteBoxModal name={'share-overlay-modal'} show={show} title={''}>
          {shareFormElement}
        </WhiteBoxModal>
      ) : (
        shareFormElement
      )}
    </>
  );
};

export default ShareOverlay;
