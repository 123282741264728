import { useTranslation } from 'next-i18next';
import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { TextBlock } from '@hotelplan/components.common.text-block';
import { TripAdvisorInfo } from '@hotelplan/components.common.tripadvisor-rating';
import { TravelType, WishlistProductItem } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { useSearchState } from '@hotelplan/libs.search-state';
import { formatDate, formatPrice, parseDateStr } from '@hotelplan/libs.utils';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';
import ProductRating from 'components/core/product/ProductRating';
import FlightShortInfo from 'components/domain/orl/offerItem/FlightShortInfo';
import { mapOrlFlightToIFlightSectionProps } from 'components/domain/orl/Orl.mappers';
import { ISrlState } from 'components/domain/srl/SRL.types';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import { useRemoveFromActiveWishlist } from 'components/domain/wishlist/hooks';
import { WishlistItemRemoveIconContainer } from 'components/domain/wishlist/ui';
import { COMPARE_ITEMS_COUNT_MOBILE } from 'components/domain/wishlist/utils';
import { mapDurationToWeeks } from 'libs';
import getFormattedTravelType from 'libs/utils/getFormattedTravelType';
import EmptySection from './EmptySection';
import WishlistCompareFeaturesSection from './WishlistCompareFeaturesSection';
import WishlistCompareItemsSkeleton from './WishlistCompareItems.skeleton';
import {
  Flex,
  ProductHead,
  RatingWrapper,
  WishlistCompareFeaturesSectionWrapper,
  WishlistCompareItemsColumn,
  WishlistCompareItemsWrapper,
  WishlistItemChangedOverlayWrapper,
  WishlistProductImage,
  FlexWrapFullWidth,
  IncludedInPriceHeader,
  IncludedInPriceWrapper,
} from './WishlistCompareItems.styled';

export default function WishlistCompareItems(): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t, { language }] = useTranslation(['common', 'orl']);
  const {
    state: { searchControl },
  } = useSearchState<ISrlState>();

  const { removeFromActiveWishlist } = useRemoveFromActiveWishlist();
  const { selectedItems, itemsLoading, updateItem } =
    useWishlistSelectedItemsContext();

  const itemsToShow = mobile
    ? selectedItems.slice(0, COMPARE_ITEMS_COUNT_MOBILE)
    : selectedItems;

  const showTransferInfo = itemsToShow.some(item => item?.offer.transferPrice);

  if (itemsLoading) return <WishlistCompareItemsSkeleton />;

  return (
    <WishlistCompareItemsWrapper>
      {/* Product rating with trash icon, title, breadcrumbs section and image */}
      <ProductHead>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} showMessage />;

          const hpRating = selectedItem.product.hpRating || 0;
          const offerId = selectedItem.offer.id;
          const breadcrumbs = selectedItem.product.breadcrumbs;
          const productImage = selectedItem.product.image;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <WishlistItemRemoveIconContainer
                onRemove={async () => await removeFromActiveWishlist(offerId)}
              >
                <RatingWrapper>
                  <ProductRating rating={hpRating} />
                </RatingWrapper>
              </WishlistItemRemoveIconContainer>
              <h2 className="product-title">{selectedItem.product.name}</h2>
              <div className="breadcrumbs">
                {breadcrumbs.map((geoObject, i) => (
                  <span key={geoObject.id}>
                    {geoObject.name}
                    {i < breadcrumbs.length - 1 ? ' |' : ''}{' '}
                  </span>
                ))}
              </div>
              <WishlistProductImage {...productImage} />
            </WishlistCompareItemsColumn>
          );
        })}
      </ProductHead>
      {/* Product offer type */}
      <Flex>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          const offerType = selectedItem.offer.travelType;
          const offerClass = selectedItem.product.productClass;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <span className="product-details-title type">
                {t(getFormattedTravelType(offerClass, offerType))}
              </span>
            </WishlistCompareItemsColumn>
          );
        })}
      </Flex>
      {/* Product flight information section */}
      {searchControl.travelType === TravelType.Package ? (
        <Flex>
          {itemsToShow.map((selectedItem, index) => {
            if (!selectedItem) return <EmptySection key={index} />;

            const inboundFlight = selectedItem.offer.inboundFlight;
            const outboundFlight = selectedItem.offer.outboundFlight;
            const offerType = selectedItem.offer.travelType;

            return (
              <WishlistCompareItemsColumn
                key={`${selectedItem.product.id}-${index}`}
              >
                {offerType === TravelType.Package ? (
                  <span className="product-details-title">
                    {t('common:return.flight')}
                  </span>
                ) : null}
                {outboundFlight ? (
                  <FlightShortInfo
                    {...mapOrlFlightToIFlightSectionProps(outboundFlight)}
                  />
                ) : null}
                {inboundFlight ? (
                  <FlightShortInfo
                    {...mapOrlFlightToIFlightSectionProps(inboundFlight)}
                  />
                ) : null}
              </WishlistCompareItemsColumn>
            );
          })}
        </Flex>
      ) : null}
      {/* Hotel information section */}
      <Flex>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          const rooms = selectedItem.offer.rooms;
          const duration = selectedItem.offer.duration;
          const returnDate = selectedItem.offer.returnDate;
          const departureDate = selectedItem.offer.departureDate;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <div className="product-details-title">
                {`${t('common:offer.travelType.hotel')}, `}
                {mapDurationToWeeks(duration, t)}
              </div>
              <div>
                {`${formatDate(
                  parseDateStr(departureDate),
                  'dd.MM.yyyy',
                  language
                )} - ${formatDate(
                  parseDateStr(returnDate),
                  'dd.MM.yyyy',
                  language
                )}`}
              </div>
              <div>
                {rooms.map((room, roomIndex) => {
                  return (
                    <div key={roomIndex}>
                      <div className="wishlist-rooms">
                        {t('common:room')} {roomIndex + 1}
                      </div>
                      <p className="wishlist-rooms-description">
                        {room.roomDescription}, {room.boardDescription}
                      </p>
                      <div>
                        <div className="room-type">
                          {t('common:adult.count', { count: room.adults })}
                          {room.children > 0 &&
                            `, ${t('common:child.count', {
                              count: room.children,
                            })}`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </WishlistCompareItemsColumn>
          );
        })}
      </Flex>
      {/* Price section */}
      <FlexWrapFullWidth>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <div className="price-title">
                {t('common:priceFrom')}
                <span className="price">
                  {formatPrice(selectedItem.offer.pricePerPerson)}
                </span>
                <span>{t('common:transfer.perPerson')}</span>
              </div>
            </WishlistCompareItemsColumn>
          );
        })}
      </FlexWrapFullWidth>
      {/* Transfer price section */}
      {showTransferInfo ? (
        <Flex>
          {itemsToShow.map((selectedItem, index) => {
            if (!selectedItem) return <EmptySection key={index} />;

            const transferPrice = selectedItem.offer.transferPrice;

            return (
              <WishlistCompareItemsColumn
                key={`${selectedItem.product.id}-${index}`}
              >
                {!!transferPrice ? (
                  <div className="transfer-title">
                    <span className={'transfer'}>
                      {t('orl:offer.transfer')}
                    </span>
                    <span>{t('common:priceFrom')}</span>
                    <span className="price">{formatPrice(transferPrice)}</span>
                    <span>{t('common:transfer.perPerson')}</span>
                  </div>
                ) : null}
              </WishlistCompareItemsColumn>
            );
          })}
        </Flex>
      ) : null}
      {/* Product link button */}
      <Flex>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <RouterLink {...getTargetPageData(selectedItem.link)} passHref>
                <PrimaryButtonLink>
                  <div className="link-button-total-from">
                    <span className="link-button-total">
                      {t('wishlist:total.price')}
                    </span>
                    <span className="link-button-from">
                      {t('common:priceFrom')}
                    </span>
                  </div>
                  <span className="price">
                    {formatPrice(selectedItem.offer.totalPrice)}
                  </span>
                </PrimaryButtonLink>
              </RouterLink>
            </WishlistCompareItemsColumn>
          );
        })}
      </Flex>
      {/* Trip advisor rating section */}
      <Flex>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          const taRating = selectedItem.product.taRating || 0;
          const taReviews = selectedItem.product.taReviews || 0;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              {taRating && taReviews ? (
                <TripAdvisorInfo
                  rating={taRating || 0}
                  reviewCount={taReviews || 0}
                  label={t('common:tripAdvisor.reviewsCount')}
                  className="tripadvisor-info"
                />
              ) : null}
            </WishlistCompareItemsColumn>
          );
        })}
      </Flex>
      {/* Product features section */}
      <FlexWrapFullWidth>
        {itemsToShow.map((selectedItem, index) => {
          if (!selectedItem) return <EmptySection key={index} />;

          const includedInPrice = selectedItem.product.includedInPrice;

          return (
            <WishlistCompareItemsColumn
              key={`${selectedItem.product.id}-${index}`}
            >
              <WishlistCompareFeaturesSectionWrapper>
                <WishlistCompareFeaturesSection wishlistItem={selectedItem} />
              </WishlistCompareFeaturesSectionWrapper>
              {includedInPrice && (
                <IncludedInPriceWrapper>
                  {includedInPrice.title && (
                    <IncludedInPriceHeader>
                      <Icon name="money-wallet" />
                      {includedInPrice.title}
                    </IncludedInPriceHeader>
                  )}
                  <TextBlock
                    title={''}
                    text={includedInPrice.text}
                    className="included-in-price-text-block"
                  />
                </IncludedInPriceWrapper>
              )}
            </WishlistCompareItemsColumn>
          );
        })}
      </FlexWrapFullWidth>
      {/* Offer expired/changed overlay */}
      {itemsToShow.map((selectedItem, index) => {
        if (!selectedItem) return null;

        return (
          <WishlistItemChangedOverlayWrapper
            key={`${selectedItem.product.id}-${index}`}
            item={selectedItem}
            index={index}
            onAccept={acceptedOffer => {
              updateItem(acceptedOffer as WishlistProductItem);
            }}
          />
        );
      })}
    </WishlistCompareItemsWrapper>
  );
}
