import { DocumentNode } from 'graphql';
import {
  DeviceType,
  PageLanguage,
  PageType,
  SearchControlComponent,
  TravelType,
} from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { useSearchState } from '@hotelplan/libs.search-state';
import useSearchParam from 'components/core/search/useSearchParam';
import { mapFiltersToFormState } from 'components/domain/filters/Filters.mappers';
import { mapSearchControlValuesToFormState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import useSearchControlValues, {
  SearchControlDataMapper,
} from 'components/domain/search-control/hooks/useSearchControlValues';
import {
  GetGeoSearchControlValuesDocument,
  GetGeoSearchControlValuesQuery,
  GetGeoSearchControlValuesQueryVariables,
} from 'graphql/geo/GetGeoSearchControlValues.generated';
import {
  GetHomeSearchControlValuesQuery,
  GetHomeSearchControlValuesQueryVariables,
  GetHomeSearchControlValuesDocument,
} from 'graphql/home/GetHomeSearchControlValues.generated';
import {
  GetOrlSearchControlValuesDocument,
  GetOrlSearchControlValuesQuery,
  GetOrlSearchControlValuesQueryVariables,
} from 'graphql/orl/GetOrlSearchControlValues.generated';
import { useGetSrlDefaultFilterValuesQuery } from 'graphql/srl/GetSRLDefaultFilterValues.generated';
import {
  GetSrlSearchValuesDocument,
  GetSrlSearchValuesQuery,
  GetSrlSearchValuesQueryVariables,
} from 'graphql/srl/GetSrlSearchValues.generated';
import {
  GetThemeSearchControlValuesDocument,
  GetThemeSearchControlValuesQuery,
  GetThemeSearchControlValuesQueryVariables,
} from 'graphql/theme/GetThemeSearchControlValues.generated';
import {
  GetWishlistSearchValuesDocument,
  GetWishlistSearchValuesQuery,
  GetWishlistSearchValuesQueryVariables,
} from 'graphql/wishlist/queries/GetWishlistSearchValues.generated';
import { ISrlState } from './SRL.types';

interface IRequestSearchControlOptions<
  TSearchControlData,
  TSearchControlVariables
> {
  query: DocumentNode;
  variables: TSearchControlVariables;
  dataMapper: SearchControlDataMapper<TSearchControlData>;
}

function useDefaultSearchState<TSearchControlData, TSearchControlVariables>({
  query,
  variables,
  dataMapper,
}: IRequestSearchControlOptions<TSearchControlData, TSearchControlVariables>) {
  const { setState } = useSearchState<ISrlState>();

  const { data: defaultFiltersData, loading: defaultFiltersLoading } =
    useGetSrlDefaultFilterValuesQuery();

  const defaultFilters =
    defaultFiltersData?.srl.searchControls.mainFilter || {};

  const { data: defaultSearchControl, loading: defaultSearchControlLoading } =
    useSearchControlValues<TSearchControlData, TSearchControlVariables>(
      query,
      variables,
      dataMapper
    );

  function onResetSearchState() {
    if (!defaultFiltersLoading && !defaultSearchControlLoading) {
      setState(prevState => ({
        ...prevState,
        searchControl: defaultSearchControl,
        filters: mapFiltersToFormState(defaultFilters),
        formConfig: {
          ...prevState.formConfig,
          reset: true,
        },
      }));
    }
  }

  return {
    onReset: onResetSearchState,
    initialSearchControl: defaultSearchControl,
    loading: defaultSearchControlLoading,
  };
}

export function useSearchDefaultSearchState() {
  const searchParam = useSearchParam();
  return useDefaultSearchState<
    GetSrlSearchValuesQuery,
    GetSrlSearchValuesQueryVariables
  >({
    query: GetSrlSearchValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.srl.searchControls.searchControl as SearchControlComponent
      ),
    variables: {
      urlParameter: searchParam,
    },
  });
}

export function useGeoDefaultSearchState(id: string) {
  return useDefaultSearchState<
    GetGeoSearchControlValuesQuery,
    GetGeoSearchControlValuesQueryVariables
  >({
    query: GetGeoSearchControlValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.geo.searchControl as SearchControlComponent
      ),
    variables: { id },
  });
}

export function useThemeDefaultSearchState(id: string) {
  return useDefaultSearchState<
    GetThemeSearchControlValuesQuery,
    GetThemeSearchControlValuesQueryVariables
  >({
    query: GetThemeSearchControlValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.theme.searchControl as SearchControlComponent
      ),
    variables: { id },
  });
}

export function useOrlDefaultSearchState(id: string) {
  return useDefaultSearchState<
    GetOrlSearchControlValuesQuery,
    GetOrlSearchControlValuesQueryVariables
  >({
    query: GetOrlSearchControlValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.orl.searchControl as SearchControlComponent
      ),
    variables: { id },
  });
}

export function useHomeDefaultSearchState(travelType: TravelType) {
  return useDefaultSearchState<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables
  >({
    query: GetHomeSearchControlValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.home.searchControl as SearchControlComponent
      ),
    variables: { travelType },
  });
}

export function useWishlistDefaultSearchState() {
  const searchParam = useSearchParam();
  const context = useRequestContext<DeviceType, PageLanguage>();
  return useDefaultSearchState<
    GetWishlistSearchValuesQuery,
    GetWishlistSearchValuesQueryVariables
  >({
    query: GetWishlistSearchValuesDocument,
    dataMapper: data =>
      mapSearchControlValuesToFormState(
        data?.wishlist.searchControl as SearchControlComponent
      ),
    variables: { searchQuery: searchParam, context },
  });
}

export function useDefaultSearchStateByPageType(pageType: PageType) {
  const defaultSearchState = {
    geo: useGeoDefaultSearchState,
    search: useSearchDefaultSearchState,
    theme: useThemeDefaultSearchState,
    orl: useOrlDefaultSearchState,
    home: useHomeDefaultSearchState,
    wishlist: useWishlistDefaultSearchState,
  };

  return {
    [PageType.GeoSrl]: defaultSearchState.geo,
    [PageType.Srl]: defaultSearchState.search,
    [PageType.ThemeSrl]: defaultSearchState.theme,
    [PageType.Orl]: defaultSearchState.orl,
    [PageType.Home]: defaultSearchState.home,
    [PageType.WishlistCompare]: defaultSearchState.wishlist,
  }[pageType];
}
