import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SearchControlFragmentDoc } from 'graphql/searchControl/SearchControl.generated';
import { OrlFilterValuesFragmentDoc } from './ORLFilterValues.generated';
import { UrlFilterValuesFragmentDoc } from 'graphql/searchFilter/URLFilterValues.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetOrlSearchControlValuesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  urlParameter?: Types.InputMaybe<Types.Scalars['String']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetOrlSearchControlValuesQuery = {
  __typename?: 'Query';
  orl: {
    __typename?: 'OrlContext';
    searchControl: {
      __typename?: 'SearchControlComponent';
      travelType: Types.TravelType;
      rooms: number;
      period: {
        __typename?: 'TravelPeriodComponent';
        type: Types.SearchPeriodType;
        exactPeriod: {
          __typename?: 'ExactTravelPeriod';
          departureDate: string;
          returnDate: string;
        };
        flexiblePeriod: {
          __typename?: 'FlexibleTravelPeriod';
          departureDate: string;
          maxDuration: number;
          minDuration: number;
          returnDate: string;
        };
      };
      destinations?: Array<{
        __typename?: 'TravelDestination';
        id: string;
        name: string;
        type: Types.TravelDestinationType;
        mythosCode?: string | null;
        location?: {
          __typename?: 'GeoLocation';
          airport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          continent?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          countryGroup?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          country?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          region?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          destination?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          resort?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
        } | null;
      }> | null;
      travellers: {
        __typename?: 'Travellers';
        adults: number;
        childrenDobs?: Array<string> | null;
      };
      travellersDistribution?: {
        __typename?: 'TravellersDistribution';
        rooms: Array<{
          __typename?: 'RoomDistribution';
          adultsAmount: number;
          childrenIdx?: Array<number> | null;
        }>;
      } | null;
    };
    filters: {
      __typename?: 'FilterComponent';
      roomTypes: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      boardTypes: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      maxPrice: {
        __typename?: 'SliderFilterComponent';
        caption: string;
        selected: string;
      };
      departureAirports: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      stopOvers: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      directFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      returnFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      departureWeekdays: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      arrivalWeekdays: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      arrivalAirports: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      minPrice: {
        __typename?: 'SliderFilterComponent';
        caption: string;
        selected: string;
      };
      productCode?: {
        __typename?: 'SingleValueFilterComponent';
        id: string;
        caption: string;
      } | null;
      provider?: {
        __typename?: 'SingleValueFilterComponent';
        id: string;
        caption: string;
      } | null;
      flightProvider?: {
        __typename?: 'SingleValueFilterComponent';
        id: string;
        caption: string;
      } | null;
      refundableFeatures: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      directFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      returnFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      flightAirlines: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
      radius?: {
        __typename?: 'RadiusFilterComponent';
        label?: string | null;
        radius: number;
        center: {
          __typename?: 'GeoCoordinates';
          latitude: number;
          longitude: number;
        };
      } | null;
    };
  };
};

export const GetOrlSearchControlValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrlSearchControlValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'urlParameter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SearchControl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ORLFilterValues' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'URLFilterValues' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SearchControlFragmentDoc.definitions,
    ...OrlFilterValuesFragmentDoc.definitions,
    ...UrlFilterValuesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetOrlSearchControlValuesQuery__
 *
 * To run a query within a React component, call `useGetOrlSearchControlValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrlSearchControlValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrlSearchControlValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      urlParameter: // value for 'urlParameter'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetOrlSearchControlValuesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOrlSearchControlValuesQuery,
    GetOrlSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetOrlSearchControlValuesQuery,
    GetOrlSearchControlValuesQueryVariables
  >(GetOrlSearchControlValuesDocument, options);
}
export function useGetOrlSearchControlValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrlSearchControlValuesQuery,
    GetOrlSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetOrlSearchControlValuesQuery,
    GetOrlSearchControlValuesQueryVariables
  >(GetOrlSearchControlValuesDocument, options);
}
export type GetOrlSearchControlValuesQueryHookResult = ReturnType<
  typeof useGetOrlSearchControlValuesQuery
>;
export type GetOrlSearchControlValuesLazyQueryHookResult = ReturnType<
  typeof useGetOrlSearchControlValuesLazyQuery
>;
export type GetOrlSearchControlValuesQueryResult = Apollo.QueryResult<
  GetOrlSearchControlValuesQuery,
  GetOrlSearchControlValuesQueryVariables
>;
