import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { NewsletterMobileBlockWrapper } from 'components/domain/footer/NewsletterMobileBlock';
import { SiteToolsButtonsWrapper } from 'components/domain/sitetools';

export const FeedbackButtonWrapper = styled(SiteToolsButtonsWrapper)(
  sx2CssThemeFn({
    position: 'relative',
    borderLeft: '0px',
    borderRadius: '0 0 0 5px',
    '.feedback-icon': {
      color: 'white',
    },
    '&:hover:after': {
      backgroundColor: 'secondary',
    },
  })
);

export const FeedbackModalWrapper = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      position: 'relative',
      maxWidth: [null, '550px'],
    },
    '.modal-header': {
      position: 'absolute',
      top: 0,
      right: 0,
      px: 4,
      border: 'none',
    },
    '.modal-body': {
      iframe: {
        minHeight: ['500px', '620px'],
      },
    },
  })
);

export const FeedbackMobileBoxWrapper = styled(NewsletterMobileBlockWrapper)(
  sx2CssThemeFn({
    mt: 0,
  })
);

export const FeedbackMobileButton = styled(Button).attrs({
  icon: { name: 'chevron-long-right' },
})(
  ({ theme: { buttons } }) => buttons.primary,
  ({ theme: { buttons } }) => buttons.btnWithIcon,
  sx2CssThemeFn({
    textTransform: 'uppercase',
    svg: {
      width: '14px',
      height: '14px',
    },
  })
);
