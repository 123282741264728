import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const ProductTypeLabel = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '-5px',
    left: '10px',
    px: 2,
    py: 1,
    color: 'white',
    fontFamily: '"Helvetica Neue", sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '&:after': {
      display: ['block', 'block'],
      content: '""',
      position: 'absolute',
      top: '0',
      right: '-5px',
      width: '0px',
      height: '0px',
      borderStyle: 'solid',
      borderWidth: '5px 0 0 5px',
      borderColor: 'transparent transparent transparent #a44809',
    },
  })
);

export default ProductTypeLabel;
