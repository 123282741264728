import { IIconProps } from '@hotelplan/components.common.icon';
import { ratingsIdsToNumberDict, TPriceOption } from './Filters.common';
import { IFilterOption } from './Filters.types';

type FilterCheckboxOnChangeAttrs = {
  options: IFilterOption[];
  checkedOptions: string[];
  setCheckedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  allOptionId?: string;
};

export function filterCheckboxOnChange({
  options,
  checkedOptions,
  setCheckedOptions,
  allOptionId,
}: FilterCheckboxOnChangeAttrs) {
  return function (nextValue: string) {
    if (allOptionId) {
      // If the user clicked on the "All" option, we need to uncheck all other options
      if (
        checkedOptions.length === options.length - 2 ||
        nextValue === allOptionId
      ) {
        setCheckedOptions([allOptionId]);
        return;
      }
    }

    if (checkedOptions.includes(nextValue)) {
      checkedOptions.length > 1
        ? setCheckedOptions(checkedOptions.filter(item => item !== nextValue))
        : setCheckedOptions([allOptionId]);
    } else {
      setCheckedOptions(
        [...checkedOptions, nextValue].filter(option => option !== allOptionId)
      );
    }
  };
}

type FilterCheckboxOnApplyAttrs = {
  checkedOptions: string[];
  allOptionId?: string;
  onChange?(finalOptions: string[]): void;
  stayWithAllOptionId?: boolean;
};

export function filterCheckboxOnApply({
  checkedOptions,
  allOptionId,
  onChange,
  stayWithAllOptionId,
}: FilterCheckboxOnApplyAttrs) {
  let finalOptions = [...checkedOptions];
  if (allOptionId) {
    if (checkedOptions.includes(allOptionId)) {
      finalOptions = stayWithAllOptionId ? [allOptionId] : [];
    }
  }
  onChange?.(finalOptions);
}

export function getMaxRating(options: IFilterOption[]): number {
  const ratingList = options.map(item => ratingsIdsToNumberDict[item.id]);
  return Math.max(...ratingList);
}

export function mapOptionsToRatingOptions(
  options: IFilterOption[],
  coloredIcon: IIconProps,
  emptyIcon: IIconProps
): IFilterOption[] {
  return options.map(option => ({
    ...option,
    caption: option.caption || 'min.',
    ratingIcons: {
      coloredIcon,
      emptyIcon,
    },
    rating: ratingsIdsToNumberDict[option.id],
  }));
}

export function mapPriceToSliderValue(
  price: number,
  priceOptions: Array<TPriceOption>
): Array<number> {
  if (!price) return [90]; // any value

  return [
    priceOptions.indexOf(priceOptions.find(option => option.value === price)) *
      10,
  ];
}
