import type { FieldMergeFunction, PossibleTypesMap } from '@apollo/client';
import { TypePolicies, TypePolicy } from '@apollo/client';
import {
  AutocompleteComponent,
  introspectionQueryResultData,
} from '@hotelplan/graphql.types';
import {
  WishlistItemFragment,
  WishlistItemFragmentDoc,
} from 'graphql/wishlist/fragments/WishlistItemFragment.generated';

export const apolloPossibleTypes: PossibleTypesMap =
  introspectionQueryResultData.possibleTypes;

const possibleTypeNames = [
  `AccountContext`,
  `AccountLoginPage`,
  `AccountMutationResponse`,
  `AccountMyProfilePage`,
  `AccountPage`,
  `AccountPersonalDataResponse`,
  `AddressDetails`,
  `AddTravelCompanionResponse`,
  `Agency`,
  `AgencyAppointmentResponse`,
  `AgencyAutocomplete`,
  `AgencyContact`,
  `AgencyContactPhone`,
  `AgencyOverviewPageContext`,
  `AgencyRecommendationGroup`,
  `Airline`,
  `Airport`,
  `AuthMethod`,
  `AuthMethodListResponse`,
  `AutocompleteComponent`,
  `BD4TravelRecommendation`,
  `BD4TravelRecommendationInfo`,
  `BD4TravelRecommendationTracking`,
  `SrlBD4TravelRecommendationsComponent`,
  `BlogArticleRecommendationItem`,
  `BoardType`,
  `BookingBoardType`,
  `BookingDetails`,
  `BookingDetailsResponse`,
  `BookingExtraService`,
  `BookingHotelRoom`,
  `BookingItem`,
  `BookingResponse`,
  `BookingRoomType`,
  `BookingTransfer`,
  `Booster`,
  `BrandBox`,
  `BrandsComponent`,
  `Breadcrumb`,
  `BreadcrumbsComponent`,
  `BusinessUnit`,
  `Catalog`,
  `CatalogOrderResponse`,
  `CatalogOverviewContext`,
  `CheckboxFilterComponent`,
  `ClimateChart`,
  `ClimateChartEntry`,
  `ClimateComponent`,
  `ContactForm`,
  `ContactFormResponse`,
  `ContactInformation`,
  `ContactPageContext`,
  `ContactRecommendationGroup`,
  `CookieDisclaimer`,
  `DeleteHistoryResponse`,
  `DoubleRangeSliderFilterComponent`,
  `EmailAdvertisingGetEmailFromTokenResponse`,
  `EmailAdvertisingGetTokenFromEmailResponse`,
  `EmailAdvertisingRecommendationGroup`,
  `EmailAdvertisingRevocationConfirmationPageContext`,
  `EmailAdvertisingRevocationPageContext`,
  `EmailAdvertisingRevocationResponse`,
  `EmailAdvertisingStaticContent`,
  `EmployeeBox`,
  `EmployeesComponent`,
  `EmployeeSocialProfile`,
  `ExactTravelPeriod`,
  `ExternalLink`,
  `ExternalMediaItem`,
  `FaqComponent`,
  `FilterComponent`,
  `FilterItem`,
  `FlexibleTravelPeriod`,
  `Flight`,
  `FlightBaggageInfo`,
  `FlightCheckoutComponent`,
  `FlightDestinationInfo`,
  `FlightHistoryResponse`,
  `FlightHomeContext`,
  `FlightHomeRecommendationGroup`,
  `FlightOffer`,
  `FlightPartition`,
  `FlightRecommendationWithoutPriceItem`,
  `FlightRecommendationWithPriceItem`,
  `FlightSearchControlComponent`,
  `FlightSegment`,
  `FlightSrlComponent`,
  `FlightSrlContainer`,
  `FlightSrlContext`,
  `FlightSrlRecommendationGroup`,
  `FlightStopOverDuration`,
  `GeoChildComponent`,
  `GeoChildrenComponent`,
  `GeoContext`,
  `GeoCoordinates`,
  `GeoDefaultGeoRecommendationsComponent`,
  `GeoFeature`,
  `GeoFeatureGroup`,
  `GeoInfoComponent`,
  `GeoLocation`,
  `GeoObject`,
  `GeoObjectRecommendationGroupComponent`,
  `GeoOverviewChildComponent`,
  `GeoOverviewContext`,
  `GeoOverviewRecommendationGroupComponent`,
  `GeoRecommendationItem`,
  `GroupOrlItem`,
  `HelpOverlayBox`,
  `HelpOverlayBoxChat`,
  `HelpOverlayBoxContact`,
  `HelpOverlayData`,
  `HeroMediaGallery`,
  `HistoryContext`,
  `HistoryFlightRecord`,
  `HistoryProductRecord`,
  `HistorySrlRecord`,
  `HolidayFinderInfo`,
  `HolidayFinderLandingPage`,
  `HolidayFinderOffer`,
  `HolidayFinderPage`,
  `HolidayFinderPageContext`,
  `HolidayFinderProduct`,
  `HolidayFinderTracking`,
  `HolidayFinderVotingResponse`,
  `HomeContext`,
  `HomeRecommendationGroup`,
  `HomeStaticContent`,
  `HomeTitle`,
  `HotelDestinationInfo`,
  `IconMenuItem`,
  `Image`,
  `ImageMediaItem`,
  `InternalLink`,
  `LinkListComponent`,
  `LinkListItem`,
  `MapSuggestion`,
  `MarketingRecommendationItem`,
  `MediaGallery`,
  `Menu`,
  `MusicMediaItem`,
  `Mutation`,
  `NewsArticle`,
  `NewsArticlePage`,
  `NewsArticlesComponent`,
  `NewsArticlesFilter`,
  `NewsArticlesOverview`,
  `NewsArticlesOverviewPage`,
  `NewsletterConfirmationContext`,
  `NewsletterFinalizationContext`,
  `NewsletterGetEmailFromTokenResponse`,
  `NewsletterGetTokenFromEmailResponse`,
  `NewsletterRecommendationGroup`,
  `NewsletterSubscription`,
  `NewsletterSubscriptionContext`,
  `NewsletterSubscriptionResponse`,
  `NewsletterUnsubscriptionContext`,
  `NewsletterUnsubscriptionFinalizationContext`,
  `NewsletterUnsubscriptionResponse`,
  `Notification`,
  `NotificationInfo`,
  `NudgeItem`,
  `OpeningHours`,
  `OrlCheckoutComponent`,
  `OrlContext`,
  `OrlFlightAlternative`,
  `OrlGroupListComponent`,
  `OrlHistoryResponse`,
  `OrlIncludedInPriceComponent`,
  `OrlPriceExplanation`,
  `OrlPriceExplanationComponent`,
  `OrlRoom`,
  `OrlSearchContainer`,
  `OrlSingleListComponent`,
  `Page`,
  `PageB2BLoginData`,
  `PageFooterData`,
  `PageHeaderData`,
  `PageMetaData`,
  `PageNotFound404Data`,
  `PdfMediaItem`,
  `PDOItem`,
  `PdpContainer`,
  `PdpContext`,
  `PdpDescriptionComponent`,
  `PdpDestinationInfoComponent`,
  `PdpFeatureRating`,
  `PdpMapComponent`,
  `PdpMapHotel`,
  `PdpMoreOffersButton`,
  `PdpOverviewComponent`,
  `PdpPriceDateOverviewComponent`,
  `PdpRecommendationGroup`,
  `PdpTripAdvisorComponent`,
  `PhoneDetails`,
  `Price`,
  `ProductFeature`,
  `ProductFeatureGroup`,
  `ProductRecommendationItem`,
  `Query`,
  `RadiobuttonFilterComponent`,
  `ReasonsOfConfidence`,
  `ResizedImage`,
  `ResortTopHotelsComponent`,
  `RoomType`,
  `SearchControlComponent`,
  `Shift`,
  `SingleOrlItem`,
  `SingleValueFilterComponent`,
  `SliderFilterComponent`,
  `SrlContext`,
  `SrlEmptyResult`,
  `SrlGeoGroupItem`,
  `SrlGeoGroupResult`,
  `SrlGeoItem`,
  `SrlHistoryResponse`,
  `SrlMapGeoPin`,
  `SrlMapPinsComponent`,
  `SrlMapProductPin`,
  `SrlProductItem`,
  `SrlRecommendationGroupComponent`,
  `SrlResultContext`,
  `SrlSearchControlsContext`,
  `SrlSingleResult`,
  `SrlSortComponent`,
  `SrlSubGeoFilterComponent`,
  `SrlSubGeoItem`,
  `StaticContext`,
  `StaticRecommendationGroup`,
  `TextComponent`,
  `TextMenuItem`,
  `ThemeContext`,
  `ThemeOverviewContext`,
  `ThemeOverviewPage`,
  `ThemePageRecommendationGroup`,
  `ThemePreviewComponent`,
  `ThemeRecommendationItem`,
  `ThemeStaticContent`,
  `TransferDate`,
  `TransferDetailInfo`,
  `TransferFlightInfo`,
  `TransferHotelInfo`,
  `TransferInfo`,
  `TravelCompanion`,
  `TravelComponentResponse`,
  `TravelDestination`,
  `Traveller`,
  `TravellerInfo`,
  `Travellers`,
  `TravelPeriodComponent`,
  `TripAdvisorRating`,
  `TripAdvisorReview`,
  `TripAdvisorSubrating`,
  `UserFinalizationResponse`,
  `UserParamsFromTokenResponse`,
  `UserRegistrationResponse`,
  `UspBox`,
  `UspBoxesComponent`,
  `VideoMediaItem`,
  `WaitingScreen`,
  `Wishlist`,
  `WishlistActiveComponent`,
  `WishlistAddMultipleToWishlistResponse`,
  `WishlistAddToWishlistResponse`,
  `WishlistContext`,
  `WishlistMutationResponse`,
  `WishlistOffer`,
  `WishlistOfferRoom`,
  `WishlistOverviewComponent`,
  `WishlistProduct`,
  `WishlistProductItem`,
] as const;

type TypeNames = typeof possibleTypeNames[number];

// add default behaviour to all types
const possibleTypePolicies: Record<TypeNames, TypePolicy> =
  possibleTypeNames.reduce(
    (policies, policyType) => ({
      ...policies,
      [policyType]: { keyFields: false, merge: true },
    }),
    {} as any
  );

function assign<K extends TypeNames>(typeName: K, policy: TypePolicy) {
  Object.assign(
    (possibleTypePolicies[typeName] = possibleTypePolicies[typeName] || {}),
    policy
  );
}

const ctxKeyArgs = ['context', ['language']];

const autoCompleteMerge: FieldMergeFunction<AutocompleteComponent> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  return {
    ...existing,
    page: incoming.page,
    destinations: existing.destinations.concat(incoming.destinations),
  };
};

assign('Query', {
  fields: {
    components: {
      keyArgs: [...ctxKeyArgs, 'input', ['currentUri', 'pageType', 'id']],
    },
    cookieDisclaimer: { keyArgs: [...ctxKeyArgs] },
    home: { keyArgs: [...ctxKeyArgs] },
    newsArticlesOverview: { keyArgs: [...ctxKeyArgs], merge: true },
    newsArticlePage: { keyArgs: [...ctxKeyArgs, 'id'] },
    pageFooter: { keyArgs: [...ctxKeyArgs] },
    pageHeader: { keyArgs: [...ctxKeyArgs] },
    pageNotFound404: { keyArgs: [...ctxKeyArgs] },
    static: { keyArgs: [...ctxKeyArgs, 'url'], merge: true },
    wishlist: { keyArgs: [...ctxKeyArgs] },
    wishlistItem: (_, { args: { id }, cache }) => {
      return cache.readFragment({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });
    },
  },
});

assign('HomeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('GeoContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('SrlContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('ThemeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('SrlBD4TravelRecommendationsComponent', {
  fields: {
    BD4TravelRecommendationAccordion: {
      keyArgs: ['currentUri', 'encodedCriteria'],
    },
    BD4TravelRecommendationBasic: {
      keyArgs: ['currentUri', 'encodedCriteria'],
    },
  },
});

assign('SrlProductItem', {
  keyFields: ['offerId'],
  fields: {
    wishlistItem(_, { cache, readField }) {
      const id = readField(`offerId`);
      const wishlistItem = cache.readFragment<WishlistItemFragment>({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });

      return wishlistItem && 'id' in wishlistItem
        ? wishlistItem
        : {
            __typename: `WishlistItem`,
            id,
            inWishlist: readField(`inWishlist`),
          };
    },
  },
});

assign('WishlistContext', {
  fields: {
    activeOffer: {
      keyArgs: ['productId', 'searchCriteria'],
    },
    shareUrl: {
      keyArgs: ['wishlistId'],
    },
  },
});

assign('WishlistOffer', {
  fields: {
    wishlistItem: (_, { cache, readField }) => {
      const id = readField(`id`);
      const wishlistItem = cache.readFragment<WishlistItemFragment>({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });

      return wishlistItem && 'id' in wishlistItem
        ? wishlistItem
        : {
            __typename: `WishlistItem`,
            id,
            inWishlist: true,
          };
    },
  },
});

export const apolloTypePolicies: TypePolicies = { ...possibleTypePolicies };
