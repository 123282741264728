import { TravelType } from '@hotelplan/graphql.types';
import type { TFunction } from 'i18next';

export default function mapTravelTypeToOfferTravelType(
  travelType: TravelType,
  t: TFunction
): string {
  return travelType === TravelType.Package
    ? t('common:offer.travelType.package')
    : t('common:offer.travelType.hotel');
}
