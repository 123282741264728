import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionMode,
} from '@hotelplan/components.common.accordion';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { TravelDestinationType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { CountryGroupItemFragment } from 'graphql/srl/GetSRLDestinationGroups.generated';
import { TDestination } from './TravelDestination.types';

const CheckboxesWrapper = styled.div(
  sx2CssThemeFn({
    width: ['100%', '210px'],
    span: {
      ml: 0,
    },
    '.accordionButton': {
      width: '100%',
      color: ['darkGrey', 'black'],
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      py: 2,
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: 'default',
      backgroundColor: '#f7f7f7',
      pl: 1,
      '.icon': {
        mr: 2,
        width: '16px',
        height: '16px',
      },
    },
    '.accordionButton.active': {
      '.icon': {
        transform: 'none',
      },
    },
    '.accordionContent': {
      '.label': {
        p: 0,
        m: 0,
        pt: 3,
        pb: 2,
      },
      '.cities': {
        m: 0,
        '.label': {
          p: 0,
          m: 0,
          py: 2,
        },
      },
    },
    '.accordionButtonWrap': {
      p: 0,
    },
  })
);

const CountryCheckbox = styled(Checkbox)(
  sx2CssThemeFn({
    mb: 2,
  })
);
const CityCheckbox = styled(Checkbox)(
  sx2CssThemeFn({
    ml: 4,
    maxWidth: '220px',
  })
);

interface ICheckboxCountryGroupProps {
  country: CountryGroupItemFragment;
  checked: TDestination[];
  onChangeDestinations(
    nextValue: string,
    currentCountry: TDestination,
    currentCities: TDestination[]
  ): void;
}

export default function CountryGroupCheckboxes(
  props: ICheckboxCountryGroupProps
): React.ReactElement {
  const { mobile } = useDeviceType();

  return (
    <CheckboxesWrapper>
      {mobile ? (
        <Accordion mode={AccordionMode.MULTIPLE}>
          <AccordionItem
            title={props.country.name}
            customIcon={{
              activeIcon: 'minus',
              inactiveIcon: 'plus',
            }}
          >
            <CheckboxesArea {...props} />
          </AccordionItem>
        </Accordion>
      ) : (
        <CheckboxesArea {...props} />
      )}
    </CheckboxesWrapper>
  );
}

function CheckboxesArea(props: ICheckboxCountryGroupProps): React.ReactElement {
  const { country, checked, onChangeDestinations } = props;

  const { mobile } = useDeviceType();
  const [t] = useTranslation('search');

  const anyRegion = t('any.region');

  function handleOnChange(nextValue: string) {
    onChangeDestinations(
      nextValue,
      {
        ...country,
        type: TravelDestinationType.Country,
      },
      country.destinations.map(city => ({
        ...city,
        type: TravelDestinationType.Destination,
      }))
    );
  }

  return (
    <>
      <CountryCheckbox
        value={country.id}
        checked={checked.some(d => d.id === country.id)}
        onChange={handleOnChange}
        content={
          <div>
            <span className={'country-name'}>
              {mobile ? anyRegion : country.name}
            </span>
            <span
              className={'product-count'}
            >{`(${country.productCount})`}</span>
          </div>
        }
      />
      <div className={'cities'}>
        {country.destinations.map(({ id, name, productCount: citiesCount }) => {
          if (id === country.id) return null;
          return (
            <CityCheckbox
              key={id}
              value={id}
              checked={checked.some(d => d.id === id)}
              onChange={handleOnChange}
              content={
                <>
                  <span className={'city-name'}>{name}</span>
                  <span className={'cities-count'}>{`(${citiesCount})`}</span>
                </>
              }
            />
          );
        })}
      </div>
    </>
  );
}
