import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import LoginLink from 'components/domain/wishlist/components/LoginLink/LoginLink';
import { AddWishlistCardWrapper } from './AddNewWishlist.styled';

interface IAddNewWishlistSectionProps {
  isAuthenticated?: boolean;
  addNewWishlist?(name: string): Promise<void>;
}

export default function AddNewWishlistSection({
  isAuthenticated,
  addNewWishlist,
}: IAddNewWishlistSectionProps): React.ReactElement {
  const addIcon = 'plus';

  return (
    <AddWishlistCardWrapper>
      {!isAuthenticated ? (
        <LoginLink iconName={addIcon} />
      ) : (
        <Button
          icon={{ name: addIcon }}
          className={'add-wishlist-btn'}
          onClick={function () {
            addNewWishlist('');
          }}
        />
      )}
    </AddWishlistCardWrapper>
  );
}
