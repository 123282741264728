import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';
import { useWishlistSelectedItemsContext } from 'components/domain/wishlist/context';
import useSRLRoute from 'components/domain/wishlist/hooks/useSRLRoute';
import {
  COMPARE_ITEMS_COUNT_DESKTOP,
  COMPARE_ITEMS_COUNT_MOBILE,
} from 'components/domain/wishlist/utils';
import {
  EmptySectionMessageWrapper,
  EmptySectionWrapper,
} from './EmptySection.styled';

interface IEmptySectionProps {
  showMessage?: boolean;
}

export default function EmptySection({
  showMessage,
}: IEmptySectionProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('wishlist');
  const { items } = useWishlistSelectedItemsContext();
  const searchRouterProps = useSRLRoute();

  const compareItemsCount = mobile
    ? COMPARE_ITEMS_COUNT_MOBILE
    : COMPARE_ITEMS_COUNT_DESKTOP;

  const canShowNotSelectedInfo = !items || items.length < compareItemsCount;

  return (
    <EmptySectionWrapper>
      {canShowNotSelectedInfo && showMessage ? (
        <EmptySectionMessageWrapper>
          <h4 className={'message-title'}>
            {t('wishlist:empty.message.title')}
          </h4>
          <p className={'message-text'}>{t('wishlist:empty.message.text')}</p>
          <RouterLink {...searchRouterProps} passHref>
            <PrimaryButtonLink>
              {t('wishlist:empty.message.link')}
            </PrimaryButtonLink>
          </RouterLink>
        </EmptySectionMessageWrapper>
      ) : null}
    </EmptySectionWrapper>
  );
}
