import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef } from 'react';
import {
  IPriceRangeInputCustomConfig,
  PriceRangeInput,
  useGetSingleRangeInputTooltip,
} from '@hotelplan/components.common.double-range';
import { useField } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { calcFormFieldInputValue } from 'components/domain/srl/CurrentSearchContext';
import { priceOptions } from './Filters.common';
import { MaxPriceFilterWrap } from './Filters.styles';
import { ICommonFilterProps } from './Filters.types';
import { mapPriceToSliderValue } from './Filters.utils';
import SearchFilterField from './SearchFilterField';

type IMaxPriceFilterFieldProps = Omit<ICommonFilterProps, 'options'>;

export default function MaxPriceFilterField({
  filterTitle,
  filterCaption,
  formFieldName,
  className,
  icon,
}: IMaxPriceFilterFieldProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');
  const [value, onChange] = useField<string | null>(formFieldName);
  const [price, setPrice] = React.useState<string | null>(value);
  const [sliderValues, setSliderValues] = React.useState<readonly number[]>(
    mapPriceToSliderValue(+price, priceOptions)
  );

  const textInputPlaceholderRef = useRef<string>(updatePlaceholder());

  function updatePlaceholder() {
    return calcFormFieldInputValue(formFieldName, price, t) || filterCaption;
  }

  function handleOnApply(): void {
    textInputPlaceholderRef.current = updatePlaceholder();
    onChange(price);
  }

  useEffect(
    // On mobile form state should be updated on every price change
    function updateFormState() {
      if (mobile) {
        handleOnApply();
      }
    },
    [price]
  );

  const getTooltip = useGetSingleRangeInputTooltip();

  const customConfig: IPriceRangeInputCustomConfig = {
    onUpdate: (options, nextValues) => {
      setSliderValues(nextValues);
      const optionsIndex = nextValues[0] ? nextValues[0] / 10 : 0;
      const nextValue = options[optionsIndex].value?.toString() || null;
      setPrice(nextValue);
    },
    step: 10,
    movingTooltip: false,
    customPriceOptions: priceOptions,
    sliderValues,
    sliderDomain: [0, 90],
  };

  return (
    <MaxPriceFilterWrap>
      <SearchFilterField
        filterTitle={filterTitle}
        textInputPlaceholder={textInputPlaceholderRef.current}
        className={className}
        onApply={handleOnApply}
        icon={icon}
      >
        <PriceRangeInput
          value={+price || null}
          min={0}
          onChange={() => noop}
          getTooltip={getTooltip}
          customConfig={customConfig}
        />
      </SearchFilterField>
    </MaxPriceFilterWrap>
  );
}
