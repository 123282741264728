import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { useSRLRoute } from 'components/domain/wishlist/hooks';
import {
  EmptyWishlistCTALink,
  EmptyWishlistWrapper,
} from './EmptyWishlist.styled';

interface IEmptyWishlistProps {
  className?: string;
}

export default function EmptyWishlist({
  className,
}: IEmptyWishlistProps): React.ReactElement {
  const [t] = useTranslation('wishlist');
  const searchRouterProps = useSRLRoute();

  return (
    <EmptyWishlistWrapper className={className}>
      <h3 className="text">{t('empty.title')}</h3>
      <h4 className="text">{t('empty.description')}</h4>
      <RouterLink {...searchRouterProps} passHref>
        <EmptyWishlistCTALink>{t('empty.cta')}</EmptyWishlistCTALink>
      </RouterLink>
    </EmptyWishlistWrapper>
  );
}
