import { getNavigationLinks } from '@hotelplan/components.common.menu';
import { IconMenuItem, Navigable } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { IconMenuEntryFragment } from 'graphql/fragments/IconMenuEntry.generated';
import { useGetFooterQuery } from 'graphql/layout/GetFooter.generated';

export const useGetFooterData = () => {
  const pageType = usePageType();

  return useGetFooterQuery({
    notifyOnNetworkStatusChange: true,
    skip: !pageType,
  });
};

const usePageFooter = () => {
  const { data: footerData, loading } = useGetFooterData();

  const navMenu = footerData?.pageFooter.navigationMenu || [];
  const navLinks = getNavigationLinks(navMenu as Navigable[]);
  return {
    loading,
    navigationMenu: navLinks,
    paymentMethods: (footerData?.pageFooter.paymentMethods ||
      []) as IconMenuEntryFragment[],
    brandLogos: (footerData?.pageFooter.brandLogos || []) as IconMenuItem[],
    socialMedia: (footerData?.pageFooter.socialMedia ||
      []) as IconMenuEntryFragment[],
  };
};

export default usePageFooter;
