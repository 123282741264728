import { useTranslation } from 'next-i18next';
import React from 'react';
import { LoginButtonLink } from '@hotelplan/components.common.auth';
import {
  OverwriteButton,
  SharingModal,
  SharingModalBody,
  SharingModalControls,
} from './WishlistShareContent.styled';

interface IWishlistSharingModalProps {
  onOverwrite(): void;
  onClose(): void;
}

export default function WishlistSharingModal({
  onOverwrite,
  onClose,
}: IWishlistSharingModalProps): React.ReactElement {
  const [t, { language }] = useTranslation('wishlist');

  return (
    <SharingModal
      show={true}
      name={'wishlist-sharing-modal'}
      title={t('wishlist:overwrite.modal.title')}
      onClose={onClose}
      controls={
        <SharingModalControls>
          <OverwriteButton onClick={onOverwrite}>
            {t('wishlist:overwrite.modal.replace')}
          </OverwriteButton>
          <LoginButtonLink language={language}>
            {t('wishlist:overwrite.modal.cta')}
          </LoginButtonLink>
        </SharingModalControls>
      }
    >
      <SharingModalBody>
        <div className="sharing-text">{t('wishlist:overwrite.modal.text')}</div>
      </SharingModalBody>
    </SharingModal>
  );
}
