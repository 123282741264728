import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { ISRLFormState } from 'components/domain/srl/SRL.types';
import useUpdateSearchState from 'components/domain/srl/useUpdateSearchState';
import ActionButtons from './MobileSearchControlActionButtons';
import {
  SearchControlFields,
  SearchControlFormHeader,
  SearchControlFormWrapper,
  SearchControlTitle,
} from './SearchControlFormLayout.styled';
import { ISearchControlFormLayoutProps } from './SearchControlFormLayout.types';
import SearchControlInfoIcon from './SearchControlInfoIcon';

export default function SearchControlFormLayout({
  travelTypes,
  travelDates,
  travelRooms,
  travelDestination,
  submitButton,
  filters,
  className,
  customConfig,
  resetForm,
  closeMobileOverlay,
}: ISearchControlFormLayoutProps): React.ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();

  const { values } = useFormContext<ISRLFormState>();
  const { updateSearchState } = useUpdateSearchState();

  const searchControlTitle = mobile
    ? t('search.control.tooltip.title')
    : t('search.control.title');

  function handleApplyForm() {
    updateSearchState(values, closeMobileOverlay);
  }

  const actionButtons =
    mobile && !customConfig?.hideActionButton ? (
      <ActionButtons
        onApply={handleApplyForm}
        onReset={() => {
          closeMobileOverlay?.();
          resetForm?.();
        }}
      />
    ) : null;

  const { headerComponent } = customConfig || {};
  const formHeaderComponent = headerComponent || (
    <>
      {!mobile ? (
        <SearchControlFormHeader>
          <SearchControlTitle>{searchControlTitle}</SearchControlTitle>
          <SearchControlInfoIcon />
        </SearchControlFormHeader>
      ) : (
        <>
          {customConfig?.showMobileTitle ? (
            <SearchControlFormHeader>
              <SearchControlTitle>{searchControlTitle}</SearchControlTitle>
            </SearchControlFormHeader>
          ) : null}
        </>
      )}
    </>
  );

  return (
    <>
      {actionButtons}
      <SearchControlFormWrapper className={className}>
        {formHeaderComponent}
        <SearchControlFields className="fields">
          {travelTypes ? (
            <div className="travelTypes">{travelTypes}</div>
          ) : null}
          {travelDestination ? (
            <div className="travel-destination">{travelDestination}</div>
          ) : null}
          {travelDates ? (
            <div className="travelDates">{travelDates}</div>
          ) : null}
          {travelRooms ? (
            <div className="travelRooms">{travelRooms}</div>
          ) : null}
          {filters ? <>{filters}</> : null}
          {submitButton ? (
            <div className="submit-button">{submitButton}</div>
          ) : null}
        </SearchControlFields>
      </SearchControlFormWrapper>
      {actionButtons}
    </>
  );
}
