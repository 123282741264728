import { useTranslation } from 'next-i18next';
import { IFilterOption } from './Filters.types';

export const ALL_FILTERS_OPTIONS_ID = 'all-filters-option';
export const ANY_ROOM_TYPE_ID = 'any-room-type';
export const ANY_BOARD_TYPE_ID = 'any-board-type';
export const HP_ANY_RATING = 'HP_ANY_RATING';
export const TA_ANY_RATING = 'TA_ANY_RATING';

export const ratingSymbols = {
  taRating: { code: 'e900', color: '#00aa6c' },
  hpRating: { code: 'e901', color: '#f60' },
};

type RatingDictType = { [key: string]: number };

export const ratingsIdsToNumberDict: RatingDictType = {
  HP_ANY_RATING: 0,
  HP_MIN_6_STARS: 6,
  HP_MIN_5_STARS: 5,
  HP_MIN_4_STARS: 4,
  HP_MIN_3_STARS: 3,
  HP_MIN_2_STARS: 2,
  HP_MIN_1_STAR: 1,
  TA_ANY_RATING: 0,
  TA_MIN_5_STARS: 5,
  TA_MIN_4_STARS: 4,
  TA_MIN_3_STARS: 3,
  TA_MIN_2_STARS: 2,
  TA_MIN_1_STAR: 1,
};

export type TPriceOption = {
  caption: string;
  value: number;
};

export const priceOptions: Array<TPriceOption> = [
  { caption: `CHF 50.-`, value: 50 },
  { caption: `CHF 200.-`, value: 200 },
  { caption: `CHF 400.-`, value: 400 },
  { caption: `CHF 600.-`, value: 600 },
  { caption: `CHF 800.-`, value: 800 },
  { caption: `CHF 1000.-`, value: 1000 },
  { caption: `CHF 1500.-`, value: 1500 },
  { caption: `CHF 2000.-`, value: 2000 },
  { caption: `CHF 2500.-`, value: 2500 },
  { caption: `Any`, value: null },
];

type MainAmenityDescriptionType = {
  [id: string]: {
    description: string;
    title: string;
  };
};

export function useMainAmenitiesDescription(
  amenities: IFilterOption[]
): MainAmenityDescriptionType {
  const { t } = useTranslation('filters');
  const result = {} as MainAmenityDescriptionType;

  amenities.forEach(({ id, caption }) => {
    result[id] = {
      description: t(`${id}.description`),
      title: caption,
    };
  });

  return result;
}

export const subAmenitiesIconsDictionary: { [key: string]: string } = {
  childrenFeatures: 'familienferien',
  beachFeatures: 'beachholidays',
  cultureFeatures: 'culture',
  adultsFeatures: 'adults-only',
  wlanFeatures: 'wlan',
  sustainableFeatures: 'nachhaltigkeit',
  nationalParkFeatures: 'nationalpark',
  resortNatureFeatures: 'natur',
  resortFlairFeatures: 'flair',
  resortShoppingFeatures: 'shopping',
  restaurantFeatures: 'utensils-solid',
  petsFeatures: 'pets',
  luxuryFeatures: 'luxus',
  clubHotelsFeatures: 'clubhotel',
  ECarChargingFeatures: 'e-car',
  winterSportFeatures: 'wintersport',
  summerSportFeatures: 'sport',
  waterSportFeatures: 'wassersport',
  wellnessFeatures: 'beautywellness',
  cityFeatures: 'hotel',
  nightlifeFeatures: 'nightlife',
  hotelFeatures: 'hotel-amenities',
  hotelEntertainmentFeatures: 'unterhaltung',
};

export const SUB_AMENITIES_LIST = [
  'childrenFeatures',
  'beachFeatures',
  'cultureFeatures',
  'adultsFeatures',
  'wlanFeatures',
  'sustainableFeatures',
  'nationalParkFeatures',
  'resortNatureFeatures',
  'resortFlairFeatures',
  'resortShoppingFeatures',
  'restaurantFeatures',
  'petsFeatures',
  'luxuryFeatures',
  'clubHotelsFeatures',
  'ECarChargingFeatures',
  'winterSportFeatures',
  'summerSportFeatures',
  'waterSportFeatures',
  'wellnessFeatures',
  'cityFeatures',
  'nightlifeFeatures',
  'hotelFeatures',
  'hotelEntertainmentFeatures',
];
