import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BrandLogos } from '@hotelplan/components.common.brands-logos';
import { FooterCopyright } from '@hotelplan/components.common.footer';
import { IMenuLink } from '@hotelplan/components.common.menu';
import { IconMenuItem } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { track } from '@hotelplan/libs.tracking-events';
import { Container } from 'components/core/container';
import {
  FeedbackMobile,
  FeedbackModal,
  FeedbackSiteTool,
  jotformLinks,
} from 'components/domain/feedback-box';
import { useFooterConfig } from './FooterConfig.context';
import FooterMenu from './FooterMenu';
import NewsletterMobileBlock from './NewsletterMobileBlock';
import usePageFooter from './usePageFooter';

const FooterWrapper = styled.footer(
  sx2CssThemeFn({
    background: ['white', 'linear-gradient(to bottom,#fff 0,#ddd 100%)'],
    boxShadow: '0 -1px 5px rgb(50 50 50 / 30%)',
    '.social-media-title': {
      fontSize: '0px',
      width: '0px',
      height: '0px',
      p: '0px',
    },
  })
);

const FooterBottomWrapper = styled.div(
  sx2CssThemeFn({
    pt: [null, 2],
    pb: 2,
    backgroundColor: [null, '#ccc'],
    borderTop: '1px solid #c5c5c5',
    textAlign: ['center', 'left'],
    '.footer-copyright': {
      fontSize: ['10px', '13px'],
      color: 'inherit',
    },
  })
);

const FooterTop = styled(Container)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: [null, 'space-between'],
    alignItems: 'flex-end',
    padding: ['10px 0px 4px', '0px 15px 8px'],
    boxShadow: ['rgb(50 50 50 / 50%) 0px -6px 5px -5px', 'none'],
    '.sitetool-wrap': {
      position: 'relative',
      overflow: 'unset',
      mx: ['auto', 0],
      borderRadius: ['0px', '0 0 5px 5px'],
      border: ['none', '1px solid #ccc'],
      borderRight: ['none', 'none'],
      borderTop: ['none', 'none'],
      borderBottom: '1px solid #ccc',
      boxShadow: ['none', 'none'],
      width: ['100%', 'auto'],
      '.sitetool-item': {
        borderLeft: [null, '1px solid #ccc'],
        borderRight: [null, '1px solid #ccc'],
        borderRadius: '0 0 5px 0',
        width: ['30px', '50px'],
        height: ['30px', '40px'],
        background: ['none', 'linear-gradient(to bottom,#fff 0,#eee 100%)'],
        ml: [3, 0],
        '&:after': {
          top: ['0px', '5px'],
          left: ['0px', '9px'],
        },
      },
      '.sitetool-overlay': {
        top: 'unset',
        bottom: '45px',
        width: '375px',
      },
    },
    '.social-media-list': {
      p: 0,
    },
  })
);

const FooterSiteTools = dynamic<{ socials: Array<IconMenuItem> }>(
  () => import('./FooterSiteTools'),
  {
    ssr: false,
  }
);

interface IFooterProps {
  menuLinks?: IMenuLink[];
}

const Footer: React.FC<IFooterProps> = () => {
  const {
    navigationMenu: links,
    brandLogos,
    socialMedia: socials,
  } = usePageFooter();

  const { mobile } = useDeviceType();
  const [, { language }] = useTranslation();
  const { hideNewsletterBlock } = useFooterConfig();

  const [isShowFeedbackModal, showFeedbackModal, hideFeedbackModal] =
    useToggleState(false);

  function handleFeedbackClick() {
    showFeedbackModal();
    track({
      event: `🚀 click.feedback`,
      eventMetadata: {
        source: `footer`,
      },
      _clear: true,
    });
  }

  return (
    <>
      <FooterWrapper>
        {mobile && !hideNewsletterBlock ? <NewsletterMobileBlock /> : null}
        {mobile ? <FeedbackMobile onClick={handleFeedbackClick} /> : null}
        <FooterTop>
          <FooterSiteTools socials={socials}>
            {!mobile ? (
              <FeedbackSiteTool onClick={handleFeedbackClick} />
            ) : null}
          </FooterSiteTools>
          <FooterMenu links={links} />
        </FooterTop>
        {!mobile ? (
          <Container>
            <BrandLogos brandLogos={brandLogos} />
          </Container>
        ) : null}
        <FooterBottomWrapper>
          <Container>
            <FooterCopyright copyright={''} />
          </Container>
        </FooterBottomWrapper>
      </FooterWrapper>
      <FeedbackModal
        show={isShowFeedbackModal}
        href={jotformLinks[language]}
        close={hideFeedbackModal}
      />
    </>
  );
};

export default Footer;
