import {
  WishlistFiltersCriteriaInput,
  WishlistProductItem,
  WishlistSearchControlCriteriaInput,
} from '@hotelplan/graphql.types';
import { AddToActiveWishlistWithSourceMutation } from 'graphql/wishlist/mutations/AddToActiveWishlistWithSource.generated';
import { RemoveFromActiveWishlistMutation } from 'graphql/wishlist/mutations/RemoveFromActiveWishlist.generated';

export type TPossibleMutation =
  | AddToActiveWishlistWithSourceMutation
  | RemoveFromActiveWishlistMutation;

export interface IWishlistOfferManaging {
  update(offerId: string, next: boolean): void;
  select(offerId: string): void;
  deselect(offerId: string): void;
}

export enum WishlistTabs {
  List = 'wishlist-list',
  Compare = 'wishlist-compare',
  Map = 'wishlist-map',
}

export interface IWishlistUrlQueryVariables {
  searchControl: WishlistSearchControlCriteriaInput;
  filters: WishlistFiltersCriteriaInput;
}

export type SelectedProductItems = (WishlistProductItem | null)[];
