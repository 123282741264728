import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const LoginLinkOverlayWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    bg: 'white',
    borderRadius: ['none', '5px 5px 0 0'],
    p: [2, 5],
    pt: [2, 0],
    pr: [2, 0],
    '.auth-buttons': {
      display: 'flex',
      justifyContent: 'end',
      mb: [2, 4],
    },
    '.content': {
      pr: [null, 5],
    },
  })
);

const AccountLink = styled(Link)<{ active?: boolean }>(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'borderGray',
    borderRadius: '0px 5px 0px 0px',
    background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
    color: 'textGray',
    fontSize: 0,
    fontFamily: 'Arial',
    px: 4,
    py: 2,
  }),
  ({ active, theme: { colors } }) => ({
    color: active ? colors.primary : undefined,
  })
);

export const LogoutButton = AccountLink.withComponent(
  styled(Button)(
    sx2CssThemeFn({
      borderRight: ['1px solid', 'none'],
      borderTop: ['1px solid', 'none'],
      borderRadius: '0px 5px 0px 0px',
      fontWeight: 'bold',
      color: 'textGray',
      ml: 3,

      '.icon': {
        ml: 2,
      },

      '&:hover, &:focus': {
        color: 'primary',
        textDecoration: 'none',
      },
    })
  )
);

export const LoginButton = styled(Link)(
  sx2CssThemeFn({
    border: '1px solid',
    borderColor: 'borderGray',
    background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
    color: 'textGray',
    fontSize: 0,
    fontFamily: 'Arial',
    px: 4,
    py: 2,
    borderTop: [null, 'none'],
    borderRight: [null, 'none'],
    borderRadius: [null, '0px 5px 0px 0px'],
    fontWeight: 'bold',
  })
);

export const LogoutSectionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    '.user-data': {
      display: 'flex',
      flexDirection: ['column', 'row'],
      alignItems: ['flex-end', 'center'],
      '.email': {
        fontSize: '14px',
      },
      '.user-account': {
        fontSize: '14px',
        fontWeight: 700,
        mr: [0, 2],
        '&:after': {
          content: "':'",
        },
        '&:before': {
          content: "'\\1F464'",
          mr: 1,
        },
      },
    },
  })
);
