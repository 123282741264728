import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { languageList } from '@hotelplan/libs.language';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import useLanguage from './useLanguage';

const LanguageControlWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    fontSize: [null, '13px'],
    textTransform: 'uppercase',
    margin: ['10px', 0],
    letterSpacing: '1px',
    '.lang-link:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  })
);

const Language = styled(Link)(({ theme: { space, colors } }) =>
  sx2CssThemeFn({
    display: ['block', 'flex'],
    px: [space[1], '5px'],
    py: ['10px', 0],
    position: 'relative',
    color: ['primary', 'textGray'],
    border: ['1px solid #bbb', 'none'],
    flex: [1, 0],
    textAlign: 'center',
    backgroundImage: ['linear-gradient(#fff,#e7e7e7)', 'none'],
    fontWeight: ['bold', 'normal'],
    '&:first-child': {
      borderRadius: ['5px 0 0 5px', '0px'],
      borderRight: '0px',
    },
    '&:not(:first-child)': {
      borderRadius: '0 5px 5px 0',
    },
    '&:last-child:before': {
      content: [null, '"|"'],
      mr: '8px',
      position: 'relative',
      top: '-1px',
    },
    '&.active': {
      color: colors.primary,
      fontWeight: 'bold',
      backgroundImage: 'none',
      backgroundColor: ['#e6e6e6', '#ffffff'],
    },
    '&:hover': {
      color: colors.primary,
    },
  })
);

const LanguageControl: React.FC = () => {
  const [language, changeLanguage] = useLanguage();

  function handleChangeLanguage(event: React.MouseEvent) {
    const nextLang = event.currentTarget.getAttribute(`data-lang`) || 'de';
    changeLanguage(nextLang);
  }

  return (
    <LanguageControlWrapper>
      {languageList.map(lang => {
        return (
          <Language
            key={`language-${lang}`}
            className={lang === language ? 'active lang-link' : 'lang-link'}
            data-lang={lang}
            onClick={handleChangeLanguage}
          >
            {lang}
          </Language>
        );
      })}
    </LanguageControlWrapper>
  );
};

export default LanguageControl;
