import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import {
  WishlistOfferFragmentDoc,
  WishlistCheckoutOfferFragmentDoc,
} from './WishlistOfferFragment.generated';
import { WishlistProductFragmentDoc } from './WishlistProductFragment.generated';
export type WishlistProductItemFragment = {
  __typename?: 'WishlistProductItem';
  type: Types.WishlistProductItemType;
  source?: string | null;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  offer: {
    __typename?: 'WishlistOffer';
    id: string;
    duration: number;
    returnDate: string;
    travelType: Types.TravelType;
    departureDate: string;
    transferIncluded: boolean;
    waitingScreenImage?: string | null;
    rooms: Array<{
      __typename?: 'WishlistOfferRoom';
      adults: number;
      children: number;
      boardCode: string;
      boardDescription: string;
      roomCode: string;
      roomDescription: string;
    }>;
    inboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    outboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  };
  product: {
    __typename?: 'WishlistProduct';
    id: string;
    giataId: number;
    name: string;
    zoom: number;
    taRating?: number | null;
    hpRating: number;
    taReviews?: number | null;
    productClass: Types.ProductClass;
    travelType?: string | null;
    fromTo?: string | null;
    coordinates: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    };
    breadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    includedInPrice?: {
      __typename?: 'TextComponent';
      title: string;
      text: string;
    } | null;
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    featureGroups: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  };
  changedOffer?: {
    __typename?: 'WishlistOffer';
    id: string;
    duration: number;
    returnDate: string;
    travelType: Types.TravelType;
    departureDate: string;
    transferIncluded: boolean;
    waitingScreenImage?: string | null;
    rooms: Array<{
      __typename?: 'WishlistOfferRoom';
      adults: number;
      children: number;
      boardCode: string;
      boardDescription: string;
      roomCode: string;
      roomDescription: string;
    }>;
    inboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    outboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  } | null;
};

export type WishlistCheckoutProductItemFragment = {
  __typename?: 'WishlistProductItem';
  type: Types.WishlistProductItemType;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  offer: {
    __typename?: 'WishlistOffer';
    id: string;
    duration: number;
    returnDate: string;
    travelType: Types.TravelType;
    departureDate: string;
    transferIncluded: boolean;
    waitingScreenImage?: string | null;
    provider?: string | null;
    productCode?: string | null;
    flightAlternativesAvailable?: boolean | null;
    rooms: Array<{
      __typename?: 'WishlistOfferRoom';
      adults: number;
      children: number;
      boardCode: string;
      boardDescription: string;
      roomCode: string;
      roomDescription: string;
    }>;
    inboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    outboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  };
  changedOffer?: {
    __typename?: 'WishlistOffer';
    id: string;
    duration: number;
    returnDate: string;
    travelType: Types.TravelType;
    departureDate: string;
    transferIncluded: boolean;
    waitingScreenImage?: string | null;
    provider?: string | null;
    productCode?: string | null;
    flightAlternativesAvailable?: boolean | null;
    rooms: Array<{
      __typename?: 'WishlistOfferRoom';
      adults: number;
      children: number;
      boardCode: string;
      boardDescription: string;
      roomCode: string;
      roomDescription: string;
    }>;
    inboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    outboundFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    wishlistItem?: {
      __typename?: 'WishlistItem';
      id: string;
      inWishlist: boolean;
    } | null;
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
  } | null;
};

export const WishlistProductItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistProductItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistProductItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistProduct' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changedOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...WishlistOfferFragmentDoc.definitions,
    ...WishlistProductFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const WishlistCheckoutProductItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistCheckoutProductItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistProductItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistCheckoutOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changedOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistCheckoutOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...WishlistCheckoutOfferFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
