const { domainDE, domainFR } = require('./next.domains.config');

module.exports = {
  i18n: {
    defaultLocale: 'de',
    locales: ['de', 'fr'],
    localeDetection: false,
    domains: [
      domainDE && {
        domain: domainDE,
        defaultLocale: 'de',
        locales: ['de'],
      },
      domainFR && {
        domain: domainFR,
        defaultLocale: 'fr',
        locales: ['fr'],
      },
    ].filter(Boolean),
  },
};
