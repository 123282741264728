import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { calcFormFieldInputValue } from 'components/domain/srl/CurrentSearchContext';
import { MainFilterCounts } from './Filters.styles';
import { ICommonFilterProps, TFilterCountsOptions } from './Filters.types';
import { filterCheckboxOnApply, filterCheckboxOnChange } from './Filters.utils';
import SearchFilterCheckboxesGroup from './SearchFilterCheckboxesGroup';
import SearchFilterField from './SearchFilterField';

interface IUnionCheckboxesFilterFieldProps extends ICommonFilterProps {
  allOptionId: string;
  filterTitle: string;
  defaultValue?: string[];
  counts?: TFilterCountsOptions;
}

export default function UnionCheckboxesFilterField({
  options,
  filterCaption,
  formFieldName,
  allOptionId,
  filterTitle,
  defaultValue,
  className,
  icon,
  counts,
}: IUnionCheckboxesFilterFieldProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [value, onChange] = useField<string[]>(formFieldName);

  const [checkedOptions, setCheckedOptions] = useState<string[]>(
    value.length ? value : defaultValue || [allOptionId]
  );

  function updatePlaceholder() {
    return (
      calcFormFieldInputValue(formFieldName, checkedOptions, null, options) ||
      filterCaption
    );
  }

  const textInputPlaceholderRef = useRef<string>(updatePlaceholder());

  function handleOnApply(): void {
    filterCheckboxOnApply({
      checkedOptions,
      onChange,
      allOptionId,
      stayWithAllOptionId: !!defaultValue,
    });
    textInputPlaceholderRef.current = updatePlaceholder();
  }

  useEffect(() => {
    if (defaultValue?.length && !value.length) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange, value.length]);

  useEffect(
    // On mobile form state should be updated on every checkbox change
    function updateFormState() {
      if (mobile) {
        handleOnApply();
      }
    },
    [checkedOptions]
  );

  return (
    <SearchFilterField
      filterTitle={filterTitle}
      textInputPlaceholder={textInputPlaceholderRef.current}
      className={className}
      onApply={handleOnApply}
      icon={icon}
    >
      <SearchFilterCheckboxesGroup
        options={options}
        checkedOptions={checkedOptions}
        onChange={filterCheckboxOnChange({
          options,
          checkedOptions,
          setCheckedOptions,
          allOptionId,
        })}
        checkIfDisabled={id =>
          counts &&
          !Boolean(
            counts?.options?.find(count => count.id === id)?.productCount
          )
        }
        getCounts={filterOptionId => {
          if (!counts) return null;

          return (
            <MainFilterCounts
              options={counts.options}
              loading={counts.loading}
              filterOptionId={filterOptionId}
            />
          );
        }}
      />
    </SearchFilterField>
  );
}
