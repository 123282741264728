import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import HeaderButtonTooltip from 'components/domain/my-account/header-section/header-button-tooltip/HeaderButtonTooltip';

export const WishlistFavContainerWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
  })
);

export const WishlistFavIcon = styled(Icon)(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    right: 0,
    border: '1px solid',
    borderRight: 'none',
    borderColor: 'borderGray',
    borderRadius: '0 0 0 5px',
    background: 'linear-gradient(to bottom,#fff 0,#eee 100%)',
    width: '30px',
    height: '30px',
    p: '6px',
    color: 'textGray',
    zIndex: 1,
    cursor: 'pointer',
    '&:hover': {
      color: 'secondary',
      background: [
        'linear-gradient(to bottom,#fff 0,#eee 100%)',
        'linear-gradient(to bottom,#fff 0,#ccc 100%)',
      ],
    },
  })
);

export const WishlistButtonTooltipWrapper = styled(HeaderButtonTooltip)(
  ({ theme, theme: { colors } }) =>
    sx2CssThemeFn({
      ...theme.tooltip,
      visibility: 'visible',
      right: '38px',
      left: 'auto',
      transform: 'none',
      bg: 'textGray',
      border: 'none',
      top: '-5px',
      fontSize: '13px',
      '&:before': {
        borderColor: `${colors.textGray} transparent transparent transparent`,
      },
      '&:after': {
        content: 'none',
      },
    })
);
