import styled from 'styled-components';
import { SelectInput } from '@hotelplan/components.common.select-input';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import FilterCounts from './FilterCounts';

export const DepartureAirportsCheckboxesWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    color: 'darkGrey',
  })
);

export const CheckboxesWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    color: 'darkGrey',
    '.customCheckboxInput': {
      background: 'none',
      borderColor: 'textGray',
      mr: 2,
      ml: 0,
    },
    '.customCheckboxInput:after': {
      left: '4px',
      top: 0,
      width: '6px',
      height: '10px',
      borderWidth: '0 1px 1px 0',
    },
    'input:checked ~ .customCheckboxInput': {
      borderColor: 'textGray',
    },
    '.checkbox-text': {
      color: 'darkGrey',
    },
    '.label': {
      pl: 3,
      mb: 0,
      alignItems: 'start',
      '&:hover': {
        'input ~ .customCheckboxInput': {
          background: 'none',
        },
      },
    },
  })
);

export const DepartureAirportsWrap = styled.div(
  sx2CssThemeFn({
    '.absolute-dropdown': {
      minWidth: '480px',
    },
    '.checkboxes-area-wrapper': {
      display: [null, 'grid'],
      gridTemplateColumns: [null, '1fr 1fr'],
      '.label:first-child': {
        gridColumn: [null, 'span 2'],
        maxWidth: [null, '219px'],
      },
    },
  })
);

export const RatingFilterWrapper = styled.div(
  sx2CssThemeFn({
    '.absolute-dropdown': {
      minWidth: '300px',
      '.label': {
        width: '100%',
        '&:not(:nth-child(2n))': {
          mr: '0px',
        },
      },
    },
  })
);

export const MaxPriceFilterWrap = styled.div(
  sx2CssThemeFn({
    '.absolute-dropdown': {
      minWidth: '330px',
    },
  })
);

export const FlightOptionsWrap = styled.div(
  sx2CssThemeFn({
    '.absolute-dropdown': {
      minWidth: '330px',
    },
    '.checkboxes-area-wrapper': {
      width: '100%',
      m: 0,
      mr: [0, 4],
      '.label': {
        fontSize: ['12px', '13px'],
        width: [null, '135px'],
        ml: [null, 'auto'],
        justifyContent: 'start',
        flexDirection: 'row',
        gap: [2, 0],
        pl: 0,
        'input ~ .customCheckboxInput': {
          borderColor: '#ccc',
        },
        '.customCheckboxInput': {
          ml: [0, 2],
        },
      },
    },
    '.inputs-wrap': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: ['row', 'column'],
      gap: [2, 3],
      px: [3, 0],
      py: [2, 0],
    },
  })
);

export const TimeSelectInput = styled(SelectInput)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    mr: [0, 4],
    '.label': {
      color: 'textGray',
      fontWeight: 'bold',
      p: 0,
      m: 0,
      fontSize: ['12px', '14px'],
      flex: 1,
      mb: [1, 0],
    },
    '.input': {
      width: ['100%', '135px'],
      fontSize: 0,
      pl: 2,
      fontWeight: 'bold',
    },
    '.icon': {
      right: ['12px', '10px'],
      bottom: ['16px', '10px'],
    },
  })
);

export const FeatureTitle = styled.h4(
  sx2CssThemeFn({
    fontFamily: 'Arial',
    color: 'darkGrey',
    fontWeight: 'bold',
    fontSize: ['0px', '14px'],
    mt: [null, 2],
    ml: [null, 2],
  })
);

export const MainFilterCounts = styled(FilterCounts)(
  sx2CssThemeFn({
    position: 'static',
    ml: 'auto',
    transform: 'none',
    borderRadius: '4px',
    height: 'auto',
    mr: '1px',
    fontSize: '10px',
    span: {
      color: 'textGray',
      ml: 0,
    },
  })
);
