import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Rating } from '@hotelplan/components.common.rating';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const RatingWrapper = styled(Rating)(
  sx2CssThemeFn({
    color: 'secondary',
    gap: '2px',
    mb: [1, 0],

    '.rating .icon, .halfWayRating .icon': {
      width: ['12px', '17px'],
      height: ['12px', '17px'],
      mr: 0,
    },
  })
);

const ProductRating = ({ rating }: { rating: number }) => {
  return (
    <RatingWrapper
      maxRating={5}
      rating={rating / 10}
      ratingContent={<Icon name="star-full" />}
      halfWayRatingContent={<Icon name="star-half" />}
    />
  );
};

export default ProductRating;
