import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SrlFilterOptionsFragmentDoc } from './SRLFilterOptions.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlFilterOptionsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  searchQuery: Types.Scalars['String'];
}>;

export type GetSrlFilterOptionsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    searchControls: {
      __typename?: 'SrlSearchControlsContext';
      mainFilter: {
        __typename?: 'FilterComponent';
        departureAirports: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        maxPrice: {
          __typename?: 'SliderFilterComponent';
          caption: string;
          selected: string;
        };
        hpRating: {
          __typename?: 'RadiobuttonFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        taRating: {
          __typename?: 'RadiobuttonFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        roomTypes: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        boardTypes: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        stopOvers: {
          __typename?: 'RadiobuttonFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        directFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        returnFlightDepartureTime: {
          __typename?: 'RadiobuttonFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        mainFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        childrenFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        beachFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        cityFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        winterSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        summerSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        waterSportFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        wellnessFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        sustainableFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        hotelEntertainmentFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        hotelFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        nationalParkFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        nightlifeFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        cultureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        resortNatureFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        wlanFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        petsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        ECarChargingFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        resortFlairFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        resortShoppingFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        luxuryFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        clubHotelsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        adultsFeatures: {
          __typename?: 'CheckboxFilterComponent';
          caption: string;
          options: Array<{
            __typename?: 'FilterItem';
            id: string;
            caption: string;
          }>;
        };
        radius?: {
          __typename?: 'RadiusFilterComponent';
          label?: string | null;
          radius: number;
          center: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
        } | null;
      };
    };
  };
};

export const GetSrlFilterOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSRLFilterOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchQuery' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: { kind: 'EnumValue', value: 'PACKAGE' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SRLFilterOptions' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlFilterOptionsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetSrlFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlFilterOptionsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetSrlFilterOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlFilterOptionsQuery,
    GetSrlFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlFilterOptionsQuery,
    GetSrlFilterOptionsQueryVariables
  >(GetSrlFilterOptionsDocument, options);
}
export function useGetSrlFilterOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlFilterOptionsQuery,
    GetSrlFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlFilterOptionsQuery,
    GetSrlFilterOptionsQueryVariables
  >(GetSrlFilterOptionsDocument, options);
}
export type GetSrlFilterOptionsQueryHookResult = ReturnType<
  typeof useGetSrlFilterOptionsQuery
>;
export type GetSrlFilterOptionsLazyQueryHookResult = ReturnType<
  typeof useGetSrlFilterOptionsLazyQuery
>;
export type GetSrlFilterOptionsQueryResult = Apollo.QueryResult<
  GetSrlFilterOptionsQuery,
  GetSrlFilterOptionsQueryVariables
>;
