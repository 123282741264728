import { ITravelDestinationState } from '@hotelplan/components.common.travel-destination';
import { useSearchState } from '@hotelplan/libs.search-state';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISrlState } from 'components/domain/srl/SRL.types';
import {
  ContinentGroupItemFragment,
  useGetSrlDestinationGroupsLazyQuery,
} from 'graphql/srl/GetSRLDestinationGroups.generated';

type TGeoDestinations = {
  getDestinationGroups: (travelDestination?: ITravelDestinationState) => void;
  loading: boolean;
  continentGroups: ContinentGroupItemFragment[];
};

export default function useGeoDestination(): TGeoDestinations {
  const {
    state: { searchControl, filters },
  } = useSearchState<ISrlState>();

  const [getGeoDestinations, { data: geoDestinations, loading }] =
    useGetSrlDestinationGroupsLazyQuery();

  function getDestinations(
    defaultTravelDestinations?: ITravelDestinationState
  ) {
    getGeoDestinations({
      variables: {
        searchCriteria: {
          searchControl: mapSearchControlToCriteria({
            ...searchControl,
            travelDestination: defaultTravelDestinations || null,
          }),
          filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
          page: {
            pageNumber: 0,
          },
        },
      },
    });
  }

  const { continentGroupItems } =
    geoDestinations?.srl.search.destinationGroup || {};

  return {
    getDestinationGroups: getDestinations,
    loading,
    continentGroups: continentGroupItems,
  };
}
