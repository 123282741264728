import React, { useEffect } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { IOption } from '@hotelplan/components.common.select-input';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { calcFormFieldInputValue } from 'components/domain/srl/CurrentSearchContext';
import { FlightOptionsWrap, TimeSelectInput } from './Filters.styles';
import {
  ICommonFilterProps,
  IFlightOptionsState,
  TFlightOptions,
} from './Filters.types';
import SearchFilterCheckboxesGroup from './SearchFilterCheckboxesGroup';
import SearchFilterField from './SearchFilterField';

interface IFlightOptionsFilterFieldProps
  extends Omit<ICommonFilterProps, 'options'> {
  fieldsCaptions: string[];
  options: TFlightOptions;
}

export default function FlightOptionsFilterField(
  props: IFlightOptionsFilterFieldProps
): React.ReactElement {
  const { mobile } = useDeviceType();

  const {
    options,
    formFieldName,
    fieldsCaptions,
    filterCaption,
    filterTitle,
    className,
    icon,
  } = props;

  const [value, onChange] = useField<IFlightOptionsState>(formFieldName);
  const [flightOptionsState, setFlightOptionsState] =
    React.useState<IFlightOptionsState>(value);

  function handleOnApply(): void {
    onChange(flightOptionsState);
    textInputPlaceholderRef.current = updatePlaceholder();
  }

  function updatePlaceholder(): string {
    return (
      calcFormFieldInputValue(
        formFieldName,
        flightOptionsState,
        null,
        options as any
      ) || filterCaption
    );
  }

  const textInputPlaceholderRef = React.useRef<string>(updatePlaceholder());

  useEffect(
    // On mobile form state should be updated on every option change
    function updateFormState() {
      if (mobile) {
        handleOnApply();
      }
    },
    [flightOptionsState]
  );

  return (
    <FlightOptionsWrap>
      <SearchFilterField
        filterTitle={filterTitle}
        textInputPlaceholder={textInputPlaceholderRef.current}
        className={className}
        onApply={handleOnApply}
        icon={icon}
      >
        <div className="inputs-wrap">
          {Object.keys(options).map((flightOption, index) => {
            if (flightOption === 'stopOvers') {
              return (
                <SearchFilterCheckboxesGroup
                  key={`${flightOption}-${index}`}
                  options={options[flightOption]}
                  checkedOptions={[flightOptionsState[flightOption]]}
                  onChange={(nextValue: string) =>
                    setFlightOptionsState(prevState => ({
                      ...prevState,
                      [flightOption]:
                        prevState[flightOption] === nextValue ? '' : nextValue,
                    }))
                  }
                />
              );
            }

            const optionsList: IOption[] = options[flightOption].map(
              ({ id, caption }) => ({
                value: id,
                label: caption,
              })
            );

            return (
              <TimeSelectInput
                key={`${flightOption}-${index}`}
                label={fieldsCaptions[flightOption]}
                options={optionsList}
                value={flightOptionsState[flightOption]}
                onChange={(nextValue: string) =>
                  setFlightOptionsState(prevState => ({
                    ...prevState,
                    [flightOption]: nextValue,
                  }))
                }
              />
            );
          })}
        </div>
      </SearchFilterField>
    </FlightOptionsWrap>
  );
}
