import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ProductFeatureItemFragmentDoc } from './ProductFeatureItem.generated';
export type GroupOfProductFeatureItemsFragment = {
  __typename?: 'ProductFeatureGroup';
  id: string;
  name: string;
  features: Array<{
    __typename?: 'ProductFeature';
    id: string;
    name: string;
    enabled?: boolean | null;
  }>;
};

export const GroupOfProductFeatureItemsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductFeatureGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFeatureItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProductFeatureItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
