import { useGoogleMap } from '@react-google-maps/api';
import { useEffect } from 'react';
import {
  MapPinView,
  useMapClusterer,
} from '@hotelplan/components.common.map-pin';

const MapClustererRedraw = ({ children }) => {
  const { clusterer } = useMapClusterer();
  const map = useGoogleMap();

  useEffect(() => {
    if (!map || !clusterer) return;

    const { remove } = google.maps.event.addListener(
      clusterer,
      'clusteringend',
      () => {
        setTimeout(() => {
          clusterer.getClusters().forEach(cluster => {
            cluster.getMarkers().forEach((marker: MapPinView) => {
              marker?.draw();
            });
          });
        }, 50);
      }
    );

    return () => {
      try {
        remove();
      } catch (e) {}
    };
  }, [clusterer, map]);

  return <>{children}</>;
};

export default MapClustererRedraw;
