import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { WishlistFragmentDoc } from 'graphql/wishlist/fragments/WishlistFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetWishlistOverviewQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type GetWishlistOverviewQuery = {
  __typename?: 'Query';
  wishlist: {
    __typename?: 'WishlistContext';
    overview: {
      __typename?: 'WishlistOverviewComponent';
      status: Types.Status;
      message?: string | null;
      wishlists: Array<{
        __typename?: 'Wishlist';
        id: string;
        active: boolean;
        name: string;
        productsAmount: number;
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
      }>;
    };
  };
};

export const GetWishlistOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWishlistOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wishlists' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'wishlist' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...WishlistFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetWishlistOverviewQuery__
 *
 * To run a query within a React component, call `useGetWishlistOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishlistOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishlistOverviewQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetWishlistOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetWishlistOverviewQuery,
    GetWishlistOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetWishlistOverviewQuery,
    GetWishlistOverviewQueryVariables
  >(GetWishlistOverviewDocument, options);
}
export function useGetWishlistOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWishlistOverviewQuery,
    GetWishlistOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetWishlistOverviewQuery,
    GetWishlistOverviewQueryVariables
  >(GetWishlistOverviewDocument, options);
}
export type GetWishlistOverviewQueryHookResult = ReturnType<
  typeof useGetWishlistOverviewQuery
>;
export type GetWishlistOverviewLazyQueryHookResult = ReturnType<
  typeof useGetWishlistOverviewLazyQuery
>;
export type GetWishlistOverviewQueryResult = Apollo.QueryResult<
  GetWishlistOverviewQuery,
  GetWishlistOverviewQueryVariables
>;
