import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { TextMenuEntryFragmentDoc } from './TextMenuEntry.generated';
export type MenuEntryFragment = {
  __typename?: 'Menu';
  fontColor?: string | null;
  caption: string;
  target?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
  entries: Array<{
    __typename?: 'TextMenuItem';
    fontColor?: string | null;
    caption: string;
    iconType?: Types.IconType | null;
    link:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
  }>;
};

export const MenuEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'menuEntry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Menu' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'target' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'textMenuEntry' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
    ...TextMenuEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
