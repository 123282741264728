import omit from 'lodash/fp/omit';
import pipe from 'lodash/fp/pipe';
import { WishlistFiltersCriteriaInput } from '@hotelplan/graphql.types';
import { mapFiltersStateToFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { IFiltersSearchState } from 'components/domain/filters/Filters.types';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';

const filtersFormStateKeyToWishlistFilterCriteriaInputKey: {
  [K in keyof IFiltersSearchState]:
    | keyof WishlistFiltersCriteriaInput
    | 'flightOptions';
} = {
  roomTypes: 'roomTypes',
  boardTypes: 'boardTypes',
  hpRating: 'hotelplanRating',
  taRating: 'tripAdvisorRating',
  departureAirports: 'departureAirports',
  maxPrice: 'maxPricePerPerson',
  flightOptions: 'flightOptions',
};

export const mapWishlistFilterValuesToWishlistFiltersCriteriaInput = (
  filterState: IFiltersSearchState | null | undefined
): WishlistFiltersCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToWishlistFilterCriteriaInputKey
  );

export const mapSearchControlToWishlistCriteria = pipe(
  mapSearchControlToCriteria,
  omit(['travelDestinations'])
);
