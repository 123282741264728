import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import LogoutLink from 'components/domain/my-account/LogoutLink';

export const MyAccountHeaderButtonWrapper = styled.div<{
  isAuthenticated: boolean;
}>(({ isAuthenticated }) =>
  sx2CssThemeFn({
    position: ['static', 'relative'],
    cursor: 'pointer',
    '&:hover': {
      bg: isAuthenticated ? 'transparent' : 'black',
      borderRadius: '0 0 0 5px',
      '.icon': {
        color: 'white',
      },
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #cccccc',
    },
  })
);

export const MyAccountButton = styled(Button)<{
  isMenuOpened?: boolean;
  isAuthenticated: boolean;
}>(({ isAuthenticated, isMenuOpened, theme: { media } }) =>
  sx2CssThemeFn({
    position: ['static', 'relative'],
    px: [2, '12px'],
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'transparent',
    width: isAuthenticated ? '40px' : ['110px', '140px'],
    '&:after': {
      display: isAuthenticated ? 'block' : 'none',
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      bg: [
        isMenuOpened ? 'secondary' : 'backgroundDarkGray',
        'backgroundDarkGray',
      ],
      borderRadius: '50%',
      zIndex: 0,
    },
    '&:hover': {
      '&:after': {
        bg: [null, 'secondary'],
      },
    },
  })
);

export const MyAccountIcon = styled(Icon)(
  sx2CssThemeFn({
    width: '100%',
    height: ['17px', '40px'],
    color: 'black',
    zIndex: 1,
  })
);

export const MyAccountHeaderMenuWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    width: ['100%', 'fit-content'],
    top: ['100%', 'calc(100% - 1px)'],
    right: [0, '-1px'],
    p: ['14px', 4],
    bg: ['#f4f4f4', 'white'],
    border: '1px solid #cccccc',
    borderRadius: ['0px', '0 0 5px 5px'],
    cursor: 'default',
    zIndex: 12,
    height: ['100vh', 'auto'],
    '.greetings': {
      color: ['secondary', 'textGray'],
      fontFamily: ['"Arial", sans-serif', '"Helvetica Neue", sans-serif'],
      fontSize: ['16px', '17px'],
      fontWeight: ['bold', 'normal'],
      px: ['12px', 0],
      whiteSpace: 'nowrap',
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export const MenuItemsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: ['flex-start', 'space-between'],
    width: '100%',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: ['#BFBFBF', '#EBEBEB'],
    py: ['0px', '10px'],
    mt: '12px',
    mb: [0, '12px'],
    '.menu-item': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      py: '10px',
      px: ['12px', 0],
      cursor: 'pointer',
      fontFamily: ['"Arial", sans-serif', '"Helvetica Neue", sans-serif'],
      fontSize: ['16px', '17px'],
      fontWeight: ['bold', 'normal'],
      color: ['primary', 'textGray'],
      '&:hover': {
        bg: '#f5f5f5',
      },
      '&:not(:last-child)': {
        borderBottom: ['1px solid', 'none'],
        borderColor: '#BFBFBF',
      },
    },
  })
);

export const MenuLogoutLink = styled(LogoutLink)(
  sx2CssThemeFn({
    width: '100%',
    justifyContent: 'space-between',
    color: ['primary', 'textGray'],
    fontFamily: ['"Arial", sans-serif', '"Helvetica Neue", sans-serif'],
    fontWeight: ['bold', 'normal'],
    fontSize: ['16px', '17px'],
    py: '10px',
    px: ['12px', 0],
    borderBottom: ['1px solid', 'none'],
    borderColor: '#BFBFBF',
    '&:hover': {
      bg: '#f5f5f5',
    },
  })
);
