import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const WishlistCompareContentWrapper = styled.div(
  sx2CssThemeFn({
    '.content-wrapper': {
      flexDirection: 'row',
      px: 0,
    },
    '.empty': {
      p: ['20px 0px', 5],
    },
  })
);

export const WishlistContentWrapper = styled.div.attrs<{
  additionalClassname?: string;
}>(({ additionalClassname }) => ({
  className: `content-wrapper ${additionalClassname}`,
}))<{
  additionalClassname?: string;
}>(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'row-reverse',
    p: ['20px 0px', 5],
    bg: 'white',
  })
);

export const SRLProductsSkeletonWrap = styled.div(
  sx2CssThemeFn({
    pl: [null, '30%'],
    py: [null, 5],
    pr: [null, 5],
    backgroundColor: 'white',
  })
);
